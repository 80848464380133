export class AutorizacionDetalleDTO {
    viajeId: number;
    tareaId: number;
    entregaId: number;
    notificacionId: number;
    orden: string;
    clienteFinal: string;
    numeroPedido: string;
    cantidadGuias: number;
    bultos: number;
    paquetesEscaneados: number;
    paquetesSinEscanear: number;
    estadoPaquete: number;

     //Constructor
     constructor(){}; 

     copiar(objeto : any){
        //Propios
        this.viajeId = objeto.viajeId
        this.tareaId = objeto.tareaId
        this.entregaId = objeto.entregaId
        this.notificacionId = objeto.notificacionId
        this.orden = objeto.orden;
        this.clienteFinal = objeto.clienteFinal;
        this.numeroPedido = objeto.numeroPedido;
        this.cantidadGuias = objeto.cantidadGuias;
        this.bultos = objeto.bultos;
        this.paquetesEscaneados = objeto.paquetesEscaneados;
        this.paquetesSinEscanear = objeto.paquetesSinEscanear;
        this.estadoPaquete = objeto.estadoPaquete;
    }

}