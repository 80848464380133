import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../../shared/commons';

//Clases
import { Canal } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarCanalDTO } from '../dto/importarCanal.dto';

@Injectable()
export class CanalService {
  ciaId : string;
  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<Canal>>{
    return this.http.listar(this.ciaId+"/canales").map(
      (data: IResultado) => {
        let list = Array<Canal>();
        let obj;
        for (const d of data.resultado) {
          obj = new Canal();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });
  }

  consultar(data) : Observable<Array<Canal>>{

    return this.http.consultar(this.ciaId+"/canales/consultar", data).map(
       (data)=>{
        let list = Array<Canal>();
        let obj : Canal;
        if(data){
          for (const d of data.resultado) {
            obj = new Canal();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      }
    );

  }

  obtener(id : number) : Observable<Canal>{
    return this.http.obtener(this.ciaId+"/canales/"+id).map(
      (data: IResultado) => {
        if(data){
          let obj = new Canal();
          obj.copiar(data.resultado);
          return obj;
        }
    });
  }

  registrar(canal : Canal, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/canales", canal, { funcionalidad });
  }

  modificar(canal : Canal, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/canales", canal, { funcionalidad });
  }

  eliminar(canalId : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/canales/"+canalId, { funcionalidad });
  }
  
  registrarImportacion(canal : Array<ImportarCanalDTO>, funcionalidad?: String) : Observable<any>{
    return this.http.registrarImportacion(this.ciaId+"/canales/registrar-importacion", canal, { funcionalidad });
  }
  
  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

  listarTipoProducto(params){
    return this.http.listar("/tipo-producto/listar",{ params: params });
  }
  registrarTipoProducto(obj:any){
    return this.http.registrar("/canal-tipo-producto",obj)
  }
  obtenerCanalTipoProducto(canalId:any){
    return this.http.obtener(`/canal-tipo-producto/${canalId}`)
  }
}
