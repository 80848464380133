import { C } from '@shared/commons';
import { AgregaProductoComponent } from 'src/app/layout/control/estados-viaje/shared/tabla-detalle/guia-detalle/detalle-documentos/agrega-producto/agrega-producto.component';
import { Guia, Usuario } from '.';
import { EstadoPaquete } from './estadoPaquete';
import { Producto } from './producto';
import { Tarea } from './tarea';
import { Transportista } from './transportista';

export class Paquete {
    //PK
    paqueteId: number;

    //Atributos
    codigoBarras: string;
    fechaHoraCarga: Date;
    formaCarga: string;
    estadoCarga: number;
    fechaHoraDescarga: Date;
    formaDescarga: string;
    tipoCarga: number;
    observacion: string;

    cantidadElementos: number;
    cantidadEntregada: number;
    cantidadRecogida: number;
    peso: number;
    precioUnitario: number;
    unidadPresentacion: string;
    unidadVenta: string;
    pesoEntregado: number;
    importe: number;
    //Relaciones
    tarea: Tarea;
    transportistaCarga: Transportista;
    transportistaDescarga: Transportista;
    producto: Producto;
    guia: Guia;
    currentUser : Usuario;
    confCalculoPrecioTotalProductos;
    EstadoPaquete: EstadoPaquete;

    //Otras Variables
    estadoRegistro: string;
    resultadoRegistro: string;

    estadoInicial: number;
    estadoInicialString: string;
    confirmacion : number;
    unidadMedidaLiquidacion: string;

    constructor(){
        this.currentUser = JSON.parse(localStorage.getItem("currentUser")); 
        this.confCalculoPrecioTotalProductos = this.currentUser.listConfCompania.find(c => c.campo === "CALCULO_PRECIO_TOTAL_PRODUCTOS").estado;
    }

    get estadoRegistroToIcon(): string {
        if (this.estadoRegistro != null) {
            switch (this.estadoRegistro) {
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor(): string {
        if (this.estadoRegistro != null) {
            switch (this.estadoRegistro) {
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    get estadoCargaToString(): string {
        switch (this.estadoCarga) {
            case 6:
                return "MODEL.paquete.estados.programado";
            case 7:
                return "MODEL.paquete.estados.recogido";
            case 8:
                return "MODEL.paquete.estados.no-encontrado";
            case 9:
                return "MODEL.paquete.estados.no-programado";
            case 10:
                return  'MODEL.paquete.estados.entregado';
            default:
                return "";
        }
    }

    get tipoCargaToString(): string {
        switch (this.tipoCarga) {
            case 1:
                return "Cargado";
            case 3:
                return "Cargado";
            case 2:
                return "Descargado";
            default:
                return "";
        }
    }

    get formaDescargaToString(): string {
        switch (this.formaDescarga) {
            case C.PAQUETE.FORMA_CARGA_DESCARGA.MANUAL:
                return "COMMON.constantes.paquetes-forma-carga-descarga.manual";
            case C.PAQUETE.FORMA_CARGA_DESCARGA.AUTOMATICO:
                return "COMMON.constantes.paquetes-forma-carga-descarga.automatico";
            default:
                return "";
        }
    }

    get formaCargaToString(): string {
        switch (this.formaCarga) {
            case C.PAQUETE.FORMA_CARGA_DESCARGA.MANUAL:
                return "COMMON.constantes.paquetes-forma-carga-descarga.manual";
            case C.PAQUETE.FORMA_CARGA_DESCARGA.AUTOMATICO:
                return "COMMON.constantes.paquetes-forma-carga-descarga.automatico";
            default:
                return "";
        }
    }

    //Metodos
    copiar(objeto: any) {
        //Propios

        this.paqueteId = objeto.paqueteId;
        this.codigoBarras = objeto.codigoBarras;
        this.fechaHoraCarga = objeto.fechaHoraCarga;
        this.formaCarga = objeto.formaCarga;
        this.estadoCarga = objeto.estadoCarga;
        this.fechaHoraDescarga = objeto.fechaHoraDescarga;
        this.formaDescarga = objeto.formaDescarga;
        this.tipoCarga = objeto.tipoCarga;
        this.observacion = objeto.observacion;
        this.cantidadElementos = objeto.cantidadElementos;
        this.cantidadEntregada = objeto.cantidadEntregada;
        this.cantidadRecogida = objeto.cantidadRecogida;
        this.peso = objeto.peso;
        this.precioUnitario = objeto.precioUnitario;
        this.unidadPresentacion = objeto.unidadPresentacion;
        this.unidadVenta = objeto.unidadVenta;
        this.pesoEntregado = objeto.pesoEntregado;
        this.importe = objeto.importe;
        this.estadoRegistro = objeto.estadoRegistro;
        this.estadoInicial = objeto.estadoInicial;
        this.confirmacion = objeto.confirmacion;

        if(this.estadoInicial == 9){
            this.estadoInicialString = "NO PROGRAMADO";
        }else{
            this.estadoInicialString = "PROGRAMADO";
        }
       
        if(this.pesoEntregado != null){           
            this.confirmacion = this.confirmacion != null ? this.confirmacion : this.pesoEntregado;
            this.unidadMedidaLiquidacion = this.unidadVenta;
        } else{
            this.confirmacion = this.confirmacion != null ? this.confirmacion :  this.cantidadRecogida;
            this.unidadMedidaLiquidacion = this.unidadPresentacion;
        }

        //Relaciones
        if (objeto.tarea != null) {
            this.tarea = new Tarea();
            this.tarea.copiar(objeto.tarea);
            this.tarea["@type"] = "Entrega"
        }

        if (objeto.transportistaCarga != null) {
            this.transportistaCarga = new Transportista();
            this.transportistaCarga.copiar(objeto.transportistaCarga);
        }

        if (objeto.transportistaDescarga != null) {
            this.transportistaDescarga = new Transportista();
            this.transportistaDescarga.copiar(objeto.transportistaDescarga);
        }
        if (objeto.guia != null) {
            this.guia = new Guia();
            this.guia.copiar(objeto.guia);
        }
        if (objeto.producto != null) {
            this.producto = new Producto();
            this.producto.copiar(objeto.producto);
        }

        if(objeto.EstadoPaquete != null){
            this.EstadoPaquete = new EstadoPaquete();
            this.EstadoPaquete.copiar(objeto.estadoPaquete);
        }
    }

    instanciar(): void {
        if (this.tarea == null) this.tarea = new Tarea();
    }

    get pesoToString(): string {
        if (this.peso != null || this.peso != undefined) {
            return this.peso.toFixed(2);
        }
        return "0.00";
    }

    get montoTotalPaqueteCalcular() {
        if ((this.cantidadEntregada != null || this.cantidadEntregada != undefined)
            && (this.precioUnitario != null || this.precioUnitario != undefined)) {
            return this.redondearDosDecimales(this.cantidadEntregada * this.precioUnitario);
        }
        return 0;
    }

    get montoTotalInicialPaqueteCalcular() {

        if (this.unidadPresentacion == this.unidadVenta) {
            if ((this.cantidadElementos != null || this.cantidadElementos != undefined)
                && (this.precioUnitario != null || this.precioUnitario != undefined)) {
                return this.redondearDosDecimales(this.cantidadElementos * this.precioUnitario);
            }
            return 0.00;
        } else if (this.unidadPresentacion != this.unidadVenta) {
            // if ((this.peso != null || this.peso != undefined)
            //     && (this.precioUnitario != null || this.precioUnitario != undefined)) {
            //     return this.redondearDosDecimales(this.peso * this.precioUnitario);
            // }
            // return 0.00;
            if(this.confCalculoPrecioTotalProductos){
                if ((this.pesoEntregado != null || this.pesoEntregado != undefined)
                    && (this.precioUnitario != null || this.precioUnitario != undefined)) {
                    return this.redondearDosDecimales(this.pesoEntregado * this.precioUnitario);
                }
                return 0.00;

            }else{
                if ((this.cantidadElementos != null || this.cantidadElementos != undefined)
                    && (this.precioUnitario != null || this.precioUnitario != undefined)) {
                    return this.redondearDosDecimales(this.cantidadElementos * this.precioUnitario);
                }
                return 0.00;
            }
        }
    }

    get montoTotalEntregadoPaqueteCalcular() {
        if (this.unidadPresentacion == this.unidadVenta) {
            if(this.cantidadEntregada == this.cantidadElementos){
                if ((this.importe != null || this.importe != undefined)) {
                    return this.redondearDosDecimales(this.importe);
                }
                return 0.00;
            }else{
                if ((this.cantidadEntregada != null || this.cantidadEntregada != undefined)
                    && (this.precioUnitario != null || this.precioUnitario != undefined)) {
                    return this.redondearDosDecimales(this.cantidadEntregada * this.precioUnitario);
                }
                return 0.00;
           }

        } else if (this.unidadPresentacion != this.unidadVenta) {
            // if (this.cantidadEntregada < this.cantidadElementos) {
            //     if ((this.pesoEntregado != null || this.pesoEntregado != undefined)
            //         && (this.precioUnitario != null || this.precioUnitario != undefined)) {
            //         return this.redondearDosDecimales(this.pesoEntregado * this.precioUnitario);
            //     }
            //     return this.montoTotalInicialPaqueteCalcular;
            // } else if (this.cantidadEntregada == this.cantidadElementos) {
            //     if ((this.pesoEntregado != null || this.pesoEntregado != undefined)
            //         && (this.precioUnitario != null || this.precioUnitario != undefined)) {
            //         return this.redondearDosDecimales(this.pesoEntregado * this.precioUnitario);
            //     }
            //     return this.montoTotalInicialPaqueteCalcular;
            // }
             if (this.cantidadEntregada < this.cantidadElementos) {
                if ((this.pesoEntregado != null || this.pesoEntregado != undefined)
                    && (this.precioUnitario != null || this.precioUnitario != undefined)) {
                        if(this.confCalculoPrecioTotalProductos){
                            return this.redondearDosDecimales(this.precioUnitario*this.pesoEntregado); 
                        }else{
                            return this.redondearDosDecimales((!Number.isNaN(((this.cantidadEntregada * this.precioUnitario)/this.peso)*this.pesoEntregado))?((this.cantidadEntregada * this.precioUnitario)/this.peso)*this.pesoEntregado:0.00);
                        }
                    
                }
                return this.montoTotalInicialPaqueteCalcular;
            } else if (this.cantidadEntregada == this.cantidadElementos) {
                // if ((this.pesoEntregado != null || this.pesoEntregado != undefined)
                //     && (this.precioUnitario != null || this.precioUnitario != undefined)) {
                //     return this.redondearDosDecimales(((this.cantidadElementos * this.precioUnitario)/this.peso)*this.pesoEntregado);
                // }
                // return this.montoTotalInicialPaqueteCalcular;
                if ((this.importe != null || this.importe != undefined)) {
                    if(this.confCalculoPrecioTotalProductos){
                        return this.redondearDosDecimales(this.precioUnitario*this.pesoEntregado); 
                    }else{
                        return this.redondearDosDecimales(this.importe);
                    }
                    //return this.redondearDosDecimales(this.importe);
                }
                return 0.00;
            }
        }
    }

    get calcularPesoEntregado() {

        if(this.pesoEntregado == null || this.pesoEntregado == undefined){
            //this.pesoEntregado = this.peso;
            
            this.pesoEntregado = this.redondearDosDecimales(this.peso);
        }   
        if (this.cantidadEntregada == this.cantidadElementos) {
                //let nuevoPesoEntregadoCalculado = (this.peso * this.cantidadEntregada) / this.cantidadElementos;
                let nuevoPesoEntregadoCalculado = (this.peso / this.cantidadElementos) * this.cantidadEntregada;
                this.pesoEntregado = nuevoPesoEntregadoCalculado;
                //this.pesoEntregado = this.redondearDosDecimales(this.peso);
                return this.redondearDosDecimales(this.pesoEntregado);
                //let nuevoPesoEntregadoCalculado = (!Number.isNaN((this.peso / this.cantidadElementos) * this.cantidadEntregada))?(this.peso / this.cantidadElementos) * this.cantidadEntregada:0.00;
                //this.pesoEntregado = this.redondearDosDecimales(nuevoPesoEntregadoCalculado);
                //return this.pesoEntregado;
        } else if (this.cantidadEntregada < this.cantidadElementos) {
            if(this.unidadPresentacion == this.unidadVenta){
                 //return this.pesoEntregado;
                 //Si la CANTIDAD ENTREGADA es menor a la CANTIDAD INICIAL -> Dividimos Peso/CantidadEntregada
                let nuevoPesoEntregadoCalculado = (this.peso / this.cantidadElementos) * this.cantidadEntregada;
                this.pesoEntregado = nuevoPesoEntregadoCalculado;                 
                return this.redondearDosDecimales(this.pesoEntregado);   
            }else{
                //let nuevoPesoEntregadoCalculado = (!Number.isNaN((this.peso / this.cantidadElementos) * this.cantidadEntregada))?(this.peso / this.cantidadElementos) * this.cantidadEntregada:0.00;
                //this.pesoEntregado = this.redondearDosDecimales(nuevoPesoEntregadoCalculado);
                let nuevoPesoEntregadoCalculado = (this.peso / this.cantidadElementos) * this.cantidadEntregada;
                this.pesoEntregado = this.redondearDosDecimales(nuevoPesoEntregadoCalculado);
                //return this.redondearDosDecimales(this.pesoEntregado);
            }
        }else{
            return this.redondearDosDecimales(this.peso);
        }
    }


    redondearDosDecimales(montoTotal): number {
        let monto = Number((Math.abs(montoTotal) * 100).toPrecision(15));
        return Math.round(monto) / 100 * Math.sign(montoTotal);
    }
}
