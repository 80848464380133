import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { map, take } from 'rxjs/operators';
import { SolicitudAutorizacioNotiDTO } from '@shared/modules/notificacion/resources';
import { NotificacionDTO } from '@shared/modules/notificacion/resources/dto/notificacion.dto';
import { NotificacionSensorDTO } from '@shared/modules/notificacion/resources/dto/notificacionSensor.dto';
import { NotificacionSmsDTO } from '@shared/modules/notificacion/resources/dto/notificacionSms.dto';

@Injectable({
  providedIn: 'root'
})
export class NotificacionProvider {

  //Variables a compartir entre componentes
  private solicitudes = new BehaviorSubject<Array<SolicitudAutorizacioNotiDTO>>([]);
  private alertasTareas = new BehaviorSubject<Array<NotificacionDTO>>([]);
  private alertasSensores = new BehaviorSubject<Array<NotificacionSensorDTO>>([]);
  private alertasSms = new BehaviorSubject<Array<NotificacionSmsDTO>>([]);

  currentSolicitudes = this.solicitudes.asObservable();
  currentAlertasTareas = this.alertasTareas.asObservable();
  currentAlertasSensores = this.alertasSensores.asObservable();
  currentAlertasSms = this.alertasSms.asObservable();

  constructor() { }

  // Metodos para actualizar la información
  changeSolicitudes(solicitudes : Array<SolicitudAutorizacioNotiDTO>) {
    this.solicitudes.next(solicitudes);
  }

  addSolicitud(solicitud : SolicitudAutorizacioNotiDTO) {
    this.solicitudes
        .pipe(take(1))
        .subscribe((solicitudes: Array<SolicitudAutorizacioNotiDTO>) => {
            this.solicitudes.next(
              [...solicitudes, solicitud]
            )
        });
  }

  removeSolicitud(notificacionId : number){
    const solicitudes: any[] = this.solicitudes.getValue();
    this.solicitudes.next(solicitudes.filter(n2 => n2.notificacionId != notificacionId));
  }

  // Metodos para actualizar la información
  changeAlertasTareas(alertasTareas : Array<NotificacionDTO>) {
    this.alertasTareas.next(alertasTareas);
  }

  addAlertasTareas(item : NotificacionDTO) {
    this.alertasTareas
        .pipe(take(1))
        .subscribe((alertasTareas: Array<NotificacionDTO>) => {
            this.alertasTareas.next(
              [...alertasTareas, item]
            )
        });
  }

  // Metodos para actualizar la información
  changeAlertasSensores(alertasSensores : Array<NotificacionSensorDTO>) {
    this.alertasSensores.next(alertasSensores);
  }

  addAlertasSensores(item : NotificacionSensorDTO) {
    this.alertasSensores
        .pipe(take(1))
        .subscribe((alertasSensores: Array<NotificacionSensorDTO>) => {
            this.alertasSensores.next(
              [...alertasSensores, item]
            )
        });
  }

  // Metodos para actualizar la información
  changeAlertasSms(alertasSms : Array<NotificacionSmsDTO>) {
    this.alertasSms.next(alertasSms);
  }

  addAlertasSms(item : NotificacionSmsDTO) {
    this.alertasSms
        .pipe(take(1))
        .subscribe((alertasSms: Array<NotificacionSmsDTO>) => {
            this.alertasSms.next(
              [...alertasSms, item]
            )
        });
  }

 

}