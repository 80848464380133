import { Component, OnInit , Input,ViewChild} from '@angular/core';
import { Paginator } from 'primeng';

@Component({
  selector: 'app-perfil-descripcion',
  templateUrl: './perfil-descripcion.component.html',
  styleUrls: ['./perfil-descripcion.component.scss']
})
export class PerfilDescripcionComponent implements OnInit {

  @Input()
  perfil: any;
  constructor() { }

  ngOnInit(): void {
  }

}
