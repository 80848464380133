export class ConfiguracionMarca{

    id: number;
    marcaId: number;
    campo: string;
    estado: string;
    valor: string;

    constructor(){}
    
    copiar(objeto: any){
        this.id = objeto.id;
        this.marcaId = objeto.marcaId;
        this.campo = objeto.campo;
        this.estado = objeto.estado;
        this.valor = objeto.valor;
    }
    
}