<p-toast></p-toast>
<div class="chat" [ngClass]="{'hide-campo': !view.view}">
    <div class="chat-header">
        <div class="wrapper-chat-header-info">
            <div class="chat-header-item"> {{'WEB.chat.titulos-chat.transportista' | translate}} : {{view.transportista}}</div>
            <div class="chat-header-item"> {{'WEB.chat.titulos-chat.viaje' | translate}} : {{view.codigo}}</div>
        </div>
        <div class="wrapper-chat-window-buttons">
            <button type="button" (click)="onClickMinimizar(view)" ><img src="assets/images/minimizar-chat.svg" alt=""></button>
            <button type="button" (click)="onClickCerrar(view)"><img src="assets/images/cerrar-chat.svg" alt=""></button>
        </div>        
    </div>
    <div class="chat-body">
        <div id="chatBodyRecord-{{view.viajeId}}" class="chat-body-record">
            <app-mensaje-chat *ngFor="let chat of chats" [chat]="chat" ></app-mensaje-chat>
        </div>
        <div class="input-message-chat"[ngClass]="{'hide-chat-input': view.viewFooter}">
            <input type="text" [(ngModel)]="mensaje" placeholder="{{'WEB.chat.inputs.input-mensaje-placeholder' | translate}}" />
            <div  #padreInput class="wrapper-messages-sending-buttons">
                <button type="button" (click)="enviarMensaje()"><img src="assets/images/icono-boton-enviar-mensaje-chat.svg" /></button>
                <button type="button" (click)="abrirSelectorArchivo()"><img src="assets/images/icono-boton-adjuntar-imagen-mensaje-chat.svg" /></button>
                <input type="file" id="archivoChat{{view.viajeId}}" accept="image/png" [hidden]="true" (change)="seleccionarArchivo($event,view.viajeId)" />
            </div>
        </div>
    </div>
</div>