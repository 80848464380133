export class Configuracion{
    //Pk
    id : number;
    //Atributos
    companiaId: number;
    campo: string;
    estado: boolean;
    valor: string;
    valorPorDefecto: string;

    //Relaciones
    //compania : Compania;
    

    copiar(objeto : any){
        //Propios
        this.id = objeto.id;
        this.companiaId = objeto.companiaId;         
        this.campo = objeto.campo;        
        this.estado = objeto.estado;        
        this.valor = objeto.valor;        
        this.valorPorDefecto = objeto.valorPorDefecto;
    }
}