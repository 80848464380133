import { Injectable } from "@angular/core";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { HttpClient2 } from "./http-client.service";
import { TranslateService } from "@ngx-translate/core";
import { Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { C, Cookies } from "../commons";
import "rxjs/add/operator/map";
import "rxjs/add/operator/filter";
import "rxjs/add/observable/timer";
import "rxjs/add/operator/do";
import "rxjs/add/operator/switch";
import { HttpClientModule } from "@angular/common/http";
/*App */
import { Usuario, Compania, TipoUsuario, Perfil, EmpresaTransporte, ClienteFinal } from "../models";
import { GeneralProvider } from "../providers";
import { ConfiguracionDTO } from 'src/app/layout/seguridad/configuracion/resources/configuracion.dto';
import { CompaniaConfiguracionService } from "./compania-configuracion.service";
import { UsuarioToken } from "@shared/models/usuarioToken";
import { HeaderDTO } from "@shared/dto/header.dto";
import { Marca } from "@shared/models/marca";

interface IResultado {
  mensaje: string;
  excepcion: string;
  resultado: Usuario;
}
export enum AuthState {
  LOGGED_IN,
  LOGGED_OUT,
  DETECTED
}
//30 minutos
const SESSION_TIMEOUT = 1800000;

@Injectable()
export class AuthenticationService {
  private authState: AuthState;
  private authManager: BehaviorSubject<AuthState>;
  public authChange$: Observable<AuthState>;

  constructor(
    private translate: TranslateService,
    private httpClient: HttpClient,
    private http: HttpClient2,
    private router: Router,
    private _http:HttpClient
  ) {
    this.authManager = new BehaviorSubject(AuthState.LOGGED_OUT);
    this.authChange$ = this.authManager.asObservable();
    this.authChange$
      .filter((authState: AuthState) => authState === AuthState.LOGGED_IN)
      .map((authState: AuthState) => Observable.timer(SESSION_TIMEOUT))
      .do(() => {
        //console.log('Actividad detectada! Session ha sido reseteado a 30 minutos')
      })
      .switch()
      .subscribe(() => {
        console.log("Tiempo expirado: Logging out");
        let snapshot = this.router.routerState.snapshot;
        if (snapshot.url.search("/login") == -1) {
          this.router.navigate(["/login"], {
            queryParams: { page: snapshot.url }
          });
        }
      });
    //Validar F5
    let url = this.router.routerState.snapshot.url;
    if (url.search("/login") == -1) {
      this.detectedActivity();
    }
  }


  login(username: string, password: string,ip?:string): Observable<IResultado> {

    return this.http
      .post(
        "/usuarios/autenticar",
        { usuario: username, contrasenha: password,ip:ip},
        this.getHttpOptions()
      )
      .map((response: HttpResponse<any>) => {
        let result = <IResultado>response.body;
        let auth = response.headers.get("Authorization");

        if (response.ok) {
          let usuario = result.resultado;        
          // Almacenar los detalles de usuario y el token jwt en el almacenamiento local para mantener al usuario conectado entre actualizaciones de página
          let currentUser: any = {};
          currentUser.token = auth;
          currentUser.usuarioId = usuario.usuarioId;
          currentUser.nombre = usuario.nombre;
          currentUser.actualizarContrasena = usuario.actualizarContrasena;
          currentUser.diasVigenciaContrasena = usuario.diasVigenciaContrasena;
          currentUser.mostrarFechaExpiracion = usuario.mostrarFechaExpiracion;
          currentUser.compania = new ConfiguracionDTO();
          currentUser.compania.companiaId = usuario.compania.companiaId;
          currentUser.compania.nombre = usuario.compania.nombre;
          currentUser.compania.paisId = usuario.compania.pais.paisId;
          currentUser.compania.paisCodigo = usuario.compania.pais.codigo;
          currentUser.compania.pais = usuario.compania.pais.pais;
          currentUser.compania.paisIdioma = usuario.compania.pais.idioma;
          currentUser.compania.coordenada = usuario.compania.pais.coordenada;
          currentUser.compania.cambioEstadoTarea = usuario.compania.cambioEstadoTarea;
          currentUser.compania.esTransportista = usuario.compania.esTransportista;
          currentUser.compania.tipoGestion = usuario.compania.tipoGestion;
          currentUser.compania.transbordo = usuario.compania.transbordo;
          currentUser.compania.skuGestion = usuario.compania.skuGestion;
          currentUser.compania.activarSms = usuario.compania.activarSms;
          currentUser.compania.agruparEntregas = usuario.compania.agruparEntregas;
          currentUser.compania.gestionPedido = usuario.compania.gestionPedido;
          currentUser.compania.gestionPedidosProvincia = usuario.compania.gestionPedidosProvincia;
          currentUser.compania.companiaCliente = false;
          currentUser.compania.checkCompaniaUsuarioSac = false;
          currentUser.compania.estadoMonitoreoGps = usuario.compania.estadoMonitoreoGps;
          currentUser.compania.rutasUniversales = usuario.compania.rutasUniversales;
          currentUser.compania.producto = usuario.compania.producto;
          currentUser.tipoUsuario = new TipoUsuario();
          currentUser.tipoUsuario.tipoUsuarioId =usuario.tipoUsuario.tipoUsuarioId;
          currentUser.tipoUsuario.descripcion = usuario.tipoUsuario.descripcion;
          currentUser.marca = new Marca();
          currentUser.clienteMarca = new Marca();
          currentUser.clienteFinal = new ClienteFinal();
          if(usuario.marca){
            currentUser.marca.marcaId=usuario.marca.marcaId;
          }
          if(usuario.clienteMarca){
            currentUser.clienteMarca.marcaId=usuario.clienteMarca.marcaId;
          }
          if(usuario.clienteFinal){
            currentUser.clienteFinal.clienteFinalId = usuario.clienteFinal.clienteFinalId;
          }
          currentUser.empresaTransporte = new EmpresaTransporte();          
          if(usuario.empresaTransporte){
            currentUser.empresaTransporte.empresaTransporteId = usuario.empresaTransporte.empresaTransporteId;
          }
          if(usuario.companiaPadre != null){
            currentUser.companiaPadre = usuario.companiaPadre;
          }
          currentUser.funcionalidades = usuario.funcionalidades;
          currentUser.logSessionId = usuario.logSessionId;
          currentUser.esSuperUsuario = usuario.esSuperUsuario;
          currentUser.esAdministrador = usuario.esAdministrador;
          currentUser.esSupervisor = usuario.esSupervisor;
          currentUser.esPerfilSac = usuario.esPerfilSac;
          currentUser.esPerfilCliente = usuario.esPerfilCliente;
          currentUser.esPerfilClienteFinal = usuario.esPerfilClienteFinal;
          currentUser.esPerfilJefeDistribucion = usuario.esPerfilJefeDistribucion;
          currentUser.esPerfilAnalistaDistribucion = usuario.esPerfilAnalistaDistribucion;

          currentUser.listPerfil = new Array<Perfil>();
          currentUser.logo = usuario.logo;
          currentUser.listConfCompania = usuario.listConfCompania;
          currentUser.companias = usuario.companias;
          for (let perfil of usuario.listPerfil) {
            currentUser.listPerfil.push(perfil);
          }
          currentUser.esEmpresaTransporteAdministrador = currentUser.listPerfil.some(perfil => perfil.perfilId===8);
          currentUser.esJefeLogistica = currentUser.listPerfil.some(perfil => perfil.perfilId===13);
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
          this.setAuthState(AuthState.LOGGED_IN);

          return result;
        } else {
          throw response;
        }       
      })
      .catch(response => {
        let resultado: IResultado = {
          mensaje: "",
          excepcion: "",
          resultado: null
        };
        console.log(response);
        try {
          switch (response.status) {
            case 0:
            case 404:
              resultado.mensaje = this.translate.instant(
                "COMMON.mensajes.error-conexion"
              );
              break;
            default:
              resultado = response.error;
              console.log(resultado.excepcion);
          }
        } catch (error) {
          resultado.mensaje = this.translate.instant(
            "COMMON.mensajes.error-envio"
          );
          console.log(error);
        }

        return Observable.throw(resultado); // Observable.throw() is undefined at runtime using Webpack
      });
  }

  logout() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null) {
      this.http
        .post(
          "/usuarios/invalidar",
          currentUser.usuarioId,
          this.getHttpOptions()
        )
        .subscribe(
          (response: HttpResponse<IResultado>) => {
            let resultado = <IResultado>response.body;
            let usuario = resultado.resultado;
            if (response.ok) {
              // Eliminar usuario del local storage para cerrar la sesión del usuario
              localStorage.removeItem("currentUser");
              localStorage.removeItem("etiquetas");
              localStorage.removeItem(CompaniaConfiguracionService.LOCALSTORAGE_LLAVE_ACTUAL);
              this.setAuthState(AuthState.LOGGED_OUT);
              console.log(resultado.mensaje);
            }
          },
          (response: HttpResponse<IResultado>) => {
            let resultado: IResultado = {
              mensaje: "",
              excepcion: "",
              resultado: null
            };
            console.log(response);
            try {
              switch (response.status) {
                case 404:
                  resultado.mensaje = this.translate.instant(
                    "COMMON.mensajes.error-envio"
                  );
                  break;
                default:
                  resultado = response.body;
                  console.log(resultado.excepcion);
              }
            } catch (error) {
              resultado.mensaje = this.translate.instant(
                "COMMON.mensajes.error-envio"
              );
              console.log(error);
            }
          }
        );
    }
  }

  public detectedActivity() {
    this.setAuthState(AuthState.DETECTED);
    this.setAuthState(AuthState.LOGGED_IN);
  }

  private emitAuthState(): void {
    this.authManager.next(this.authState);
  }

  private setAuthState(newAuthState: AuthState): void {
    //console.log('AuthService: setAuthState: ', AuthState[newAuthState.toString()]);
    if (newAuthState != this.authState) {
      this.authState = newAuthState;
      this.emitAuthState();
    }
  }

  private getHttpOptions() {
    return {
      headers: { Language: Cookies.getCookie("LANGUAGE") },
      observe: "response"
    };
  }

  validarToken(data,companiaId,usuarioId, httpOptions?: Object) : Observable<UsuarioToken>{

    return this.http.consultarSinCurrentUser("/"+companiaId+"/usuarios/validar-token/"+usuarioId, data).map(
      (data: IResultado) => {
        if(data){
          let obj = new UsuarioToken();
          obj.copiar(data.resultado);
          return obj;
        }
      });

  }

  generarContrasena(data,companiaId,usuarioId, httpOptions?: Object) : Observable<Usuario>{

    return this.http.consultarSinCurrentUser("/"+companiaId+"/usuarios/creacion-contrasena/"+usuarioId, data).map(
      (data: IResultado) => {
        if(data){
          let obj = new Usuario();
          obj.copiar(data.resultado);
          return obj;
        }
      });

  }

  recuperarContrasena(data,companiaId,tipoEnvio, httpOptions?: Object) : Observable<Usuario>{

    return this.http.consultarSinCurrentUser(`/${companiaId}/usuarios/envio-correo-reseteo-olvido-clave/${tipoEnvio}`, data).map(
      (data: IResultado) => {
        if(data){
          let obj = new Usuario();
          obj.copiar(data.resultado);
          return obj;
        }
      });

  }

  modificarUsuarioEmpresaTransporte(companiaTransporteId : number) : Observable<any>{    
     let currentUser = JSON.parse(localStorage.getItem("currentUser"));
     return this.http.obtener("/"+null + "/relacion-compania/obtener-empresa-transporte/"+companiaTransporteId+"/"+currentUser.usuarioId);    
  }

  updateIp(logSessionId, ip) : Observable<any>{
    return this.http.put(`/usuarios/update-session-ip`, { logSessionId: logSessionId, ip:  ip}, this.getHttpOptions());
  }

}
