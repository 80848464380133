<div class="ui-g-12">
  <h5><strong>{{'COMMON.etiquetas.busqueda-avanzada' | translate}}</strong></h5>

  <div style="background-color: black; height: 2px; width: 100%;" ></div>

</div>

<form [formGroup]="formBusquedaAvanzada">
    <div class="ui-g-12">
      <label><strong>{{'COMMON.combo.seleccion-zona' | translate}}:</strong></label>
      <p-multiSelect 
                #msZonas
                formControlName="zonas"
                [filter]="false"
                defaultLabel="{{('WEB.control.estados-viaje.resumen-viaje.seleccione' | translate)}}" 
                [options]="listZona"
                styleClass="st-multiSelect" [displaySelectedLabel]="false"
                [showToggleAll]="false" ></p-multiSelect>

    </div>
    <div class="ui-g-12">
      <label><strong>{{'MODEL.ruta.titulo' | translate}}:</strong></label><br>
      <p-multiSelect 
                #msRutas
                formControlName="rutasId"
                defaultLabel="{{('WEB.control.estados-viaje.resumen-viaje.seleccione' | translate)}}" 
                [options]="listRutaEmpresaTransporte"
                styleClass="st-multiSelect" [displaySelectedLabel]="false"
                [showToggleAll]="false" ></p-multiSelect>

    </div>
    <div class="ui-g-12">
      <label><strong>{{'MODEL.unidadTransporte.placa' | translate}}:</strong></label>
      <p-multiSelect 
                #msUnidadesTransporte
                formControlName="unidadesTransporteId"
                defaultLabel="{{('WEB.control.estados-viaje.resumen-viaje.seleccione' | translate)}}" 
                [options]="listUnidadTransporte"
                styleClass="st-multiSelect" [displaySelectedLabel]="false"
                [virtualScroll]="true" [itemSize]="34"
                [showToggleAll]="false" ></p-multiSelect>

    </div>
    <div class="ui-g-12">
      <label><strong>{{'MODEL.empresaTransporte.titulo' | translate}}:</strong></label>
      <p-multiSelect 
                #msEmpresasTransporte
                formControlName="empresasTransporteId"
                defaultLabel="{{('WEB.control.estados-viaje.resumen-viaje.seleccione' | translate)}}" 
                [options]="listEmpresaTransporte"
                styleClass="st-multiSelect" [displaySelectedLabel]="false"
                [virtualScroll]="true" [itemSize]="34"
                [showToggleAll]="false" ></p-multiSelect>

    </div>
    <div class="ui-g-12" [hidden]="tipoReporte != 'PreLiquidacion'">
      <label><strong>{{'MODEL.clienteFinal.titulo2' | translate}}:</strong></label>
      <p-multiSelect 
                #msClientesFinal
                formControlName="clientesFinalId"
                defaultLabel="{{('WEB.control.estados-viaje.resumen-viaje.seleccione' | translate)}}" 
                [options]="listClientesFinal"
                styleClass="st-multiSelect" [displaySelectedLabel]="false"
                [virtualScroll]="true" [itemSize]="34"
                [showToggleAll]="false" ></p-multiSelect>

    </div>
    <div class="ui-g-12" [hidden]="tipoReporte != 'PreLiquidacion'">
      <label><strong>{{'MODEL.clienteFinal.centroCosto' | translate}}:</strong></label>
      <p-multiSelect 
                #msCentrosCosto
                formControlName="centrosCostoId"
                defaultLabel="{{('WEB.control.estados-viaje.resumen-viaje.seleccione' | translate)}}" 
                [options]="listCentrosCosto"
                styleClass="st-multiSelect" [displaySelectedLabel]="false"
                [virtualScroll]="true" [itemSize]="34"
                [showToggleAll]="false" ></p-multiSelect>

    </div>
    <div [hidden]="tipoReporte != 'OnTime' && tipoReporte != 'OnTimeHora'" class="ui-g-12">
        <label><strong>{{'MODEL.viaje.fechaEstimadaInicio' | translate}}:</strong></label><br>
        <p-calendar #cFechaEstimada name="fechaInicio" formControlName="fechaEstimadaInicio" [locale]="lang" dateFormat="dd/mm/yy"
                    selectionMode="range" [readonlyInput]="true"
                    [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                    ></p-calendar>
    </div>
    <div [hidden]="tipoReporte != 'OnTime' && tipoReporte != 'OnTimeHora'" class="ui-g-12">
      <label><strong>{{'MODEL.viaje.fechaProgramada' | translate}}:</strong></label><br>
      <p-calendar #cFechaProgramada name="fechaInicio" formControlName="fechaProgramada" [locale]="lang" dateFormat="dd/mm/yy"
                  selectionMode="range" [readonlyInput]="true"
                  [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                  ></p-calendar>
    </div>
    <div class="ui-g-12"[hidden]="tipoReporte == 'PreLiquidacion'">
      <label><strong>{{'MODEL.viaje.fechaRealAtencion' | translate}}:</strong></label><br>
      <p-calendar #cFechaRealAtencion name="fechaFin" formControlName="fechaRealAtencion" [locale]="lang" dateFormat="dd/mm/yy"
                    selectionMode="range" [readonlyInput]="true"
                    [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                    ></p-calendar>
    </div>
    
    <div class="ui-g-12" [hidden]="tipoReporte != 'PreLiquidacion'">
      <label><strong>{{'WEB.reporte.busqueda.tipos-fecha-viaje.fecha-facturacion' | translate}}:</strong></label><br>
      <p-calendar #cFechaFacturacion name="fechaFacturacion" formControlName="fechaFacturacion" [locale]="lang" dateFormat="dd/mm/yy"
                    selectionMode="range" [readonlyInput]="true"
                    [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                    ></p-calendar>
    </div>
    <div class="ui-g-12 center-all">
        <button class="btn btn-primary btn-block" (click)="onClickBuscar($event)">            
            <span> <i class="fa fa-fw fa-search"></i> {{'COMMON.boton.buscar' | translate}} </span>            
        </button>    
    </div>

</form>