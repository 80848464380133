import { ClienteFinal } from './clienteFinal';
import { Contacto } from './contacto';
import { Distrito } from './distrito';
import { Marca } from '@shared/models/marca';
import { Tarifario } from './tarifario';
import { RutaEmpresaTransporte } from './rutaEmpresaTransporte';

export class Sede {
    //PK
    sedeId: number;
    clienteFinalId: number;

    //Atributos
    codigo: string;
    estado: number;
    nombre: string;
    leadTime: number;
    direccion: string;
    referencia: string;
    coordenada: string;
    radioControl: number;
    tiempoDescarga: number;
    tiempoEspera: number;
    ventanaHorariaInicio: string;
    ventanaHorariaFin: string;
    ventanaHorariaInicio2: string;
    ventanaHorariaFin2: string;
    //Relaciones
    distrito: Distrito;
    clienteFinal: ClienteFinal;
    marca: Marca;
    listContacto: Contacto[];
    tarifarios: Tarifario[];
    rutaEmpresaTransporte: RutaEmpresaTransporte;
    //Otras Variables
    estadoRegistro: string;
    resultadoRegistro: string;
    //Opcional
    cacheRutaId?: number;
    cacheFechaModificacionRuta?: Date;

    get direccionCompleta(): string {
        let resultado = "";
        if (this.direccion != undefined) {
            resultado = this.direccion;
            if (this.distrito != undefined && this.distrito.distrito != undefined) {
                resultado += " ";
                resultado += this.distrito.distrito;
            }
        }
        return resultado;
    }
    get latitud(): number {
        if (this.coordenada != null) {
            let coordenada = this.coordenada.split(" ");
            if (coordenada) {
                let lat = Number.parseFloat(coordenada[1]);
                if (lat != 0) {
                    return lat;
                }
            }
        }
        return null;
    }
    get longitud(): number {
        if (this.coordenada != null) {
            let coordenada = this.coordenada.split(" ");
            if (coordenada) {
                let lng = Number.parseFloat(coordenada[0]);
                if (lng != 0) {
                    return lng;
                }
            }
        }
        return null;
    }

    static clonar(s: Sede): Sede {
        let sede = new Sede();
        for (let prop in s) {
            try {
                sede[prop] = s[prop];
            } catch (error) {
                console.log(error);
            }
        }
        return sede;
    }
    //Metodos
    //Propios
    copiar(objeto: any) {
        this.sedeId = objeto.sedeId;
        this.clienteFinalId = objeto.clienteFinalId;
        this.codigo = objeto.codigo;
        this.estado = objeto.estado;
        this.nombre = objeto.nombre;
        this.leadTime = objeto.leadTime;
        this.direccion = objeto.direccion;
        this.rutaEmpresaTransporte = objeto.rutaEmpresaTransporte;
        this.marca = objeto.marca;
        this.coordenada = objeto.coordenada;
        this.radioControl = objeto.radioControl;
        this.tiempoDescarga = objeto.tiempoDescarga;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.tiempoEspera = objeto.tiempoEspera;
        this.referencia = objeto.referencia;
        this.ventanaHorariaInicio = objeto.ventanaHorariaInicio;
        this.ventanaHorariaFin = objeto.ventanaHorariaFin;
        this.ventanaHorariaInicio2 = objeto.ventanaHorariaInicio2;
        this.ventanaHorariaFin2 = objeto.ventanaHorariaFin2;
        this.cacheRutaId = objeto.rutaEmpresaTransporte?.rutaEmpresaTransporteId;
        this.cacheFechaModificacionRuta = objeto.rutaEmpresaTransporte?.fechaHoraModificacion;

        //Relaciones
        if (objeto.clienteFinal != null) {
            this.clienteFinal = new ClienteFinal();
            this.clienteFinal.copiar(objeto.clienteFinal);
        }
        if (objeto.distrito != null) {
            this.distrito = new Distrito();
            this.distrito.copiar(objeto.distrito);
        }

        if (objeto.rutaEmpresaTransporte != null) {
            this.rutaEmpresaTransporte = new RutaEmpresaTransporte();
            this.rutaEmpresaTransporte.copiar(objeto.rutaEmpresaTransporte)
        }

        if (objeto.marca != null) {
            this.marca = new Marca();
            this.marca.copiar(objeto.marca)
        }

        if (objeto.listContacto != null) {
            let contacto: Contacto;
            this.listContacto = new Array();
            for (let r of objeto.listContacto) {
                if (r != null) {
                    contacto = new Contacto();
                    contacto.copiar(r);
                    this.listContacto.push(contacto);
                }
            }
        }
        if (objeto.tarifarios != null) {
            let tarifario: Tarifario;
            this.tarifarios = new Array();
            for (let r of objeto.tarifarios) {
                if (r != null) {
                    tarifario = new Tarifario();
                    tarifario.copiar(r);
                    this.tarifarios.push(tarifario);
                }
            }
        }
    }

    actualizarValores() {
        this.direccion = this.reescribirDireccion();
    }

    reescribirDireccion(): string {
        let resultado = "";
        if (this.direccion != undefined) {
            resultado = this.direccion;
            if (this.distrito != undefined
                && this.distrito.distrito != undefined
                && !resultado.includes(this.distrito.distrito)) {
                resultado += " ";
                resultado += this.distrito.distrito;
            }
        }
        return resultado;
    }

    instanciar(): void {
        if (this.clienteFinal == null) this.clienteFinal = new ClienteFinal();
        if (this.distrito == null) this.distrito = new Distrito();
        if (this.rutaEmpresaTransporte == null) this.rutaEmpresaTransporte = new RutaEmpresaTransporte();
        if (this.marca == null) this.marca = new Marca();
    }
}
