import { Compania, Usuario, Configuracion, Etiqueta } from '../../../../shared';
import { C } from '../../../../shared';
import { TranslateService } from '@ngx-translate/core';
import { CompaniaImportacionConfiguracion } from '../../../../shared/models/companiaImportacionConfiguracion';

export class ConfiguracionCompaniaDTO {
    configuracion: Array<Configuracion>;
    listCompaniaEtiqueta: Array<Etiqueta>;
    companiaConfiguracionImportacion: Array<CompaniaImportacionConfiguracion>;

    constructor() { }

    copiar(objeto: any) {
        this.configuracion = objeto.configuracion;
        this.listCompaniaEtiqueta = objeto.listCompaniaEtiqueta;
        this.companiaConfiguracionImportacion = objeto.companiaConfiguracionImportacion;
    }
}