import { Pipe, PipeTransform } from '@angular/core';
import { C } from '@shared/commons';

import memo from 'memo-decorator';

@Pipe({
  name: 'estadoViajeToString'
})
export class EstadoViajeToStringPipe implements PipeTransform {

  @memo()
  transform(estado: number, etiquetas: any): string {
    if (estado != null) {
      switch (estado) {
        case C.VIAJE.ESTADO.PROGRAMADO:
          return etiquetas['estado_viaje_programado'];
        case C.VIAJE.ESTADO.APT:
          return etiquetas['estado_viaje_en_apt'];
        case C.VIAJE.ESTADO.EN_RUTA:
          return etiquetas['estado_viaje_en_ruta'];
        case C.VIAJE.ESTADO.FINALIZADO:
          return etiquetas['estado_viaje_finalizado'];
        case C.VIAJE.ESTADO.INICIO_CARGA:
          return etiquetas['estado_viaje_inicio_carga'];
        case C.VIAJE.ESTADO.FIN_CARGA:
          return etiquetas['estado_viaje_fin_carga'];
        case C.VIAJE.ESTADO.EN_HUB:
          return etiquetas['estado_viaje_en_hub'];
        case C.VIAJE.ESTADO.EN_PROGRAMACION:
          return etiquetas['estado_viaje_en_programacion'];
        case C.VIAJE.ESTADO.EN_REPARTO:
          return etiquetas['estado_viaje_en_reparto'];
      }
    }
    return "";
  }

}
