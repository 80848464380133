import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResultado } from '@shared/commons';
import { FechaEstimadaEquivalencia } from '@shared/models';
import { Observable } from 'rxjs';
import { HttpClient2 } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class FechaEstimadaEquivalenciaService {

  constructor(private http: HttpClient2) { }

  buscarPorCompaniaId(): Observable<Array<FechaEstimadaEquivalencia>> {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const ciaId = currentUser.compania.companiaId;

    return this.http.listar(`/${ciaId}/fecha-estimada-equivalencia`)
      .map((data: IResultado) => data?.resultado);
  }

  consultar({ rangoInicial, rangoFinal }): Observable<Array<FechaEstimadaEquivalencia>> {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const ciaId = currentUser.compania.companiaId;

    let options = {};
    let params = new HttpParams();

    if (rangoInicial !== null && rangoInicial !== undefined) {
      params = params.append("rangoInicial", String(rangoInicial));
    }

    if (rangoFinal !== null && rangoFinal !== undefined) {
      params = params.append("rangoFinal", String(rangoFinal));
    }

    options = { params };

    return this.http.listar(`/${ciaId}/fecha-estimada-equivalencia/consultar`, options)
      .map((data: IResultado) => data?.resultado);
  }

  insertar(fechaEstimadaEquivalencia: FechaEstimadaEquivalencia): Observable<IResultado> {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const ciaId = currentUser.compania.companiaId;

    return this.http.registrar(`/${ciaId}/fecha-estimada-equivalencia`, fechaEstimadaEquivalencia);
  }

  actualizar(rangoInicial: number, rangoFinal: number, fechaEstimadaEquivalencia: FechaEstimadaEquivalencia): Observable<IResultado> {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const ciaId = currentUser.compania.companiaId;

    return this.http.modificar(`/${ciaId}/fecha-estimada-equivalencia/${rangoInicial}/${rangoFinal}`, fechaEstimadaEquivalencia);
  }
  
  eliminar(rangoInicial: number, rangoFinal: number): Observable<IResultado> {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const ciaId = currentUser.compania.companiaId;

    return this.http.eliminar(`/${ciaId}/fecha-estimada-equivalencia/${rangoInicial}/${rangoFinal}`);
  }
}
