import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificacionProvider } from '@shared/providers';
import { Usuario } from '@shared/models';
import { MessageService } from 'primeng';
import { SolicitudAutorizacioNotiDTO } from '../resources';
import { NotificacionDTO } from '../resources/dto/notificacion.dto';
import { NotificacionSensorDTO } from '../resources/dto/notificacionSensor.dto';
import { NotificacionSmsDTO } from '../resources/dto/notificacionSms.dto';

@Component({
  selector: 'app-notificacion-panel',
  templateUrl: './notificacion-panel.component.html',
  styleUrls: ['./notificacion-panel.component.scss'],
  providers: [ MessageService]
})
export class NotificacionPanelComponent implements OnInit {

  //Eventos a Padre
  @Output()
  cerrar = new EventEmitter<void>();

  //Variables Notificacion
  notificacionGeneral: Array<any>;
  solicitudes: Array<SolicitudAutorizacioNotiDTO>;
  alertasTareas: Array<NotificacionDTO>;
  alertaSensores: Array<NotificacionSensorDTO>;
  alertasSms: Array<NotificacionSmsDTO>;

  //Variables Tab
  tabActual: string;
  mensajeToast: string = "";

  //Secciónes
  @Input()
  showNotiSolicitud: boolean;
  constructor(
    private notificacionProvider: NotificacionProvider,
    private messageService: MessageService,
    private translate : TranslateService) { 
  }

  ngOnInit(): void {
    this.notificacionGeneral = [];
    this.solicitudes = [];
    this.alertasTareas = [];
    this.alertaSensores = [];
    this.alertasSms = [];
    this.translate.get(['WEB.control.solicitud-autorizacion.toast']).subscribe
    (translations => {
      this.mensajeToast = translations['WEB.control.solicitud-autorizacion.toast'];
    });

    this.cargarNotificaciones();

    if (this.showNotiSolicitud) {
      this.tabActual = "generales";
    } else {
      this.tabActual = "alertas-tarea";
    }
  }

  cerrarPanel(event) {
    if (event) {
      event.stopPropagation();
    }
    this.cerrar.emit();
  }
  // Funciones Tab
  cambiarContent(contenido: string): void {
    this.tabActual = contenido;
  }

  cargarNotificaciones() {
    this.solicitudes = [];
    let that = this;
    this.notificacionProvider.currentSolicitudes.subscribe((solicitudes: Array<SolicitudAutorizacioNotiDTO>) => {
      this.solicitudes = solicitudes;
      that.prepararListaGeneral();
    });

    this.alertasTareas = [];
    this.notificacionProvider.currentAlertasTareas.subscribe((alertasTareas: Array<NotificacionDTO>) => {
      this.alertasTareas = alertasTareas;
      that.prepararListaGeneral();
    });

    this.alertaSensores = [];
    this.notificacionProvider.currentAlertasSensores.subscribe((alertaSensores: Array<NotificacionSensorDTO>) => {
      this.alertaSensores = alertaSensores;
      that.prepararListaGeneral();
    });

    this.alertasSms = [];
    this.notificacionProvider.currentAlertasSms.subscribe((alertasSms: Array<NotificacionSmsDTO>) => {
      this.alertasSms = alertasSms;
      that.prepararListaGeneral();
    });
  }

  prepararListaGeneral() {
    this.notificacionGeneral = [];

    //Agregamos las dos listas en el general.
    this.solicitudes.forEach(item => {
      this.notificacionGeneral.push(item);
    });
    this.alertasTareas.forEach(item => {
      this.notificacionGeneral.push(item);
    });

    this.alertaSensores.forEach(item => {
      this.notificacionGeneral.push(item);
    });

    this.alertasSms.forEach(item => {
      this.notificacionGeneral.push(item);
    });
    //Lo ordenamos de forma ascendente:
    this.notificacionGeneral.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? 1 : -1);
  }

  mostrarToast(e){
    this.showToast('success', '', this.mensajeToast);
  }
  showToast(severidad:string, titulo:string, detalle:string) {
    this.messageService.add({severity:severidad, summary:titulo, detail:detalle});
  }  

}
