import { Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import { Funcionalidad } from "../../models";
import { Router } from '@angular/router';
import { C } from '@shared/commons';
import { GeneralProvider } from '@shared/providers';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit{
    funcionalidadesArray : Array<Funcionalidad>;
    isActive : boolean; 
    selected: any;
    preSelected: any;
    //etiqueta especial marca
    etiquetaMarca : string;

    constructor(private elementRef : ElementRef, 
                private renderer : Renderer2,
                private general : GeneralProvider,
                private router: Router) {        
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));  
      this.etiquetaMarca = this.general.getConfiguracionPersonalizada(C.ETIQUETA_CONFIGURABLE.CONF_ETIQUETA_MARCA);   
      this.funcionalidadesArray = currentUser.funcionalidades;

      if(this.funcionalidadesArray.some(func => func.ruta === "/mantenimiento")){
        this.funcionalidadesArray.filter(funcionalidad => funcionalidad?.ruta === "/mantenimiento")[0].funcionalidades.filter(fun => fun.ruta === "/mantenimiento/marca").map(e =>{
            if(e.ruta === "/mantenimiento/marca"){
                e.etiqueta = this.etiquetaMarca == undefined ? e.etiqueta : this.etiquetaMarca
            }
            return e
        });    
      }
      
      if(currentUser.compania?.skuGestion != 1){
        let funcionalidadProgramacion :any = this.funcionalidadesArray.filter(funcionalidad => funcionalidad?.ruta === "/programacion");
        if(funcionalidadProgramacion.length){
            let filtradoProgramacionProductos = funcionalidadProgramacion[0].funcionalidades.filter(funcionalidadProgramacion => funcionalidadProgramacion?.ruta !== "/programacion/importacion-productos");
            funcionalidadProgramacion[0].funcionalidades = [...filtradoProgramacionProductos]; 
            let filtradoProgramacionResanes = funcionalidadProgramacion[0].funcionalidades.filter(funcionalidadProgramacion => funcionalidadProgramacion?.ruta !== "/programacion/importacion-resanes");
            funcionalidadProgramacion[0].funcionalidades = [...filtradoProgramacionResanes];     
        }       
    }

      if(currentUser.compania?.skuGestion != 1){
        let funcionalidadCobranzas : any = this.funcionalidadesArray.filter(funcionalidad => funcionalidad?.ruta === "/cobranzas");
  
        if(funcionalidadCobranzas.length){
          let filtradoCobranzas = this.funcionalidadesArray.filter(funcionalidad => funcionalidad?.ruta !== "/cobranzas");
          this.funcionalidadesArray = [...filtradoCobranzas];
        }       
    }

      if(!currentUser.compania?.activarSms){
          let funcionalidadReportes : any = this.funcionalidadesArray.filter(funcionalidad => funcionalidad?.ruta === "/reporte");
    
          if(funcionalidadReportes.length){
            let filtradoReportes = funcionalidadReportes[0].funcionalidades.filter(funcionalidadReporte => funcionalidadReporte?.ruta !== "/reporte/consumo-sms");
            funcionalidadReportes[0].funcionalidades = [...filtradoReportes];
          }       
      }
      
      if(currentUser.compania?.companiaCliente){
        let funcionalidadesSeguridad : any = this.funcionalidadesArray.filter(funcionalidad => funcionalidad?.ruta === "/seguridad");
  
        if(funcionalidadesSeguridad.length){
          let filtradoSeguridad = funcionalidadesSeguridad[0].funcionalidades.filter(funcionalidadSeguridad => funcionalidadSeguridad?.ruta !== "/seguridad/usuario");
          funcionalidadesSeguridad[0].funcionalidades = [...filtradoSeguridad];
        }     
        
        let funcionalidadesMantenimiento : any = this.funcionalidadesArray.filter(funcionalidad => funcionalidad?.ruta === "/mantenimiento");
  
        if(funcionalidadesMantenimiento.length){
          let filtradoMantenimiento = funcionalidadesMantenimiento[0].funcionalidades.filter(funcionalidadMantenimiento => funcionalidadMantenimiento?.ruta !== "/mantenimiento/compania");
          funcionalidadesMantenimiento[0].funcionalidades = [...filtradoMantenimiento];
        }

      }

      
    }
    
    ngOnInit() {
        //Menu SideBar Adaptable
        setTimeout(()=>{ this.onResizeWindow(); },1000);
        window.addEventListener('resize', this.onResizeWindow, false);

        this.isActive = false;
    }

    onResizeWindow(){
        var header = document.getElementsByClassName("header");
        var listgroup = document.getElementsByClassName("list-group");
        var footer = document.getElementsByClassName("footer");
        let menu = document.getElementsByClassName("sidebar");

        var tamWindow = window.innerHeight;
        var tamGrupo = header[0].clientHeight + listgroup[0].clientHeight + footer[0].clientHeight;

        if(tamGrupo>tamWindow){
            if(!/tablet/.test(menu[0].className)){
                menu[0].classList.add("tablet");
            }
        }else{
            if(/tablet/.test(menu[0].className)){
                menu[0].classList.remove("tablet");
            }
        }
    }

    onClickMenu(funcionalidad: Funcionalidad) {         
        let menu = this.elementRef.nativeElement.querySelector('#menu'+funcionalidad.funcionalidadId+'>.list-group-item');
        let submenu = this.elementRef.nativeElement.querySelector('#menu'+funcionalidad.funcionalidadId+'>.nested');
        let menuClass: string = menu.className;
        

        if(submenu != null){
            let subMenuClass: string = submenu.className;
            if(menuClass.includes("router-link-active")){
                if(this.selected != menu){
                    this.renderer.removeClass(submenu, "expand");
                    this.renderer.removeClass(menu, "router-link-active");
                    this.renderer.addClass(this.selected, "router-link-active");
                } else {
                    if(subMenuClass.includes("expand")){
                        this.renderer.removeClass(submenu, "expand");
                    } else {
                        this.limpiarSeleccion();
                        this.mostrarMenu(menu, submenu);
                    }
                }
            } else {
                this.preSelected = menu;
                this.limpiarSeleccion();
                this.mostrarMenu(menu, submenu);
            }
        } else {
            //asumimos que es un ruteo directo
            this.limpiarSeleccion(true);
            this.renderer.addClass(menu, "router-link-active");
            this.router.navigate([`${funcionalidad.ruta}`]);    
            this.selected = menu;
        }
        
    }
    
    onClickSubMenu(funcionalidad: Funcionalidad){
        this.selected = this.elementRef.nativeElement.querySelector('#menu'+funcionalidad.funcionalidadId+'>.list-group-item');
        this.limpiarSeleccion();
    }

    mostrarMenu(menu: any, submenu: any){
        this.renderer.addClass(submenu,"expand");
        this.renderer.addClass(menu,"router-link-active");   
    }

    limpiarSeleccion(todo?: boolean){
        //Limpiar
        this.esconderExpandidos();
        this.quitarSeleccionados(todo);
    }

    esconderExpandidos(){
        let expandidos = this.elementRef.nativeElement.querySelectorAll('.expand');
        for(let index=0; index < expandidos.length; index++ ) {
            var element = expandidos[index];
            this.renderer.removeClass(element, "expand");    
        }
    }

    quitarSeleccionados(todo?: boolean){
        let seleccionados = this.elementRef.nativeElement.querySelectorAll('.router-link-active');
       
        for(let index=0; index < seleccionados.length; index++ ) {
            var element = seleccionados[index];
            
            if(this.selected != element || this.preSelected != element || todo){
                this.renderer.removeClass(element, "router-link-active");
            }
        } 
    }

    seleccionarMenuAnterior(){
        if(this.selected){
            this.renderer.addClass(this.selected, "router-link-active");
        }
    }

    onDashBoardClick(click: any){
        this.selected = click.currentTarget;
        this.limpiarSeleccion();
    }

}
