import { Tarea } from './tarea';
import { Usuario } from './usuario';
import { C } from '../commons/constantes';
import { dateToString, dateAndHourToString } from '../../shared/commons';

export class EstadoTarea {
    //PK
    estadoTareaId : number;

    //Atributos
    estado : number;
    fechaInicio : Date;
    fechaFin : Date;
    coordenada: string;

    fechaRealAtencion: Date;

    //Relaciones
    tarea : Tarea;
    usuario : Usuario;

    etiquetas: any = {};

    //Otras variables
    distancia : number;
    fueraRango : boolean;
    motivoEstadoId: number;

    //Metodos
    copiar(objeto : any){
        //Propios
        this.estadoTareaId = objeto.estadoTareaId;
        this.estado = objeto.estado;
        this.fechaInicio = objeto.fechaInicio;
        this.fechaFin = objeto.fechaFin;
        this.coordenada = objeto.coordenada;
        this.distancia = objeto.distancia;
        this.fueraRango = objeto.fueraRango;
        this.fechaRealAtencion = objeto.fechaRealAtencion;

        //Relaciones
        if(objeto.tarea != null){
            this.tarea = new Tarea();
            this.tarea.copiar(objeto.tarea);
        }
        if(objeto.usuario != null){
            this.usuario = new Usuario();
            this.usuario.copiar(objeto.usuario);
        }

        this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
    }

    get latitud() : number{
        if(this.coordenada!=null){
            let coordenada = this.coordenada.split(" ");
            if(coordenada){
                return Number.parseFloat( coordenada[1]);
            }
        }
        return null;
    }
    get longitud() : number{
        if(this.coordenada!=null){
            let coordenada = this.coordenada.split(" ");
            if(coordenada){
                return Number.parseFloat( coordenada[0]);
            }
        }
        return null;
    }
    get estadoTareaToString() : string{
        if(this.estado!=null){
            switch(this.estado){
                case C.TAREA.ESTADO.PENDIENTE:
                    return this.etiquetas['estado_tarea_pendiente'];
                case C.TAREA.ESTADO.EN_APT:
                    return this.etiquetas['estado_tarea_en_apt'];
                case C.TAREA.ESTADO.EN_RUTA:
                    return this.etiquetas['estado_tarea_en_ruta'];
                case C.TAREA.ESTADO.EN_ESPERA:
                    return this.etiquetas['estado_tarea_en_espera'];
                case C.TAREA.ESTADO.EN_CLIENTE:
                    return this.etiquetas['estado_tarea_en_cliente'];
                case C.TAREA.ESTADO.REALIZANDO_TAREA:
                    return this.etiquetas['estado_tarea_inicio_descarga'];
                case C.TAREA.ESTADO.TERMINADO_TOTAL:
                case C.TAREA.ESTADO.EN_HUB:
                    return this.etiquetas['estado_tarea_terminado_total'];
                case C.TAREA.ESTADO.TERMINADO_PARCIAL:
                    return this.etiquetas['estado_tarea_terminado_parcial'];
                case C.TAREA.ESTADO.RECHAZADO:
                    return this.etiquetas['estado_tarea_rechazado'];
                case C.TAREA.ESTADO.NO_REALIZADO:
                    return this.etiquetas['estado_tarea_no_realizado'];
                case C.TAREA.ESTADO.EN_CAMINO:
                    return this.etiquetas['estado_tarea_en_camino'];
            }
        }
        return "";
    }

    get debeCobrar() : boolean{
        if(this.estado!=null){
           if(this.estado == C.TAREA.ESTADO.EN_CLIENTE || this.estado == C.TAREA.ESTADO.REALIZANDO_TAREA){
                return true;
           }
        }
        return false;
    }

    get estadoTareaToIcon() : string{
        if(this.estado!=null){
            switch(this.estado){
                case C.TAREA.ESTADO.PENDIENTE:
                case C.TAREA.ESTADO.EN_APT:
                case C.TAREA.ESTADO.EN_RUTA:
                    return "text-danger";
                case C.TAREA.ESTADO.EN_ESPERA:
                case C.TAREA.ESTADO.EN_CLIENTE:
                case C.TAREA.ESTADO.REALIZANDO_TAREA:
                    return "text-warning";
                case C.TAREA.ESTADO.TERMINADO_TOTAL:
                case C.TAREA.ESTADO.TERMINADO_PARCIAL:
                case C.TAREA.ESTADO.RECHAZADO:
                case C.TAREA.ESTADO.NO_REALIZADO:
                    return "text-success";

            }
        }
        return "";
    }

    get estadoTareaToColor() : string{
        if(this.estado!=null){
            switch(this.estado){
                case C.TAREA.ESTADO.PENDIENTE:
                case C.TAREA.ESTADO.EN_APT:
                case C.TAREA.ESTADO.EN_RUTA:
                    return "Inicio";
                case C.TAREA.ESTADO.EN_ESPERA:
                case C.TAREA.ESTADO.EN_CLIENTE:
                case C.TAREA.ESTADO.REALIZANDO_TAREA:
                    return "En Viaje";
                case C.TAREA.ESTADO.TERMINADO_TOTAL:
                case C.TAREA.ESTADO.TERMINADO_PARCIAL:
                case C.TAREA.ESTADO.RECHAZADO:
                case C.TAREA.ESTADO.NO_REALIZADO:
                    return "Terminado";
            }
        }
        return "";
    }

    get fechaInicioToFiltro() : string{
        let label = '';
        if(typeof(this.fechaInicio) != "undefined" && this.fechaInicio != null){
            label = dateToString(this.fechaInicio);
        }
        return label;
    }

    get fechaInicioToString() : string{
      let label = '';
      if(typeof(this.fechaInicio) != "undefined" && this.fechaInicio != null){
          label = dateAndHourToString(this.fechaInicio);
      }
      return label;
    }

    get fechaFinToFiltro() : string{
        let label = '';
        if(typeof(this.fechaFin) != "undefined" && this.fechaFin != null){
            label = dateToString(this.fechaFin);
        }
        return label;
    }

    get fechaFinToString() : string{
      let label = '';
      if(typeof(this.fechaFin) != "undefined" && this.fechaFin != null){
          label = dateAndHourToString(this.fechaFin);
      }
      return label;
    }


    get fechaRealAtencionToString() : string{
        let label = '';
        if(typeof(this.fechaRealAtencion) != "undefined" && this.fechaRealAtencion != null){
            label = dateAndHourToString(this.fechaRealAtencion);
        }
        return label;
    }
}
