import { Compania } from '../index';

export class ImportarZonaTransporteDTO {
    //PK
    zonaTransporteId: Number;

    //Atributos
    codigo : string;
    nombre : string;

    //Relaciones
    compania : Compania;

    //validaciones
    zonaTransporteValida: boolean;
    codigoValido: boolean;
    nombreValido: boolean;

    //Otras Variables
    styleClass: string;

    //mensajes de error
    mensajeErrorCodigo: string;
    mensajeErrorNombre: string;

    //Constructor
    constructor(){};

    //Metodos Color de Celda
    /*get getColorCeldaCodigo() : string{
        if(this.mensajeErrorCodigo!='')
            return "red-bg";
        else return "";
    }
    get getColorCeldaNombre() : string{
        if(this.mensajeErrorNombre!='')
            return "red-bg";
        else return "";
    }*/

    //Metodo Color de Celda
    cellStyle(isValid: boolean){
        if(!isValid)
            return "red-bg";
        return "";
    }
      //Metodo Color de Celda
    cellStyleDOS(isValid: boolean){
        if(!isValid)
            return "red-bg";
        return "";
    }
    //Metodos Mensaje de Error
    get getMensajeErrorCodigo(): string{
        if(this.mensajeErrorCodigo!='')
            return this.mensajeErrorCodigo;
        else return "";
    }
    get getMensajeErrorNombre(): string{
        if(this.mensajeErrorNombre!='')
            return this.mensajeErrorNombre;
        else return "";
    }

    //Otros
    clone(): any {
        var cloneObj = new (this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    copiar(objeto : any){
        //Propios
        this.zonaTransporteId = objeto.zonaTransporteId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.mensajeErrorCodigo = objeto.mensajeErrorCodigo;
        this.mensajeErrorNombre = objeto.mensajeErrorNombre;
        this.zonaTransporteValida = objeto.zonaTransporteValida;
        this.codigoValido = objeto.codigoValido;
        this.nombreValido = objeto.nombreValido;
        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }
}