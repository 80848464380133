import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
import { WidgetQuimicaComponent } from './widget-quimica/widget-quimica.component';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'entrega', loadChildren: () => import('./entrega/entrega.module').then(m => m.EntregaModule) },
  { path: 'log-integracion', loadChildren: () => import('./log-integracion/log-integracion.module').then(m => m.LogIntegracionModule),canActivateChild: [AuthGuard] },
  { path: 'widget-quimica-suiza', component: WidgetQuimicaComponent},
  { path: 'widget', loadChildren: () => import('./widget/widget.module').then(m => m.WidgetModule) },
  { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivateChild: [AuthGuard]},
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
