import { viewClassName } from '@angular/compiler';
import { error } from '@angular/compiler/src/util';
import { AfterViewInit, Component,ViewChild, Input, OnDestroy, OnInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { C, dateToString } from '@shared/commons';
import { ChatViewDTO } from '@shared/dto';
import { ChatProvider } from '@shared/providers';
import { SocketIOService } from '@shared/services/socket-io.service';
import { MessageService } from 'primeng';
import { element } from 'protractor';
import { Socket } from 'socket.io-client';
import { ChatDTO, ChatService } from '../resources';


@Component({
    selector: 'app-chat-maximizado',
    templateUrl: './chat-maximizado.component.html',
    styleUrls: ['./chat-maximizado.component.scss'],
    providers: [  MessageService]
})
export class ChatMaximizadoComponent implements OnInit, AfterViewInit, OnDestroy
{

    private currentUser = JSON.parse(localStorage.getItem("currentUser"));
    private limiteArchivo = Math.pow(1024,2) * 100; //Equivalente a 100mb (1024^2 * 100)

    public mensaje: string;
    public chatSocket: Socket;
    public chats: ChatDTO[] = [];
    public transportistaId: number;
    private txtErrorSinConexion: string;
    private txtErrorEnviarImagen: string;
    private txtErrorEnviarMensaje: string;
    private txtErrorTamanoArchivo: string;
    private txtImagenEnviada: string;

    @Input()view: ChatViewDTO;
    @ViewChildren('padreInput') divs: QueryList<ElementRef>

    constructor(
        private translate : TranslateService,
        private socketIOService : SocketIOService,
        private chatService: ChatService,
        public chatProvider: ChatProvider,
        private messageService: MessageService
    ){
        this.mensaje = '';
        this.chatSocket = socketIOService.obtenerSocket();
        this.conectar();
    }
        
    ngOnInit(){
        this.cargarLista();
        this.obtenerEtiquetas();
    }

    ngAfterViewInit(){
       this.chatProvider.editNotificacionChat(this.view.viajeId,C.CHAT_VARS.ESTADO.LEIDO );
    }

    ngOnDestroy(){
       this.view.view =  false;
    }

    obtenerTransportistaId(){
        for(let chat of this.chats) {
            if(chat.usuarioId != this.currentUser.usuarioId && chat.usuarioNombre == this.view.transportista){
                this.transportistaId = chat.usuarioId;  
                break;
            }
         }
    }
    

    seleccionarArchivo(e,viajeId){
        if (e.target.files && e.target.files.length) {
            const file = e.target.files[0];
            let fileReader = new FileReader();

            fileReader.onload = (a : any) => {
                if(a.loaded > 0 && a.loaded <= this.limiteArchivo){
                    this.enviarImagen(file);
                } else {
                    this.showToast('error', '', this.txtErrorTamanoArchivo);
                }
                this.cleanChat();
            };
            

            fileReader.readAsDataURL(file);
        }
    }

    abrirSelectorArchivo(){
        document.getElementById('archivoChat' + this.view.viajeId).click();
    }
    
    
    cargarLista(){
        if(this.view.viajeId){
            this.chatService.listarHistorial(this.view.viajeId).subscribe(
                (data : ChatDTO[]) =>{
                    this.chats = data;
                    this.scrollBottom(1000);
                },
                (error) => {
                    console.log(error);
                },
                () => { // when complete
                    this.obtenerTransportistaId();
                    this.mandarMensajesLeidos();
                }
            );
        }
        
    }

    enviarMensaje(): void {
        if(this.mensaje == '')
            return;
        if(this.chatSocket.connected){
            let chat : ChatDTO = this.buildMensaje();
            //Completamos el mensaje
            chat.tipo   = C.CHAT_VARS.TIPO.TEXTO;
            chat.texto  = this.mensaje;
            this.chatSocket.emit(C.CHAT_VARS.EVENTO_ENVIA_MENSAJE_SUPERVISOR, chat);
            this.chats.push(chat);
            this.scrollBottom(500);
        } else {
            this.showToast('error', '', this.txtErrorSinConexion);
        }

        this.mensaje = '';
    }

    enviarImagen(file: File){
        if(this.chatSocket.connected){
            let chat : ChatDTO = this.buildMensaje();
            let formData = new FormData();
            var content = `{ "path": "entidades/conversaciones/${chat.viajeId}-${this.obtenerFormatoFecha(chat.fechaEnvio)}-conversacion.png" }`;
            var blob = new Blob([content], {type: 'application/json'});
            formData.append('props', blob);
            formData.append('image', file);
    
            this.chatService.subirArchivo(formData).subscribe(body => {
                let resultado = body.resultado;
                if(resultado){
                    //completamos el mensaje
                    chat.tipo   = C.CHAT_VARS.TIPO.IMAGEN;
                    chat.url    = resultado.path
                    this.chatSocket.emit(C.CHAT_VARS.EVENTO_ENVIA_MENSAJE_SUPERVISOR, chat);
                    this.chats.push(chat);
                    this.scrollBottom(500);
                    this.showToast('success', '', this.txtImagenEnviada);
                } else {
                    this.showToast('error', '', this.txtErrorEnviarImagen);
                }
            },(err)=> {
                console.log(err)
                this.showToast('error', '', err.mensaje);
            });
        } else {
            this.showToast('error', '', this.txtErrorSinConexion);
        }
       
    }

    onClickMinimizar(view: ChatViewDTO){
        this.chatProvider.editViewChat(view.viajeId,false);
    }
    onClickCerrar(view: ChatViewDTO){
       this.chatProvider.removeViewChat(view.viajeId);
    }

    private buildMensaje(){
        let chat : ChatDTO = new ChatDTO();
        chat.companiaId = this.currentUser.compania.companiaId;
        chat.viajeId    = this.view.viajeId;
        chat.codigoViaje = this.view.codigo;
        chat.usuarioId  = this.currentUser.usuarioId;
        chat.usuarioNombre = this.currentUser.nombre;
        chat.estado     = C.CHAT_VARS.ESTADO.ENVIADO;
        chat.fechaEnvio = new Date().getTime();

        return chat;
    }

    private mandarMensajesLeidos(){
        let mensajeActualizar: ChatDTO = new ChatDTO;

        mensajeActualizar.usuarioId = this.transportistaId;
        mensajeActualizar.viajeId = this.view.viajeId;
        mensajeActualizar.estado = C.CHAT_VARS.ESTADO.LEIDO;

        this.chatSocket.emit(C.CHAT_VARS.EVENTO_ENVIA_MENSAJES_LIEDOS_SUPERVISOR, mensajeActualizar);
        
    }

    private showToast(severidad:string, titulo:string, detalle:string) {
        this.messageService.add({severity:severidad, summary:titulo, detail:detalle});
    }

    private scrollBottom(tiempo){
        setTimeout(()=> {
            var elem = document.getElementById('chatBodyRecord-'+this.view.viajeId);
            elem.scrollTop = elem.scrollHeight;
        },tiempo);
    }

    private cleanChat(){
            let input = this.divs.first.nativeElement.children[2]; 
            input.value = '';
    }

    private obtenerFormatoFecha(fechaHora :number): string{
        return dateToString(new Date(fechaHora),"yyMMdd-HHmmss")
    }

    private obtenerEtiquetas(){
        this.translate.get([
            'WEB.chat.mensajes-error.sin-conexion',
            'WEB.chat.mensajes-error.en-enviar-imagen',
            'WEB.chat.mensajes-error.archivo-grande',
            'WEB.chat.mensajes.imagen-enviada',
            'WEB.chat.mensajes-error.enviar-mensaje',
        ]).subscribe (translations => {
            this.txtErrorSinConexion    = translations['WEB.chat.mensajes-error.sin-conexion'];
            this.txtErrorEnviarImagen   = translations['WEB.chat.mensajes-error.en-enviar-imagen'];
            this.txtErrorTamanoArchivo  = translations['WEB.chat.mensajes-error.archivo-grande'];
            this.txtErrorEnviarMensaje  = translations['WEB.chat.mensajes-error.enviar-mensaje'];
            this.txtImagenEnviada       = translations['WEB.chat.mensajes.imagen-enviada'];
        });
    }

    private conectar(){
        this.chatSocket.on(C.CHAT_VARS.EVENTO_LLEGA_MENSAJE_TRANSPORTISTA, this.onMensajeTransportista);
        this.chatSocket.on(C.CHAT_VARS.EVENTO_ERROR_MENSAJE_SUPERVISOR, this.onMensajeError);
        this.chatSocket.on(C.CHAT_VARS.EVENTO_LLEGA_MENSAJES_LEIDOS_TRANSPORTISTA, this.onMensajesLeidos);
        this.chatSocket.connect();
    }
     
    private onMensajeTransportista = (...arg) => {
        if(this.view.view){
            let argumento = arg[0];
            if(this.view.viajeId == argumento.viajeId){
                this.chats.push(argumento);
                this.chatProvider.addNotificacionChat(argumento);
                this.mandarMensajesLeidos();
                setTimeout(()=> {
                    this.chatProvider.editNotificacionChat(this.view.viajeId,C.CHAT_VARS.ESTADO.LEIDO );
                },500);
                this.scrollBottom(500);
            }
        }
    }

    private onMensajeError = (...arg) => {
        if(this.view.view){
            this.showToast('error', '', this.txtErrorEnviarMensaje);
        }
    }

    private onMensajesLeidos = (...arg) => {
        if(this.view.view){
            this.chats = this.chats.map(e => {
                let chat :  ChatDTO = new ChatDTO();
                chat.copiar(e);
                if(chat.usuarioId == this.currentUser.usuarioId){
                    this.chatProvider.editNotificacionChat(this.view.viajeId,C.CHAT_VARS.ESTADO.LEIDO );
                    chat.estado = C.CHAT_VARS.ESTADO.LEIDO;
                }
                return chat;
            });
        }
        
    }
}
   