import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';

//Clases
import { EstadoTarea } from '../models';

//Otros
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class EstadoTareaService {

  constructor(private http : HttpClient2) {

  }

  registrar(tareaId : number, estadoTarea : EstadoTarea, funcionalidad?: String){
    return this.http.registrar('/'+tareaId+'/estados-tarea', estadoTarea,  { funcionalidad });
  }

  listarIndicadoresEstado(params : URLSearchParams){
    return this.http.listar('/'+0+'/estados-tarea/indicadores-estado', params);
  }
  
  historial(tareaId: number){
    return this.http.listar(`/${tareaId}/estados-tarea/historial`)
      .map(res => res && res.resultado);
  }
 
 obtenerTareaPadre(tareaHijaId : number){
     return this.http.obtener(`/tarea/tarea-hija/${tareaHijaId}`)
 }

 registrarMasivo( estadoTareas : Array<EstadoTarea>, funcionalidad?: String){
  let offset = new Date().getTimezoneOffset();
  let gmt = offset < 0 ? Math.abs(offset) : -offset;

  return this.http.registrar(`/estados-tarea/registrar-masivo-2`, estadoTareas,  { funcionalidad, gmt });
}

}
