import { Compania } from './compania';
import { Funcionalidad } from './funcionalidad';
import { TipoUsuario } from './tipoUsuario';
import { Perfil } from './perfil';
import { Configuracion } from './configuracion';
import { EmpresaTransporte } from '@shared/models/empresaTransporte';
import { FuncionalidadPersonalizacion } from '@shared/models/funcionalidadPersonalizacion';
import { Marca } from './marca';
import { ClienteFinal } from './clienteFinal';

export class Usuario {
    //PK
    usuarioId : number;
    usuario : string;
    contrasenha : string;
    nombre : string;
    correo : string;
    estado : number;

    //Relaciones
    compania : Compania;
    tipoUsuario : TipoUsuario;
    listPerfil : Array<Perfil>;
    empresaTransporte : EmpresaTransporte;
    marca : Marca;
    clienteMarca : Marca;
    clienteFinal: ClienteFinal;

    //Otras Variables
    logSessionId : number;
    funcionalidades : Array<Funcionalidad>;
    esSuperUsuario : boolean;
    esAdministrador : boolean;
    esSupervisor : boolean;
    esEmpresaTransporteAdministrador : boolean;
    esPerfilSac: boolean;
    esPerfilCliente: boolean;
    esPerfilJefeDistribucion: boolean;
    esPerfilAnalistaDistribucion: boolean;
    esPerfilClienteFinal: boolean;
    estadoRegistro : string;
    resultadoRegistro : string;
    logo : string;
    listConfCompania? : Array<Configuracion>;
    listFuncionalidadPersonalizacion? : Array<FuncionalidadPersonalizacion>;
    companiaPadre: number;
    supervisorPorDefecto: number;
    //variable para saber si necesita cambiar la contrasena
    actualizarContrasena : boolean;
    mostrarFechaExpiracion : boolean;
    diasVigenciaContrasena : number;

    listCompania : Array<Compania>;
    companias : Array<Compania>;


    //Metodos
    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }
    copiar(objeto : any){
        //Propios
        this.usuarioId = objeto.usuarioId;
        this.usuario = objeto.usuario;
        this.contrasenha = objeto.contrasenha;
        this.nombre = objeto.nombre;
        this.correo = objeto.correo;
        this.estado = objeto.estado;
        this.actualizarContrasena = objeto.actualizarContrasena;
        this.diasVigenciaContrasena = objeto.diasVigenciaContrasena;
        this.mostrarFechaExpiracion = objeto.mostrarFechaExpiracion;
        this.esSuperUsuario = objeto.esSuperUsuario;
        this.esAdministrador = objeto.esAdministrador;
        this.esSupervisor = objeto.esSupervisor;
        this.esEmpresaTransporteAdministrador = objeto.esEmpresaTransporteAdministrador;
        this.esPerfilSac = objeto.esPerfilSac;
        this.esPerfilCliente =  objeto.esPerfilCliente;
        this.esPerfilJefeDistribucion =  objeto.esPerfilJefeDistribucion;
        this.esPerfilAnalistaDistribucion =  objeto.esPerfilAnalistaDistribucion;
        this.logSessionId = objeto.logSessionId;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.companiaPadre = objeto.companiaPadre;
        this.supervisorPorDefecto = objeto.supervisorPorDefecto;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
        if(objeto.tipoUsuario != null){
            this.tipoUsuario = new TipoUsuario();
            this.tipoUsuario.copiar(objeto.tipoUsuario);
        }
        if(objeto.empresaTransporte != null){
            this.empresaTransporte = new EmpresaTransporte();
            this.empresaTransporte.copiar(objeto.empresaTransporte);
        }
        if(objeto.marca != null){
            this.marca = new Marca();
            this.marca.copiar(objeto.marca);
        }
        if(objeto.clienteMarca != null){
            this.clienteMarca = new Marca();
            this.clienteMarca.copiar(objeto.clienteMarca);
        }
        if(objeto.clienteFinal != null){
            this.clienteFinal = new ClienteFinal();
            this.clienteFinal.copiar(objeto.clienteFinal);
        }
        if(objeto.listPerfil != null){
            let perfil : Perfil;
            this.listPerfil = new Array();
            for (let r of objeto.listPerfil) {
                if(r != null){
                    perfil = new Perfil();
                    perfil.copiar(r);
                    this.listPerfil.push(perfil);
                }
            }
        }

        if(objeto.listCompania != null){
            let compania : Compania;
            this.listCompania = new Array();
            for (let r of objeto.listCompania) {
                if(r != null){
                    compania = new Compania();
                    compania.copiar(r);
                    this.listCompania.push(compania);
                }
            }
        }

        if(objeto.companias != null){
            let compania : Compania;
            this.companias = new Array();
            for (let r of objeto.companias) {
                if(r != null){
                    compania = new Compania();
                    compania.copiar(r);
                    this.companias.push(compania);
                }
            }
        }
    }

    get estadoUsuarioToString() : string{
        if(this.estado!=null){
            switch(this.estado){
                case 1:
                    return "COMMON.boton.activo";
                case 2:
                    return "COMMON.boton.inactivo";
            }
        }
    }

}
