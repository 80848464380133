import { Compania, Usuario } from '../../../../shared';
import { C } from '../../../../shared';
import { TranslateService } from '@ngx-translate/core';

export class ConfiguracionDTO{
    fechaHoraEjecucion : Date;
    compania : Compania;
    usuario : Usuario;
    tipoAcceso : string;
    aplicacion: string;
    operacion: string;
    duracion: number;
    detalle: string;
    funcionalidad: string;
    private translate: TranslateService;

    constructor(){}

    copiar(objeto : any){        
        this.fechaHoraEjecucion = objeto.fechaHoraEjecucion;
        this.tipoAcceso = objeto.tipoAcceso;      
        this.aplicacion = objeto.aplicacion;
        this.operacion = objeto.operacion;
        this.duracion = objeto.duracion;
        this.detalle = objeto.detalle;
        this.funcionalidad = objeto.funcionalidad;
        
        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
        if(objeto.usuario != null){
            this.usuario = new Usuario();
            this.usuario.copiar(objeto.usuario);
        }
                    
    }

    
}