import { Usuario } from './usuario';
import { MotivoNoAutorizacion } from './motivoNoAutorizacion';

export class Autorizacion{
    //pk
    canalId: number;

    //atributos
    comentario: string;
    tipo: number;

    //Relaciones
    usuario: Usuario;
    motivoNoAutorizacion: MotivoNoAutorizacion;

     
    copiar(objeto : any){
    //Propios
    this.canalId = objeto.canalId;
    this.comentario = objeto.comentario;
    this.tipo = objeto.tipo;

    //Relaciones
    if(objeto.usuario != null){
        this.usuario = new Usuario();
        this.usuario.copiar(objeto.usuario);
    }
    if(objeto.motivoNoAutorizacion != null){
        this.motivoNoAutorizacion = new MotivoNoAutorizacion();
        this.motivoNoAutorizacion.copiar(objeto.motivoNoAutorizacion);
    }

    }

}