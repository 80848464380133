import { Injectable } from '@angular/core';
import { Configuracion } from '@shared/models';
import { ConfiguracionService } from 'src/app/layout/seguridad/configuracion/resources';
import { UsuarioService } from './usuario.service';

export enum PrefijosValidos {
  LINK = 'LINK',
  PERSO = 'PERSO',
  NOTI = 'NOTI',
  SENS = 'SENS',
  SIN_PREFIJO = 'SIN_PREFIJO'
}

@Injectable({
  providedIn: 'root'
})
export class CompaniaConfiguracionService {

  static readonly LOCALSTORAGE_LLAVE_ACTUAL = "compania_configuraciones";

  // Se usará en el futuro para obtener las configuraciones despues de cierto tiempo
  //static readonly LOCALSTORAGE_LLAVE_FECHA_EXPIRACION = "compania_configuraciones_fecha_expiracion";

  constructor(private usuarioService: UsuarioService, private configuracionService: ConfiguracionService) { }

  /**
   * Retorna las configuraciones de la compania actual.
   * Si las configuraciones no existen en el local storage retornará null.
   * Si el prefijo solicitado es indefinido, el método retornará todas las configuraciones que se encuentren en el local storage.  
   * Como se tienen configuraciones que no usan algún prefijo. Si el prefijo solicitado es 'SIN_PREFIJO', 
   * el método retornará las configuraciones que no tengan algun prefijo definido en el enum PrefijosValidos exceptuando 'SIN_PREFIJO'.
   * Si el prefijo solicitado no es válido, se lanzará una excepción.
   * @param {number} prefijo
   * @returns {Array<Configuracion> | null}
  */
  obtenerConfiguraciones(prefijo?: string): Array<Configuracion> {
    const configuracionesDesdeLocalStorage = localStorage.getItem(CompaniaConfiguracionService.LOCALSTORAGE_LLAVE_ACTUAL);

    if (!configuracionesDesdeLocalStorage) {
      return null;
    }

    const configuracionesCompania: Array<Configuracion> = JSON.parse(localStorage.getItem(CompaniaConfiguracionService.LOCALSTORAGE_LLAVE_ACTUAL));

    if (!prefijo) {
      return configuracionesCompania;
    }

    this.validarPrefijoSolicitado(prefijo);

    if (prefijo === PrefijosValidos.SIN_PREFIJO) {
      return configuracionesCompania.filter((configuracionCompania: Configuracion) =>
        !configuracionCompania.campo.startsWith(PrefijosValidos.LINK.toString()) &&
        !configuracionCompania.campo.startsWith(PrefijosValidos.PERSO.toString()) &&
        !configuracionCompania.campo.startsWith(PrefijosValidos.NOTI.toString())
      );
    }

    return configuracionesCompania.filter(e => e.campo.startsWith(prefijo));
  }

  /**
   * Retorna el valor de la configuración de compania según el campo solicitado.
   * Si el campo solicitado no se encuentra en la lista de configuraciones, se lanzará una excepción.
   * @param {string} campo
   * @param {Array<Configuracion>} configuraciones
   * @returns {string}
  */
  obtenerValorSegunCampo(campo: string, configuraciones: Array<Configuracion>): string {
    const configuracion = configuraciones.find((configuracion: Configuracion) => configuracion.campo === campo);

    if (!configuracion) {
      throw new Error(`El campo solicitado no es válido - ${campo}`);
    }

    return configuracion.valor;
  }

  obtenerValorPorEstadoSegunCampo(campo: string, configuraciones: Array<Configuracion>): string {
    const configuracion = configuraciones.find((configuracion: Configuracion) => configuracion.campo === campo);

    if (!configuracion) {
      throw new Error(`El campo solicitado no es válido - ${campo}`);
    }

    return configuracion.estado ? configuracion.valor : configuracion.valorPorDefecto;
  }

  /**
   * Retorna el estado de la configuración de compania según el campo solicitado.
   * Si el campo solicitado no se encuentra en la lista de configuraciones, se lanzará una excepción.
   * @param {string} campo
   * @param {Array<Configuracion>} configuraciones
   * @returns {boolean}
  */
  obtenerEstadoSegunCampo(campo: string, configuraciones: Array<Configuracion>): boolean {
    const configuracion = configuraciones.find((configuracion: Configuracion) => configuracion.campo === campo);

    if (!configuracion) {
      throw new Error(`El campo solicitado no es válido - ${campo}`);
    }

    return configuracion.estado;
  }

  /**
   * Persiste en el local storage las configuraciones de compania obtenidas del servicio de Configuracion.
   * @returns {void}
  */
  persistirConfiguracionesDeCompania(): void {
    const currentUser = this.usuarioService.obtenerUsuarioActual();
    if (currentUser && currentUser.compania) {
      this.configuracionService.obtenerConfiguracionCompania(currentUser.compania.companiaId)
        .subscribe((configuraciones: Array<Configuracion>) => {
          localStorage.setItem(CompaniaConfiguracionService.LOCALSTORAGE_LLAVE_ACTUAL, JSON.stringify(configuraciones));
          // localStorage.setItem(CompaniaConfiguracionService.LOCALSTORAGE_LLAVE_FECHA_EXPIRACION,
          //   JSON.stringify(moment(new Date()).add(30, 'm').format('DD/MM/YYYY HH:mm:ss'))
          // );
        },
          console.error
        );
    }
  }

  /**
   * Persiste en el local storage las configuraciones de compania obtenidas del servicio de Configuracion.
   * Permite esperar a la inserción en el local storage mediante un await
   * @returns {void}
  */
   async persistirConfiguracionesDeCompaniaAsync(companiaId?: number): Promise<void> {
    const currentUser = this.usuarioService.obtenerUsuarioActual();
    const _companiaId = companiaId || (currentUser && currentUser.compania && currentUser.compania.companiaId);
    if (_companiaId) {
      const configuraciones = await this.configuracionService.obtenerConfiguracionCompania(_companiaId).toPromise();
      localStorage.setItem(CompaniaConfiguracionService.LOCALSTORAGE_LLAVE_ACTUAL, JSON.stringify(configuraciones));
    }
  }

  /* Métodos de utilidad */
  private validarPrefijoSolicitado(prefijo: string): void {
    const esPrefijoValido = this.enumKeys(PrefijosValidos)
      .find(value => prefijo.toUpperCase() === PrefijosValidos[value]);

    if (!esPrefijoValido) {
      throw new Error(`El prefijo solicitado no es válido - ${prefijo}`);
    }
  }

  private enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
  }

  // Se usará en el futuro para obtener las configuraciones despues de cierto tiempo
  // private esFechaExpiracionVencida(): boolean {
  //   return moment(moment(new Date()).format('DD/MM/YYYY HH:mm:ss'))
  //     .isAfter(JSON.parse(localStorage.getItem(CompaniaConfiguracionService.LOCALSTORAGE_LLAVE_FECHA_EXPIRACION)));
  // }

}

