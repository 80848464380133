import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { HttpParams } from '@angular/common/http';
//Clases
import { Transportista, EmpresaTransporte } from '../models';
import { Observable } from 'rxjs/Observable';
import { IResultado } from '../index';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { C } from '@shared/commons';

@Injectable()
export class TransportistaService {

  currentUser = JSON.parse(localStorage.getItem("currentUser"));

  constructor(private http : HttpClient2) { }

  listar(empresaTransporteId : number) : Observable<Array<Transportista>>{
    return this.http.listar("/"+empresaTransporteId + "/transportistas").map(
      (data: IResultado) => {
        let list = Array<Transportista>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Transportista();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  obtener(transportistaId : number) : Observable<Transportista>{
    return this.http.obtener(`/transportistas/${transportistaId}`).map(
      (data: IResultado) => {
        let obj = new Transportista();
        obj.copiar(data.resultado);
        return obj;
    });
  }
  
  consultar(filtro): Observable<Array<Transportista>> {     
    const options = { funcionalidad: C.FUNCIONALIDAD_LOG.TRANSPORTISTA }; 
    let empresaTransporteId = this.currentUser.empresaTransporte.empresaTransporteId      
    let companiaId = this.currentUser.compania.companiaId; 
    return this.http.consultar(`/${empresaTransporteId}/transportistas/consultar`, filtro, options).map(
      (data: IResultado) => {
        let newData = Array<Transportista>();
        if (data && data.resultado) {
          return data.resultado.map((row, indice) => {
            const dto = new Transportista();       
            dto.transportistaId = row.transportistaId;
            dto.nombre = row.nombre;
            dto.apellido = row.apellido;
            dto.docIdentificacion = row.docIdentificacion;
            dto.licencia = row.licencia;
            dto.empresaTransporte = row.empresaTransporte;

            return dto;
          });
        }
        return newData;

      });
  }

  //consultar transportistas disponibles segun parametros
  consultarDisponibilidad(parametrosTransportista) : Observable<Array<Transportista>>{
    return this.http.consultar("/transportistas/consultar/disponibilidad", parametrosTransportista).map(
      (data: IResultado) => {
        let list = Array<Transportista>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Transportista();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  esEliminable(empresaTransporteId : number, params : HttpParams){
    return this.http.consultar("/"+empresaTransporteId + "/transportistas/es-eliminable", {}, { params: params });
  }
 

  eliminar(transportistaId : number, funcionalidad?: String){
    return this.http.eliminar(`/transportistas/${transportistaId}`, { funcionalidad });
  }
  
  addOrEdit(transportista: Transportista, funcionalidad?: string){
    let empresaTransporteId = this.currentUser.empresaTransporte.empresaTransporteId;
    let companiaId = this.currentUser.compania.companiaId;
    if (transportista.transportistaId) {
      return this.http.modificar(`/${empresaTransporteId}/transportistas/compania/${companiaId}`, transportista,  { funcionalidad });
    } else {      
      return this.http.registrar(`/${empresaTransporteId}/transportistas/compania/${companiaId}`, transportista,  { funcionalidad });
    }
  }

  //Consultar por Compañia
  consultarPorCompania(data): Observable<Array<Transportista>> {
    let companiaId = this.currentUser.compania.companiaId;
    return this.http.consultar(`/transportistas/consultar/compania/${companiaId}`, data).map(
        (data: IResultado) => {
          let list = Array<Transportista>();
          if (data) {
            for (const t of data.resultado) {
              let transportista = new Transportista();
              transportista.copiar(t);
              list.push(transportista);
            }
          }
          return list;
        });
        
  }

  //busca x empresa de transporte
  consultarPorEmpresaTransporte(data): Observable<Array<Transportista>> {
    return this.http.consultar(`/transportistas/obtener-por-empresa-transportes`, data).map(
        (data: IResultado) => {
          let list = Array<Transportista>();
          if (data) {
            for (const t of data.resultado) {
              let transportista = new Transportista();
              transportista.copiar(t);
              list.push(transportista);
            }
          }
          return list;
        });
  }

}
