export class Validable{
    validable : boolean;
    id : number;
    valor : string;
    mensajeError : string;
    fecha : Date;

    duplicado : boolean;
    diferente : boolean;
    existe : boolean;
    valido: boolean;

    copiar(objeto : any){
        this.validable = objeto.validable;
        this.id = objeto.id;
        this.valor = objeto.valor;
        this.mensajeError = objeto.mensajeError;
        this.fecha = objeto.fecha;
        this.valido = objeto.valido;

        this.duplicado = objeto.duplicado;
        this.diferente = objeto.diferente;
        this.existe = objeto.existe;
    }
}