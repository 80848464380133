import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Observable } from 'rxjs/Observable';
import { IResultado } from '../commons/interfaces';
import { ClienteCobranzaDto } from '../dto/clienteCobranza.dto';
import { ClienteCobranza } from '@shared/models/clienteCobranza';
import { ControlCobranzaDetalleDTO, ControlCobranzaDTO } from 'src/app/layout/cobranza/control/resources';

@Injectable()
export class ClienteCobranzaService {
  ciaId : string;
  usuId: number;

  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = currentUser.esEmpresaTransporteAdministrador? "/"+currentUser?.companiaPadre: "/"+currentUser.compania.companiaId;
    this.usuId = currentUser.usuarioId;
  }

  consultar(data) : Observable<Array<ClienteCobranzaDto>>{
    return this.http.consultar(this.ciaId+"/clientes-cobranzas/consultar", data).map(
      (data: IResultado) => {
        let list = Array<ClienteCobranzaDto>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ClienteCobranzaDto();
            obj.copiar(d);
            list.push(obj);
          }
        } 
        return list;
    });
  }

  registrar(data : ClienteCobranza) : Observable<IResultado>{
    return this.http.consultar(`/clientes-cobranzas/registro-cobranzas?usuarioId=${this.usuId}`, data).map(
      (data: IResultado) => {
        return data;
    });
  }

  obtener(id:number) : Observable<ClienteCobranzaDto>{
    return this.http.obtener('/clientes-cobranzas/'+id).map(
      (data: IResultado) => {
        let res = new ClienteCobranzaDto();
        res.copiar(data.resultado);
        return res;
    });
  }

  actualizar(data : ClienteCobranza){
    return this.http.modificar('/clientes-cobranzas',data);
  }

  eliminar(id:number){
    return this.http.eliminar('/clientes-cobranzas/'+id);
  }

  listarControl(data:any) {
    return this.http.listar(this.ciaId+"/clientes-cobranzas/control-listar", { params: data });
  }

  obtenerResumen(data){
    return this.http.listar(this.ciaId+"/clientes-cobranzas/resumen-cobranza", { params: data })
  }

  listarControlDetalle(sedeId: number, data:any)  : Observable<Array<ControlCobranzaDetalleDTO>>{
    return this.http.listar(this.ciaId+"/clientes-cobranzas/control-detalle/" + sedeId, { params: data }).map(
      (data: IResultado) => {
        let listDetalle : Array<ControlCobranzaDetalleDTO> = [];
        if(data && data.resultado){
          for(var detalle of data.resultado){
            let res = new ControlCobranzaDetalleDTO();
            res.copiar(detalle);
            listDetalle.push(res);
          }
        }
        return listDetalle;
    });
  }
}
