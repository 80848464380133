//Libreria: ExcelJs

export class ExcelUtils {

    //Este metodo Aplica para filas (Rows) y Celdas (Cell)
    public static addStyle(object : any, type : string){

        if(type == 'title'){
            object.fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'C00000'} };
            object.alignment = { vertical: 'middle', horizontal: 'center'};

            //Estilo de Fuente
            object.font = { name: 'Arial', family: 4, size: 18, bold: true,
            color: { argb: 'ffffff' } };

        }else if(type == 'header'){
            object.fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'010101'} };
            object.alignment = { vertical: 'middle', horizontal: 'center'};

            //Estilo de Fuente
            object.font = { name: 'Arial', family: 4, size: 10, bold: true,
            color: { argb: 'ffffff' } };

        }else if(type == 'data'){
            //Estilo de Fuente
            object.font = { name: 'Arial', family: 4, size: 10};

        }else if(type == 'data-bold'){
            //Estilo de Fuente
            object.font = { name: 'Arial', family: 4, size: 10, bold: true};
        }

        
    }

    public static addAutoSize(sheet , fromRow: number) {
      const PIXELS_PER_EXCEL_WIDTH_UNIT = 6.0
      
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        return
      }
    
      const maxColumnLengths: Array<number> = []
      sheet.eachRow((row, rowNum) => {
        if (rowNum < fromRow) {
          return
        }
    
        row.eachCell((cell, num) => {
          if (typeof cell.value === 'string') {
            if (maxColumnLengths[num] === undefined) {
              maxColumnLengths[num] = 0
            }
    
            const fontSize = cell.font && cell.font.size ? cell.font.size : 11
            ctx.font = `${fontSize}pt Arial`
            const metrics = ctx.measureText(cell.value)
            const cellWidth = metrics.width
            
            maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth)
          }
        })
      })
    
      for (let i = 1; i <= sheet.columnCount; i++) {
        const col = sheet.getColumn(i)
        const width = maxColumnLengths[i]
        if (width) {
          col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1
        }
      }
    }

    //Metodo para añadir una imagen en Base64
    public static addBase64Image(workbook, worksheet, image, {col, row, w, h}){

        //Añadimos el logo de la Compañia
        let imageId1;
        imageId1 = workbook.addImage({ 
        base64: image,
        extension: 'png',
        });

        worksheet.addImage(imageId1, {
        tl: { col: col, row: row },
        ext: { width: w, height: h }
        });
    }


}