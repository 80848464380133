import { Compania } from './compania';
import { EmpresaTransporte } from './empresaTransporte';
import { Marca } from './marca';
import { ClienteFinal } from './clienteFinal';
import { EstadoSolicitudPedido } from './estadoSolicitudPedido';
import { C, Sede, Usuario, dateAndHourToString } from '..';
import { DuracionPipe } from '@shared/pipes';

export class SolicitudPedido {

    //PK
    solicitudPedidoId: number;

    //Atributos
    codigoPedido: string;
    zona: number;
    evidencia1: string;
    evidencia2: string;
    fechaCreacion: Date;
    prioridad: number;
    estadoRegistro: number;

    //Relaciones
    compania: Compania;
    marca: Marca;
    clienteFinal: ClienteFinal;
    empresaTransporte: EmpresaTransporte;
    estadoSolicitudPedido: EstadoSolicitudPedido;

    //Otros
    sede: Sede;
    nombreArchivoEvidencia1: string;
    nombreArchivoEvidencia2: string;
    fechaCreacionString: string;
    usuarioEstado1: string;
    fechaEstado1: Date;
    fechaEstado2: Date;
    fechaEstado3: Date;
    fechaEstado4: Date;
    fechaEstado5: Date;

    //Privado
    private currentUser: Usuario;

    constructor() {
        this.instanciar();
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    }

    //Metodos
    static clonar(objeto: any): SolicitudPedido {
        let clone: SolicitudPedido = new SolicitudPedido();
        //Propios
        clone.solicitudPedidoId = objeto.solicitudPedidoId;
        clone.codigoPedido = objeto.codigoPedido;
        clone.zona = objeto.zona;
        clone.evidencia1 = objeto.evidencia1;
        clone.evidencia2 = objeto.evidencia2;
        clone.fechaCreacion = objeto.fechaCreacion;
        clone.fechaCreacionString = objeto.fechaCreacionString;
        clone.prioridad = objeto.prioridad;
        clone.estadoRegistro = objeto.estadoRegistro;
        clone.nombreArchivoEvidencia1 = objeto.nombreArchivoEvidencia1;
        clone.nombreArchivoEvidencia2 = objeto.nombreArchivoEvidencia2;
        clone.usuarioEstado1 = objeto.usuarioEstado1;
        clone.fechaEstado1 = objeto.fechaEstado1;
        clone.fechaEstado2 = objeto.fechaEstado2;
        clone.fechaEstado3 = objeto.fechaEstado3;
        clone.fechaEstado4 = objeto.fechaEstado4;
        clone.fechaEstado5 = objeto.fechaEstado5;

        //Relaciones
        if (objeto.compania != null) {
            clone.compania.copiar(objeto.compania);
        }
        if (objeto.marca != null) {
            clone.marca.copiar(objeto.marca);
        }
        if (objeto.clienteFinal != null) {
            clone.clienteFinal.copiar(objeto.clienteFinal);
        }
        if (objeto.empresaTransporte != null) {
            clone.empresaTransporte.copiar(objeto.empresaTransporte);
        }
        if (objeto.estadoSolicitudPedido != null) {
            clone.estadoSolicitudPedido.copiar(objeto.estadoSolicitudPedido);
        }
        if (objeto.sede != null) {
            clone.sede.copiar(objeto.sede);
        }
        return clone;
    }

    public copiar(objeto: any): void {
        //Propios
        this.solicitudPedidoId = objeto.solicitudPedidoId;
        this.codigoPedido = objeto.codigoPedido;
        this.zona = objeto.zona;
        this.evidencia1 = objeto.evidencia1;
        this.evidencia2 = objeto.evidencia2;
        this.fechaCreacion = objeto.fechaCreacion;
        this.fechaCreacionString = dateAndHourToString(objeto.fechaCreacion);
        this.prioridad = objeto.prioridad;
        this.estadoRegistro = objeto.estadoRegistro;
        this.nombreArchivoEvidencia1 = objeto.nombreArchivoEvidencia1;
        this.nombreArchivoEvidencia2 = objeto.nombreArchivoEvidencia2;
        this.usuarioEstado1 = objeto.usuarioEstado1;
        this.fechaEstado1 = objeto.fechaEstado1;
        this.fechaEstado2 = objeto.fechaEstado2;
        this.fechaEstado3 = objeto.fechaEstado3;
        this.fechaEstado4 = objeto.fechaEstado4;
        this.fechaEstado5 = objeto.fechaEstado5;

        //Relaciones
        if (objeto.compania != null) {
            this.compania.copiar(objeto.compania);
        }
        if (objeto.marca != null) {
            this.marca.copiar(objeto.marca);
        }
        if (objeto.clienteFinal != null) {
            this.clienteFinal.copiar(objeto.clienteFinal);
        }
        if (objeto.empresaTransporte != null) {
            this.empresaTransporte.copiar(objeto.empresaTransporte);
        }
        if (objeto.estadoSolicitudPedido != null) {
            this.estadoSolicitudPedido.copiar(objeto.estadoSolicitudPedido);
        }
        if (objeto.sede != null) {
            this.sede.copiar(objeto.sede);
        }
    }

    instanciar(): void {
        if (this.compania == null) this.compania = new Compania();
        if (this.marca == null) this.marca = new Marca();
        if (this.clienteFinal == null) this.clienteFinal = new ClienteFinal();
        if (this.empresaTransporte == null) this.empresaTransporte = new EmpresaTransporte();
        if (this.estadoSolicitudPedido == null) this.estadoSolicitudPedido = new EstadoSolicitudPedido();
        if (this.sede == null) this.sede = new Sede();
    }

    get zonaToString(): string {
        if (this.zona != null) {
            switch (this.zona) {
                case C.ZONA.LIMA:
                    return "WEB.dashboard.diario.filtros.zona.lima";
                case C.ZONA.PROVINCIA:
                    return "WEB.dashboard.diario.filtros.zona.provincia";
            }
        }
        return "";
    }

    get isSelectable(): boolean {
        //Si es despachado ya no se selecciona
        if (this.estadoSolicitudPedido.estado == C.ESTADO_SOLICITUD_PEDIDO.ESTADO.DESPACHADO) {
            return false;
        }
        //Si el pedido ya está en facturao y es de Lima, el despacho será mendiante un viaje
        if (this.zona == C.ZONA.LIMA && this.estadoSolicitudPedido.estado == C.ESTADO_SOLICITUD_PEDIDO.ESTADO.FACTURADO) {
            return false;
        }
        //Si la compañía tiene gestionPedidosProvincia activo, el pedido se trata como el de lima (El despacho será mendiante un viaje)
        if (this.currentUser.compania.gestionPedidosProvincia == 1 && this.estadoSolicitudPedido.estado == C.ESTADO_SOLICITUD_PEDIDO.ESTADO.FACTURADO) {
            return false;
        }
        return true;
    }

    get prioridadBackgroundColor(): string {
        return this.prioridad == 1 ? '#FFFBDC' : '';
    }

    get duracion(): string {
        if (this.fechaEstado1 != null) {
            let maxFechaEstado = new Date(Math.max(...[this.fechaEstado5, this.fechaEstado4, this.fechaEstado3, this.fechaEstado2, this.fechaEstado1].map(Number)));
            let firstFechaEstado: Date = new Date(this.fechaEstado1);
            const diferenciaMinutos = maxFechaEstado.getTime() - firstFechaEstado.getTime();

            return new DuracionPipe().transform(diferenciaMinutos, 'millisecond', 'short');
        } else {
            return "";
        }
    }
}
