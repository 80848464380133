import { Cobranza } from '../models/cobranza';
export class GuiaDto{
    //PK
    guiaId : number;

    //Atributos
    clienteCobranzaId: number;
    nroDocumento: string;
    tipoDocumento: string;
    fechaEmision : number; 
    montoPorCobrar: number;
    montoCobrado: number;
    estado: number;
    tipoDocumentoId: number;

    viajeId : number;
    tareaId : number;
    detalle : string;

    //Relaciones
    cobranza: Cobranza;

    copiar(objeto : any){     
        //Propios
        this.guiaId = objeto.guiaId;
        this.nroDocumento = objeto.nroDocumento;
        this.tipoDocumento = objeto.tipoDocumento;
        this.fechaEmision = objeto.fechaEmision;
        this.montoPorCobrar = objeto.montoPorCobrar;
        this.montoCobrado = objeto.montoCobrado;
        this.estado = objeto.estado;
        this.tipoDocumentoId= objeto.tipoDocumentoId;
        this.detalle= objeto.detalle;

        if(objeto.cobranza != null){
            this.cobranza = new Cobranza();
            this.cobranza.copiar(objeto.cobranza);
        }
    }


}