import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';
import { Observacion } from '../models';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class ObservacionService {

  constructor(private http : HttpClient2) {
    
  }

  listar(tareaId : number ) : Observable<Array<Observacion>>{    
    return this.http.listar('/'+tareaId+"/observaciones").map(
      (data: IResultado) => {
        let list = Array<Observacion>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Observacion();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  registrar(tareaId : number, observacion : Observacion, funcionalidad?: string){      
    return this.http.registrar('/'+tareaId+'/observaciones', observacion, { funcionalidad });     
  }

}
