export class CompaniaImportacionConfiguracion {
    //Pk
    id: number;
    //Atributos
    companiaId: number;
    plantilla: string;
    campo: string;
    estado: boolean;
    valor: boolean;

    copiar(objeto: any) {
        //Propios
        this.id = objeto.id;
        this.companiaId = objeto.companiaId;
        this.plantilla = objeto.plantilla;
        this.campo = objeto.campo;
        this.estado = objeto.estado;
        this.valor = objeto.valor;
    }
}