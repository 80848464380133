import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Supervisor, Usuario } from '../models';
import { IResultado } from '../index';

//Otros
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class SupervisorService {

  ciaId : string;
  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<Supervisor>>{
    
    return this.http.listar(this.ciaId+"/supervisores").map(
      (data: IResultado) => {
        let list = Array<Supervisor>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Supervisor();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
    
  }

  consultar(data) : Observable<Array<Supervisor>>{
    
    return this.http.consultar(this.ciaId+"/supervisores/consultar", data).map(
      (data: IResultado) => {
        let list = Array<Supervisor>();
        let obj;
        for (const d of data.resultado) {
          obj = new Supervisor();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });
    
  }

  usuarios() : Observable<Array<Usuario>>{
    
    return this.http.listar(this.ciaId+"/supervisores/usuarios").map(
      (data: IResultado) => {
        let list = Array<Usuario>();
        let obj;
        for (const d of data.resultado) {
          obj = new Usuario();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });
    
  }

  obtener(id : number) : Observable<Supervisor>{    
    return this.http.obtener(this.ciaId+"/supervisores/"+id).map(
      (data: IResultado) => {
        let obj = new Supervisor();
        obj.copiar(data);
        return obj;
    });    
  }

  registrar(supervisor : Supervisor){
    return this.http.registrar(this.ciaId+"/supervisores", supervisor);
  }

  modificar(supervisor : Supervisor){
    return this.http.modificar(this.ciaId+"/supervisores", supervisor);
  }

  eliminar(supervisorId : number){
    return this.http.eliminar(this.ciaId+"/supervisores/"+supervisorId);
  }


}
