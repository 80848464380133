import { Cuenta } from '..';
import { Motivo } from '../models/motivo';
export class estadoCobranzaDto {
    estadoCobranzaId: number;
    nroOperacion: string;
    estado: number;

    //Relaciones
    cuenta: Cuenta;
    motivo: Motivo;

    copiar(objeto: any) {
        this.estadoCobranzaId = objeto.estadoCobranzaId;
        this.estado = objeto.estado;
        this.nroOperacion = objeto.nroOperacion;

        if (objeto.cuenta != null) {
            this.cuenta = new Cuenta();
            this.cuenta.copiar(objeto.cuenta);
        }

        if (objeto.motivo != null) {
            this.motivo = new Motivo();
            this.motivo.copiar(objeto.motivo);
        }
    }
}