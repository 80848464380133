<p-toast position="top-right"></p-toast>
<div id="header" class="ui-dialog-titlebar ui-helper-clearfix ui-corner-top">
    <span class="ui-dialog-title" id="ui-dialog-4-label">{{'WEB.notificacion.titulo' | translate }}</span>
    <div class="ui-dialog-titlebar-icons">        
        <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all" (click)="cerrarPanel($event);"><span class="pi pi-times"></span></a>
    </div>
</div>

<div id="body">
    <div class="content-tab-info-principal">
        <div class="content-tab">
            <div *ngIf="showNotiSolicitud" class="content-tab-info"  [ngClass]="{'barra': tabActual === 'generales'}"  >
                <div [style.color]="tabActual == 'generales' ? '#D81F26' : '#171717'" (click)="cambiarContent('generales')">
                    {{'WEB.notificacion.tab-general.titulo' | translate }}
                </div>
            </div>
            <div *ngIf="showNotiSolicitud" class="content-tab-info" [ngClass]="{'barra': tabActual === 'solicitudes-autorizacion'}" >
                <div [style.color]="tabActual == 'solicitudes-autorizacion' ? '#D81F26' : '#171717'" (click)="cambiarContent('solicitudes-autorizacion')">
                    {{'WEB.notificacion.tab-solicitud-autorizacion.titulo' | translate }}
                </div>
            </div>
            <div class="content-tab-info" [ngClass]="{'barra': tabActual === 'alertas-tarea'}" >
                <div [style.color]="tabActual == 'alertas-tarea' ? '#D81F26' : '#171717'" (click)="cambiarContent('alertas-tarea')">
                    {{'WEB.notificacion.tab-alerta-tarea.titulo' | translate }}
                </div>
            </div>
            <div class="content-tab-info" [ngClass]="{'barra': tabActual === 'alertas-sensores'}" >
                <div [style.color]="tabActual == 'alertas-sensores' ? '#D81F26' : '#171717'" (click)="cambiarContent('alertas-sensores')">
                    SENSORES
                </div>
            </div>
        </div>
    </div>
    
    <div [hidden]="tabActual != 'generales'" class="content-tab-info-body {{ notificacionGeneral.length > 0 ? 'of-y' : '' }} ">
        <ng-container *ngFor="let item of notificacionGeneral; let i = index">
            <app-solicitud-autorizacion-item *ngIf="item.tipoNotificacion == 1" [solicitud]="item" (cerrar)="cerrarPanel($event);"></app-solicitud-autorizacion-item>
            <app-alerta-tarea-item *ngIf="item.tipoNotificacion == 2" [notificacion]="item" (cerrar)="cerrarPanel($event);"></app-alerta-tarea-item>
            <app-alerta-sensor-item *ngIf="item.tipoNotificacion == 3" [notificacion]="item" (cerrar)="cerrarPanel($event);"></app-alerta-sensor-item>
            <app-alerta-sms-item  *ngIf="item.tipoNotificacion == 4" [index]="i+1" [notificacion]="item" (cerrar)="cerrarPanel($event);"></app-alerta-sms-item>
        </ng-container>
    </div>
    <div [hidden]="tabActual != 'solicitudes-autorizacion'" class="content-tab-info-body {{ solicitudes.length > 0 ? 'of-y' : '' }} ">
        <app-solicitud-autorizacion-item *ngFor="let solicitud of solicitudes" [solicitud]="solicitud" (cerrar)="cerrarPanel($event);" (mostrarToast)="mostrarToast($event);"></app-solicitud-autorizacion-item>
    </div>
    <div [hidden]="tabActual != 'alertas-tarea'" class="content-tab-info-body {{ alertasTareas.length > 0 ? 'of-y' : '' }} ">
        <app-alerta-tarea-item *ngFor="let item of alertasTareas" [notificacion]="item" (cerrar)="cerrarPanel($event);"></app-alerta-tarea-item>
    </div>
    <div [hidden]="tabActual != 'alertas-sensores'" class="content-tab-info-body {{ alertaSensores.length > 0 ? 'of-y' : '' }} ">
        <app-alerta-sensor-item *ngFor="let item of alertaSensores; let i = index" [index]="i+1" [notificacion]="item" (cerrar)="cerrarPanel($event);"></app-alerta-sensor-item>
    </div>
</div>
