import { Injectable } from '@angular/core';
import { HttpClient2 } from '@shared/services/http-client.service';
import { Observable } from 'rxjs';
import { CentroCosto } from '@shared/models/centroCosto';
import { IResultado } from '@shared/commons';

@Injectable({
  providedIn: 'root'
})
export class CentroCostoService {

  currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
  ciaId = "/"+this.currentUser.compania.companiaId;

  constructor(private http : HttpClient2) { }

  listar() : Observable<Array<CentroCosto>>{
    return this.http.listar(this.ciaId+"/centro-costo").map(
      (data: IResultado) => {
        let list = Array<CentroCosto>();
        let obj;
        
        if(data){
          for (const d of data.resultado) {
            obj = new CentroCosto();
            obj.copiar(d);
            list.push(obj);      
          }
        }
        return list;
      }
    );
  }
}
