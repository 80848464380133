import { Usuario } from './usuario';

export class EstadoViajeFacturacion{
    //PK
    estadoViajeFacturacionId: number;

    //Atributos
    fechaHora: string;
    estado: number;

    //Relaciones
    usuario: Usuario;


    constructor() {
        this.usuario = new Usuario();
    }

    //Metodos
    static clonar(objeto: any): EstadoViajeFacturacion {
        let estadoFacturacion: EstadoViajeFacturacion = new EstadoViajeFacturacion();
        //Propios
        estadoFacturacion.estadoViajeFacturacionId = objeto.estadoViajeFacturacionId;
        estadoFacturacion.fechaHora = objeto.fechaHora;
        estadoFacturacion.estado = objeto.estado;

        //Relaciones
        if (objeto.usuario != null) {
            estadoFacturacion.usuario.copiar(objeto.usuario);
        }  

        return estadoFacturacion;
    }

    public copiar(objeto: any): void {
        //Propios
        this.estadoViajeFacturacionId = objeto.estadoViajeFacturacionId;
        this.fechaHora = objeto.fechaHora;
        this.estado = objeto.estado;

        //Relaciones
        if (objeto.usuario != null) {
            this.usuario.copiar(objeto.usuario);
        }

    }

    instanciar(): void {
        if (this.usuario == null) this.usuario = new Usuario();
    }

    get estadoToString() : string{
        if (this.estado != null) {
            switch (this.estado) {
                case 1:         
                    return "PENDIENTE";
                case 2:
                    return "POR FACTURAR";
                case 3:
                    return "FACTURADO";
            }
        }
        return "";
    }


}
