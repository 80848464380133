import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Observable } from 'rxjs/Observable';
import { CompaniaImportacionConfiguracion } from '../models/companiaImportacionConfiguracion';

interface IResultado {
  mensaje: string;
  excepcion: string;
  resultado: any;
}

@Injectable()
export class CompaniaImportacionConfiguracionService {
  ciaId: string;

  constructor(private http: HttpClient2) { 
      let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
      this.ciaId = "/"+currentUser.compania.companiaId;
  }

  consultar(data) : Observable<Array<CompaniaImportacionConfiguracion>>{
    return this.http.consultar("/companias" + this.ciaId + "/configuracion/importacion/consultar", data).map(
      (data: IResultado) => {
        let list = Array<CompaniaImportacionConfiguracion>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new CompaniaImportacionConfiguracion();
            obj.copiar(d);
            list.push(obj);
          }
        } 
        return list;
    });

  }

}
