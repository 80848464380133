import { Almacen } from './almacen';
import { Compania } from './compania';
import { EmpresaTransporte } from './empresaTransporte';
import { Tarifario } from './tarifario';
import { Transportista } from './transportista';

export class RutaEmpresaTransporte {
    //PK
    rutaEmpresaTransporteId : number;

    //Atributos
    codigoRuta : string;
    descripcion : string;
    horaEstimadaInicio : string;
    horaEstimadaFin : string;
    leadTime : number;
    zona: string;
    hub : string;
    tarifaTipo : number;
    tarifaModo : number;
    estado : number;
    fechaHoraCreacion : Date;
    fechaHoraModificacion : Date;
    rutaId?: number;
    

    //Relaciones
    compania: Compania;
    empresaTransporte : EmpresaTransporte;
    transportista : Transportista; 
    almacen : Almacen;
    tarifarios : Tarifario[];

    

    constructor(){
        this.compania = new Compania();
        this.empresaTransporte = new EmpresaTransporte();
        this.transportista = new Transportista();
        this.almacen = new Almacen();
    }

    //Metodos
    
    copiar(objeto : any){
        //Propios
        this.rutaEmpresaTransporteId = objeto.rutaEmpresaTransporteId;
        this.codigoRuta = objeto.codigoRuta;
        this.descripcion = objeto.descripcion;
        this.horaEstimadaInicio = objeto.horaEstimadaInicio;
        this.horaEstimadaFin = objeto.horaEstimadaFin;
        this.leadTime = objeto.leadTime;
        this.zona = objeto.zona;
        this.hub = objeto.hub;
        this.tarifaTipo = objeto.tarifaTipo;
        this.tarifaModo = objeto.tarifaModo;
        this.estado = objeto.estado;
        this.fechaHoraCreacion = objeto.fechaHoraCreacion;
        this.fechaHoraModificacion = objeto.fechaHoraModificacion;
        this.rutaId = objeto.rutaId;

        //Relaciones
        if(objeto.compania != null){
            this.compania.copiar(objeto.compania);
        }
        if(objeto.empresaTransporte != null){
            this.empresaTransporte.copiar(objeto.empresaTransporte);
        }
        if(objeto.transportista != null){
            this.transportista.copiar(objeto.transportista);
        }
        if(objeto.almacen != null){
            this.almacen.copiar(objeto.almacen);
        }
        if(objeto.tarifarios != null){
            let tarifario : Tarifario;
            this.tarifarios = new Array();
            for (let r of objeto.tarifarios) {
                if(r != null){
                    tarifario = new Tarifario();
                    tarifario.copiar(r);
                    this.tarifarios.push(tarifario);
                }
            }
        }
        
    }
   
    get zonaString() : string{
        if(this.zona == null)
            return '';
        
        if(+this.zona == 1)
            return 'WEB.dashboard.diario.filtros.zona.lima';

        return 'WEB.dashboard.diario.filtros.zona.provincia';
    }
   
}