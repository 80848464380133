import { Canal } from './canal';

export class SubCanal {
    //PK
    subCanalId : number;

    //Atributos
    codigo : string;
    estado : number;
    nombre : string;

    //Relaciones
    canal : Canal;

    //Metodos
    copiar(objeto : any){
        //Propios
        this.subCanalId = objeto.subCanalId;
        this.codigo = objeto.codigo;
        this.estado = objeto.estado;
        this.nombre = objeto.nombre;

        //Relaciones
        if(objeto.canal != null){
            this.canal = new Canal();
            this.canal.copiar(objeto.canal);
        }
    }
}
