import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { SelectItem } from 'primeng/primeng';
import { IResultado } from '../index';
import { Compania } from '../models';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class CompaniaService {
  ciaId: string;
  constructor(private http: HttpClient2) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/" + currentUser?.compania?.companiaId;
  }

  listar(): Observable<Array<Compania>> {
    return this.http.listar("/companias").map(
      (data: IResultado) => {
        let list = Array<Compania>();
        let obj;
        for (const d of data.resultado) {
          obj = new Compania();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
      });
  }

  listarSac(usuarioId: number): Observable<Array<Compania>> {
    return this.http.listar("/companias/"+usuarioId+"/listar-sac").map(
      (data: IResultado) => {
        let list = Array<Compania>();
        let obj;
        for (const d of data.resultado) {
          obj = new Compania();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
      });
  }
  

  consultar(data): Observable<Array<Compania>> {

    return this.http.consultar("/companias/consultar", data).map(
      (data: IResultado) => {
        let list = Array<Compania>();
        let obj;
        if (data) {
          for (const d of data.resultado) {
            obj = new Compania();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      });

  }

  obtener(id: number): Observable<Compania> {
    return this.http.obtener("/companias/" + id).map(
      (data: IResultado) => {
        let obj = new Compania();
        if (data) {
          obj.copiar(data.resultado);
        }
        return obj;
      });
  }

  registrar(compania: Compania, funcionalidad?: string) {
    return this.http.registrar("/companias", compania, { funcionalidad });
  }

  modificar(compania: Compania, funcionalidad?: string) {
    return this.http.modificar("/companias", compania, { funcionalidad });
  }

  eliminar(companiaId: number, funcionalidad?: string) {
    return this.http.eliminar("/companias/" + companiaId, { funcionalidad });
  }

  companiasTransporte(): Observable<Array<SelectItem>> {
    let param = { "esTransportista": 1 };
    return this.http.consultar("/companias/consultar", param).map(
      result => {
        let list = new Array<SelectItem>();
        for (const item of result.resultado) {
          list.push({ label: item.nombre, value: item.companiaId });
        }
        return list;
      }
    );
  }

  validarOpcionesHomologacion(companiaId: number): Observable<any> {
    return this.http.obtener("/companias/" + companiaId + "/validar-opciones-homologacion").map(
      (data: IResultado) => {
        return data.resultado;
      });
  }
  
  obtenerExcelExportacionReportes(data: any, tipoReporte: string, funcionalidad?: string): Observable<any> {
    return this.http.consultar("/reportes/" + tipoReporte + "/exportar-excel", data, { responseType: "text", funcionalidad });
  }

  validarContrasenhaTask(payload: any) {
    return this.http.registrar("/companias/contrasenha-task/validar", payload);
  }

  actualizarContrasenhaTask(payload: any, funcionalidad?: string){
    return this.http.modificar("/companias/contrasenha-task", payload, { funcionalidad });
  }

  registrarActualizarCompaniasHomologadas(payload: any){
    return this.http.registrar("/companias/registrar-actualizar-homologacion", payload);
  }

  actualizarAgruparEntregas(companiaId: number, agruparEntregas: number){
    return this.http.registrar("/companias/actualizar-agrupar-entregas", {companiaId, agruparEntregas});
  }
}
