import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { HttpHeaders } from '@angular/common/http'
import { Cookies } from '../../shared/commons';
//Clases
import { Marca } from '@shared/models/marca';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { IResultado, MarcaDTO } from '../index';

@Injectable()
export class MarcaService {

  ciaId : string;

  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<Marca>>{
    return this.http.listar(this.ciaId+'/marca').map(
      (data: IResultado) => {
        let list = Array<Marca>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Marca();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  consultar(parametros:any) : Observable<Array<Marca>>{
    return this.http.consultar(this.ciaId+'/marca/consultar',parametros).map(
      (data: IResultado) => {
        let list = Array<Marca>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Marca();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  registrar(data:MarcaDTO) : Observable<IResultado>{
    return this.http.registrar('/marca',data).map(
      (data : IResultado) => {
        let resultado : IResultado;
        if(data){
            resultado = data;
            return resultado;
        }
        return resultado;
      }
    );
  }

  obtener(id:number) : Observable<Marca>{
    return this.http.obtener('/marca/'+id).map(
      (data: IResultado) => {
        let res = new Marca();
        res.copiar(data.resultado);
        return res;
    });
  }

  actualizar(data: MarcaDTO){
    return this.http.modificar('/marca',data).map(
      (data : IResultado) => {
        let resultado : IResultado;
        if(data){
            resultado = data;
            return resultado;
        }
        return resultado;
      }
    );
  }
  eliminar(id:number){
    return this.http.eliminar('/marca/'+id);
  }
}
