import { Supervisor } from './supervisor';
import { ClienteTransporte } from './clienteTransporte';
import { Canal } from './canal';
import { SubCanal } from './subcanal';
import { ZonaTransporte } from './zonaTransporte';
import { Categoria } from './categoria';
import { dateAndHourToString } from '../commons';

export class SupervisorProgramacion {
    //PK
    supervisorProgramacionId : number;
    fechaInicio : Date;
    fechaFin : Date;

    //Relaciones
    supervisor : Supervisor;
    clienteTransporte : ClienteTransporte;
    canal : Canal;
    subCanal : SubCanal;
    zonaTransporte : ZonaTransporte;
    categoria : Categoria;

    //Metodos
    copiar(objeto : any){
        //Propios
        this.supervisorProgramacionId = objeto.supervisorProgramacionId;
        this.fechaInicio = objeto.fechaInicio;
        this.fechaFin = objeto.fechaFin;

        //Relaciones
        if(objeto.supervisor != null){
            this.supervisor = new Supervisor();
            this.supervisor.copiar(objeto.supervisor);
        }
        if(objeto.clienteTransporte != null){
            this.clienteTransporte = new ClienteTransporte();
            this.clienteTransporte.copiar(objeto.clienteTransporte);
        }
        if(objeto.canal != null){
            this.canal = new Canal();
            this.canal.copiar(objeto.canal);
        }
        if(objeto.subCanal != null){
            this.subCanal = new SubCanal();
            this.subCanal.copiar(objeto.subCanal);
        }
        if(objeto.zonaTransporte != null){
            this.zonaTransporte = new ZonaTransporte();
            this.zonaTransporte.copiar(objeto.zonaTransporte);
        }
        if(objeto.categoria != null){
            this.categoria = new Categoria();
            this.categoria.copiar(objeto.categoria);
        }
    }

    instanciar(){
        if(this.supervisor==null)this.supervisor = new Supervisor();
        if(this.clienteTransporte==null)this.clienteTransporte = new ClienteTransporte();
        if(this.canal==null)this.canal = new Canal();
        if(this.subCanal==null) this.subCanal = new SubCanal();
        if(this.zonaTransporte==null)this.zonaTransporte = new ZonaTransporte();
        if(this.categoria==null)this.categoria= new Categoria();
    }

    get fechaInicioToString() : string{
        let label = '';
        if(typeof(this.fechaInicio) != "undefined" && this.fechaInicio != null){
            label = dateAndHourToString(this.fechaInicio);
        }
        return label;
    }

    get fechaFinToString() : string{
        let label = '';
        if(typeof(this.fechaFin) != "undefined" && this.fechaFin != null){
            label = dateAndHourToString(this.fechaFin);
        }
        return label;
    }
}
