import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';

//Clases
import { EvidenciaMotivo } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class EvidenciaMotivoService {

  constructor(private http : HttpClient2) { }

  listar(motivoId : number) : Observable<Array<EvidenciaMotivo>>{
    return this.http.listar("/"+motivoId + "/evidencias").map(
      (data: IResultado) => {
        let evidenciaMotivos = Array<EvidenciaMotivo>();
        let evidenciaMotivo;
        if(data){
          for (const d of data.resultado) {
            evidenciaMotivo = new EvidenciaMotivo();
            evidenciaMotivo.copiar(d);
            evidenciaMotivos.push(evidenciaMotivo);
          }
        }
        return evidenciaMotivos;
    });
  }
}
