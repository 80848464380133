import { Paquete } from "..";

export class PaqueteProductoDTO{

    paqueteId: number;
    configuracionContenedorCanal: number;
    paquete : Paquete;
    montoTotalEntregadoPaquete: number;
    estadoGuia: number;
    debeCobrar: number;
    constructor(){}
    
    copiar(objeto: any){
        this.paqueteId = objeto.paqueteId;
        this.configuracionContenedorCanal = objeto.configuracionContenedorCanal;
        this.montoTotalEntregadoPaquete = objeto.montoTotalEntregadoPaquete;
        this.estadoGuia = objeto.estadoGuia;
        this.debeCobrar = objeto.debeCobrar;
        if(objeto.paquete != null){
            this.paquete = new Paquete();
            this.paquete.copiar(objeto.paquete);
        }
    
    }
    
}