import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';

//Clases
import { Sede } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { IResultado } from '../commons';
import { of } from 'rxjs';

@Injectable()
export class SedeService {

  constructor(private http: HttpClient2) { }

  listar(clienteFinalId: number): Observable<Array<Sede>> {
    return this.http.listar("/" + clienteFinalId + "/sedes").map(
      (data: IResultado) => {
        let list = Array<Sede>();
        let obj;
        if (data) {
          for (const d of data.resultado) {
            obj = new Sede();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      });
  }

  listarByCompany(companiaId: number): Observable<Array<Sede>> {
    return this.http.listar(`/sedes/compania/${companiaId}`).map(
      (data: IResultado) => {
        let list = Array<Sede>();
        let obj;
        if (data) {
          for (const d of data.resultado) {
            obj = new Sede();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      });
  }

  listarMultiple(clientes: number[]): Observable<Array<Sede>> {

    let consultas: Array<Observable<Array<Sede>>> = [];
    if (clientes.length > 0) {
      for (let clienteFinalId of clientes) {
        consultas.push(this.listar(clienteFinalId));
      }

      return Observable.forkJoin(consultas).map(results => {
        let listSedes: Array<Sede> = [];
        for (let array of results) {
          listSedes = listSedes.concat(array);
        }
        return listSedes;
      });
    } else {
      return of([]);
    }
  }

}
