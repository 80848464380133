import { Compania } from "../models";

export class ImportarTipoIncidenteDTO{
    //Pk
    tipoIncidenteId: number;

    //Atributos
    nombre : string;
    codigo : string;

    //Relaciones
    compania : Compania;

    //validaciones
    tipoIncidenteValido: boolean;
    codigoIncidenteValido: boolean;

    nombreValido: boolean;
    codigoValido: boolean;

    //Otras variables
    styleClass: string;

    //mensajes de error
    mensajeErrorNombre: string;
    mensajeErrorCodigo: string;
    
    //constructor
    constructor(){};

    //Metodos Color de Celda
    get getColorCeldaNombre() : string{
        if(this.mensajeErrorNombre!=''){
            return "red-bg";
        }else{
            return "";
        }
    }

    get getColorCeldaCodigo() : string{
        if(this.mensajeErrorCodigo!=''){
            return "red-bg";
        }else{
            return "";
        }
    }

    //Metodos mensaje de Error
    get getMensajeErrorNombre(): string{
        if(this.mensajeErrorNombre!=''){
            return this.mensajeErrorNombre;
        }else{
            return "";
        }
    }

    get getMensajeErrorCodigo(): string{
        if(this.mensajeErrorCodigo!=''){
            return this.mensajeErrorCodigo;
        }else{
            return "";
        }
    }

    //Otros
    clone(): any{
        var cloneObj = new (this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    copiar(objeto: any){
        //Propios
        this.tipoIncidenteId = objeto.tipoIncidenteId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.mensajeErrorNombre = objeto.mensajeErrorNombre;
        this.mensajeErrorCodigo = objeto.mensajeErrorCodigo;
        this.tipoIncidenteValido = objeto.tipoIncidenteValido;
        this.codigoIncidenteValido = objeto.codigoIncidenteValido;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }

    


}