import { Injectable } from '@angular/core';
import { C, Cookies, IResultado } from '@shared/commons';
import { RutaEmpresaTransporte } from '@shared/models/rutaEmpresaTransporte';
import { HttpClient2 } from './http-client.service';
import { Observable } from 'rxjs/Observable';
import { Almacen } from '@shared/models';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ImportarRutaDTO } from '@shared/dto/importarRuta.dto';

@Injectable()
export class RutaEmpresaTransporteService {
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  ciaId : string;
  constructor(private http : HttpClient2) { 
    this.ciaId = "/"+this.currentUser.compania.companiaId;
  }

  consultar(filtro): Observable<Array<RutaEmpresaTransporte>> {     
    const options = { funcionalidad: C.FUNCIONALIDAD_LOG.RUTA };      
    let companiaId = this.currentUser.compania.companiaId; 
    return this.http.consultar(`/${companiaId}/ruta-empresa-transporte/consultar`, filtro).map(
      (data: IResultado) => {
        let newData = Array<RutaEmpresaTransporte>();
        if (data && data.resultado) {
          return data.resultado.map((row, indice) => {
            const dto = new RutaEmpresaTransporte();       
            dto.rutaEmpresaTransporteId = row.rutaEmpresaTransporteId;
            dto.codigoRuta = row.codigoRuta;
            dto.descripcion = row.descripcion;
            dto.horaEstimadaInicio = row.horaEstimadaInicio;
            dto.horaEstimadaFin = row.licencia;
            dto.leadTime = row.leadTime;
            dto.zona = row.zona;
            dto.hub = row.hub;
            dto.estado = row.estado;
            dto.compania = row.compania;
            dto.empresaTransporte = row.empresaTransporte;
            dto.transportista = row.transportista;
            dto.almacen = row.almacen;

            return dto;
          });
        }
        return newData;

      });
  }
  obtener(rutaEmpresaTransporteId : number) : Observable<RutaEmpresaTransporte>{
    let companiaId = this.currentUser.compania.companiaId; 
    return this.http.obtener(`/${companiaId}/ruta-empresa-transporte/obtener/${rutaEmpresaTransporteId}`).map(
      (data: IResultado) => {
        let obj = new RutaEmpresaTransporte();
        obj.copiar(data.resultado);
        return obj;
    });
  }
  eliminar(ruta : RutaEmpresaTransporte){
    let companiaId = this.currentUser.compania.companiaId; 
    return this.http.modificar(`/${companiaId}/ruta-empresa-transporte/eliminar`, ruta );
  }
  addOrEdit(rutaEmpresaTransporte: RutaEmpresaTransporte){
    let companiaId = this.currentUser.compania.companiaId;
    if (rutaEmpresaTransporte.rutaEmpresaTransporteId) {
      return this.http.modificar(`/${companiaId}/ruta-empresa-transporte`, rutaEmpresaTransporte);
    } else {      
      return this.http.registrar(`/${companiaId}/ruta-empresa-transporte`, rutaEmpresaTransporte);
    }
  }
  //listar almacenes segun empresa transporte
  listar(empresaTransporteId : number) : Observable<Array<Almacen>>{
    let companiaId = this.currentUser.compania.companiaId;
    return this.http.listar(`/${companiaId}/ruta-empresa-transporte/${empresaTransporteId}/almacenes`).map(
      (data: IResultado) => {
        let list = Array<Almacen>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Almacen();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

   
  listarRutaEmpresaEmpresaTransporte(): Observable<Array<RutaEmpresaTransporte>>{
    let companiaId = this.currentUser.compania.companiaId;
    return this.http.listar(`/${companiaId}/ruta-empresa-transporte/listar`).map(
      (data: IResultado) => {
        let list = Array<RutaEmpresaTransporte>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new RutaEmpresaTransporte();
            obj.copiar(d);
            if(d.empresaTransporteId != null){
              obj.empresaTransporte.empresaTransporteId = d.empresaTransporteId;
            }
            
            list.push(obj);
          }
        }
        return list;
    });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

  registrarImportacion(ruta : Array<ImportarRutaDTO>, funcionalidad?: String, httpParams? : HttpParams) : Observable<any>{
    return this.http.registrarImportacion(this.ciaId+"/ruta-empresa-transporte/registrar-importacion-csv", ruta, { params: httpParams , funcionalidad });
  }

}
