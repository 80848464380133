<div class="content-evidencia">
    <div class="evidencia-name">
        <span>{{evidencia1}}</span>
    </div>
    <div class="evidencia-boton">
        <button type="button" class="btn btn-primary mr-1" (click)="downloadFile(1)" [disabled]="evidencia1 == null">
            <span><i class="fa fa-fw fa-download"></i>{{'COMMON.boton.descargar' | translate}}</span>
        </button>
    </div>

    <div class="evidencia-name">
        <span>{{evidencia2}}</span>
    </div>
    <div class="evidencia-boton">
        <button type="button" class="btn btn-primary mr-1" (click)="downloadFile(2)" [disabled]="evidencia2 == null">
            <span><i class="fa fa-fw fa-download"></i>{{'COMMON.boton.descargar' | translate}}</span>
        </button>
    </div>
</div>

<app-loading-animation></app-loading-animation>