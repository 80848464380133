import { Injectable } from '@angular/core';
import { IResultado } from '../index';
import { HttpClient2 } from './http-client.service';
import { Guia } from '../models';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { PaqueteProductoDTO } from '@shared/dto/paqueteProducto.dto';
import { GuiaDto } from '../dto/guia.dto';

@Injectable()
export class GuiaService {
  ciaId : string;
  usuId: number;

  constructor(private http : HttpClient2) {

    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
    this.usuId = currentUser.usuarioId;

  }

  modificar(entregaId : number, guia : Guia, funcionalidad?: string) {
    return this.http.modificarParcial('/'+entregaId+'/guias/'+guia.guiaId, guia, { funcionalidad }).map(result => result);
  }

  listarPaquetesProductosPorGuia(guiaId) : Observable<Array<PaqueteProductoDTO>>{
    return this.http.listar(this.ciaId+'/guia/producto/'+guiaId).map(
      (data: IResultado) => {
        let list = Array<PaqueteProductoDTO>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new PaqueteProductoDTO();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  buscarDocumentosPorEntregaId(entregaId: number) : Observable<Array<GuiaDto>>{
    return this.http.listar('/'+entregaId+"/guias/documentos").map(
      (data: IResultado) => {
        let list = Array<GuiaDto>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new GuiaDto();
            obj.copiar(d);
            list.push(obj);
          }
        } 
        return list;
    });
  }

}
