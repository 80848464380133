import { estadoCobranzaDto } from '../dto/estadoCobranza.dto';


export class Cobranza {

    cobranzaId: number;
    estadoCobranza?: estadoCobranzaDto;
    
    copiar(objeto: any){

        this.cobranzaId = objeto.cobranzaId;

        if(objeto.estadoCobranza != null){
            this.estadoCobranza = new estadoCobranzaDto();
            this.estadoCobranza.copiar(objeto.estadoCobranza);
        }
    }
}