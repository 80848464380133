import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Cookies } from '@shared/commons';
import { PrimeUtils } from '@shared/commons/prime-utils';
import { EmpresaTransporte, UnidadTransporte } from '@shared/models';
import { Marca } from '@shared/models/marca';
import { GeneralProvider } from '@shared/providers';
import { ClienteFinalService, EmpresaTransporteService, UnidadTransporteService } from '@shared/services';
import { CentroCostoService } from '@shared/services/centro-costo.service';
import { MarcaService } from '@shared/services/marca.service';
import { RutaEmpresaTransporteService } from '@shared/services/ruta-empresa-transporte.service';
import { Calendar, MultiSelect, SelectItem } from 'primeng';

@Component({
  selector: 'app-busqueda-avanzada',
  templateUrl: './busqueda-avanzada.component.html',
  styleUrls: ['./busqueda-avanzada.component.scss'],
  providers: [RutaEmpresaTransporteService, MarcaService, 
    EmpresaTransporteService, UnidadTransporteService, 
    CentroCostoService, ClienteFinalService, TranslateService]
})
export class BusquedaAvanzadaComponent implements OnInit, OnDestroy {

  @Input() tipoReporte: string = "OnTime";

  @Output() onBuscarEmmiter = new EventEmitter<any>();

  lang : any;

  listEmpresaTransporte: Array<SelectItem>;
  listUnidadTransporte: Array<SelectItem>;
  listMarca: Array<SelectItem>;
  listZona: Array<SelectItem>;
  listRutaEmpresaTransporte: Array<SelectItem>;
  listClientesFinal: Array<SelectItem>;
  listCentrosCosto: Array<SelectItem>;

  formBusquedaAvanzada: FormGroup;

  @ViewChild("msZonas")
  msZonas : MultiSelect;

  @ViewChild("msRutas")
  msRutas : MultiSelect;

  @ViewChild("msEmpresasTransporte")
  msEmpresasTransporte : MultiSelect;

  @ViewChild("msUnidadesTransporte")
  msUnidadesTransporte : MultiSelect;

  @ViewChild("msClientesFinal")
  msClientesFinal : MultiSelect;

  @ViewChild("msCentrosCosto")
  msCentrosCosto : MultiSelect;

  @ViewChild("cFechaEstimada")
  cFechaEstimada : Calendar;

  @ViewChild("cFechaProgramada")
  cFechaProgramada : Calendar;

  @ViewChild("cFechaRealAtencion")
  cFechaRealAtencion : Calendar;

  @ViewChild("cFechaFacturacion")
  cFechaFacturacion : Calendar;
  
  constructor(
    private general: GeneralProvider,  
    private translate: TranslateService,  
    private empresaTransporteService: EmpresaTransporteService,
    private unidadTransporteService: UnidadTransporteService,
    private marcaService: MarcaService,
    private rutaEmpresaTransporteService: RutaEmpresaTransporteService,
    private clienteFinalService: ClienteFinalService,
    private centroCostoService: CentroCostoService,
    private fb: FormBuilder
) { }

  ngOnInit(): void {
    this.inicializarDatos();
    this.cargarDatos();
  }

  ngOnDestroy(){
  }

  inicializarDatos(){

    this.formBusquedaAvanzada = this.fb.group({
      zonas: new FormControl([]),
      rutasId: new FormControl([]),
      unidadesTransporteId: new FormControl([]),
      empresasTransporteId: new FormControl([]),
      marcasId: new FormControl([]),
      clientesFinalId: new FormControl([]),
      centrosCostoId: new FormControl([]),
      fechaEstimadaInicio: new FormControl(),
      fechaProgramada: new FormControl(),
      fechaRealAtencion: new FormControl(),
      fechaHoraCarga : new FormControl(),
      fechaHoraRealInicio: new FormControl(),
      fechaHoraRealFin: new FormControl(),
      fechaFacturacion: new FormControl(),
      supervisorId: new FormControl(null),
      tipoPeriodo: new FormControl(null),
      //Filtros desde dashboard
      clientesTransporteId: new FormControl([]),
      canalesId: new FormControl([]),
      subcanalesId: new FormControl([]),
      zonasTransporteId: new FormControl([]),
      categoriasId: new FormControl([]),
      tiposTareaId: new FormControl([]),
      ningunoId: new FormControl([]),
    });

    let language = Cookies.getCookie('LANGUAGE');
    this.lang = PrimeUtils.getCalendarLanguage(language);

    this.listEmpresaTransporte = [];
    this.listUnidadTransporte = [];
    this.listMarca = [];
    this.listZona = [];
    this.listRutaEmpresaTransporte = [];
    this.listClientesFinal = [];
    this.listCentrosCosto = [];

  }

  cargarDatos(){
    
    //Cargamos Empresas de transporte
    this.empresaTransporteService.listar().subscribe(
      (data:Array<EmpresaTransporte>) => {
        this.listEmpresaTransporte = [];
        for (let s of data) {
          this.listEmpresaTransporte.push({ label: s.nombre, value: s.empresaTransporteId });
        }
      }
    );

    //Cargamos Unidades de transporte
    this.unidadTransporteService.consultarPorCompania({}).subscribe(
      (data:Array<UnidadTransporte>) => {
        this.listUnidadTransporte = [];
        for (let s of data) {
          this.listUnidadTransporte.push({ label: s.placa, value: s.unidadTransporteId });
        }
      }
    );

    //Cargamos las Marcas 
    this.marcaService.listar().subscribe(
      (data:Array<Marca>) => {
        this.listMarca = [];
        for (let s of data) {
          this.listMarca.push({ label: s.descripcion, value: s.marcaId });
        }
      }
    );

    //Cargamos las rutas de empresa de transporte
    this.rutaEmpresaTransporteService.consultar({}).subscribe(
      (data) => {         
        this.listRutaEmpresaTransporte = [];
        for (let s of data) {
          this.listRutaEmpresaTransporte.push({ label: s.codigoRuta + " | " + s.descripcion, value: s.rutaEmpresaTransporteId });
        }
      }
    );

    //Cargamos las rutas de empresa de transporte
    this.clienteFinalService.listar().subscribe(
      (data) => {         
        this.listClientesFinal = [];
        for (let s of data) {
          this.listClientesFinal.push({ label: s.nombre, value: s.clienteFinalId });
        }
      }
    );

    //Cargamos las rutas de empresa de transporte
    this.centroCostoService.listar().subscribe(
      (data) => {         
        this.listCentrosCosto = [];
        for (let s of data) {
          this.listCentrosCosto.push({ label: s.descripcion , value: s.centroCostoId });
        }
      }
    );

    //Cargamos las Zonas
    this.listZona = this.general.getListaZona();

  }

  //Acciones
  onClickBuscar(event){
    let form = this.formBusquedaAvanzada.value;
    this.onBuscarEmmiter.emit(form);
    if(this.tipoReporte == "PreLiquidacion"){
      this.formBusquedaAvanzada.controls.fechaEstimadaInicio.reset();
    }
  }

  onClickLimpiar(){
    this.formBusquedaAvanzada.reset();
  }

  actualizarFiltros(params : HttpParams){

    if(params.get("supervisorId")){
      this.formBusquedaAvanzada.controls.supervisorId.setValue(params.get("supervisorId"));
    }
    if(params.get("zona")){
      this.formBusquedaAvanzada.controls.zonas.setValue(JSON.parse("[" + params.get("zona") + "]"));
    } 
    if(params.get("empresasTransporte")){
      this.formBusquedaAvanzada.controls.empresasTransporteId.setValue(JSON.parse("[" + params.get("empresasTransporte") + "]"));
    }
    if(params.get("marcas")){
      this.formBusquedaAvanzada.controls.marcasId.setValue(JSON.parse("[" + params.get("marcas") + "]"));
    }
    if(params.get("rutasEmpresaTransporte")){
      this.formBusquedaAvanzada.controls.rutasId.setValue(JSON.parse("[" + params.get("rutasEmpresaTransporte") + "]"));
    }
    if(params.get("periodo")){
      let arrayFechas = params.get("periodo").split("|").map(fString =>{
        let fecha = fString.split("-");
        // Date (año, mes, dia)
        return new Date(parseInt(fecha[0]), parseInt(fecha[1]) - 1, parseInt(fecha[2]));
      } );

      this.formBusquedaAvanzada.controls.fechaEstimadaInicio.setValue(arrayFechas);
    }
    if(params.get("tipoPeriodo")){
      this.formBusquedaAvanzada.controls.tipoPeriodo.setValue(params.get("tipoPeriodo"));
    }
    //Filtros desde dashboard
    if(params.get("canales")){
      this.formBusquedaAvanzada.controls.canalesId.setValue(JSON.parse("[" + params.get("canales") + "]"));
    }
    if(params.get("clientesTransporte")){
      this.formBusquedaAvanzada.controls.clientesTransporteId.setValue(JSON.parse("[" + params.get("clientesTransporte") + "]"));
    }
    if(params.get("subcanales")){
      this.formBusquedaAvanzada.controls.subcanalesId.setValue(JSON.parse("[" + params.get("subcanales") + "]"));
    }
    if(params.get("zonasTransporte")){
      this.formBusquedaAvanzada.controls.zonasTransporteId.setValue(JSON.parse("[" + params.get("zonasTransporte") + "]"));
    }
    if(params.get("categorias")){
      this.formBusquedaAvanzada.controls.categoriasId.setValue(JSON.parse("[" + params.get("categorias") + "]"));
    }
    if(params.get("tiposTarea")){
      this.formBusquedaAvanzada.controls.tiposTareaId.setValue(JSON.parse("[" + params.get("tiposTarea") + "]"));
    }
    if(params.get("supervisorId")){
      this.formBusquedaAvanzada.controls.ningunoId.setValue(JSON.parse("[" + params.get("supervisorId") + "]"));
    }

  }

  cerrarComponentes(){
    this.msZonas.hide();
    this.msRutas.hide();
    this.msEmpresasTransporte.hide();
    this.msUnidadesTransporte.hide();
    this.msClientesFinal.hide();
    this.msCentrosCosto.hide();
    
    this.cFechaEstimada.hideOverlay();
    this.cFechaProgramada.hideOverlay();
    this.cFechaRealAtencion.hideOverlay();
    this.cFechaFacturacion.hideOverlay();
  }

}
