import { estadoCobranzaDto } from "./estadoCobranza.dto";
import { Cobranza } from '../models/cobranza';

export class ClienteCobranzaDto{
    clienteCobranzaId: number;
    sede: string;
    numeroDocumento: string;
    tipoDocumento: string;
    fechaHoraCreacion : number; 
    fechaEmision : number; 
    montoPorCobrar: number;
    montoTotalCobrado: number;
    clienteFinal: string;
    canal: string;
    estado: number;

    clienteFinalId: number;
    tipoDocumentoId: number;
    sedeId: number;

    cobranza : Cobranza;

    constructor(){}
    
    copiar(objeto: any){
        this.clienteCobranzaId = objeto.clienteCobranzaId;
        this.sede = objeto.sede;
        this.numeroDocumento = objeto.numeroDocumento;
        this.tipoDocumento = objeto.tipoDocumento;
        this.fechaHoraCreacion = objeto.fechaHoraCreacion;
        this.fechaEmision = objeto.fechaEmision;
        this.montoPorCobrar = objeto.montoPorCobrar;
        this.montoTotalCobrado = objeto.montoTotalCobrado;
        this.clienteFinal = objeto.clienteFinal;
        this.canal = objeto.canal;
        this.estado = objeto.estado;

        this.clienteFinalId= objeto.clienteFinalId;
        this.tipoDocumentoId= objeto.tipoDocumentoId;
        this.sedeId= objeto.sedeId;

        if(objeto.cobranza != null){
            this.cobranza = new Cobranza();
            this.cobranza.copiar(objeto.cobranza);
        }
        
    }

    instanciar() : void{
    }

}


