import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { NotificacionComponent } from './notificacion.component';
import { NotificacionPanelComponent } from './notificacion-panel/notificacion-panel.component';
import { SolicitudAutorizacionItemComponent } from './solicitud-autorizacion-item/solicitud-autorizacion-item.component';
import { AlertaTareaItemComponent } from './alerta-tarea-item/alerta-tarea-item.component';

import { CardModule, InputTextareaModule, BlockUIModule, DataViewModule, PaginatorModule, TabViewModule, 
  PanelModule, CheckboxModule, TableModule, GMapModule, ToastModule, DialogModule, RadioButtonModule, 
  AccordionModule, InputTextModule, CalendarModule, MessagesModule, MultiSelectModule, DropdownModule, 
  ListboxModule, OverlayPanelModule, ConfirmDialogModule } from 'primeng';
import { NotificacionWebPanelComponent } from './notificacion-web-panel/notificacion-web-panel.component';
import { AlertaSensorItemComponent } from './alerta-sensor-item/alerta-sensor-item.component';
import { AlertaSmsItemComponent } from './alerta-sms-item/alerta-sms-item.component';

@NgModule({
  declarations: [NotificacionComponent, NotificacionPanelComponent, SolicitudAutorizacionItemComponent, AlertaTareaItemComponent, NotificacionWebPanelComponent, AlertaSensorItemComponent, AlertaSmsItemComponent],
  imports: [
    CommonModule, DropdownModule, DialogModule,InputTextareaModule,  
    CardModule ,DataViewModule, PaginatorModule, TabViewModule, PanelModule, CheckboxModule, TableModule, GMapModule, ToastModule, DialogModule, RadioButtonModule, AccordionModule, InputTextModule, CalendarModule, MessagesModule, MultiSelectModule, DropdownModule, ListboxModule, OverlayPanelModule,ConfirmDialogModule,
    NgbDropdownModule,
    TranslateModule,
    ToastModule
  ],
  exports: [NotificacionComponent]
})
export class NotificacionModule { }
