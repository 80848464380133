import { Injectable } from '@angular/core';
import { C } from '@shared/commons';
import { ChatDTO } from '@shared/components/chat/resources';
import { ChatViewDTO } from '@shared/dto';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatProvider {
  displayModal: boolean;


  // obsevable para chats nuevos
  private contadorNotificaciones = new BehaviorSubject<number>(0);
  currentContador = this.contadorNotificaciones.asObservable();

  //observable para ventanas de chats
  private viewsChats = new BehaviorSubject<Array<ChatViewDTO>>([]);
  currentViewsChats = this.viewsChats.asObservable();

  //observable para chats activos
  private notificacionChats = new BehaviorSubject<Array<ChatDTO>>([]);
  currentNotificacionChats = this.notificacionChats.asObservable();
  constructor() { }

  /**
   * Funciones: Contador
   */
  //Metodo para ver el contador 
  changeContador(contador : number){
    this.contadorNotificaciones.next(contador);
  }

  // Metodos para actualizar la información de chat notificacion
  changeNotificacionChats(notificacionChats : Array<ChatDTO>) {
    this.notificacionChats.next(notificacionChats);

    this.contadorNotificaciones.next(this.contadorNotificacion([...notificacionChats]));
  }

  /**
   * Funciones: Ventanas del Chat
   */

  // Metodos para actualizar la información
  changeViewsChats(viewsChats : Array<ChatViewDTO>) {
    this.viewsChats.next(viewsChats);
  }

  //Agregar la vista de chat
  addViewChat(viewChat : ChatViewDTO) {
  this.viewsChats
      .pipe(take(1))
      .subscribe((viewsChats: Array<ChatViewDTO>) => {
        //validar si ya existe la vista de chat
        if(!viewsChats.filter(n2 => n2.viajeId == viewChat.viajeId).length){
          if(this.viewsChats.value.length < 4){
            this.viewsChats.next( [...viewsChats, viewChat]);
          }else{
            this.showModalDialog(true);
          }
        }
      });
  }

  //Remover la vistas del chat
  removeViewChat(viajeId : number){
    const viewsChats: any[] = this.viewsChats.getValue();
    this.viewsChats.next(viewsChats.filter(n2 => n2.viajeId != viajeId));
  }

  //Editar los valores del chat
  editViewChat(viajeId : number, accion: boolean){
    const viewsChatsfd: ChatViewDTO[] = this.viewsChats.getValue();
    const data: ChatViewDTO[] = viewsChatsfd.map(view => {
      let newView: ChatViewDTO = {...view};
      if(viajeId == view.viajeId){        
        newView.view = accion;
      }
      return newView;
    });
    this.viewsChats.next(data)
  }

  /**
   * Funciones: Chats Activos
   */
   //Editar los valores del chat notificacion
   editNotificacionChat(viajeId : number, estado?: number){

    const notificacionChats: ChatDTO[] = this.notificacionChats.getValue();
    const data: ChatDTO[] = notificacionChats.map(view => {
      let newView: ChatDTO = {...view};
      if(viajeId == view.viajeId){
        newView.estado = estado;
      }
      return newView;
    });

    this.changeContador(this.contadorNotificacion([...data]));
    this.notificacionChats.next(data)
  }

  addNotificacionChat(chat : ChatDTO) {
    this.notificacionChats
        .pipe(take(1))
        .subscribe((notificacionChats: Array<ChatDTO>) => {
          let newChats = notificacionChats.filter(n=>n.viajeId != chat.viajeId);
            this.notificacionChats.next(
              [...newChats, chat]
            )
        });
  }
  //Remover las vistas del chat
  removeNotificacionChat(viajeId : number){
    const notificacionChats: any[] = this.notificacionChats.getValue();
    this.notificacionChats.next(notificacionChats.filter(n2 => n2.viajeId != viajeId));
  }


 /**
   * Funciones: Otras Funciones
   */
  contadorNotificacion(chats : ChatDTO[]): number{
    chats=chats.filter(n2 => n2.estado == C.CHAT_VARS.ESTADO.ENVIADO);
    return chats.length;
    
  }

  showModalDialog(accion: boolean) {
    this.displayModal = accion;
}

}