import { Injectable } from '@angular/core';
import { CustomFeatureSectionDTO, FuncionalidadPersonalizacionDTO, SolicitudAutorizacionDTO } from '@shared/dto';
import { FuncionalidadPersonalizacion } from '@shared/models/funcionalidadPersonalizacion';
import { BehaviorSubject } from 'rxjs';
import { Cookies } from '..';

@Injectable({
  providedIn: 'root'
})
export class CustomFeatureProvider {
 
  lenguage =  JSON.parse(localStorage.getItem("currentUser")).compania.paisIdioma;
 
  
  //Variables a compartir entre componentes
  private funcionalidadPersonalizadas = new BehaviorSubject<Array<FuncionalidadPersonalizacion>>([]);
  private customFeatureSections       = new BehaviorSubject<Array<CustomFeatureSectionDTO>>([]);
  private isOpen                      = new BehaviorSubject<boolean>(false);

  currentfuncionalidadPersonalizadas  = this.funcionalidadPersonalizadas.asObservable();
  currentCustomFeatureSections        = this.customFeatureSections.asObservable();
  currentIsOpen                       = this.isOpen.asObservable();

  constructor() { }

  changeFuncionalidadPersonalizadas(funcionalidadPersonalizadas : Array<FuncionalidadPersonalizacion>) {
    funcionalidadPersonalizadas.forEach(fp => {
      let nombre = fp.funcionalidadPersonalizacionId != null 
                    ? fp.nombre
                    : (this.lenguage == "en" ? fp.etiqueta.nombreEn : fp.nombre);

      fp.nombre = nombre;
    })
    this.funcionalidadPersonalizadas.next(funcionalidadPersonalizadas);
  }

  changeCurrentCustomFeatureSections(customFeatureSections : Array<CustomFeatureSectionDTO>) {  
    this.customFeatureSections.next(customFeatureSections);
  }

  changeIsOpen(isOpen: boolean){
    this.isOpen.next(isOpen);
  }

}
