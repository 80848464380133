import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTextareaComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTextareaComponent implements OnInit, ControlValueAccessor {

  /** Por defecto el tamaño máximo de letras en el textarea es 160 */
  @Input() maxLength = 160;

  contadorPorLetra = 0;
  value: string = '';
  isDisabled: boolean;
  onChange = (_: any) => { }
  onTouch = () => { }

  constructor() { }

  ngOnInit(): void {
  }

  onInputKey(value: string): void {
    this.contadorPorLetra = value.length;
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
      this.contadorPorLetra = value.length;
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
