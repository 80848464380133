import { C } from "@shared/commons";
import { GeneralProvider } from "@shared/providers";

export class UnidadTransporteSensor {
    unidadTransporteId?: number;
    sensorId : string;
    descripcionSensor: string;
    umbralMax : number;
    umbralMin : number;
    umbralGeneral : boolean;
    estado : boolean | number;

    unidadMedidaRaw?: string = "";
    rangoGeneral?: Array<number>;
    umbralEspecifico?: boolean;

    copiar(objeto : any){
        this.unidadTransporteId = objeto.unidadTransporteId;
        this.sensorId = objeto.sensorId;
        this.descripcionSensor = objeto.descripcionSensor || objeto.descripcion;
        this.umbralMax = objeto.umbralMax || objeto.maximo;
        this.umbralMin = objeto.umbralMin || objeto.minimo;
        this.umbralGeneral = objeto.umbralGeneral;
        this.estado = objeto.estado;
        this.rangoGeneral = [this.umbralMin, this.umbralMax];
        const dataGeneralSensores: any = GeneralProvider.getDataSensores();

        if (this.sensorId === C.SENSOR.TEMPERATURA) {
            this.unidadMedidaRaw = dataGeneralSensores.temperatura.unidad_medida_raw;
        } else if (this.sensorId === C.SENSOR.ACELERACION) {
            this.unidadMedidaRaw = dataGeneralSensores.aceleracion.unidad_medida_raw;
        } else if (this.sensorId === C.SENSOR.HUMEDAD) {
            this.unidadMedidaRaw = dataGeneralSensores.humedad.unidad_medida_raw;
        } else if (this.sensorId === C.SENSOR.LUZ) {
            this.unidadMedidaRaw = dataGeneralSensores.luz.unidad_medida_raw;
        } else {
            this.unidadMedidaRaw = "";
        }
    }
}