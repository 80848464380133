import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';

//Clases
import { Viaje, Usuario, EmpresaTransporte, UnidadTransporte, Transportista, GuiaEvidencia } from '../models';

//Otros
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarViajeDTO } from '../dto/importarViaje.dto';
import { Cookies } from '../commons';
import { FacturacionEmpresaTransporteDTO } from '@shared/dto/facturacionEmpresaTransporte.dto';
import { TransbordoDTO } from '@shared/dto';
import { viajeAlmacenDTO } from 'src/app/layout/control/estados-viaje/resources/dto/viaje-almacen';
import { MiniViajeDTO } from '@shared/dto/mini-viaje.dto';
import { TareaEvidenciaDTO } from '@shared/dto/tareaEvidencia.dto';

@Injectable()
export class ViajeService {
  ciaId: string;
  usuarioId: string;
  constructor(private http: HttpClient2) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/" + currentUser.compania.companiaId;
    this.usuarioId = "" + currentUser.usuarioId;
  }

  consultar(viaje: any): Observable<Array<Viaje>> {
    viaje.usuarioId = this.usuarioId;
    return this.http.consultar(this.ciaId + '/viajes/consultar', viaje).map(
      (data: IResultado) => {
        let list = Array<Viaje>();
        let obj;
        if (data) {
          for (const d of data.resultado) {
            obj = new Viaje();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      });
  }

  obtenerPorFechaRealInicio(viaje: any): Observable<Array<Viaje>> {
    return this.http.consultar(this.ciaId + '/viajes/obtener-por-fecha-real-inicio', viaje).map(
      (data: IResultado) => {
        let list = Array<Viaje>();
        let obj;
        if (data) {
          for (const d of data.resultado) {
            obj = new Viaje();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      });
  }

  obtenerViajeRecojo(viaje: any): Observable<Array<Viaje>> {
    return this.http.consultar(this.ciaId + '/viajes/viajes-recojos', viaje).map(
      (data: IResultado) => {
        let list = Array<Viaje>();
        let obj;
        if (data) {
          for (const d of data.resultado) {
            obj = new Viaje();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      });
  }

  registrar(viaje: Viaje, httpParams: HttpParams, funcionalidad?: string) {
    return this.http.registrar(this.ciaId + '/viajes', viaje, { params: httpParams, funcionalidad });
  }

  registrarImportacion(listImportarViajeDTO: Array<ImportarViajeDTO>, companiaId?: string, funcionalidad?: string) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar(companiaId + "/viajes/registrar-importacion?usuarioId=" + this.usuarioId, listImportarViajeDTO, { funcionalidad });
  }

  modificarParcial(viaje: Viaje, httpParams: HttpParams, funcionalidad?: string) {
    return this.http.modificarParcial(this.ciaId + '/viajes', viaje, { params: httpParams, funcionalidad });
  }

  modificar(viaje: Viaje, httpParams: HttpParams, funcionalidad?: string) {
    return this.http.modificar(this.ciaId + '/viajes', viaje, { params: httpParams, funcionalidad });
  }

  modificarViajeEmpresaTransporte(objeto, httpParams: HttpParams) {
    return this.http.modificarViajeEmpresaTransporte(this.ciaId + '/viajes/modificar-viaje-empresa-transporte', objeto, { params: httpParams });
  }

  eliminar(viajesId: String, funcionalidad?: string) {
    return this.http.eliminar(this.ciaId + '/viajes/' + viajesId, { funcionalidad });
  }

  eliminarDevolucion(viajeId: number, httpParams: HttpParams, funcionalidad?: string) {
    return this.http.eliminar(this.ciaId + '/viajes/' + viajeId+ '/eliminar-devolucion', { params: httpParams, funcionalidad });
  }

  obtener(viajeId: number): Observable<Viaje> {

    return this.http.obtener(this.ciaId + '/viajes/' + viajeId).map(
      (data: IResultado) => {
        let obj = new Viaje();
        if (data) {
          obj.copiar(data.resultado);
        }
        return obj;
      });

  }

  obtenerViajeAlmacen(viajeId: number): Observable<Viaje> {

    return this.http.obtener(this.ciaId + '/viajes/obtener-viaje-almacen/' + viajeId).map(
      (data: IResultado) => {
        let obj = new Viaje();
        if (data) {
          obj.copiar(data.resultado);
        }
        return obj;
      });

  }

  obtenerViajeDevolucion(viajeId: number): Observable<any> {

    return this.http.obtener(this.ciaId + '/viajes/obtener-viaje-devolucion/' + viajeId).map(
      (data: IResultado) => {
        return data.resultado;
      });

  }
  obtenerEmpresaTransporteFacturacion(): Observable<Array<FacturacionEmpresaTransporteDTO>>{
    return this.http.obtener(this.ciaId+'/viajes/obtener-empresa-transporte-facturacion').map(
      (data: IResultado) => {
        return data.resultado;
    });
  }

  consolidar(params: any) {
    return this.http.modificar(this.ciaId + "/viajes/consolidar", params);
  }

  //Control de Estados
  listarProgresoEstados(httpParams: HttpParams) {
    //Setaear Usuario
    httpParams = httpParams.append("usuarioId", this.usuarioId);
    return this.http.listar(this.ciaId + '/viajes/no-finalizados', { params: httpParams });
  }

  listarNoFinalizados(params?: HttpParams): Observable<Viaje[]> {
    return this.http.listar(this.ciaId + '/viajes/no-finalizados', { params: params }).map(
      (data: IResultado) => {
        let list = Array<Viaje>();
        let obj;
        for (const d of data.resultado) {
          obj = new Viaje();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
      });
  }

  obtenerViajeAndPuntos(viajeId: number): Observable<Viaje> {

    return this.http.get(this.ciaId + '/viajes/' + viajeId + '/puntos-visita')
      .map(response => {
        switch (response.status) {
          case 200:
            return response.json().resultado;
          default:
            let json = response.json();
            console.log(json.excepcion);
            return [];
        }
      })
      .catch(response => {
        let json = response.json();
        console.log(json.excepcion);
        return [];
        //return Observable.throw(json.mensaje || 'Server error'); // Observable.throw() is undefined at runtime using Webpack
      });

  }

  gestionAlmacenIntermedio(): Observable<any> {
    return this.http.listar(this.ciaId + '/viajes/gestion-almacen-intermedio').map(
      (data: IResultado) => {
        let list = Array<any>();
        let obj;
        for (const d of data.resultado) {

          list.push(d);
        }
        return list;
      });

  }

  consultarSensores(dataSensores): Observable<any> {
    const payload = {...dataSensores, vehiculoId: null};
    return this.http.consultar('/viajes/sensores/consultar', payload).map(
      (data: IResultado) => {
        let list = Array<any>();
        if (data) {
          for (const d of data.resultado) {
            list.push(d);
          }
        }
        return list;
      });

  }

  consultarSensoresSegunUnidadTransporte(dataSensores): Observable<any> {
    const payload = {...dataSensores, vehiculoId: null};
    return this.http.consultar('/viajes/sensores/consultar/unidad-transporte', payload).map(
      (data: IResultado) => {
        let list = Array<any>();
        if (data) {
          for (const d of data.resultado) {
            list.push(d);
          }
        }
        return list;
      });

  }

  //Listar viajes devolucion
  consultarViajeDevolucion(httpParams: HttpParams) {
    return this.http.listar(this.ciaId + '/viajes/devoluciones', { params: httpParams });
  }

  //Registrar o editar viajes devolucion
  addOrEdit(viaje: Viaje, httpParams: HttpParams, funcionalidad?: string){
    if (viaje.viajeId) {
      return this.http.modificar(this.ciaId + '/viajes/modificar-devolucion', viaje, { params: httpParams, funcionalidad });
    } else {  
      return this.http.registrar(this.ciaId + '/viajes/registrar-devolucion', viaje, { params: httpParams, funcionalidad });
    }
  }
  
  generarTransbordo(payload: TransbordoDTO, funcionalidad?: string): Observable<any> {
    const _payload = {
      ...payload,
      tareasId: [...payload.tareasId]
    };

    return this.http.registrar(this.ciaId + '/viajes/transbordo', _payload, { funcionalidad }).map(
      (data: IResultado) => {
        return data;
      }
    )
  }

  getAlmacenesViaje(viajeIds: Array<number>){
    return this.http.obtener(`${this.ciaId}/viajes/${viajeIds}/viaje-almacen`).map(
      (data:IResultado) => {
        let viaje : Array<Viaje> = [];
        if (data) {
          viaje = data.resultado;
        }
        return viaje;
      }
    )
  }

  obtenerHeaders(httpOptions?: Object): HttpHeaders {
    return this.http.obtenerHeaders();
  }


  listar(): Observable<Array<Viaje>>{
    return this.http.listar(this.ciaId+"/viajes").map(
      (data: IResultado) => {
        let list = Array<Viaje>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Viaje();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  obtenerViajeConPvPadre(viajeId: number): Observable<Viaje> {

    return this.http.obtener(this.ciaId + '/viajes/' + viajeId + "/con-punto-visita-padre").map(
      (data: IResultado) => {
        let obj = new Viaje();
        if (data) {
          obj.copiar(data.resultado);
        }
        return obj;
      });

  }

  registrarMiniViaje(listImportarViajeDTO: Array<MiniViajeDTO>, companiaId?: string, funcionalidad?: string) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar(companiaId + "/viajes/crear-mini-viaje-web?usuarioId=" + this.usuarioId, listImportarViajeDTO, { funcionalidad });
  }

  obtenerTareaEvidencia(tareaId: number){
    return this.http.obtener('/tareas-evidencia/'+tareaId).map(
      (data: IResultado) => {
        let list = Array<any>();
        list = data.resultado;
        return list;
      });
  }

}
