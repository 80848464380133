import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';

//Clases
import { Canal } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { PaqueteDevolucionDto } from '@shared/dto/PaqueteDevolucion.dto';

@Injectable()
export class DevolucionService {
  ciaId : string;
  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  cargarDatosGuiasEnHub(params: any) : Observable<Array<PaqueteDevolucionDto>>{
    return this.http.consultar(this.ciaId+"/devoluciones/cargar-datos-guias-hub", params).map(
      (data: IResultado) => {
        let list = Array<PaqueteDevolucionDto>();
        let obj;
        if(data?.resultado){
          for (const d of data.resultado) {
            obj = new PaqueteDevolucionDto();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  obtenerExcelExportacionReportes(data: any): Observable<any> {
    return this.http.consultar("/devoluciones/exportar-excel-devolucion", data, { responseType: "text" });
  }

}
