import { Usuario } from './usuario';

export class Supervisor {
    //PK
    supervisorId : number;
    estado : number;

    //Relaciones
    usuario : Usuario;

    //Metodos
    copiar(objeto : any){
        //Propios
        this.supervisorId = objeto.supervisorId;
         this.estado = objeto.estado;
        //Relaciones
        if(objeto.usuario != null){
            this.usuario = new Usuario();
            this.usuario.copiar(objeto.usuario);
        }
    }
}
