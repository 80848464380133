import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ceInputDecimal]'
})
export class InputDecimalDirective {

  constructor() { }

  @HostListener('input', ['$event']) onInput(e: any) {    
    e.target.value = e.target.value.replace(/[^0-9|.]/,'');
  }
}
