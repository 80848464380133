import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { C } from '@shared/commons';
import { Usuario } from '@shared/models';
import { NotificacionProvider } from '@shared/providers';
import { NotificacionService } from '../resources';
import { NotificacionDTO } from '../resources/dto/notificacion.dto';
import { NotificacionSmsDTO } from '../resources/dto/notificacionSms.dto';

@Component({
  selector: 'app-alerta-sms-item',
  templateUrl: './alerta-sms-item.component.html',
  styleUrls: ['./alerta-sms-item.component.scss']
})
export class AlertaSmsItemComponent implements OnInit {

  @Input()
  notificacion: NotificacionSmsDTO;
  @Input()
  index: any;
  comentario: string;
  currentUser;

  constructor( private notificacionService: NotificacionService,private translate: TranslateService,
    private notificacionProvider : NotificacionProvider) { 
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.cargarDatos();
  }

  cargarDatos(){
    this.comentario = this.notificacion.comentario;
  }

  procesar(){   
    let notificacion = new NotificacionDTO();
    notificacion.notificacionId = this.notificacion.notificacionId;
    notificacion.estado = 2;
    notificacion.usuario = new Usuario();
    notificacion.usuario.usuarioId =  this.currentUser.usuarioId;
    notificacion.comentario = this.comentario;
    this.notificacionService.modificar(notificacion, C.FUNCIONALIDAD_LOG.NOTIFICACION_ALERTAS).subscribe(data => {
      this.cargarAlertasSms();
    }); 
  }

  cargarAlertasSms() {
    this.notificacionService.listarAlertasSms()
    .subscribe(
      (data : NotificacionSmsDTO[]) =>{                    
        this.notificacionProvider.changeAlertasSms(data);
      }, (error) => {
        console.log(error);
      },
      () => {
        //this.loadingComponent.ocultarLoading();
      }
    );
  }

}
