import { C, dateToString } from "@shared/commons";

export class DetalleReporteSmsDTO {

    //PK
    consumoSMSId: number;

    //Atributos
    app : string;
    companiaId: number;
    fecha: Date;
    hora: string;
    viajeCodigo: string;
    tareaId: number;
    nroTarea: string;
    estadoTarea: number;
    estadoSMS: string;
    clienteCodigo: string;
    clienteNombre: string;
    tipoDestinatario: string;
    destinatarioId: number;
    destinatarioCodigo: string;
    destinatarioNombre: string;
    cantSMS: number;
    telefono: string;
    correo: string;
    origenEnvioSms: string;
    fechaString;

    etiquetas: any = {};
    //Constructor
    constructor(){};

    //Metodos
    copiar(objeto : any){
        //Propios
        this.consumoSMSId = objeto.consumoSMSId;
        this.app  = objeto.app;
        this.companiaId = objeto.companiaId;
        this.fecha = objeto.fecha;
        this.hora = objeto.hora;
        this.viajeCodigo = objeto.viajeCodigo;
        this.tareaId = objeto.tareaId;
        this.nroTarea = objeto.nroTarea;
        this.estadoTarea = objeto.estadoTarea;
        this.estadoSMS = objeto.estadoSMS;
        this.clienteCodigo = objeto.clienteCodigo;
        this.clienteNombre = objeto.clienteNombre;
        this.tipoDestinatario = objeto.tipoDestinatario;
        this.destinatarioId = objeto.destinatarioId;
        this.destinatarioCodigo = objeto.destinatarioCodigo;
        this.destinatarioNombre = objeto.destinatarioNombre;
        this.cantSMS = objeto.cantSMS;
        this.telefono = objeto.telefono;
        this.correo = objeto.correo;
        this.origenEnvioSms = objeto.origenEnvioSms;
        this.fechaString = dateToString(objeto.fecha);

        this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
    }

    get estadoTareaToString() : string{
        if(this.estadoTarea!=null){
            switch(this.estadoTarea){
                case C.TAREA.ESTADO.PENDIENTE:
                    return this.etiquetas['estado_tarea_pendiente'];
                case C.TAREA.ESTADO.EN_APT:
                    return this.etiquetas['estado_tarea_en_apt'];
                case C.TAREA.ESTADO.EN_RUTA:
                    return this.etiquetas['estado_tarea_en_ruta'];
                case C.TAREA.ESTADO.EN_ESPERA:
                    return this.etiquetas['estado_tarea_en_espera'];
                case C.TAREA.ESTADO.EN_CLIENTE:
                    return this.etiquetas['estado_tarea_en_cliente'];
                case C.TAREA.ESTADO.REALIZANDO_TAREA:
                    return this.etiquetas['estado_tarea_inicio_descarga'];
                case C.TAREA.ESTADO.TERMINADO_TOTAL:
                    return this.etiquetas['estado_tarea_terminado_total'];
                case C.TAREA.ESTADO.TERMINADO_PARCIAL:
                    return this.etiquetas['estado_tarea_terminado_parcial'];
                case C.TAREA.ESTADO.RECHAZADO:
                    return this.etiquetas['estado_tarea_rechazado'];
                case C.TAREA.ESTADO.NO_REALIZADO:
                    return this.etiquetas['estado_tarea_no_realizado'];
                case C.TAREA.ESTADO.EN_CAMINO:
                    return this.etiquetas['estado_tarea_en_camino'];
                case C.TAREA.ESTADO.POR_RECOGER:
                    return this.etiquetas['estado_tarea_en_camino'];
            }
        }
        return "";
    }

    get estadoSMSToString() : string{
        if (this.estadoSMS!=null) {
            switch(this.estadoSMS){
                case C.ESTADOS_SMS.ENVIADO:
                    return C.ESTADOS_SMS.ESTADO_SMS_ENVIADO;
                case C.ESTADOS_SMS.INVALIDO:
                    return C.ESTADOS_SMS.ESTADO_SMS_FALLIDO;
            }
        }
        return "";
        
    }

    get tipoDestinatarioToString() : string{
        if (this.tipoDestinatario!=null) {
            switch(this.tipoDestinatario){
                case C.TIPO_CONTACTO_SMS.CLIENTE_FINAL:
                    return C.TIPO_CONTACTO_SMS.CLIENTE_FINAL_TIPO;
                case C.TIPO_CONTACTO_SMS.EMPRESA_TRANSPORTE:
                    return C.TIPO_CONTACTO_SMS.EMPRESA_TRANSPORTE_TIPO;
                case C.TIPO_CONTACTO_SMS.CONTACTO_SEDE:
                    return C.TIPO_CONTACTO_SMS.CONTACTO_SEDE_TIPO;
            }
        }
        return "";
        
    }

    get aplicacionToLongConstant() : string{
        if(this.app == C.APP_ST){
            return C.APP_ST_LONG;
        }else if(this.app == C.APP_DRIVER){
            return C.APP_ST_DRIVER_LONG;
        }else if(this.app == C.APP_TASK){
            return C.APP_ST_TASK_LONG;
        }
        return C.APP_PLANNER_LONG;
    }

   
}