import { Sede } from './sede';

export class Contacto {
    //PK
    contactoId : number;

    //Atributos
    docIdentificacion : string;
    estado : number;
    nombre : string;
    apellido : number;
    telefono :string;
    correo : string;

    //Relaciones
    sede : Sede;

    //Metodos
    copiar(objeto : any){
        //Propios
        this.contactoId = objeto.contactoId;
        this.docIdentificacion = objeto.docIdentificacion;
        this.estado = objeto.estado;
        this.nombre = objeto.nombre;
        this.apellido = objeto.apellido;
        this.telefono = objeto.telefono;
        this.correo = objeto.correo;

        //Relaciones
        if(objeto.sede != null){
            this.sede = new Sede();
            this.sede.copiar(objeto.sede);
        }
    }
}
