<div class="fixed-bottom footer d-flex justify-content-center">
    <small> {{'WEB.login.footer.aplicacion' | translate}} {{appVersion}} ({{environmentName}}) |
        <small style="color: #499DFF;">
            <span class="terminos-politicas-wrapper" [routerLink]="">

                <a style="color: #499DFF;" href="{{terminosLegales}}" target="_blank">{{ "WEB.login.footer.terminos" |
                    translate}}</a> |
                <a style="color: #499DFF;" href="{{politicasPrivacidad}}" target="_blank">{{ "WEB.login.footer.politicas" |
                    translate}}</a> |
                <a style="color: #499DFF;" href="{{webUrl}}" target="_blank">{{(environmentName !== 'bo'
                    ?'WEB.login.footer.nosotros':'WEB.login.footer.nosotros-bo') | translate}}</a> |
            </span>
            <a style="color: #499DFF;" [routerLink]="" (click)="changeLanguage('es')">{{'COMMON.idioma.espanol' |
                translate}}</a> |
            <a style="color: #499DFF;" [routerLink]="" (click)="changeLanguage('en')">{{'COMMON.idioma.ingles' |
                translate}}</a> |
        </small>
        <img src="{{logoConTexto}}" class="logo" />
    </small>
</div>