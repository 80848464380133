import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';

import { Observable } from 'rxjs/Observable';
import { AutorizacionDetalleDTO, SolicitudAutorizacionDTO, ProcesoSolicitudDTO } from '../dto';
import { IResultado } from '../index';
import { Notificacion } from '@shared/models/notificacion';

@Injectable({
  providedIn: 'root'
})
export class SolicitudAutorizacionService {
  ciaId : string;
  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<SolicitudAutorizacionDTO>>{

    return this.http.listar(this.ciaId+"/solicitud-autorizacion").map(
      (data: IResultado) => {
        let list = Array<SolicitudAutorizacionDTO>();
        let obj;
        for (const d of data.resultado) {
          obj = new SolicitudAutorizacionDTO();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });

  }
  
  listarDetalle(viajeId: number) : Observable<Array<AutorizacionDetalleDTO>>{

    return this.http.listar(this.ciaId+"/detalle-puntos-visita/"+viajeId).map(
      (data: IResultado) => {
        let list = Array<AutorizacionDetalleDTO>();
        let obj;
        for (const d of data.resultado) {
          obj = new AutorizacionDetalleDTO();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });

  }
  
  modificar(notificacion : Notificacion, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/notificaciones/web/solicitudes-autorizacion", notificacion, { funcionalidad });
  }
  registarProceso(porcesoSolicitud: ProcesoSolicitudDTO, usuarioId : number, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/solicitud-autorizacion/proceso/"+usuarioId,porcesoSolicitud,{funcionalidad});
  }
}
