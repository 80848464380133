import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificacionService } from './../resources';
import { ChatViewDTO} from '@shared/dto';
import { ChatDTO } from '@shared/components/chat/resources/chat.dto';
import { ChatProvider } from '@shared/providers';
import { SocketIOService } from '@shared/services/socket-io.service';
import { Socket } from 'socket.io-client';
import { C } from '@shared/commons';
import { ViajeService } from '@shared/services';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-notificacion-web-panel',
  templateUrl: './notificacion-web-panel.component.html',
  styleUrls: ['./notificacion-web-panel.component.scss'],
  providers:[ViajeService]
})
export class NotificacionWebPanelComponent implements OnInit {

  //Eventos a Padre
  @Output()
  cerrar = new EventEmitter<void>();

  @Output()
  cargarNotificacionesEvent = new EventEmitter<number>();

  mensajes: Array<ChatDTO>;
  contador: number = 0;
  viajesId: number[]=[];
  chatViews: ChatViewDTO[]=[];

  //variables globales
  currentUser: any;
  chatSocket: Socket;

  constructor(
    private socketIO: SocketIOService,
    private notificacionService: NotificacionService,
    private chatProvider: ChatProvider,
    private viajeService: ViajeService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.chatSocket = socketIO.obtenerSocket();
    this.conectar();
  }

  ngOnInit(): void {
    this.chatProvider.currentNotificacionChats.subscribe((chats: ChatDTO[]) => {
      this.mensajes = chats;
    }); 
    this.chatProvider.currentViewsChats.subscribe((views : Array<ChatViewDTO>) => {
      this.chatViews =views;
    });
    this.cargarViajes();
  }

  cargarViajes(){
    this.contador =0;
    let viajeConsulta : HttpParams = new HttpParams();
    viajeConsulta = viajeConsulta.set('usuarioId',this.currentUser.usuarioId);

    this.viajeService.listarNoFinalizados(viajeConsulta)
    .subscribe(viajes => {
      this.viajesId = viajes.filter(v=> v.estadoViaje.estado != C.VIAJE.ESTADO.FINALIZADO).map(v => v.viajeId);
      this.chatSocket.emit(C.CHAT_VARS.EVENTO_UNIRSE_CHAT, this.viajesId)

      this.cargarNotificaciones();
    })
  }

  cargarNotificaciones() {
    this.notificacionService.listarNotificacionWeb(this.currentUser.compania.companiaId, this.viajesId,this.currentUser.usuarioId)
    .subscribe(
      (data: ChatDTO[]) => {
        this.chatProvider.changeNotificacionChats(data);
      }, (error) => {
        console.log(error);
      }
    );
  }

  cerrarPanel(event) {
    if (event) {
      event.stopPropagation();
    }
    this.cerrar.emit();
  }

  onClickOpciones(item: ChatDTO) {
 
    let chat = new ChatViewDTO();

    chat.codigo         = item.codigoViaje;
    chat.viajeId        = item.viajeId;
    chat.transportista  = item.usuarioNombre;

    this.viajeService.obtener(item.viajeId)
    .subscribe(viaje => {
      const estadoViaje = viaje.estadoViaje.estado;
      if(estadoViaje === 4){
        chat.viewFooter=true;
      }
      else{
        chat.view  = true;
        // llama al chat
        let mensajeActualizar: ChatDTO = new ChatDTO;
    
        mensajeActualizar.usuarioId = item.usuarioId;
        mensajeActualizar.viajeId = item.viajeId;
        mensajeActualizar.estado = C.CHAT_VARS.ESTADO.LEIDO;
    
        this.chatSocket.emit(C.CHAT_VARS.EVENTO_ENVIA_MENSAJES_LIEDOS_SUPERVISOR, mensajeActualizar);
    
        this.chatProvider.editNotificacionChat(item.viajeId,C.CHAT_VARS.ESTADO.LEIDO)
        this.chatProvider.editViewChat(item.viajeId, true);
      }
      this.chatProvider.addViewChat(chat);
      
    }, (error) => {
      console.log(error);
    })
  
  }
   
  //
  cargar = ()=>{
    this.cargarNotificaciones()
  }


  private conectar(){
    this.chatSocket.on(C.CHAT_VARS.EVENTO_LLEGA_MENSAJE_TRANSPORTISTA, this.onMensajeTransportista);
    this.chatSocket.on(C.CHAT_VARS.EVENTO_LLEGA_MENSAJES_LEIDOS_TRANSPORTISTA, this.onMensajeTransportista);
    this.chatSocket.connect();
  }
  
  private onMensajeTransportista = (...arg) => {
      let chatDTO: ChatDTO = arg[0];  
      let indexChatView = this.chatViews.findIndex( v => v.viajeId == chatDTO.viajeId);    
      if(indexChatView == -1){
        this.chatProvider.addNotificacionChat(chatDTO);      
      }
      this.cargarNotificaciones();
      
  }
}
