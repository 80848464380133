import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent implements OnInit{
  public mostrarSpinner: boolean;
  public mensajePersonalizado : string;

  //Componente DIV
  @ViewChild('loader')
  loader: ElementRef;
  loadingSubscription: Subscription;

  ngOnInit() {
    this.mostrarSpinner = false;
  }

  mostrarLoading() {
    this.mostrarSpinner = true;
  }

  ocultarLoading() {
    this.mostrarSpinner = false;
    this.mensajePersonalizado = null;
  }

  cambiarMensaje(msg : string){
    this.mensajePersonalizado = msg;
  }
}
