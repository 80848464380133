import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient2 } from '../../../../../shared/services';
import { ISelectObject, IResultado } from '../../../../../shared/commons';
import { Usuario, Configuracion } from '../../../../../shared';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../../../../../shared/commons';
import { ConfiguracionCompaniaDTO } from './../configuracion-compania.dto';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CompaniaImportacionConfiguracion } from '../../../../../shared/models/companiaImportacionConfiguracion';

@Injectable()
export class ConfiguracionService {

  constructor(private translate : TranslateService,
              private http : HttpClient2) { }

  //Servicios
  obtenerConfiguracionCompania(idCompania: number) : Observable<Array<Configuracion>>{
    return this.http.listar("/companias/" + idCompania + "/configuracion").map(
      (data) => {
        let resultado = new Array<Configuracion>();
        if(data){
            resultado = data.resultado;
            return resultado;
        }
        return resultado;
      }
    );
  }

  modificarConfiguracionCompania(idCompania : number, configuracionCompaniaDTO: ConfiguracionCompaniaDTO, funcionalidad?: string) : Observable<IResultado>{
    return this.http.modificar("/companias/" + idCompania + "/configuracion", configuracionCompaniaDTO, { funcionalidad }).map(
        (data : IResultado) => {
          let resultado : IResultado;
          if(data){
              resultado = data;
              return resultado;
          }
          return resultado;
        }
      );
  }

  obtenerConfiguracionImportacion(idCompania: number, companiaImportacionConfiguracion : CompaniaImportacionConfiguracion): Observable<Array<CompaniaImportacionConfiguracion>>{
    return this.http.consultar("/companias/" + idCompania + "/configuracion/importacion/consultar", companiaImportacionConfiguracion).map(
      (data) => {
        let resultado = new Array<CompaniaImportacionConfiguracion>();
        if(data){
            resultado = data.resultado;
            return resultado;
        }
        return resultado;
      }
    );
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

}