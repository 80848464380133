import { Usuario } from './usuario';
import { C } from '../commons/constantes';
import { dateToString, dateAndHourToString, estadoSolicitudPedidoString } from '../commons';
import { SolicitudPedido } from './solicitudPedido';

export class EstadoSolicitudPedido {

    //PK
    estadoSolicitudPedidoId: number;

    //Atributos
    fechaInicio: Date;
    fechaFin: Date;
    estado: number;

    //Relaciones
    solicitudPedido: SolicitudPedido;
    usuario: Usuario;

    //Metodos
    copiar(objeto: any) {
        //Propios
        this.estadoSolicitudPedidoId = objeto.estadoSolicitudPedidoId;
        this.fechaInicio = objeto.fechaInicio;
        this.fechaFin = objeto.fechaFin;
        this.estado = objeto.estado;

        //Relaciones
        if (objeto.solicitudPedido != null) {
            this.solicitudPedido = new SolicitudPedido();
            this.solicitudPedido.copiar(objeto.solicitudPedido);
        }
        if (objeto.usuario != null) {
            this.usuario = new Usuario();
            this.usuario.copiar(objeto.usuario);
        }
    }

    get fechaInicioToString(): string {
        let label = '';
        if (typeof (this.fechaInicio) != "undefined" && this.fechaInicio != null) {
            label = dateAndHourToString(this.fechaInicio);
        }
        return label;
    }

    get fechaInicioToFiltro(): string {
        let label = '';
        if (typeof (this.fechaInicio) != "undefined" && this.fechaInicio != null) {
            label = dateToString(this.fechaInicio);
        }
        return label;
    }

    get estadoToString(): string {
        if (this.estado != null) {
            return estadoSolicitudPedidoString(this.estado);
        }
        return "";
    }
}
