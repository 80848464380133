<div class="pos-f-t fixed-top header">
    <nav class="navbar navbar-light navbar-toggleable-md">
        <button class="navbar-toggler navbar-toggler-right" (click)="toggleSidebar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-brand">
            <img src="{{result}}" height="40px" />
        </div>
        
        <div class="collapse navbar-collapse">            
            <ul class="navbar-nav ml-auto mr-5">
                <li class="nav-item soporte-whatsapp">
                    <span>{{'COMMON.general.log-integracion' | translate}}</span>
                    <a id="logIntegracionURL" href="javascript:void(0)" target="_blank">
                        <img src="assets/svg/icons/icono_log_integracion.svg" style="width: 32px; height: 32px; margin-bottom: 2px;" />
                    </a>                    
                </li>
                <li class="nav-item separador_header">
                    <img src="assets/svg/icons/separador_header.svg" style="width: 1px; height: 32px; margin-bottom: 2px;" />                    
                </li>
                <li class="nav-item soporte-whatsapp">
                    <span>{{'COMMON.general.soporte' | translate}}</span>
                    <a id="soporteWhatsapp" href="javascript:void(0)" target="_blank">
                        <img src="assets/svg/icons/icono_whatsapp.svg" style="width: 32px; height: 32px; margin-bottom: 2px;" />
                    </a>                    
                </li>
                <li class="nav-item separador_header">
                        <img src="assets/svg/icons/separador_header.svg" style="width: 1px; height: 32px; margin-bottom: 2px;" />                    
                </li>
                
                <app-notificacion class="notificacion-component"></app-notificacion>
                
                <li class="nav-item dropdown" ngbDropdown>
                    <a href="javascript:void(0)" class="nav-link fs-18" ngbDropdownToggle>
                        <img src="assets/images/persona-sin-fondo.png" style="width: 32px; height: 32px; margin-right: 2px;" />
                        {{currentUser.nombre}} <b class="caret"></b>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <span class="dropdown-item"><b>{{'MODEL.compania.titulo' | translate}}:</b>
                            {{currentUser.compania.nombre}}</span>
                        <span class="dropdown-item"><b>{{'MODEL.tipoUsuario.titulo' | translate}}:</b>
                            {{currentUser.tipoUsuario.descripcion}}</span>
                        <hr>
                        <a class="dropdown-item" [routerLink]="['/login']" (click)="onLoggedout()"><i
                                class="fa fa-fw fa-power-off"></i> {{'COMMON.boton.cerrar' | translate}}</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>