import { Compania, EntidadFinanciera } from ".";

export class Cuenta {
    cuentaId?: number;
    descripcion?: string;
    nroCuenta?: string;

    //Relaciones
    compania? : Compania;
    entidadFinanciera?: EntidadFinanciera

    copiar(objeto: any){
        this.cuentaId = objeto.cuentaId;
        this.descripcion = objeto.descripcion;
        this.nroCuenta = objeto.nroCuenta;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }

        if(objeto.entidadFinanciera != null){
            this.entidadFinanciera = new EntidadFinanciera();
            this.entidadFinanciera.copiar(objeto.entidadFinanciera);
        }
    }
}