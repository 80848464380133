<h3 [ngStyle]="showTitle && {'display': 'block'}">{{ 'WEB.control.estados-viaje.historial-estados-tarea.titulo' | translate }}</h3>

<div class="codigo-rastreo wrapper">
    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.7008 19.0204L17.4165 14.7368C17.2231 14.5435 16.961 14.4361 16.686 14.4361H15.9855C17.1716 12.9194 17.8763 11.0118 17.8763 8.93663C17.8763 4 13.8756 0 8.93815 0C4.00068 0 0 4 0 8.93663C0 13.8733 4.00068 17.8733 8.93815 17.8733C11.0137 17.8733 12.9216 17.1686 14.4386 15.9828V16.6831C14.4386 16.9581 14.546 17.2202 14.7394 17.4135L19.0237 21.6971C19.4276 22.101 20.0808 22.101 20.4804 21.6971L21.6965 20.4812C22.1004 20.0773 22.1004 19.4243 21.7008 19.0204ZM8.93815 14.4361C5.90004 14.4361 3.43775 11.9785 3.43775 8.93663C3.43775 5.89903 5.89574 3.43716 8.93815 3.43716C11.9763 3.43716 14.4386 5.89474 14.4386 8.93663C14.4386 11.9742 11.9806 14.4361 8.93815 14.4361Z" fill="#FB2A2D"/>
    </svg>
    <p>{{ 'WEB.control.estados-viaje.historial-estados-tarea.codigo-rastreo' | translate }}:</p>
    <p>{{ codigoRastreo }}</p>
</div>

<estados-wrapper *ngFor="let historial of (historialEstados$ | async)" [historial]="historial"></estados-wrapper>