import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Incidente } from '../models';
import { IResultado } from '../index';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class IncidenteService {

  constructor(private http : HttpClient2) { }

  listar(viajeId : number ) : Observable<Array<Incidente>>{
    return this.http.listar("/"+viajeId+"/incidentes").map(
      (data: IResultado) => {
        let list = new Array<Incidente>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Incidente();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  registrar(viajeId : number, incidente : Incidente, funcionalidad?: string){
    return this.http.registrar("/"+viajeId+"/incidentes", incidente, {funcionalidad}).map(
      (data: IResultado) => {
        let obj = new Incidente();
        obj.copiar(data);
        return obj;
    });
  }

  reporte(viajeId : number ) : Observable<Array<Incidente>>{
    return this.http.listar("/"+viajeId+"/incidentes").map(
      (data: IResultado) => {
        let list = Array<Incidente>();
        let obj;
        for (const d of data.resultado) {
          obj = new Incidente();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });
  }
}
