import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Cookies, dateToString, formatDate, uniqueFilterFull, uniqueFilter } from '@shared/commons';
import { PrimeUtils } from '@shared/commons/prime-utils';
import { LoadingAnimationComponent } from '@shared/components';
import { DetalleReporteSmsDTO } from '@shared/dto';
import { DetalleSmsService } from '@shared/services/detalle-sms.service';
import { MessageService, SelectItem, Table } from 'primeng';
import { ReporteService } from 'src/app/layout/seguridad/reporte-consumo-sms/resources';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-detalle-sms',
  templateUrl: './detalle-sms.component.html',
  styleUrls: ['./detalle-sms.component.scss'],
  providers: [DetalleSmsService, ReporteService, MessageService]
})
export class DetalleSmsComponent implements OnInit {
  consumoDetalleSms: DetalleReporteSmsDTO[];
  colsDetalleSms: any[];
  selectedFechaInicio;
  selectedFechaFin;
  lang: any;
  sinRegistros : boolean;
  companiaId: number;
  //Variables Viaje  
  @ViewChild('dt')
  dt: Table;

  //pagina inicio
  first:number;
  detalleReporteBoolean: boolean;
  currentUser;

  //variables para filtros
  listFilterAplicacion: SelectItem[];
  listFilterViajeCodigo: SelectItem[];
  listFilterNroTarea: SelectItem[];
  listFilterEstadoTarea: SelectItem[];
  listFilterEstadoSms: SelectItem[];
  listFilterClienteCodigo: SelectItem[];
  listFilterClienteNombre: SelectItem[];
  listFilterTipoDestinatario: SelectItem[];
  listFilterDestinatarioNombre: SelectItem[];
  listFilterTelefono: SelectItem[];
  listFilterCorreo: SelectItem[];
  listFilterOrigenSms: SelectItem[];
  fechaValue: Date;
  horaValue: Date;

  @ViewChild(LoadingAnimationComponent, {static: true})
  loadingComponent: LoadingAnimationComponent;
  constructor(private detalleSmsService: DetalleSmsService,  
     private translate : TranslateService, 
     private reporteService : ReporteService,
     private messageService: MessageService,
     private router : Router
   ) { 
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.detalleReporteBoolean = router.url.includes("/seguridad/reporte-consumo-sms");
              
   }

  ngOnInit(): void {
    this.inicializarDatos();
  }
  inicializarDatos(){
    let language = Cookies.getCookie('LANGUAGE');
    this.lang = PrimeUtils.getCalendarLanguage(language);
    this.first = 0;
    this.limpiarFiltros();
    this.translate
      .get([
        "WEB.seguridad.reporte-consumo-sms.detalle.aplicacion",
        "WEB.seguridad.reporte-consumo-sms.detalle.fecha",
        "WEB.seguridad.reporte-consumo-sms.detalle.hora",
        "WEB.seguridad.reporte-consumo-sms.detalle.codigo-viaje",
        "WEB.seguridad.reporte-consumo-sms.detalle.nro-tarea",
        "WEB.seguridad.reporte-consumo-sms.detalle.estado",
        "WEB.seguridad.reporte-consumo-sms.detalle.estado-sms",
        "WEB.seguridad.reporte-consumo-sms.detalle.codigo-cliente",       
        "WEB.seguridad.reporte-consumo-sms.detalle.nombre-cliente",       
        "WEB.seguridad.reporte-consumo-sms.detalle.tipo-contacto",
        "WEB.seguridad.reporte-consumo-sms.detalle.nombre-contacto",
        "WEB.seguridad.reporte-consumo-sms.detalle.cantidad-sms",
        "WEB.seguridad.reporte-consumo-sms.detalle.celular",
        "WEB.seguridad.reporte-consumo-sms.detalle.correo",
        "WEB.seguridad.reporte-consumo-sms.detalle.origen"
  
      ])
      .subscribe((t) => {
        if (t) {
          this.colsDetalleSms = [
            { field: "aplicacionToLongConstant",header: t["WEB.seguridad.reporte-consumo-sms.detalle.aplicacion"] },
            { field: "fechaString",header: t["WEB.seguridad.reporte-consumo-sms.detalle.fecha"] },
            { field: "hora", header: t["WEB.seguridad.reporte-consumo-sms.detalle.hora"] },
            { field: "viajeCodigo", header: t["WEB.seguridad.reporte-consumo-sms.detalle.codigo-viaje"] },
            { field: "nroTarea", header: t["WEB.seguridad.reporte-consumo-sms.detalle.nro-tarea"] },
            { field: "estadoTareaToString", header: t["WEB.seguridad.reporte-consumo-sms.detalle.estado"] },
            { field: "estadoSMSToString", header: t["WEB.seguridad.reporte-consumo-sms.detalle.estado-sms"] },
            { field: "clienteCodigo", header: t["WEB.seguridad.reporte-consumo-sms.detalle.codigo-cliente"] },
            { field: "clienteNombre", header: t["WEB.seguridad.reporte-consumo-sms.detalle.nombre-cliente"] },
            { field: "tipoDestinatarioToString", header: t["WEB.seguridad.reporte-consumo-sms.detalle.tipo-contacto"] },
            { field: "destinatarioNombre", header: t["WEB.seguridad.reporte-consumo-sms.detalle.nombre-contacto"] },
            { field: "cantSMS", header: t["WEB.seguridad.reporte-consumo-sms.detalle.cantidad-sms"] },
            { field: "telefono", header: t["WEB.seguridad.reporte-consumo-sms.detalle.celular"] },
            { field: "correo", header: t["WEB.seguridad.reporte-consumo-sms.detalle.correo"] },
            { field: "origenEnvioSms", header: t["WEB.seguridad.reporte-consumo-sms.detalle.origen"] }     
          ];
        }
      });
  }
  cargarDatos(companiaId, selectedFechaInicio, selectedFechaFin) {  
      this.companiaId = companiaId
      this.selectedFechaInicio = selectedFechaInicio;
      this.selectedFechaFin =  selectedFechaFin;

      let params = this.obtenerParams();
      
      this.loadingComponent.mostrarLoading();
      if(companiaId != null){     
       this.cargaDetalleReporteSMS(params);
      } else {
        this.consumoDetalleSms = [];
        this.loadingComponent.ocultarLoading();
      } 
  }

  refrescarDetalleReporte(){   

    if(this.selectedFechaInicio > new Date() || this.selectedFechaFin > new Date()){
      this.showToast('error', '', 'la fecha no puede ser mayor al dia actual');
      return ;
    }

    if (this.selectedFechaInicio > this.selectedFechaFin || this.selectedFechaFin < this.selectedFechaInicio) {
      this.showToast('error', '', 'La fecha inicio debe ser menor a la fecha fin');
      return;
    }
    let params = this.obtenerParams();
    
    this.loadingComponent.mostrarLoading();

    if(this.companiaId != null){
      this.cargaDetalleReporteSMS(params);
    } else {
      this.consumoDetalleSms = [];
      this.loadingComponent.ocultarLoading();
    }
  }

  cargaDetalleReporteSMS(params){
    this.detalleSmsService.reporte(params).subscribe( (data: Array<DetalleReporteSmsDTO>) => {  
      this.first = 0;            
      this.consumoDetalleSms = [...data]; 
      this.cargarFiltrosTabla( this.consumoDetalleSms);
    }, (error)=>{},
       ()=>{
        this.loadingComponent.ocultarLoading();
    });
  }

  obtenerParams(){
    const params = {
      companiaId :  this.companiaId,
      fechaInicio: (moment(this.selectedFechaInicio).format('YYYY-MM-DD')),
      fechaFin: (moment(this.selectedFechaFin).format('YYYY-MM-DD'))
    };

    return params;
  }



  cargarFiltrosTabla(consumoDetalleSms : Array<DetalleReporteSmsDTO>){
    for (const objeto of consumoDetalleSms) {
      let detalleReporteSmsDTO = new DetalleReporteSmsDTO();    
      detalleReporteSmsDTO.copiar(objeto);
      //Llenar filtros
      uniqueFilterFull(detalleReporteSmsDTO.app != null ? this.translate.instant(detalleReporteSmsDTO.aplicacionToLongConstant) : null, detalleReporteSmsDTO.aplicacionToLongConstant, this.listFilterAplicacion);
      uniqueFilter(detalleReporteSmsDTO.viajeCodigo, this.listFilterViajeCodigo);
      uniqueFilter(detalleReporteSmsDTO.nroTarea, this.listFilterNroTarea);
      uniqueFilterFull(detalleReporteSmsDTO.estadoTarea? this.translate.instant(detalleReporteSmsDTO.estadoTareaToString) : null, detalleReporteSmsDTO.estadoTareaToString, this.listFilterEstadoTarea);
      uniqueFilterFull(detalleReporteSmsDTO.estadoSMS? this.translate.instant(detalleReporteSmsDTO.estadoSMSToString) : null, detalleReporteSmsDTO.estadoSMSToString, this.listFilterEstadoSms);
      uniqueFilter(detalleReporteSmsDTO.clienteCodigo, this.listFilterClienteCodigo);
      uniqueFilter(detalleReporteSmsDTO.clienteNombre, this.listFilterClienteNombre);
      uniqueFilterFull(detalleReporteSmsDTO.tipoDestinatario? this.translate.instant(detalleReporteSmsDTO.tipoDestinatarioToString) : null, detalleReporteSmsDTO.tipoDestinatarioToString, this.listFilterTipoDestinatario);
      uniqueFilter(detalleReporteSmsDTO.destinatarioNombre, this.listFilterDestinatarioNombre);
      uniqueFilter(detalleReporteSmsDTO.telefono, this.listFilterTelefono);
      uniqueFilter(detalleReporteSmsDTO.correo, this.listFilterCorreo);
      uniqueFilterFull(detalleReporteSmsDTO.origenEnvioSms != null ? this.translate.instant(detalleReporteSmsDTO.origenEnvioSms) : null, detalleReporteSmsDTO.origenEnvioSms, this.listFilterOrigenSms);
    }
  }
  exportarComoXLS(){
    if(this.consumoDetalleSms.length > 0){

      //Título del Header
      const tituloHeader = this.translate.instant("WEB.seguridad.reporte-consumo-sms.detalle-sms");
               
      //Preparamos los registros para exportar
      const datosReporte = this.consumoDetalleSms.map((consumoSMS: DetalleReporteSmsDTO) => ({
        app : consumoSMS.aplicacionToLongConstant,
        fecha : formatDate(consumoSMS.fecha, "dd/MM/yyyy"),
        hora : consumoSMS.hora,
        viajeCodigo: consumoSMS.viajeCodigo,
        nroTarea: consumoSMS.nroTarea,
        estadoTarea: consumoSMS.estadoTareaToString,
        estadoSMS: consumoSMS.estadoSMSToString,
        clienteCodigo: consumoSMS.clienteCodigo,
        clienteNombre: consumoSMS.clienteNombre,
        tipoDestinatario: consumoSMS.tipoDestinatarioToString,
        destinatarioNombre: consumoSMS.destinatarioNombre,
        cantSMS: consumoSMS.cantSMS,
        telefono: consumoSMS.telefono,
        correo: consumoSMS.correo,
        origenEnvioSms: consumoSMS.origenEnvioSms
      }));

      let resultDatos;
      resultDatos = datosReporte.map(Object.values);

      //Preparamos las columnas para exportar
      const headersColumn = new Object();
      this.colsDetalleSms.forEach(function(column ){
        headersColumn[column.field] = column.header
      }); 

      let headers = Object.values(headersColumn);
      let resultHeaders : Array<any> = [];
      resultHeaders.push(headers);

      //Llamamos al servicio
      this.reporteService.obtenerExcelExportacionReportes(tituloHeader, resultHeaders, resultDatos, {inicio: this.selectedFechaInicio, fin: this.selectedFechaFin });
     
    }else{
      this.sinRegistros = true;
      setTimeout(() => {
        this.sinRegistros = false;
      }, 2000);
    }
  }

  limpiarFiltros(){
    this.listFilterAplicacion = [];
    this.listFilterViajeCodigo = [];
    this.listFilterNroTarea = [];
    this.listFilterEstadoTarea = [];
    this.listFilterEstadoSms = [];
    this.listFilterClienteCodigo = [];
    this.listFilterClienteNombre = [];
    this.listFilterTipoDestinatario = [];
    this.listFilterDestinatarioNombre = [];
    this.listFilterTelefono = [];
    this.listFilterCorreo = [];
    this.listFilterOrigenSms = [];
    this.fechaValue = null;
    this.horaValue = null;
  }

  

  limpiarCalendar() {
    this.dt.reset();
  }

  showToast(severidad: string, titulo: string, detalle: string) {
    this.messageService.add({ severity: severidad, summary: titulo, detail: detalle });
  }

}
