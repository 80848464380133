import { Component, Input, OnInit } from '@angular/core';
import { C, Cookies } from '@shared/commons';
import { EstadoTareaService } from '@shared/services';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const getEstadoTareaToString = (estado) => {
  const etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
  if (estado != null) {
    switch (estado) {
      case C.TAREA.ESTADO.PENDIENTE:
        return etiquetas['estado_tarea_pendiente'];
      case C.TAREA.ESTADO.EN_APT:
        return etiquetas['estado_tarea_en_apt'];
      case C.TAREA.ESTADO.EN_RUTA:
        return etiquetas['estado_tarea_en_ruta'];
      case C.TAREA.ESTADO.EN_ESPERA:
        return etiquetas['estado_tarea_en_espera'];
      case C.TAREA.ESTADO.EN_CLIENTE:
        return etiquetas['estado_tarea_en_cliente'];
      case C.TAREA.ESTADO.REALIZANDO_TAREA:
        return etiquetas['estado_tarea_inicio_descarga'];
      case C.TAREA.ESTADO.TERMINADO_TOTAL:
      case C.TAREA.ESTADO.EN_HUB:
        return 'Entregado cerca de la puerta principal o porche';
      case C.TAREA.ESTADO.TERMINADO_PARCIAL:
        return etiquetas['estado_tarea_terminado_parcial'];
      case C.TAREA.ESTADO.RECHAZADO:
        return etiquetas['estado_tarea_rechazado'];
      case C.TAREA.ESTADO.NO_REALIZADO:
        return etiquetas['estado_tarea_no_realizado'];
      case C.TAREA.ESTADO.EN_CAMINO:
        return etiquetas['estado_tarea_en_camino'];
      case C.TAREA.ESTADO.NO_PROGRAMADO:
        return etiquetas['estado_tarea_no_programado'];
    }
  }
  return "";
}

@Component({
  selector: 'historial-estado-tarea',
  templateUrl: './historial-estado-tarea.component.html',
  styleUrls: ['./historial-estado-tarea.component.scss']
})
export class HistorialEstadoTareaComponent implements OnInit {

  @Input() showTitle = false;

  codigoRastreo: string;
  historialEstados$: Observable<any>;
  lng: string;

  constructor(private estadoTareaService: EstadoTareaService) { }

  ngOnInit(): void {
    this.lng = Cookies.getCookie("LANGUAGE");  
    moment.locale(this.lng || 'es');
  }

  cargarDatos(codigoRastreo: string, tareaId: number): void {
    this.codigoRastreo = codigoRastreo;
    this.historialEstados$ = this.estadoTareaService.historial(tareaId).pipe(
      map((res: any) => res.map(e => ({ ...e, fechaFormateada: moment(e.fecha).format(`dddd, DD [${this.lng === 'en' ? 'of' : 'de'}] MMMM`) }))),
      map(data => groupBy(data, 'fechaFormateada')),
      map(data => Object.keys(data)
        .map(key => ({
          dia: key, 
          estados: data[key].map(e => ({
            hora: moment(e.fecha).format("h:mm:ss a"),
            descripcion: getEstadoTareaToString(e.estado),
            valor: e.estado,
            ubigeo: !e.distrito && !e.provincia && !e.distrito ? null : `${e.distrito || ''} ${e.provincia || ''} ${e.departamento || ''}`,
            usuario: e.usuarioNombre,
            perfiles: e?.perfiles,
            motivoId:e.motivoId,
            motivo:e.motivo
          }))
        }))),
        // tap(console.log)
    );
  }

}
