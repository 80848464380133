import { C } from '@shared/commons';
import { Compania } from './compania';
import { EvidenciaMotivo } from './evidenciaMotivo';
import { GrupoDevolucion } from './grupoDevolucion';

export class Motivo {
    //PK
    motivoId : number;

    //Atributos
    codigo : string;
    descripcion : string;
    notificarCliente : number;
    notificarInternamente : number;
    correosContactosInternos : number;
    tipo : number;
    estado: number;

    //Relaciones
    compania : Compania;
    grupoDevolucion : GrupoDevolucion;
    listEvidenciaMotivo : EvidenciaMotivo[];

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;
    estadoString: string;

    //Metodos
    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    get notificarClienteToString(){
        if(this.notificarCliente!=null && this.notificarCliente == 1){
            return "COMMON.boton.si";
        }

        return "COMMON.boton.no";
    }
    get notificarInternamenteToString(){
        if(this.notificarInternamente!=null && this.notificarInternamente == 1){
            return "COMMON.boton.si";
        }

        return "COMMON.boton.no";
    }

    copiar(objeto : any){
        //Propios
        this.motivoId = objeto.motivoId;
        this.codigo = objeto.codigo;
        this.descripcion = objeto.descripcion;
        this.notificarCliente = objeto.notificarCliente;
        this.notificarInternamente = objeto.notificarInternamente;
        this.correosContactosInternos = objeto.correosContactosInternos;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.tipo = objeto.tipo;
        this.estado = objeto.estado;
        this.estadoString = this.obtenerEstadoString(this.estado);

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
        if(objeto.grupoDevolucion != null){
            this.grupoDevolucion = new GrupoDevolucion();
            this.grupoDevolucion.copiar(objeto.grupoDevolucion);
        }
        if(objeto.listEvidenciaMotivo != null){
            let evidencia : EvidenciaMotivo;
            this.listEvidenciaMotivo = new Array();
            for (let r of objeto.listEvidenciaMotivo) {
                if(r != null){
                    evidencia = new EvidenciaMotivo();
                    evidencia.copiar(r);
                    this.listEvidenciaMotivo.push(evidencia);
                }
            }
        }

    }

    instanciar() : void{
        if(this.compania==null) this.compania = new Compania();
    }

    obtenerEstadoString (estado : number){
        return estado === C.MOTIVO.ESTADO.ACTIVO ? "COMMON.boton.activo" : "COMMON.boton.inactivo"; 
    }

}
