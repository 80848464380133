import { Compania } from "./compania";
import { Etiqueta } from "./etiqueta";
import { Funcionalidad } from "./funcionalidad";

export class FuncionalidadPersonalizacion{
    //Pk
    funcionalidadPersonalizacionId : number;

    //Atributos
    nombre : string;
    estado : boolean;
    
    // Relaciones
    compania : Compania ;
    etiqueta : Etiqueta ;


    copiar(objeto : any){
        //Propios
        this.funcionalidadPersonalizacionId = objeto.funcionalidadPersonalizacionId;
        this.nombre = objeto.nombre;
        this.estado = objeto.estado;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
        if(objeto.etiqueta != null){
            this.etiqueta = new Etiqueta();
            this.etiqueta.copiar(objeto.etiqueta);
        }
    }
}