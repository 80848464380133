export class Rubro {
    //PK
    rubroId : number;
    
    //Atributos    
    codigo : number;    
    nombre: string;
    i18n: string;


    //Metodos
    copiar(objeto : any){
        //Propios
        this.rubroId = objeto.rubroId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;  
        this.i18n= objeto.i18n;               
    }

}