import { Viaje } from './viaje';
import { Usuario } from './usuario';
import { dateToString, dateAndHourToString } from '../commons';
import { TipoIncidente } from './tipoIncidente';

export class Incidente {
    //PK
    incidenteId : number;

    //Atributos
    fecha : Date;
    descripcion : string;
    gestionRealizada : string;

    //Relaciones
    viaje : Viaje;
    usuario : Usuario;
    tipoIncidente : TipoIncidente;
    //Otras Variables
    fechaRealInicioString : string;
    fechaString : string;

    //Metodos
    static clonar(objeto : any) : Incidente{
        let incidente : Incidente = new Incidente();
        //Propios
        incidente.incidenteId = objeto.incidenteId;
        incidente.descripcion = objeto.descripcion;
        incidente.gestionRealizada = objeto.gestionRealizada;
        incidente.fecha = objeto.fecha;

        //Relaciones
        if(incidente.viaje != null){
            incidente.viaje = new Viaje();
            incidente.viaje.copiar(objeto.tarea);
        }

        if(incidente.usuario != null){
            incidente.usuario = new Usuario();
            incidente.usuario.copiar(objeto.usuario);
        }

        if(incidente.tipoIncidente != null){
            incidente.tipoIncidente = new TipoIncidente();
            incidente.tipoIncidente.copiar(objeto.tipoIncidente);
        }

        return incidente;
    }
    copiar(objeto : any){
        //Propios
        this.incidenteId = objeto.incidenteId;
        this.descripcion = objeto.descripcion;
        this.gestionRealizada = objeto.gestionRealizada;
        this.fecha = objeto.fecha;

        //Relaciones
        if(objeto.viaje != null){
            this.viaje = new Viaje();
            this.viaje.copiar(objeto.viaje);
        }
        if(objeto.usuario != null){
            this.usuario = new Usuario();
            this.usuario.copiar(objeto.usuario);
        }
        if(objeto.tipoIncidente != null){
            this.tipoIncidente = new TipoIncidente();
            this.tipoIncidente.copiar(objeto.tipoIncidente);
        }

    }

    get fechaToFiltro() : string{
        let label = '';
        if(typeof(this.fecha) != "undefined" && this.fecha != null){
            label = dateToString(this.fecha);
        }
        return label;
    }

    get fechaToString() : string{
      let label = '';
      if(typeof(this.fecha) != "undefined" && this.fecha != null){
          label = dateAndHourToString(this.fecha);
      }
      return label;
    }

}
