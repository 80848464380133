import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { HttpHeaders } from '@angular/common/http'
import { Cookies } from '../../shared/commons';

//Clases
import { Categoria } from '../models';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarCategoriaDTO } from '../dto';
import { IResultado } from '../index';

@Injectable()
export class CategoriaService {
  ciaId : string;
  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<Categoria>>{

    return this.http.listar(this.ciaId+"/categorias").map(
      (data: IResultado) => {
        let list = Array<Categoria>();
        let obj;
        for (const d of data.resultado) {
          obj = new Categoria();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });

  }

  consultar(data) : Observable<Array<Categoria>>{

    return this.http.consultar(this.ciaId+"/categorias/consultar", data).map(
      (data: IResultado) => {
        if(data){
          let list = Array<Categoria>();
          let obj;
          for (const d of data.resultado) {
            obj = new Categoria();
            obj.copiar(d);
            list.push(obj);
          }
          return list;
        }
    });

  }

  obtener(id : number) : Observable<Categoria>{
    return this.http.obtener(this.ciaId+"/categorias/"+id).map(
      (data: IResultado) => {
        let obj = new Categoria();
        obj.copiar(data.resultado);
        return obj;
    });
  }

  registrar(categoria : Categoria, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/categorias", categoria, { funcionalidad });
  }

  modificar(categoria : Categoria, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/categorias", categoria, { funcionalidad });
  }

  eliminar(categoriaId : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/categorias/"+categoriaId, { funcionalidad });
  }
  
  obtenerExcelExportacionReportes(data: any, tipoReporte: string) : Observable<any> {
    return this.http.consultar("/reportes/" + tipoReporte + "/exportar-excel", data, { responseType: "text" });
  }

  registrarImportacion(categoria : Array<ImportarCategoriaDTO>, funcionalidad?: String) : Observable<any>{
    return this.http.registrarImportacion(this.ciaId+"/categorias/registrar-importacion", categoria, { funcionalidad });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }
}
