import { Compania } from "@shared/models";
import { ConfiguracionMarcaDTO } from "./configuracionMarca.dto";

export class MarcaDTO{

    marcaId: number;
    codigo: string;
    descripcion: string;
    estado: number;
    compania?: Compania;
    configuracionMarcas?: ConfiguracionMarcaDTO[];


    constructor(
        marcaId?:number,
        codigo?:string,
        descripcion?:string,
        estado?:number,
        compania?: Compania,
        configuracionMarcas?: ConfiguracionMarcaDTO[]) {
        this.marcaId=marcaId;
        this.codigo=codigo;
        this.descripcion=descripcion;
        this.estado=estado;
        this.compania=compania;
        this.configuracionMarcas=configuracionMarcas;
      }
    
    copiar(objeto: any){
        this.marcaId = objeto.marcaId;
        this.codigo = objeto.codigo;
        this.descripcion = objeto.descripcion;
        this.estado = objeto.estado;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
        
        //Relaciones
        if(objeto.configuracionMarcas != null){
            let confmMarca : ConfiguracionMarcaDTO;
            this.configuracionMarcas = new Array();
            for (let r of objeto.configuracionMarcas) {
                if(r != null){
                    confmMarca = new ConfiguracionMarcaDTO();
                    confmMarca.copiar(r);
                    this.configuracionMarcas.push(confmMarca);
                }
            }
        }
    }
    
}