import { NgModule } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect'
import { SharedModule } from './../shared.module';
import { MultipleComponent } from './';
import { SeccionModule } from './../../components/seccion.module';

@NgModule({
  imports: [
    SharedModule, SeccionModule,
    MultiSelectModule, CalendarModule
  ],
  declarations: [ MultipleComponent ],
  exports:[ MultipleComponent ]
})
export class BusquedaModule { }
