import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';
import { HttpParams } from '@angular/common/http';
//Clases
import { EmpresaTransporte, UnidadTransporte } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { UnidadTransporteSensor } from '@shared/models/unidadTransporteSensor';

@Injectable()
export class UnidadTransporteService {

  currentUser : any;

  constructor(private http : HttpClient2) { 
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  listar(empresaTransporteId : number){
    return this.http.listar("/"+empresaTransporteId + "/unidades-transporte").map(
      (data: IResultado) => {
        let list = Array<UnidadTransporte>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new UnidadTransporte();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  //consultar unidades disponibles segun parametros
  listarUnidadesDisponibles(parametros){
    return this.http.consultar("/unidades-transporte/disponibles", parametros).map(
      (data: IResultado) => {
        let list = Array<UnidadTransporte>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new UnidadTransporte();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  } 

  esEliminable(empresaTransporteId : number, params : HttpParams){
    return this.http.consultar("/"+empresaTransporteId + "/unidades-transporte/es-eliminable", {}, { params: params });
  }


  consultar(data): Observable<Array<UnidadTransporte>> {
    let empresaTransporteId = this.currentUser.empresaTransporte.empresaTransporteId;
    return this.http.consultar(`/${empresaTransporteId}/unidades-transporte/consultar`, data).map(
        (data: IResultado) => {
          let list = Array<UnidadTransporte>();
          if (data) {
            for (const unidadTransporte of data.resultado) {
              let unidad = new UnidadTransporte();
              unidad.copiar(unidadTransporte);
            
              //Datos de tipo de unidad
              //unidad.tipoUnidadTransporte = d.tipoUnidadTransporte;
              list.push(unidad);
            }
          }
          return list;
        });
        
  }

  //Obtener Unidad de Transporte por ID de Unidad
  obtener(id : number) : Observable<UnidadTransporte>{
    return this.http.obtener("/unidades-transporte/" + id).map(
      (data: IResultado) => {
        let obj = new UnidadTransporte();
        if(data){
          obj.copiar(data.resultado);
        }
        return obj;
    });
  }

  //Elimina Unidad Transporte
  eliminar(unidadTransporteId : number, funcionalidad?: String){
    return this.http.eliminar("/unidades-transporte" + "/" + unidadTransporteId, {funcionalidad});
  }

  //Modificar Unidad Transporte
  guardar(unidadTransporte : UnidadTransporte, funcionalidad?: String){

    if (unidadTransporte.unidadTransporteId) {
      return this.http.modificar("/" + unidadTransporte.empresaTransporte.empresaTransporteId + "/unidades-transporte", unidadTransporte, {funcionalidad});
    } else {
      return this.http.registrar("/" + unidadTransporte.empresaTransporte.empresaTransporteId + "/unidades-transporte", unidadTransporte, { funcionalidad});
    }
  }

  //Consultar por Compañia
  consultarPorCompania(data): Observable<Array<UnidadTransporte>> {
    let companiaId = this.currentUser.compania.companiaId;
    return this.http.consultar(`/unidades-transporte/compania/${companiaId}`, data).map(
        (data: IResultado) => {
          let list = Array<UnidadTransporte>();
          if (data) {
            for (const unidadTransporte of data.resultado) {
              let unidad = new UnidadTransporte();
              unidad.copiar(unidadTransporte);
            
              //Datos de tipo de unidad
              //unidad.tipoUnidadTransporte = d.tipoUnidadTransporte;
              list.push(unidad);
            }
          }
          return list;
        });
        
  }

  // obtener las sensores de la unidad de transporte
  consultarSensores(unidadTransporteSensor): Observable<Array<UnidadTransporteSensor>> {
    let empresaTransporteId = this.currentUser.empresaTransporte.empresaTransporteId;
    return this.http.consultar(`/${empresaTransporteId}/unidades-transporte/consultar-sensores`, unidadTransporteSensor).map(
      (data: IResultado) => {
        let list = Array<UnidadTransporteSensor>();
        if (data) {
          for (const unidadTransporte of data.resultado) {
            //unidades con sensor
            let unidadSensor = new UnidadTransporteSensor();
            unidadSensor.copiar(unidadTransporte);                 
            list.push(unidadSensor);
          }
        }
        return list;
      }
    );        
  }

  //Obtener listado de Unidades de Transporte con su procedencia de CLocator
  listarUnidadesTransporteProcedencia(empresaTransporteId : number,unidadesTransporte: Array<UnidadTransporte>): Observable<Array<UnidadTransporte>>{
    return this.http.consultar(`/${empresaTransporteId}/unidades-transporte/listado-procedencia-gps`,unidadesTransporte).map(
      (data: IResultado) => {
        let list = Array<UnidadTransporte>();
        if (data) {
          for (const unidadTransporte of data.resultado) {
            let unidad = new UnidadTransporte();
            unidad.copiar(unidadTransporte);
          
            list.push(unidad);
          }
        }
        return list;
      });
  }

  //busca x empresa de transporte
  consultarPorEmpresaTransporte(data): Observable<Array<UnidadTransporte>> {
    return this.http.consultar(`/unidades-transporte/obtener-por-empresa-transportes`, data).map(
        (data: IResultado) => {
          let list = Array<UnidadTransporte>();
          if (data) {
            for (const t of data.resultado) {
              let unidadtransporte = new UnidadTransporte();
              unidadtransporte.copiar(t);
              list.push(unidadtransporte);
            }
          }
          return list;
        });
  }

}
