import { Compania } from './compania';
import { Categoria } from './categoria';
import { Canal } from './canal';
import { SubCanal } from './subcanal';
import { Rubro } from './rubro';
import { Sede } from './sede';
import { CentroCosto } from '@shared/models/centroCosto';

export class ClienteFinal {
    //PK
    clienteFinalId: number;

    //Atributos
    codigo: string;
    nombre: string;
    telefono: string;
    correo: string;

    //Relaciones
    compania: Compania;
    categoria: Categoria;
    canal: Canal;
    subCanal: SubCanal;
    rubro: Rubro;
    listSede: Sede[];
    tarifa: number;
    centroCosto: CentroCosto;
    guias: number;
    bultos: number;

    //Otras Variables
    estadoRegistro: string;
    resultadoRegistro: string;
    nombreSede: string;

    constructor() {

    }

    get estadoRegistroToIcon(): string {
        if (this.estadoRegistro != null) {
            switch (this.estadoRegistro) {
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor(): string {
        if (this.estadoRegistro != null) {
            switch (this.estadoRegistro) {
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    //Metodos
    copiar(objeto: any) {
        //Propios
        this.clienteFinalId = objeto.clienteFinalId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.telefono = objeto.telefono;
        this.correo = objeto.correo;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.tarifa = objeto.tarifa;
        this.guias = objeto.guias;
        this.bultos = objeto.bultos;
        this.nombreSede = objeto.nombreSede;


        //Relaciones
        if (objeto.compania != null) {
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
        if (objeto.categoria != null) {
            this.categoria = new Categoria();
            this.categoria.copiar(objeto.categoria);
        }
        if (objeto.canal != null) {
            this.canal = new Canal();
            this.canal.copiar(objeto.canal);
        }
        if (objeto.subCanal != null) {
            this.subCanal = new SubCanal();
            this.subCanal.copiar(objeto.subCanal);
        }
        if (objeto.rubro != null) {
            this.rubro = new Rubro();
            this.rubro.copiar(objeto.rubro);
        }
        if (objeto.listSede != null) {
            let sede: Sede;
            this.listSede = new Array();
            for (let r of objeto.listSede) {
                if (r != null) {
                    sede = new Sede();
                    sede.copiar(r);
                    this.listSede.push(sede);
                }
            }
        }
        if (objeto.centroCosto != null) {
            this.centroCosto = new CentroCosto();
            this.centroCosto.copiar(objeto.centroCosto);
        }
    }

    instanciar(): void {
        if (this.compania == null) this.compania = new Compania();
        if (this.categoria == null) this.categoria = new Categoria();
        if (this.canal == null) this.canal = new Canal();
        if (this.subCanal == null) this.subCanal = new SubCanal();
        if (this.rubro == null) this.rubro = new Rubro();
        if (this.centroCosto == null) this.centroCosto = new CentroCosto();
    }


}
