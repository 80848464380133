<div class="container"> 

    <ng-template [ngIf]="headerMultiple != undefined">
        <div class="headerMultiple">
            <div class="contentMultiple">
                <ng-template ngFor let-item [ngForOf]="headerMultiple">
                    <div   class="">             
                        <div [style.background-color]="header_background">  <p  class="flex-center">{{item.valor}}</p></div> 
                       <div [style.--colorFondo]="header_background"><p [style.color]="header_background"  class="flex-center">{{item.etiqueta}}</p></div> 
                    </div>                  
                </ng-template>
            </div>
          
        </div>
    </ng-template>

    <ng-template [ngIf]="cabecera != undefined">
      <div class="body">
        <ng-template [ngIf]="cabecera != undefined">
          <div class="cabecera flex-center">
            <p>{{cabecera}}</p>
          </div>
        </ng-template>  
       
      </div>
    </ng-template>
  
    <ng-template [ngIf]="footer != undefined">
      <div class="footer">
        <div>
            <button (click)="onClickDetalle.emit()">
                <span><i class="fa fa-fw fa-list-ul"></i>{{'COMMON.boton.detalle' | translate}}</span>
            </button>
        </div>
      </div>
    </ng-template>
  </div>