import { Usuario } from './usuario';

export class LogSession {
     //PK
    logSessionId : number;

    //Atributos
    fechaHoraInicio : Date;
    fechaHoraFin : Date;
    ip : string;
    imei : string;
    tipoAcceso : string;

    //Relaciones
    usuario : Usuario ;

    //Constructor
    constructor(){};

    //Metodos
    clone(): any {
        var cloneObj = new (this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    copiar(objeto : any){
        //Propios
        this.logSessionId = objeto.logSessionId;
        this.fechaHoraInicio = objeto.fechaHoraInicio;
        this.fechaHoraFin = objeto.fechaHoraFin;
        this.ip = objeto.ip;
        this.imei = objeto.imei;
        this.tipoAcceso = objeto.tipoAcceso;

        //Relaciones
        if(objeto.usuario != null){
            this.usuario = new Usuario();
            this.usuario.copiar(objeto.usuario);
        }
    }
}

