import { C } from '@shared/commons';
import { AgregaProductoComponent } from 'src/app/layout/control/estados-viaje/shared/tabla-detalle/guia-detalle/detalle-documentos/agrega-producto/agrega-producto.component';
import { Compania, Guia } from '.';
import { Tarea } from './tarea';
import { Transportista } from './transportista';

export class TipoProducto {
    //PK
    tipoProductoId : number;

    //Atributos
    descripcion : string;
    contenedor : number;

    //Metodos
    copiar(objeto : any){
        //Propios

        this.tipoProductoId = objeto.tipoProductoId;
        this.descripcion = objeto.descripcion;
        this.contenedor = objeto.contenedor;
        
    }    


}
