import { Compania } from "./compania";



export class GrupoDevolucion{
    //Pk
    grupoDevolucionId : number;

    //Atributos
    codigo : string;
    descripcion : string;
    estado : number;

    compania : Compania;

    copiar(objeto : any){
        //Propios
        this.grupoDevolucionId = objeto.grupoDevolucionId;
        this.codigo = objeto.codigo;
        this.descripcion = objeto.descripcion;
        this.estado = objeto.estado;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }
}