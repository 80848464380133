<li class="nav-item dropdown item-notificacion" ngbDropdown #dropNotificacion="ngbDropdown"
    (openChange)="checkDropDown($event)" >
    <a href="javascript:void(0)" class="nav-link" ngbDropdownToggle>
        <img src="assets/svg/icons/circulo_rojo_campana.svg" style="width: 32px; height: 32px; margin-bottom: 2px;" />                 
        <span class="contador fs-18">{{cantidadNotificaciones}}</span>
    </a>
   
    <div class="dropdown-menu dropdown-menu-right ui-dialog" ngbDropdownMenu>        
        <app-notificacion-panel [showNotiSolicitud]="showNotiSolicitud" (cerrar)="cerrarDialog();"></app-notificacion-panel>
    </div> 
</li>

<li class="nav-item separador_header">
    <img src="assets/svg/icons/separador_header.svg" style="width: 1px; height: 32px; margin-bottom: 2px;" />                    
</li>

<li *ngIf="currentUser.esSupervisor" class="nav-item dropdown item-notificacion" ngbDropdown #dropNotificacionWeb="ngbDropdown"
    (openChange)="checkDropDown($event)" >
    <a href="javascript:void(0)" class="nav-link" ngbDropdownToggle>
        <div [className]="contadorNotificacionWeb != 0 ? 'icon-circle circle-rojo' : 'icon-circle circle-dark'" >
            <img src="assets/svg/icons/notificacion_web.svg" class="icon-chat" />
        </div>                        
        <span class="contador fs-18">{{contadorNotificacionWeb}}</span>
    </a>
   
    <div class="dropdown-menu dropdown-menu-right ui-dialog" ngbDropdownMenu>        
        <app-notificacion-web-panel (cerrar)="cerrarDialogNotificacionWeb();"></app-notificacion-web-panel>
    </div>
    
</li>

<li *ngIf="currentUser.esSupervisor" class="nav-item separador_header">
    <img src="assets/svg/icons/separador_header.svg" style="width: 1px; height: 32px; margin-bottom: 2px;" />                    
</li>

