import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputEnteroDirective, InputDecimalDirective }  from './';

const SHARED_DIRECTIVES = [
  InputEnteroDirective,
  InputDecimalDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SHARED_DIRECTIVES],
  exports: [ SHARED_DIRECTIVES ]
})
export class SharedDirectivesModule { }
