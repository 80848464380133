import { Injectable } from '@angular/core';
import { EstadoNotificacion } from '@shared/models/estadoNotificacion';
import { HttpClient2 } from '.';

@Injectable()
export class EstadoNotificaionService {
  ciaId;
  constructor(private http: HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser")); 
    this.ciaId = "/"+currentUser.compania.companiaId;
   }

  registrar(estadoNotificacion: EstadoNotificacion, funcionalidad?: string) { 
    return this.http.registrar(`${this.ciaId}/estado-notificacion/por-tarea`, estadoNotificacion, { funcionalidad }); 
  } 
} 
