import { Tarea, } from './tarea';
import { Usuario } from './usuario';
import { Guia } from './guia';
import { GuiaEvidencia } from './guiaEvidencia';
import { C } from '../commons/constantes';
import { dateAndHourToString, dateToString } from '../commons';
import { Motivo } from './motivo';

export class EstadoGuiaEntrega {
    //PK
    estadoGuiaEntregaId: number;

    //Atributos
    estado: number;
    fechaInicio: Date;
    fechaFin: Date;
    observacion: string;

    //Relaciones
    guia: Guia;
    usuario: Usuario;
    listGuiaEvidencia: GuiaEvidencia[];
    motivo: Motivo;

    //Metodos
    copiar(objeto: any) {
        if(objeto != null || objeto != undefined) {
            //Propios
            this.estadoGuiaEntregaId = objeto.estadoGuiaEntregaId;
            this.estado = objeto.estado;
            this.fechaInicio = objeto.fechaInicio;
            this.fechaFin = objeto.fechaFin;
            this.observacion = objeto.observacion;

            //Relaciones
            if (objeto.guia != null) {
                this.guia = new Guia();
                this.guia.copiar(objeto.guia);
            }
            if (objeto.usuario != null) {
                this.usuario = new Usuario();
                this.usuario.copiar(objeto.usuario);
            }
            if (objeto.listGuiaEvidencia != null) {
                this.listGuiaEvidencia = [];
                let guiaEvidencia: GuiaEvidencia;
                for (let r of objeto.listGuiaEvidencia) {
                    if (r != null) {
                        guiaEvidencia = new GuiaEvidencia();
                        guiaEvidencia.copiar(r);
                        this.listGuiaEvidencia.push(guiaEvidencia);
                    }
                }
            }
        }



    }

    get estadoGuiaEntregaToString(): string {
        if (this.estado != null) {
            switch (this.estado) {
                case C.GUIA.ESTADO.ENTREGA_PENDIENTE:
                    return "COMMON.constantes.estados-guia.entrega-pendiente";
                case C.GUIA.ESTADO.ENTREGA_TOTAL:
                    return "COMMON.constantes.estados-guia.entrega-total";
                case C.GUIA.ESTADO.ENTREGA_PARCIAL:
                    return "COMMON.constantes.estados-guia.entrega-parcial";
                case C.GUIA.ESTADO.RECHAZO_TOTAL:
                    return "COMMON.constantes.estados-guia.rechazo-total";
            }
        }
        return "";
    }

    get fechaFinToFiltro(): string {
        let label = '';
        if (typeof (this.fechaFin) != "undefined" && this.fechaFin != null) {
            label = dateToString(this.fechaFin);
        }
        return label;
    }

    get fechaInicioToString(): string {
        let label = '';
        if (typeof (this.fechaInicio) != "undefined" && this.fechaInicio != null) {
            label = dateAndHourToString(this.fechaInicio);
        }
        return label;
    }

    get fechaFinToString(): string {
        let label = '';
        if (typeof (this.fechaFin) != "undefined" && this.fechaFin != null) {
            label = dateAndHourToString(this.fechaFin);
        }
        return label;
    }

}
