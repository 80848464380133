<div class="alerta-tarea-item">
    <div class="seccion-titulo d-flex">
        <span class="mr-2">
            <i class="fa fa-bell"></i>
        </span>
        <span class="campana">{{'WEB.notificacion.tab-alerta-tarea.alerta' | translate }}:</span>
        <span class="ml-auto fecha-solicitud">{{notificacion.fechaCreacion | date:'dd/MM/yyyy HH:mm'}}</span>
    </div>
    <div class="seccion-contenido">
        <div class="contenido">
            <span>{{'WEB.notificacion.tab-alerta-tarea.nro-orden' | translate }}: {{notificacion.puntoVisita.orden}}</span>
        </div>
        <div class="contenido">
            <span class="negrita">{{
                (notificacion.puntoVisita.tarea.estadoTarea.estado==9 ? 'WEB.notificacion.tab-alerta-tarea.detalle-rechazo' :
                                                                        'WEB.notificacion.tab-alerta-tarea.detalle-no-realizado') | 
                                                                        translate:{value: notificacion.puntoVisita.tarea.nroTarea} }}</span>
        </div>
        <div *ngIf="showbtnDetalle" class="d-flex justify-content-center mt-3 mb-3">
            <button type="button" class="btn btn-secondary mr-3" (click)="abrirDetalleViaje()">
                <span>{{'COMMON.boton.ver-detalle' | translate}}</span>
            </button>
        </div>
    </div>
</div>