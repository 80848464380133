import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';

//Clases
import { SubCanal } from '../models';

import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class SubCanalService {

  constructor(private http : HttpClient2) { }

  listar(canalaId : number) : Observable<Array<SubCanal>>{
    return this.http.listar("/"+canalaId + "/subcanales").map(
      (data: IResultado) => {
        let list = Array<SubCanal>();
        let obj;
        for (const d of data.resultado) {
          obj = new SubCanal();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });
  }

  listarMultiple(canales : number[]) : Observable<Array<SubCanal>>{

    let consultas : Array<Observable<Array<SubCanal>>> = [];
    if(canales.length>0){
      for(let canalId of canales){
        consultas.push(this.listar(canalId));
      }

      return Observable.forkJoin(consultas).map(results => {
          let listSubCanales : Array<SubCanal> = [];
          // results[0] is our canal
          // results[1] is our canal
          for(let array of results){
            listSubCanales = listSubCanales.concat(array);
          }
          return listSubCanales;
      });
    }else{
      return of([]);
    }
  }
}
