import { Injectable } from '@angular/core';

@Injectable()
export class ControlCobranzaProvider {

    public devolucion : any;
    
    //Devolucion - Listado
    public busquedaControl: any;
    public filtroTablaCobranzas : any[] = [];

    constructor(){}
}