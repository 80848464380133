import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../../shared/commons';

//Clases
import { Almacen } from '../models';
import { IResultado } from '../index';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarAlmacenDTO } from '../dto/importarAlmacen.dto';

@Injectable()
export class AlmacenService {
  ciaId : string;
  padreCiaId: string;

  constructor(private http : HttpClient2) { 
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.ciaId = "/"+currentUser.compania.companiaId;
    this.padreCiaId = currentUser.companiaPadre;
  }

  listar() : Observable<Array<Almacen>>{

    return this.http.listar(this.ciaId+"/almacenes").map(
      (data: IResultado) => {
        let almacenes = Array<Almacen>();
        let almacen;
        if(data){
          for (const d of data.resultado) {
            almacen = new Almacen();
            almacen.copiar(d);
            almacenes.push(almacen);
          }
        }
        return almacenes;
    });
  }

  consultar(data) : Observable<Array<Almacen>>{
    return this.http.consultar(this.ciaId+"/almacenes/consultar", data).map(
      (data: IResultado) => {
        let almacenes = Array<Almacen>();
        let almacen;
        if(data){
          for (const d of data.resultado) {
            almacen = new Almacen();
            almacen.copiar(d);
            almacenes.push(almacen);
          }
        }
        return almacenes;
    });
  }

  obtener(id : number) : Observable<Almacen>{    
    return this.http.obtener(this.ciaId+"/almacenes/"+id).map(
      (data: IResultado) => {
        let almacen = new Almacen();
        if(data){
          almacen.copiar(data.resultado);
        }
        return almacen;
      });    
  }

  registrar(almacen : Almacen, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/almacenes", almacen, { funcionalidad });
  }

  modificar(almacen : Almacen, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/almacenes", almacen, { funcionalidad});
  }

  eliminar(almacenId : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/almacenes/"+almacenId, { funcionalidad});
  }

  registrarImportacion(almacen : Array<ImportarAlmacenDTO>, funcionalidad?: String) : Observable<any>{
    return this.http.registrarImportacion(this.ciaId+"/almacenes/registrar-importacion", almacen, { funcionalidad });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }



  listarAlmacenDevolucionProgramacion(ciaIdVar : number){

    return this.http.listar("/"+ciaIdVar+"/almacenes/listar-almacen-devolucion").map(
      (data: IResultado) => {
        let almacenes = Array<Almacen>();
        let almacen;
        if(data){
          for (const d of data.resultado) {
            almacen = new Almacen();
            almacen.copiar(d);
            almacenes.push(almacen);
          }
        }
        return almacenes;
    });;
  }

  listarAlmacenDevolucionHub(empresaTransporteId : number){

    return this.http.listar(this.ciaId+"/almacenes/"+empresaTransporteId+"/listar-almacen-devolucion-hub").map(
      (data: IResultado) => {
        let almacenes = Array<Almacen>();
        let almacen;
        if(data){
          for (const d of data.resultado) {
            almacen = new Almacen();
            almacen.copiar(d);
            almacenes.push(almacen);
          }
        }
        return almacenes;
    });;
  }

}
