import { Compania } from './compania';
import { Distrito } from './distrito';

export class Almacen {
    //PK
    almacenId : number;

    //Atributos
    codigo : string;
    nombre : string;
    direccion : string;
    coordenada : string;
    radio_control : number;
    acepta_devolucion: number = 1 || 0;
    orden: number;

    //Relaciones
    compania : Compania;
    distrito : Distrito;

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    /*Constructor
    constructor();
    constructor(obj?: any) {
        if(obj && obj.x){
            this.bind(obj);
        }
    }
    */

    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    //Metodos
    copiar(objeto : any){
        //Propios
        this.almacenId = objeto.almacenId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.direccion = objeto.direccion;
        this.coordenada = objeto.coordenada;
        this.radio_control = objeto.radio_control;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.acepta_devolucion = objeto.acepta_devolucion;
        this.orden = objeto.orden;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
        if(objeto.distrito != null){
            this.distrito = new Distrito();
            this.distrito.copiar(objeto.distrito);
        }

    }

    instanciar() : void{
        if(this.compania==null) this.compania = new Compania();
        if(this.distrito==null)this.distrito = new Distrito();
    }
}
