<link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet">

<div class="container" *ngIf="mostrarSpinner">
  <div class="wrap">
    <div class="spinner-wrap">
      <div class="spinner">
        <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
      </div>
    </div>
    <div class="loading-text">
      <span *ngIf="mensajePersonalizado == null" class="loading-text-words">{{'WEB.loader.cargando' | translate}}</span>
      <span *ngIf="mensajePersonalizado != null" class="loading-text-words">{{mensajePersonalizado}}</span>
    </div>
  </div>
  <!-- <div class="loading">
    <div class="loading-text">
      <span class="loading-text-words">{{'WEB.loader.cargando' | translate}}</span>
    </div>
  </div> -->
</div>