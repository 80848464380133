export class PaqueteDevolucionDto{

    paqueteId: number;
    grupoId: number;
    motivoId: number;
    recojoId: number;
    grupo: string;
    motivoDevolucion: string;
    guiaDevolucion: string;
    codigoBarras: string;
    estadoRegistro: string;

    constructor(){}
    
    copiar(objeto: any){
        this.grupoId = objeto.grupoId;
        this.paqueteId = objeto.paqueteId;
        this.motivoId = objeto.motivoId;
        this.recojoId = objeto.recojoId;
        this.grupo = objeto.grupoDescripcion;
        this.motivoDevolucion = objeto.motivoDescripcion;
        this.guiaDevolucion = objeto.nroRecojo;
        this.codigoBarras = objeto.codigoBarras;
        this.estadoRegistro = objeto.estadoRegistro;
    }
    
}