import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'estados-wrapper',
  templateUrl: './estados-wrapper.component.html',
  styleUrls: ['./estados-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EstadosWrapperComponent implements OnInit {

  @Input() historial: any;
  ventanaPerfiles: boolean;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  perfilesEstadosActual:any[];
  usuario:any;
  nombrePerfil:any;

  constructor() { }

  ngOnInit(): void {
    this.perfilesEstadosActual = [];
    this.usuario = '';
    this.nombrePerfil = '';
    this.ventanaPerfiles = false;
    const estadoPerfilesToView = this.historial.estados.map(estado => {
      if (estado.perfiles && estado.perfiles.length) {
        const perfilesOrdenados  = estado.perfiles.sort((a,b) => a.perfilId - b.perfilId);
        const perfilesDescripcion = perfilesOrdenados.map(perfil => perfil.descripcion);
        const perfilesIniciales = estado.perfiles.length >= 2 ? perfilesDescripcion.slice(0, 2) : perfilesDescripcion;
        const perfilesToString = estado.perfiles.length >= 2 ? perfilesIniciales.join(', ') : perfilesIniciales;

        return {
          ...estado,
          perfilesToString
        }
      }
      return estado
    });

    this.historial.estados = [...estadoPerfilesToView];
  }

  onClickCargarVentanaPerfiles(perfilesEstado) {
    this.usuario = perfilesEstado.usuario? perfilesEstado.usuario :'';
    this.nombrePerfil = "Perfiles de" + " " + this.usuario
    this.perfilesEstadosActual = perfilesEstado?perfilesEstado.perfiles: [];
    this.ventanaPerfiles = true;
  }

  paginate(event): void {
    this.itemsPerPage = event['rows'];
    this.currentPage = event['page'] + 1;
  }

}
