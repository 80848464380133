import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { C } from '@shared/commons';
import { Usuario } from '@shared/models';
import { NotificacionProvider } from '@shared/providers';
import { NotificacionService } from '../resources';
import { NotificacionDTO } from '../resources/dto/notificacion.dto';
import { NotificacionSensorDTO } from '../resources/dto/notificacionSensor.dto';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

@Component({
  selector: 'app-alerta-sensor-item',
  templateUrl: './alerta-sensor-item.component.html',
  styleUrls: ['./alerta-sensor-item.component.scss']
})
export class AlertaSensorItemComponent implements OnInit {

  @Input()
  notificacion: NotificacionSensorDTO;
  @Input()
  index: any;

  comentario: string;
  unidadMedidaSensor: string;
  labelSensor: string;

  currentUser;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private notificacionService: NotificacionService,private translate: TranslateService,
              private notificacionProvider : NotificacionProvider) { 
                this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
              }

  ngOnInit(): void {
    this.cargarDatos();
  }

  cargarDatos(){
    this.comentario = this.notificacion.comentario;
    this.unidadMedidaSensor = this.getSensorIdToString(this.notificacion.multiReadingTypeId);
    this.labelSensor = capitalizeFirstLetter(this.getSensorString(this.notificacion.multiReadingTypeId));
  }

  abrirDetalleViaje() {
    //this.router.navigate([ '/control/estados-viaje/', { codigoViaje: this.notificacion.codigoViaje } ]);
    this.router.navigate([ '/control/estados-viaje/', { codigoViaje: this.notificacion.codigoViaje } ]);
  }

  procesar(){    
    let notificacion = new NotificacionDTO();
    notificacion.notificacionId = this.notificacion.notificacionId;
    notificacion.estado = 2;
    notificacion.usuario = new Usuario();
    notificacion.usuario.usuarioId =  this.currentUser.usuarioId;
    notificacion.comentario = this.comentario;
    this.notificacionService.modificar(notificacion, C.FUNCIONALIDAD_LOG.NOTIFICACION_ALERTAS).subscribe(data => {
      this.cargarAlertasSensores();
    });
  }

  cargarAlertasSensores() {
    this.notificacionService.listarAlertasSensores()
    .subscribe(
      (data : NotificacionSensorDTO[]) =>{                    
        this.notificacionProvider.changeAlertasSensores(data);
      }, (error) => {
        console.log(error);
      },
      () => {
        //this.loadingComponent.ocultarLoading();
      }
    );
  }

  getSensorIdToString(key?) {
    if(key === C.SENSOR.HUMEDAD){
      return  " " + "(" + C.UNIDADES_MEDIDA.HUMEDAD + ")";
    }else if(key == C.SENSOR.TEMPERATURA){
      return  " " + "(" + C.UNIDADES_MEDIDA.TEMPERATURA + ")";
    }else if(key == C.SENSOR.ACELERACION){
      return  " " + "(" + C.UNIDADES_MEDIDA.ACELERACION + ")";
    }
    else if(key == C.SENSOR.LUZ){
      return " " + "(" + C.UNIDADES_MEDIDA.LUZ + ")";
    }
    else if(key == C.SENSOR.MAGNETISMO){
      return C.UNIDADES_MEDIDA.MAGNETISMO;
    }
    return "";
  }

  getSensorString(key?) {
    if(key === C.SENSOR.HUMEDAD){
      return  this.translate.instant("MODEL.sensores.tipo-sensor.humedad");
    }else if(key == C.SENSOR.TEMPERATURA){
      return this.translate.instant("MODEL.sensores.tipo-sensor.temperatura");
    }else if(key == C.SENSOR.ACELERACION){
      return this.translate.instant("MODEL.sensores.tipo-sensor.aceleracion");
    }
    else if(key == C.SENSOR.LUZ){
      return this.translate.instant("MODEL.sensores.tipo-sensor.luz");
    }
    else if(key == C.SENSOR.MAGNETISMO){
      return this.translate.instant("MODEL.sensores.tipo-sensor.magnetismo");
    }
    return "";
  }

}
