export class ZonaTransporte {
    //PK
    zonaTransporteId : number;

    //Atributos
    codigo : string;
    nombre : string;
    estado : string;
    area : string;
    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    //Metodos
    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':                    
                    return "fa fa-fw fa-check";
                    
                case 'P':                    
                    return "fa fa-fw fa-warning";
                    
                case 'F':                    
                    return "fa fa-fw fa-times";
                    
            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':                    
                    return "btn-success";
                    
                case 'P':                    
                    return "btn-warning";
                    
                case 'F':                    
                    return "btn-danger";
                    
            }
        }
    }
    
    copiar(objeto : any){
        //Propios
        this.zonaTransporteId = objeto.zonaTransporteId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.estado = objeto.estado;
        this.area = objeto.area;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
    }
}