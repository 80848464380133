<div class="alerta-sensor-item">
    <div class="seccion-titulo d-flex">
        <span class="mr-2">
            <i class="fa fa-bell"></i>
        </span>
        <span class="campana">{{'WEB.notificacion.tab-alerta-tarea.alerta' | translate }}: {{notificacion.notificacionId}}</span>
        <span class="ml-auto fecha-solicitud">{{notificacion.fechaCreacion | date:'dd/MM/yyyy HH:mm'}}</span>
    </div>
    <div class="seccion-contenido">
        <div class="alinear">
            <div class="contenido">
                <span>{{'WEB.notificacion.tab-alerta-tarea.nro-orden' | translate }}: {{index}}</span>
            </div>
            <div class="contenido">
                <span class="borde-sensor-umbral">Umbral: {{notificacion.umbralMin}} {{unidadMedidaSensor}} a {{notificacion.umbralMax}} {{unidadMedidaSensor}}</span>
            </div>
        </div>

        <div class="contenido">
            <span class="negrita">{{'WEB.notificacion.tab-sensores.fuera-umbral' | translate: { codigoViaje: notificacion.codigoViaje, labelSensor: labelSensor } }}</span><span class="color-sensor-umbral">{{notificacion.value1MultiReadingId}} {{unidadMedidaSensor}}</span>
        </div>
        <div class="contenido">
            <span class="negrita">{{'WEB.notificacion.tab-sensores.comentario' | translate}}</span>
        </div>
        <div class="contenido">
            <textarea class="tamano-text-area" rows="5" cols="30" pInputTextarea autoResize="autoResize" [(ngModel)]="comentario"></textarea>
        </div>
        <div class="d-flex justify-content-center mt-3 mb-3">
            <button type="button" class="btn btn-procesar mr-3" (click)="procesar()">
                <span>{{'COMMON.boton.procesar' | translate}}</span>
            </button>
            <button type="button" class="btn btn-secondary mr-3" (click)="abrirDetalleViaje()">
                <span>{{'COMMON.boton.ver-detalle' | translate}}</span>
            </button>
        </div>
    </div>
</div>
