import { Component, OnInit, Input } from '@angular/core';
import { NotificacionDTO } from '../resources/dto/notificacion.dto';
import { Usuario } from '@shared/models';
import { C } from '@shared/commons';

@Component({
  selector: 'app-alerta-tarea-item',
  templateUrl: './alerta-tarea-item.component.html',
  styleUrls: ['./alerta-tarea-item.component.scss']
})
export class AlertaTareaItemComponent implements OnInit {

  @Input()
  notificacion: NotificacionDTO;

  currentUser: Usuario;
  showbtnDetalle: boolean;

  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.validarRoles();
  }

  abrirDetalleViaje() {
    location.href = '#/control/estados-viaje/' + this.notificacion.puntoVisita.viajeId;
  }

  validarRoles() {
    let index = this.currentUser.listPerfil.findIndex(p =>
      p.perfilId == C.TIPO_PERFIL.ADMINISTRADOR_SISTEMA ||
      p.perfilId == C.TIPO_PERFIL.OPERADOR_SEGUIMIENTO_TRANSPORTISTA ||
      p.perfilId == C.TIPO_PERFIL.EMPRESA_TRANSPORTE ||
      p.perfilId == C.TIPO_PERFIL.ASISTENTE_DISTRIBUCION ||
      p.perfilId == C.TIPO_PERFIL.JEFE_DISTRIBUCION ||
      p.perfilId == C.TIPO_PERFIL.SUPERVISOR ||
      p.perfilId == C.TIPO_PERFIL.SUPER_USUARIO);

    this.showbtnDetalle = (index != -1);
  }

}
