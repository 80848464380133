import { Component, OnInit } from '@angular/core';
import { ChatProvider } from '@shared/providers';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss']
})
export class ChatModalComponent implements OnInit {
 
  constructor(public chatProvider: ChatProvider){}

  ngOnInit(): void {
  }
  showModalDialog(accion: boolean){
    this.chatProvider.showModalDialog(accion);
  }
}
