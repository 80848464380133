import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { C } from '@shared/commons';
import { ViajeService } from '@shared/services';
import * as moment from 'moment';
import { Table } from 'primeng';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-tabla-capturas',
  templateUrl: './tabla-capturas.component.html',
  styleUrls: ['./tabla-capturas.component.scss'],
  providers: [ViajeService]
})
export class TablaCapturasComponent implements OnInit {

  listConfCompania;
  // @Input() anchoTabla = "62.45rem";
  @Input() anchoTabla = "91rem";
  sensores;
  sensoresFiltered;
  columnasSensores;
  listUTS;
  dataSensorExportacion;

  mostrarFueraRango : Boolean = false;
  rangoValidacionSensores;

  @Output() changeDataSensores: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("tbCapturas")
  tbCapturas : Table;

  constructor(private viajeService: ViajeService,
              private translate: TranslateService) {
                this.listConfCompania =  JSON.parse(localStorage.getItem("compania_configuraciones"))
               }

  ngOnInit(): void {
  }

  cargarDatos(dataSensores, listUTS) {

    this.listUTS = listUTS;

    this.cargarRangoValidacion();

    this.viajeService.consultarSensores(dataSensores).pipe(
      map(data => data.map(item =>  ({...item, fechaHora: moment(item.fechaHora).format('DD/MM/YYYY HH:mm')})))
    ).subscribe(
      (data: Array<any>) => {
        if (data.length) {
          const sensorData = data[0];
          this.columnasSensores  = Object.keys(sensorData).map(key => ({ field: key, header: this.getSensorIdToString(key) }))
          this.columnasSensores.sort((a,b) => {
            if (a.field === 'fechaHora') return -1; // Coloca 'fechaHora' primero
            if (b.field === 'fechaHora') return 1;  // Coloca 'fechaHora' primero
            return 0; 
          });
        }

        this.dataSensorExportacion =(JSON.parse(JSON.stringify(data))).map(sensor => this.darFormatoDecimal(sensor));

        this.sensores = data;        

        //Iteramos para reconocer los valores que estan fuera de rango
        this.sensores.forEach(sensor => this.validarDentroRangoItem(sensor));
                     
        this.sensoresFiltered = this.sensores.map(sensor => this.darFormatoDecimal(sensor));
      },
      error => {
        this.sensores = [];
        this.sensoresFiltered = [];
      },() =>{

        //Cargamos con la opcion de de valores fuera de rango deschecada
        //y en la página 1 de la tabla
        this.mostrarFueraRango = false;
        this.tbCapturas._first = 0;

        //Al terminar la petición emitimos un evento para devolver la data al Padre
        this.changeDataSensores.emit(this.sensores);
      });
  }


  getSensorIdToString(key) {
    if(key === C.SENSOR.HUMEDAD){
      return  this.translate.instant("MODEL.sensores.tipo-sensor.humedad") + " " + "(" + C.UNIDADES_MEDIDA.HUMEDAD + ")";
    }else if(key == C.SENSOR.TEMPERATURA){
      return this.translate.instant("MODEL.sensores.tipo-sensor.temperatura") + " " + "(" + C.UNIDADES_MEDIDA.TEMPERATURA + ")";
    }else if(key == C.SENSOR.ACELERACION){
      return this.translate.instant("MODEL.sensores.tipo-sensor.aceleracion") + " " + "(" + C.UNIDADES_MEDIDA.ACELERACION + ")";
    }
    else if(key == C.SENSOR.LUZ){
      return this.translate.instant("MODEL.sensores.tipo-sensor.luz") + " " + "(" + C.UNIDADES_MEDIDA.LUZ + ")";
    }
    else if(key == C.SENSOR.MAGNETISMO){
      return this.translate.instant("MODEL.sensores.tipo-sensor.magnetismo") + C.UNIDADES_MEDIDA.MAGNETISMO;
    }else if(key == "fechaHora"){
      return this.translate.instant("COMMON.etiquetas.fecha-hora");
    }
    return "";
  }

  cargarRangoValidacion(){

    this.rangoValidacionSensores = {
      [C.SENSOR.ACELERACION]:{
        umbralMin : this.getValorMinMaxValido(C.SENSOR.ACELERACION, 'umbralMin'),
        umbralMax : this.getValorMinMaxValido(C.SENSOR.ACELERACION, 'umbralMax')
      },
      [C.SENSOR.HUMEDAD]:{
        umbralMin : this.getValorMinMaxValido(C.SENSOR.HUMEDAD, 'umbralMin'),
        umbralMax : this.getValorMinMaxValido(C.SENSOR.HUMEDAD, 'umbralMax')
      },
      [C.SENSOR.LUZ]:{
        umbralMin : this.getValorMinMaxValido(C.SENSOR.LUZ, 'umbralMin'),
        umbralMax : this.getValorMinMaxValido(C.SENSOR.LUZ, 'umbralMax')
      },
      [C.SENSOR.TEMPERATURA]:{
        umbralMin : this.getValorMinMaxValido(C.SENSOR.TEMPERATURA, 'umbralMin'),
        umbralMax : this.getValorMinMaxValido(C.SENSOR.TEMPERATURA, 'umbralMax')
      },
    };

  }

  getValorMinMaxValido(tipoSensor, umbralMinMax){

    let unidadTransporteSensor = this.listUTS.filter(uts => uts.sensorId == tipoSensor);
    let finalUmbralMinMax = 0;

    if(unidadTransporteSensor.length > 0){
      if(unidadTransporteSensor[0].umbralGeneral){
        let valorConfiguracion =  this.obtenerValorSegunCampoString( this.getConstanteConfiguracion(tipoSensor) ,this.listConfCompania);
        let valorRango = valorConfiguracion.split("|").map( n => +n) 
  
        finalUmbralMinMax = umbralMinMax == 'umbralMin' ? valorRango[0] : valorRango [1];
      }else{
        finalUmbralMinMax = unidadTransporteSensor[0][umbralMinMax];
      }
    }else{
      return null;
    }

    return finalUmbralMinMax;
  }

  getConstanteConfiguracion(tipoSensor){

    if(tipoSensor == C.SENSOR.ACELERACION){
      return C.CONFIGURACION.SENSORES.ACELERACION.CONF_SENS_ACELERACION;
    }else if(tipoSensor == C.SENSOR.HUMEDAD){
      return C.CONFIGURACION.SENSORES.HUMEDAD.CONF_SENS_HUMEDAD;
    }else if(tipoSensor == C.SENSOR.LUZ){
      return C.CONFIGURACION.SENSORES.LUZ.CONF_SENS_LUZ;
    }else if(tipoSensor == C.SENSOR.TEMPERATURA){
      return C.CONFIGURACION.SENSORES.TEMPERATURA.CONF_SENS_TEMPERATURA;
    }

    return "";
  }

  obtenerValorSegunCampoString(campo, configuracionList): string {
    return configuracionList.find(c => c.campo === campo).valor;
  }

  //Metodo para filtrado de datos de tabla
  onChange(event){

    if(event){
      //Filtramos los valores de sensores que esten fuera de rango
      this.sensoresFiltered = this.sensoresFiltered.filter( dataSensores => !dataSensores["valid"]);
    }else{
      this.sensoresFiltered = this.sensores;
    }

    this.tbCapturas._first = 0;
    
  }

  /**
   * 
   * Metodo para validar valores de sensores dentro del rango configurado
   * @param dataSensor 
   * @returns 
   */
  validarDentroRangoItem(dataSensor){

    let valido : boolean = true;
    let rowValid : boolean = true;

    if(dataSensor[C.SENSOR.ACELERACION]){ // && valido 
      valido = this.validarValorPorTipoSensor(C.SENSOR.ACELERACION, dataSensor[C.SENSOR.ACELERACION]);

      if(!valido){
        rowValid = false;
        dataSensor[C.SENSOR.ACELERACION+"-clss"] = "text-color-red";
      }
    }

    if(dataSensor[C.SENSOR.HUMEDAD]){     
      valido = this.validarValorPorTipoSensor(C.SENSOR.HUMEDAD, dataSensor[C.SENSOR.HUMEDAD]);
      if(!valido){
        rowValid = false;
        dataSensor[C.SENSOR.HUMEDAD+"-clss"] = "text-color-red";
      }
    }

    if(dataSensor[C.SENSOR.LUZ]){     
      valido = this.validarValorPorTipoSensor(C.SENSOR.LUZ, dataSensor[C.SENSOR.LUZ]);
      if(!valido){
        rowValid = false;
        dataSensor[C.SENSOR.LUZ+"-clss"] = "text-color-red";
      }
    }

    if(dataSensor[C.SENSOR.TEMPERATURA]){
      valido = this.validarValorPorTipoSensor(C.SENSOR.TEMPERATURA, dataSensor[C.SENSOR.TEMPERATURA]);
      if(!valido){
        rowValid = false;
        dataSensor[C.SENSOR.TEMPERATURA+"-clss"] = "text-color-red";
      }
    }
    dataSensor["valid"] = rowValid;
  }

  validarValorPorTipoSensor(tipoSensor, valor){

    if(valor >= this.rangoValidacionSensores[tipoSensor].umbralMin  
      && valor <= this.rangoValidacionSensores[tipoSensor].umbralMax ){
        return true;
    }

    return false;
  }

  darFormatoDecimal(dataSensor){
    if(dataSensor[C.SENSOR.ACELERACION]){ // && valido
      dataSensor[C.SENSOR.ACELERACION] = (dataSensor[C.SENSOR.ACELERACION]).toFixed(1);     
    }

    if(dataSensor[C.SENSOR.HUMEDAD]){
      //dataSensor[C.SENSOR.HUMEDAD] = Math.round(dataSensor[C.SENSOR.HUMEDAD]);
      dataSensor[C.SENSOR.HUMEDAD] = (dataSensor[C.SENSOR.HUMEDAD]).toFixed(1);
    }

    if(dataSensor[C.SENSOR.LUZ]){
      dataSensor[C.SENSOR.LUZ] = (dataSensor[C.SENSOR.LUZ]).toFixed(1);
    }

    if(dataSensor[C.SENSOR.TEMPERATURA]){
      dataSensor[C.SENSOR.TEMPERATURA] = (dataSensor[C.SENSOR.TEMPERATURA]).toFixed(1);
    }

    return dataSensor;
  }

}