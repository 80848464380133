import { Injectable } from '@angular/core';

import { HttpClient2 } from './http-client.service';

//Clases
import { EstadoViaje } from '../models';

//Otros
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';


@Injectable()
export class EstadoViajeService {


  constructor(private http : HttpClient2) {

  }


  registrar(viajeId : number, estadoViaje : EstadoViaje, funcionalidad?: String){
    let offset = new Date().getTimezoneOffset();
    let gmt = offset < 0 ? Math.abs(offset) : -offset;
    return this.http.registrar('/'+viajeId+'/estados-viaje', estadoViaje, { funcionalidad, gmt});
  }

  registrarRuta(viajeId : number, estadoViaje : EstadoViaje){
    return this.http.registrar('/'+viajeId+'/estados-viaje/ruta', estadoViaje);
  }

  validarCambioEstado(viajeId : number, viajesId : any){
    return this.http.consultar('/'+viajeId+'/estados-viaje/validar-cambio-estado', viajesId)
  }

  consultarPosicionPlacas(ciaId: number,param: any) {
    return this.http.consultar('/'+ ciaId+'/posiciones/consultar', param);
  }

  consultarPosicionSedes(ciaId: number,param: any) {
    return this.http.consultar('/'+ ciaId+'/posiciones/consultar-sedes', param);
  }

}
