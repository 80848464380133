import { Pipe, PipeTransform } from '@angular/core';
import { C } from '@shared/commons';

import memo from 'memo-decorator';

@Pipe({
  name: 'estadoViajeToIconName'
})
export class EstadoViajeToIconNamePipe implements PipeTransform {

  @memo()
  transform(estado: number): string {
    if (estado != null) {
      switch (estado) {
        case C.VIAJE.ESTADO.PROGRAMADO:
          return "estado_viaje_programado.svg";
        case C.VIAJE.ESTADO.APT:
          return "estado_viaje_apt.svg";
        case C.VIAJE.ESTADO.EN_RUTA:
          return "estado_viaje_ruta.svg";
        case C.VIAJE.ESTADO.FINALIZADO:
          return "estado_viaje_finalizado.svg";
        case C.VIAJE.ESTADO.INICIO_CARGA:
          return "estado_viaje_inicio_carga.svg";
        case C.VIAJE.ESTADO.FIN_CARGA:
          return "estado_viaje_fin_carga.svg";
      }
    }
    return "";
  }

}
