import { Funcionalidad } from "./funcionalidad";


export class Etiqueta{
    //Pk
    etiquetaId : number;

    //Atributos
    nombre : string;
    nombreEn : string;
    codigo: string;
    obligatorio : boolean;

    //Relaciones
    funcionalidad : Funcionalidad;

    //otros
    isConfigurable : boolean;

    copiar(objeto : any){
        //Propios
        this.etiquetaId = objeto.etiquetaId;
        this.nombre = objeto.etiqueta; 
        this.nombreEn = objeto.nombreEn; 
        this.codigo = objeto.codigo; 
        this.obligatorio = objeto.obligatorio;   
           
        //Relaciones
        if(objeto.funcionalidad != null){
            this.funcionalidad = new Funcionalidad();
            this.funcionalidad.copiar(objeto.funcionalidad);
        }     

        //Variable para reemplazar los diferentes idiomas segun configuracion
        this.isConfigurable = false;
    }

}