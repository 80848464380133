import { Viaje } from './viaje';

export class ViajeObservacion{
    //PK
    viajeObservacionId: number;

    //Atributos
    cantDaniados: number;
    cantFaltantes: number;
    cantOtros: number;
    observacion: string;

    //Relaciones
    viaje: Viaje;

    constructor() {}

    copiar(objeto : any){
        //Propios
        this.viajeObservacionId = objeto.viajeObservacionId;
        this.cantDaniados = objeto.cantDaniados;
        this.cantFaltantes = objeto.cantFaltantes;
        this.cantOtros = objeto.cantOtros;
        this.observacion = objeto.observacion;
                        
        //Relaciones        
        if(objeto.viaje != null){
            this.viaje = new Viaje();
            this.viaje.copiar(objeto.viaje);
        }
    }

}