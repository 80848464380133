import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';

//Clases
import { PuntoVisita } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class PuntoVisitaService {

  constructor(private http : HttpClient2) { }

  modificar(viajeId : number, puntoVisita : PuntoVisita){
    return this.http.modificar("/"+viajeId+"/puntos-visita", puntoVisita);
  }

  modificarReprogramacionPuntoVisita(viajeId : number, puntovisitaId : number, puntoVisita : PuntoVisita){
    return this.http.modificar("/"+viajeId+"/puntos-visita"+ "/" + puntovisitaId +"/reprogramacionPuntoVisita",  puntoVisita)
  }

  cancelacionPuntoVisita(viajeId : number, puntovisitaId : number, puntoVisita : PuntoVisita){
    return this.http.modificar("/"+viajeId+"/puntos-visita"+ "/" + puntovisitaId +"/cancelacion",  puntoVisita)
  }
}
