import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Observable } from 'rxjs/Observable';
import { PlantillaDTO } from '../dto/plantilla.dto';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Injectable()
export class ExcelService {

  constructor(private http : HttpClient2) { }
  

  public exportExcelFromBase64(dataInBase64: any, excelFileName: string){
    var blob = new Blob([this.s2ab(atob(dataInBase64))], {type: EXCEL_TYPE});
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = excelFileName;
    link.click();
  }

  
  private s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  obtenerExcelExportacionReportes(data: any, tipoReporte: string): Observable<any> {
    return this.http.consultar("/reportes/" + tipoReporte + "/exportar-excel", data, { responseType: "text" });
  }  

  obtenerExcelPlantilla(data: PlantillaDTO): Observable<any> {
    return this.http.consultar("/plantillas/generar", data, { responseType: "text" });
  }

  obtenerExcelPlantillaViaje(data: PlantillaDTO): Observable<any> {
    return this.http.consultar("/plantillas/viaje", data, { responseType: "text" });
  }
}
