import { Compania } from './compania';

export class FormaPago {
    //PK
    formaPagoId : number;

    //Atributos
    codigo : string;
    tipo : string;
    descripcion : string;

    //Relaciones
    compania : Compania;
    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;
    requiereDatosBancarios: number;
    requiereEvidencia: number;

    //Constructor
    constructor(){};

    //Metodos
    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    clone(): any {
        var cloneObj = new (this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    copiar(objeto : any){
        //Propios
        this.formaPagoId = objeto.formaPagoId;
        this.codigo = objeto.codigo;
        this.tipo = objeto.tipo;
        this.descripcion = objeto.descripcion;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.requiereDatosBancarios = objeto.requiereDatosBancarios;
        this.requiereEvidencia = objeto.requiereEvidencia;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }

}
