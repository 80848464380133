
import { C } from '@shared/commons';
import { Compania } from './compania';
import { Marca } from './marca';

export class Tarifario {
    //PK
    tarifarioId : number;

    //Atributos
    entidad : string;
    entidadId : number;
    tarifaModo : number;
    tarifa: number;
    tipoTarifa : number;
    

    //Relaciones
    marcaId: number;
    descripcion:string;
    modoSeleccionado: number;

    //Tabla
    tarifaFijo:number;
    tarifarioFijoId: number;
    tarifaPeso:number;
    tarifarioPesoId: number;
    tarifaDistancia:number;
    tarifarioDistanciaId: number;
    modoNombre:string;

    constructor(){
    }

    //Metodos
    
    copiar(objeto : any){
        //Propios
        this.tarifarioId = objeto.tarifarioId;
        this.entidad = objeto.entidad;
        this.entidadId = objeto.entidadId;
        this.tarifaModo = objeto.tarifaModo;
        this.tarifa = objeto.tarifa;
        this.marcaId=objeto.marcaId;
        this.modoSeleccionado=objeto.modoSeleccionado;
        this.descripcion=objeto.descripcion;
        this.tipoTarifa = objeto.tipoTarifa;
        this.tarifaFijo = objeto.tarifaFijo;
        this.tarifaPeso = objeto.tarifaPeso;
        this.tarifaDistancia = objeto.tarifaDistancia;
        this.modoNombre = objeto.modoNombre;
        this.tarifarioFijoId = objeto.tarifarioFijoId;
        this.tarifarioPesoId = objeto.tarifarioPesoId;
        this.tarifarioDistanciaId = objeto.tarifarioDistanciaId;
    }

    get getModoNombreToString() : string {
        if (this.modoSeleccionado != null){
            switch (this.modoSeleccionado) {
                case C.TARIFARIO.MODO.FIJO:
                    return "MODEL.tarifario.fijo";
                case C.TARIFARIO.MODO.PESO:
                    return "MODEL.tarifario.peso";
                case C.TARIFARIO.MODO.DISTANCIA:
                    return "MODEL.tarifario.distancia";

            }
        }
    }
    
   
    get tipoTarifaToString() : string{
        if (this.tipoTarifa != null) {
            switch (this.tipoTarifa) {
                case C.TARIFARIO.TIPO_TARIFA.VIAJE:         
                    return "MODEL.tarifario.viaje";            
                case C.TARIFARIO.TIPO_TARIFA.PUNTO_VISITA:
                    return "MODEL.tarifario.punto-visita";
                case C.TARIFARIO.TIPO_TARIFA.SEDE:
                    return "MODEL.tarifario.sede";
            }
        }
        return "";
    }
    get modoToString() : string{
        if (this.tarifaModo != null) {
            // switch (this.tarifaModo) {
            //     case C.TARIFARIO.MODO.PESO:         
            //         return "MODEL.tarifario.peso";
            //     case C.TARIFARIO.MODO.DISTANCIA:
            //         return "MODEL.tarifario.distancia";
            //     case C.TARIFARIO.MODO.TIPO_VEHICULO:
            //         return "MODEL.tarifario.tipo-vehiculo";
            //     case C.TARIFARIO.MODO.FIJO:
            //         return "MODEL.tarifario.fijo";
            //     case C.TARIFARIO.MODO.RUTA:
            //         return "MODEL.tarifario.ruta";
            // }
        }
        return "";
    }

}