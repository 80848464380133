import { Usuario } from './usuario';

export class UsuarioToken {
    //PK
    tokenId : number;
    token : string;
    estado : number;
    fechaCreacion : Date;
    fechaVencimiento : Date;
    tipoToken : number;

    //Relaciones
    usuario : Usuario;

    copiar(objeto : any){
        //Propios
        this.tokenId = objeto.tokenId;
        this.estado = objeto.estado;
        this.fechaCreacion = objeto.fechaCreacion;
        this.fechaVencimiento = objeto.fechaVencimiento;
        this.tipoToken = objeto.tipoToken;

        //Relaciones
        if(objeto.usuario != null){
            this.usuario = new Usuario();
            this.usuario.copiar(objeto.usuario);
        }
    }


}
