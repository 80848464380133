<p-toast position="top-right"></p-toast>
<div class="solicitud-autorizacion-item">
    <div class="seccion-titulo d-flex">
        <span class="mr-2">
            <i class="fa fa-truck"></i>
        </span>        
        <span class="vehiculo"> {{'WEB.notificacion.tab-solicitud-autorizacion.item.label-vehiculo' | translate }} {{ solicitud.placa }}</span> 
        <span class="ml-auto fecha-solicitud"> {{solicitud.fechaCreacion | date:'dd/MM/yyyy HH:mm'}}</span>                
    </div>
    <div class="seccion-contenido">
        <div class="contenido">
            <span class="negrita">{{'WEB.notificacion.tab-solicitud-autorizacion.item.label-1' | translate }}</span> 
            <span>{{'WEB.notificacion.tab-solicitud-autorizacion.item.label-2' | translate }} </span> 
            <span class="negrita">{{ solicitud.codigoViaje }}</span>
        </div>
        <div class="contenido">
            <span class="negrita">{{'WEB.notificacion.tab-solicitud-autorizacion.item.label-3' | translate }}</span>
            <span class="negrita resaltado">{{ solicitud.paquetesCompletados }}</span>
            <span class="negrita">/ {{ solicitud.totalPaquetes }}</span>
            <span class="st-paquete"></span>            
        </div> 
        <div class="d-flex justify-content-center mt-3 mb-3">
            <button type="button" class="btn btn-secondary mr-3" (click)="onClickVerDetalle($event)">
                <span>{{'COMMON.boton.ver-detalle' | translate}}</span>
            </button>

            <button type="button" class="btn btn-primary mr-3" (click)= "onclickAutorizar(solicitud,1,$event)">
                <span>{{'COMMON.boton.autorizar' | translate}}</span>
            </button>

            <button type="button" class="btn btn-darck" (click)= "onClickNoAutorizar(solicitud,$event)">
                <span>{{'COMMON.boton.no-autorizar' | translate}}</span>
            </button>
        </div>
    </div>
</div>

<p-dialog [(visible)]="dialogAutorizacion" header="{{'WEB.control.solicitud-autorizacion.detalle.modal.titulo' | translate }}" (onHide)="ocultarDialog()" [modal]="true">
    <div class="modal-contenido">
        <div class="ui-md-12 pregunta">
            <span style= "font-weight: bold;">{{'WEB.control.solicitud-autorizacion.detalle.modal.pregunta' | translate }}</span><br>
        </div>
        <div class="ui-g-12 ui-md-8">
            <span style= "font-weight: bold;">{{'WEB.control.solicitud-autorizacion.detalle.modal.seleccionMotivo' | translate }}</span><span style="color:red">*</span>
            <p-dropdown [options]="motivos" [(ngModel)]="motivoId" (click)="onClickSeleccionar()" [style]="{'width':'100%'}" placeholder="{{'WEB.control.solicitud-autorizacion.detalle.modal.seleccion-item' | translate }}"></p-dropdown>
        </div>
        <div class="ui-g-12 ui-md-12">
            <span style= "font-weight: bold;">{{'WEB.control.solicitud-autorizacion.detalle.modal.comentario' | translate }}</span>
            <textarea style="resize: none;" rows="4" [style]="{'width':'100%'}" pInputTextarea [(ngModel)]="comentario" required autofocus></textarea>
        </div>     
        <div class="ui-g-12 ui-md-12 botones-modal">
            <button class="btn btn-primary mr-1" (click)="onClickConfirmarModal(2)" [disabled]="bloqueado">
                <span>{{'WEB.control.solicitud-autorizacion.detalle.modal.boton.confirmar' | translate }}</span>  
            </button>
            <button class="btn btn-darck" (click)="onClickCancelarModal()">
                <span>{{'WEB.control.solicitud-autorizacion.detalle.modal.boton.cancelar' | translate }}</span>  
            </button>
        </div>           
    </div> 
</p-dialog>