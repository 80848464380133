import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { MotivoNoAutorizacion } from '../models';
import { IResultado } from '../index';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../commons';

@Injectable()
export class MotivoNoAutorizacionService {

  ciaId : string;
  constructor(private http : HttpClient2) { 
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<MotivoNoAutorizacion>>{
    
    return this.http.listar(this.ciaId+"/motivos-no-autorizacion").map(
      (data: IResultado) => {
        let list = Array<MotivoNoAutorizacion>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new MotivoNoAutorizacion();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });

  }

  consultar(data) : Observable<Array<MotivoNoAutorizacion>>{

    return this.http.consultar(this.ciaId+"/motivos-no-autorizacion/consultar",data).map(
      (data: IResultado) => {
        let list = Array<MotivoNoAutorizacion>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new MotivoNoAutorizacion();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });

  }

  obtener(id : number) : Observable<MotivoNoAutorizacion>{
    return this.http.obtener(this.ciaId+"/motivos-no-autorizacion/"+id).map(
      (data: IResultado) => {
        let obj = new MotivoNoAutorizacion();
        if(data){
          obj.copiar(data.resultado);
        }
        return obj;
    });
  }

  registrar(motivoNoAutorizacion : MotivoNoAutorizacion, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/motivos-no-autorizacion/", motivoNoAutorizacion, { funcionalidad });
  }

  modificar(motivoNoAutorizacion : MotivoNoAutorizacion, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/motivos-no-autorizacion/", motivoNoAutorizacion, { funcionalidad });

  }

  eliminar(motivoNoAutorizacion : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/motivos-no-autorizacion/"+motivoNoAutorizacion, { funcionalidad });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

  obtenerExcelExportacionReportes(data: any, tipoReporte: string): Observable<any> {
    return this.http.consultar("/reportes/" + tipoReporte + "/exportar-excel", data, { responseType: "text" });
  }  

}
