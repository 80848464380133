import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-sensor',
  templateUrl: './card-sensor.component.html',
  styleUrls: ['./card-sensor.component.scss']
})
export class CardSensorComponent implements OnInit {
  @Input() titulo: string;
  @Input() sensor: CardSensor;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(simpleChanges : SimpleChanges){
    if (simpleChanges.sensor && simpleChanges.sensor.currentValue) {
        
    }
  }

  

}
export interface CardSensor {
  	maximo : {
		icono:string,
		data : {
			valor:string,
			color:string
			}	
		},
	minimo : {
		icono:string,
		data : {
			valor:string,
			color:string
			}		
		},
	promedio: {
		icono:string,
		data : {
			valor:string,
			color:string
			}	
	}
}