import { Pais } from "./pais";

export class Departamento{
    //Pk
    departamentoId : number;
    //Atributos
    departamento : string;
    pais: Pais;

    copiar(objeto : any){
        //Propios
        this.departamentoId = objeto.departamentoId;
        this.departamento = objeto.departamento;

        //Relaciones
        if(objeto.pais != null){
            this.pais = new Pais();
            this.pais.copiar(objeto.pais);
        }   
             
    }
}