import { Transportista } from './transportista';
import { Compania } from './compania';
import { UnidadTransporte } from './unidadTransporte';
import { EmpresaTransporte } from './empresaTransporte';
import { Almacen } from './almacen';
import { ZonaTransporte } from './zonaTransporte';
import { PuntoVisita } from './puntoVisita';
import { EstadoViaje } from './estadoViaje';
import { Incidente } from './incidente';
import { dateToString, hourToString, dateAndHourToString } from '../commons';
import { C } from '../commons';
import { Supervisor } from './supervisor';
import { RutaEmpresaTransporte } from '@shared/models/rutaEmpresaTransporte';
import { Tarifario } from './tarifario';
import { EstadoViajeFacturacion } from './estadoViajeFacturacion';
import { AlmacenViajeDTO } from 'src/app/layout/control/estados-viaje/resources/dto/almacenViaje.dto';


export class Viaje {
    //PK
    viajeId: number;

    //Atributos
    codigo: string;
    reordenaRuta: boolean;
    fechaEstimadaInicio: Date;
    fechaEstimadaFin: Date;
    fechaRealInicio: Date;
    fechaRealFin: Date;
    pesoCarga: number;
    volumenCarga: number;
    adicionarPuntosVisita: boolean;
    notificacionTransportista: boolean;
    notificacionCliente: boolean;
    estadoVehiculo: number;

    //Relaciones
    transportista: Transportista;
    compania: Compania;
    unidadTransporte: UnidadTransporte;
    empresaTransporte: EmpresaTransporte;
    almacen: Almacen;
    zonaTransporte: ZonaTransporte;
    //listAlmacen: Almacen[];
    listAlmacen: AlmacenViajeDTO[];
    listPuntoVisita: PuntoVisita[];
    estadoViaje: EstadoViaje;
    listIncidente: Incidente[];
    supervisor: Supervisor;
    rutaEmpresaTransporte: RutaEmpresaTransporte;
    tarifario: Tarifario;
    estadoViajeFacturacion: EstadoViajeFacturacion;

    //Otras Variables
    estadoRegistro: string;
    resultadoRegistro: string;
    duracion: number;
    selectedPuntoVisita: PuntoVisita[];
    horaEstimadaInicio:  string;
    horaEstimadaFin: string;
    almacenDevolucionId: number;
    destino: number;
    esEliminable: boolean;
    
    etiquetas: any = {};

    constructor() {
        this.transportista = new Transportista();
        this.compania = new Compania();
        this.unidadTransporte = new UnidadTransporte();
        this.empresaTransporte = new EmpresaTransporte();
        this.almacen = new Almacen();
        this.zonaTransporte = new ZonaTransporte();
        this.listPuntoVisita = new Array<PuntoVisita>();
        this.listAlmacen = new Array<AlmacenViajeDTO>();
        this.estadoViaje = new EstadoViaje();
        this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
        this.supervisor = new Supervisor();
        this.rutaEmpresaTransporte = new RutaEmpresaTransporte();
        this.tarifario = new Tarifario();
        this.estadoViajeFacturacion = new EstadoViajeFacturacion();
    }

    get estadoRegistroToIcon(): string {
        if (this.estadoRegistro != null) {
            switch (this.estadoRegistro) {
                case 'R':
                    return "fa fa-fw fa-check-circle";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times-circle";

            }
        }
    }

    get estadoRegistroToColor(): string {
        if (this.estadoRegistro != null) {
            switch (this.estadoRegistro) {
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    get noEsEditable(): boolean {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let func = currentUser.funcionalidades.find(f => f.funcionalidadId == 6).funcionalidades.find(f => f.funcionalidadId == 9).funcionalidades.find(f => f.funcionalidadId == 51);

        let result: boolean = false;
        if (this.estadoViaje != null && this.estadoViaje.estado != null) {
            let isNotFuncEdit = (func == null || this.estadoViaje.estado == C.VIAJE.ESTADO.FINALIZADO);
            return isNotFuncEdit && this.estadoViaje.estado > C.VIAJE.ESTADO.APT
        }

        return result;
    }

    get fechaEstimadaInicioToFiltro(): string {
        let label = '';
        if (typeof (this.fechaEstimadaInicio) != "undefined" && this.fechaEstimadaInicio != null) {
            label = dateToString(this.fechaEstimadaInicio);
        }
        return label;
    }

    get fechaEstimadaFinToFiltro(): string {
        let label = '';
        if (typeof (this.fechaEstimadaFin) != "undefined" && this.fechaEstimadaFin != null) {
            label = dateToString(this.fechaEstimadaFin);
        }
        return label;
    }

    get fechaEstimadaInicioToString(): string {
        let label = '';
        if (typeof (this.fechaEstimadaInicio) != "undefined" && this.fechaEstimadaInicio != null) {
            label = dateAndHourToString(this.fechaEstimadaInicio);
        }
        return label;
    }

    get horaEstimadaInicioToString(): string {
        let label = '';
        if (typeof (this.fechaEstimadaInicio) != "undefined" && this.fechaEstimadaInicio != null) {
            label = hourToString(this.fechaEstimadaInicio);
        }
        return label;
    }

    get fechaEstimadaFinToString(): string {
        let label = '';
        if (typeof (this.fechaEstimadaFin) != "undefined" && this.fechaEstimadaFin != null) {
            label = dateAndHourToString(this.fechaEstimadaFin);
        }
        return label;
    }

    get horaEstimadaFinToString(): string {
        let label = '';
        if (typeof (this.fechaEstimadaInicio) != "undefined" && this.fechaEstimadaInicio != null) {
            label = hourToString(this.fechaEstimadaFin);
        }
        return label;
    }

    get fechaRealInicioToFiltro(): string {
        let label = '';
        if (typeof (this.fechaRealInicio) != "undefined" && this.fechaRealInicio != null) {
            label = dateToString(this.fechaRealInicio);
        }
        return label;
    }

    get fechaRealFinToFiltro(): string {
        let label = '';
        if (typeof (this.fechaRealFin) != "undefined" && this.fechaRealFin != null) {
            label = dateToString(this.fechaRealFin);
        }
        return label;
    }

    get fechaRealInicioToString(): string {
        let label = '';
        if (typeof (this.fechaRealInicio) != "undefined" && this.fechaRealInicio != null) {
            label = dateAndHourToString(this.fechaRealInicio);
        }
        return label;
    } 
    
    get fechaRealFinToString(): string {
        let label = '';
        if (typeof (this.fechaRealFin) != "undefined" && this.fechaRealFin != null) {
            label = dateAndHourToString(this.fechaRealFin);
        }
        return label;
    }

    //Metodos
    static clonar(objeto: any): Viaje {
        let viaje: Viaje = new Viaje();
        //Propios
        viaje.viajeId = objeto.viajeId;
        viaje.codigo = objeto.codigo;
        viaje.reordenaRuta = objeto.reordenaRuta;
        viaje.fechaEstimadaInicio = objeto.fechaEstimadaInicio;
        viaje.fechaEstimadaFin = objeto.fechaEstimadaFin;
        viaje.fechaRealInicio = objeto.fechaRealInicio;
        viaje.fechaRealFin = objeto.fechaRealFin;
        viaje.pesoCarga = objeto.pesoCarga;
        viaje.volumenCarga = objeto.volumenCarga;
        viaje.adicionarPuntosVisita = objeto.adicionarPuntosVisita;
        viaje.notificacionTransportista = objeto.notificacionTransportista;
        viaje.notificacionCliente = objeto.notificacionCliente;
        viaje.resultadoRegistro = objeto.resultadoRegistro;
        viaje.estadoRegistro = objeto.estadoRegistro;
        viaje.estadoVehiculo = objeto.estadoVehiculo;
        viaje.duracion = objeto.duracion;
        viaje.almacenDevolucionId = objeto.almacenDevolucionId;
        viaje.destino = objeto.destino;
        viaje.esEliminable = objeto.esEliminable;

        //Relaciones
        if (objeto.transportista != null) {
            viaje.transportista.copiar(objeto.transportista);
        }
        if (objeto.compania != null) {
            viaje.compania.copiar(objeto.compania);
        }
        if (objeto.unidadTransporte != null) {
            viaje.unidadTransporte.copiar(objeto.unidadTransporte);
        }
        if (objeto.empresaTransporte != null) {
            viaje.empresaTransporte.copiar(objeto.empresaTransporte);
        }
        if (objeto.almacen != null) {
            viaje.almacen.copiar(objeto.almacen);
        }
        if (objeto.zonaTransporte != null) {
            viaje.zonaTransporte.copiar(objeto.zonaTransporte);
        }
        if (objeto.estadoViaje != null) {
            viaje.estadoViaje.copiar(objeto.estadoViaje);
        }
        if (objeto.supervisor != null) {
            viaje.supervisor.copiar(objeto.supervisor);
        }
        if (objeto.rutaEmpresaTransporte != null) {
            viaje.rutaEmpresaTransporte.copiar(objeto.rutaEmpresaTransporte);
        }
        if (objeto.tarifario != null) {
            viaje.tarifario.copiar(objeto.tarifario);
        }
        if (objeto.estadoViajeFacturacion != null) {
            viaje.estadoViajeFacturacion.copiar(objeto.estadoViajeFacturacion);
        }
        if (objeto.listPuntoVisita != null) {
            let puntoVisita: PuntoVisita;
            for (let r of objeto.listPuntoVisita) {
                if (r != null) {
                    puntoVisita = new PuntoVisita();
                    puntoVisita.copiar(r);
                    viaje.listPuntoVisita.push(puntoVisita);
                }
            }
        }
        if (objeto.listIncidente != null) {
            let incidente: Incidente;
            for (let r of objeto.listIncidente) {
                if (r != null) {
                    incidente = new Incidente();
                    incidente.copiar(r);
                    viaje.listIncidente.push(incidente);
                }
            }
        }    
        if (objeto.listAlmacen != null) {
            let almacen: AlmacenViajeDTO;
            for (let r of objeto.listAlmacen) {
                if (r != null) {
                    almacen = new AlmacenViajeDTO();
                    almacen.copiar(r);
                    viaje.listAlmacen.push(almacen);
                }
            }
        }   

        return viaje;
    }

    public copiar(objeto: any): void {
        //Propios
        this.viajeId = objeto.viajeId;
        this.codigo = objeto.codigo;
        this.reordenaRuta = objeto.reordenaRuta;
        this.fechaEstimadaInicio = objeto.fechaEstimadaInicio;
        this.fechaEstimadaFin = objeto.fechaEstimadaFin;
        this.fechaRealInicio = objeto.fechaRealInicio;
        this.fechaRealFin = objeto.fechaRealFin;
        this.pesoCarga = objeto.pesoCarga;
        this.volumenCarga = objeto.volumenCarga;
        this.adicionarPuntosVisita = objeto.adicionarPuntosVisita;
        this.notificacionTransportista = objeto.notificacionTransportista;
        this.notificacionCliente = objeto.notificacionCliente;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.estadoRegistro = objeto.estadoRegistro;
        this.estadoVehiculo = objeto.estadoVehiculo;
        this.duracion = objeto.duracion;
        this.almacenDevolucionId = objeto.almacenDevolucionId;
        this.destino = objeto.destino;
        this.esEliminable = objeto.esEliminable;

        //Relaciones
        if (objeto.transportista != null) {
            this.transportista.copiar(objeto.transportista);
        }
        if (objeto.compania != null) {
            this.compania.copiar(objeto.compania);
        }
        if (objeto.unidadTransporte != null) {
            this.unidadTransporte.copiar(objeto.unidadTransporte);
        }
        if (objeto.empresaTransporte != null) {
            this.empresaTransporte.copiar(objeto.empresaTransporte);
        }
        if (objeto.almacen != null) {
            this.almacen.copiar(objeto.almacen);
        }
        if (objeto.zonaTransporte != null) {
            this.zonaTransporte.copiar(objeto.zonaTransporte);
        }
        if (objeto.estadoViaje != null) {
            this.estadoViaje.copiar(objeto.estadoViaje);
        }
        if (objeto.supervisor != null) {
            this.supervisor.copiar(objeto.supervisor);
        }
        if (objeto.rutaEmpresaTransporte != null) {
            this.rutaEmpresaTransporte.copiar(objeto.rutaEmpresaTransporte);
        }
        if (objeto.tarifario != null) {
            this.tarifario.copiar(objeto.tarifario);
        }
        if (objeto.estadoViajeFacturacion != null) {
            this.estadoViajeFacturacion.copiar(objeto.estadoViajeFacturacion);
        }
        if (objeto.listPuntoVisita != null) {
            let puntoVisita: PuntoVisita;
            for (let r of objeto.listPuntoVisita) {
                if (r != null) {
                    puntoVisita = new PuntoVisita();
                    puntoVisita.copiar(r);
                    this.listPuntoVisita.push(puntoVisita);
                }
            }
        }
        if (objeto.listIncidente != null) {
            let incidente: Incidente;
            for (let r of objeto.listIncidente) {
                if (r != null) {
                    incidente = new Incidente();
                    incidente.copiar(r);
                    this.listIncidente.push(incidente);
                }
            }
        }

        if (objeto.listAlmacen != null) {
            let almacen: AlmacenViajeDTO;
            for (let r of objeto.listAlmacen) {
                if (r != null) {
                    almacen = new AlmacenViajeDTO();
                    almacen.copiar(r);
                    this.listAlmacen.push(almacen);
                }
            }
        }   
    }

    instanciar(): void {
        if (this.transportista == null) this.transportista = new Transportista();
        if (this.compania == null) this.compania = new Compania();
        if (this.unidadTransporte == null) this.unidadTransporte = new UnidadTransporte();
        if (this.empresaTransporte == null) this.empresaTransporte = new EmpresaTransporte();
        if (this.almacen == null) this.almacen = new Almacen();
        if (this.zonaTransporte == null) this.zonaTransporte = new ZonaTransporte();
        if (this.estadoViaje == null) this.estadoViaje = new EstadoViaje();
        if (this.supervisor == null) this.supervisor = new Supervisor();
        if (this.rutaEmpresaTransporte == null) this.rutaEmpresaTransporte = new RutaEmpresaTransporte();
        if (this.tarifario == null) this.tarifario = new Tarifario();
        if (this.estadoViajeFacturacion == null) this.estadoViajeFacturacion = new EstadoViajeFacturacion();

    }

    get estadoVehiculoString(): string {
        this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
        if (this.estadoVehiculo != null) {
            switch (this.estadoVehiculo) {
                case C.VIAJE.VEHICULO_ESTADO.PROGRAMADO:
                    return this.etiquetas['estado_viaje_programado']
                case C.VIAJE.VEHICULO_ESTADO.EN_RUTA:
                    return this.etiquetas['estado_tarea_en_ruta']
                case C.VIAJE.VEHICULO_ESTADO.EN_ESPERA:
                    return this.etiquetas['estado_tarea_en_espera']
                case C.VIAJE.VEHICULO_ESTADO.EN_CLIENTE:
                    return this.etiquetas['estado_tarea_en_cliente']
                case C.VIAJE.VEHICULO_ESTADO.EN_INICIO_DE_DESCARGA:
                    return this.etiquetas['estado_tarea_inicio_descarga']
                case C.VIAJE.VEHICULO_ESTADO.FINALIZADO:
                    return this.etiquetas['estado_viaje_finalizado']
            }
        }
        return "";
    }
}
