import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Observable } from 'rxjs';
import { TipoIncidente } from '../models';
import { ImportarTipoIncidenteDTO } from '../dto';
import { IResultado } from '../index';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../commons';

@Injectable()
export class TipoIncidenteService {

  ciaId : string;
  constructor(private http : HttpClient2) { 
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<TipoIncidente>>{
    
    return this.http.listar(this.ciaId+"/tipos-incidente").map(
      (data: IResultado) => {
        let list = Array<TipoIncidente>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new TipoIncidente();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
    
  }

  consultar(data) : Observable<Array<TipoIncidente>>{
    
    return this.http.consultar(this.ciaId+"/tipos-incidente/consultar",data).map(
      (data: IResultado) => {
        let list = Array<TipoIncidente>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new TipoIncidente();
            obj.copiar(d);
            list.push(obj);
          }  
        }
        return list;
    });
    
  }

  obtener(id : number) : Observable<TipoIncidente>{    
    return this.http.obtener(this.ciaId+"/tipos-incidente/"+id).map(
      (data: IResultado) => {
        let obj = new TipoIncidente();
        if(data){
          obj.copiar(data.resultado);
        }
        return obj;
    });
  }

  registrar(tipoIncidente : TipoIncidente, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/tipos-incidente/", tipoIncidente, { funcionalidad });
  }

  modificar(tipoIncidente : TipoIncidente, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/tipos-incidente/", tipoIncidente, { funcionalidad });

  }

  eliminar(tipoIncidente : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/tipos-incidente/"+tipoIncidente, { funcionalidad });
  }

  registrarImportacion(tiposIncidente: Array<ImportarTipoIncidenteDTO>, companiaId?: string, funcionalidad?: String) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar(companiaId + "/tipos-incidente/registrar-importacion", tiposIncidente, { funcionalidad });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

  obtenerExcelExportacionReportes(data: any, tipoReporte: string): Observable<any> {
    return this.http.consultar("/reportes/" + tipoReporte + "/exportar-excel", data, { responseType: "text" });
  }  
}
