import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DuracionPipe, VacioPipe, DomseguroPipe } from './';
import { EstadoViajeToIconNamePipe } from './estado-viaje-to-icon-name.pipe';
import { EstadoViajeToStringPipe } from './estado-viaje-to-string.pipe';
import { EstadoTareaToStringPipe } from './estado-tarea-to-string.pipe';

const SHARED_PIPES = [
    DuracionPipe, VacioPipe, DomseguroPipe, EstadoViajeToStringPipe, EstadoViajeToIconNamePipe, EstadoTareaToStringPipe
];
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [SHARED_PIPES],
    exports: [SHARED_PIPES]
})
export class SharedPipesModule { }
