import { Compania } from './compania';

export class Categoria {
    //PK
    categoriaId : number;

    //Atributos
    codigo : string;
    estado : number;
    nombre : string;

    //Relaciones
    compania : Compania;

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    constructor(){
    }

    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    //Metodos
    copiar(objeto : any){
        //Propios
        this.categoriaId = objeto.categoriaId;
        this.codigo = objeto.codigo;
        this.estado = objeto.estado;
        this.nombre = objeto.nombre;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }
}
