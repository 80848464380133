import { Component, OnInit, ViewChild } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { takeUntil } from 'rxjs/operators';
import { NotificacionService, SocketResultDTO, SolicitudAutorizacioNotiDTO } from './resources';
import { Dropdown } from 'primeng';
import { NgbDropdown, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { NotificacionDTO } from './resources/dto/notificacion.dto';
import { ChatProvider, NotificacionProvider } from '@shared/providers';
import { Usuario } from '@shared/models';
import { C } from '@shared/commons';
import { NotificacionSensorDTO } from './resources/dto/notificacionSensor.dto';
import { NotificacionSmsDTO } from './resources/dto/notificacionSms.dto';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss'],
  providers: [NotificacionService]
})
export class NotificacionComponent implements OnInit {

  currentUser: Usuario;

  //Variables Web Socket
  private wsNotificacion : WebSocketSubject<SocketResultDTO>;
  private unsuscribe$ = new Subject<void>();
  private ciaId: number;
  
  //Variables Notificacion
  listSolicitudesAutorizacion : Array<SolicitudAutorizacioNotiDTO>;
  listAlertaTarea : Array<NotificacionDTO>;
  listAlertaSensor : Array<NotificacionSensorDTO>;
  listAlertaSms : Array<NotificacionSmsDTO>;
  cantidadNotificaciones: number;

  //Variables Notificacion web
  contadorNotificacionWeb: number = 0;

  @ViewChild("dropNotificacion")
  dropNotificacion : NgbDropdown;

  @ViewChild("dropNotificacionWeb")
  dropNotificacionWeb : NgbDropdown;

  //Secciónes
  showNotiSolicitud: boolean;

  constructor(
    private notificacionService: NotificacionService,
    private chatProvider: ChatProvider,
    private notificacionProvider: NotificacionProvider) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    // Obtener Compañía
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = currentUser.compania.companiaId;
    // Obtener Notificaciones Pendientes
    this.cargarNotificaciones();
    // Establecer conexión Web Socket
    this.initNotificacionSocket();
    //Cargamos el rol
    this.validarSeccionNotificaciones();
    //carga el contador
    this.obtenerNumeroNotificacionesActivas();
  }

  ngOnDestroy(): void {
    this.unsuscribe$.next();
    this.unsuscribe$.complete();
  }

  private cargarNotificaciones() {
    // Inciializar
    this.cantidadNotificaciones = 0;
    this.listSolicitudesAutorizacion = [];
    this.listAlertaTarea = [];
    this.listAlertaSensor = [];
    this.listAlertaSms = [];

    // Obtener Datos de solicitud de autorización.
    this.cargarSolicitudesAutorizacion();

    // Obtener Datos de alertas de tareas.
    this.cargarAlertasTareas();

    // Obtener Datos de alertas de sensores.
    this.cargarAlertasSensores();

    // Obtener Datos de alertas de sms.
    this.cargarAlertasSms();

    // Compartir Datos
    this.notificacionProvider.currentSolicitudes.subscribe((solicitudes : Array<SolicitudAutorizacioNotiDTO>) => {
      this.listSolicitudesAutorizacion = solicitudes;
      //Contavilizar las alertas.
      this.cantidadNotificaciones = this.listSolicitudesAutorizacion.length + this.listAlertaTarea.length + this.listAlertaSensor.length + this.listAlertaSms.length;
    });

    this.notificacionProvider.currentAlertasTareas.subscribe((alertasTareas: Array<NotificacionDTO>) => {
      this.listAlertaTarea = alertasTareas;
      //Contavilizar las alertas.
      this.cantidadNotificaciones = this.listSolicitudesAutorizacion.length + this.listAlertaTarea.length + this.listAlertaSensor.length + this.listAlertaSms.length;
    });

    this.notificacionProvider.currentAlertasSensores.subscribe((alertasSensores: Array<NotificacionSensorDTO>) => {
      this.listAlertaSensor = alertasSensores;
      //Contavilizar las alertas.
      this.cantidadNotificaciones = this.listSolicitudesAutorizacion.length + this.listAlertaTarea.length + this.listAlertaSensor.length + this.listAlertaSms.length;
    });

    this.notificacionProvider.currentAlertasSms.subscribe((alertasSms: Array<NotificacionSmsDTO>) => {
      this.listAlertaSms = alertasSms;
      //Contavilizar las alertas.
      this.cantidadNotificaciones = this.listSolicitudesAutorizacion.length + this.listAlertaTarea.length + this.listAlertaSensor.length + this.listAlertaSms.length;
    });
  }

  cargarSolicitudesAutorizacion() {
    if (this.showNotiSolicitud) {
      this.notificacionService.listarSolicitudesAutorizacion()
      .subscribe(
        (data : SolicitudAutorizacioNotiDTO[]) =>{                    
          this.notificacionProvider.changeSolicitudes(data);
        }, (error) => {
          console.log(error);
        },
        () => {
          //this.loadingComponent.ocultarLoading();
        }
      );
    }
  }

  cargarAlertasTareas() {
    if(this.currentUser.esSupervisor){
      this.notificacionService.listarAlertasTareasSupervisor(this.currentUser.usuarioId).subscribe(
        (data: NotificacionDTO[]) => {
          this.notificacionProvider.changeAlertasTareas(data)
        }, (error) => {
          console.log(error);
        },
        () => {
          //this.loadingComponent.ocultarLoading();
        }
      );
          
    }else{
      this.notificacionService.listarAlertasTareas()
        .subscribe(
            (data: NotificacionDTO[]) => {
              this.notificacionProvider.changeAlertasTareas(data)
            }, (error) => {
              console.log(error);
            },
            () => {
              //this.loadingComponent.ocultarLoading();
            }
          );
    }
  }

  cargarAlertasSensores() {
        this.notificacionService.listarAlertasSensores()
        .subscribe(
          (data : NotificacionSensorDTO[]) =>{                    
            this.notificacionProvider.changeAlertasSensores(data);
          }, (error) => {
            console.log(error);
          },
          () => {
            //this.loadingComponent.ocultarLoading();
          }
        );
  }

  cargarAlertasSms() {
    this.notificacionService.listarAlertasSms()
    .subscribe(
      (data : NotificacionSmsDTO[]) =>{                    
        this.notificacionProvider.changeAlertasSms(data);
      }, (error) => {
        console.log(error);
      },
      () => {
        //this.loadingComponent.ocultarLoading();
      }
    );
}
    

  

  private initNotificacionSocket(){
    this.wsNotificacion = webSocket(environment['notificacionUrl']+'/itracing.notificacion.'+this.ciaId+'/');
    let that = this;

    this.wsNotificacion.pipe(takeUntil(this.unsuscribe$)).subscribe(    
      // Llamado cada vez que hay un mensaje del servidor    
      (result : SocketResultDTO) => {        
        let notificacion: NotificacionDTO = result.message;

        if (notificacion.tipoNotificacion == 1) {
          that.cargarSolicitudesAutorizacion();
        } else if (notificacion.tipoNotificacion == 2) {
          that.cargarAlertasTareas();
        }
        else if (notificacion.tipoNotificacion == 3) {
          that.cargarAlertasSensores();
        }
        else if (notificacion.tipoNotificacion == 4) {
          that.cargarAlertasSms();
        }
      },

      // Se llama si la API de WebSocket indica algún tipo de error   
      err => console.log(err)
   );
  }

  cerrarDialog(){
    this.dropNotificacion.close();
  }

  cerrarDialogNotificacionWeb(){
    this.dropNotificacionWeb.close();
  }

  obtenerNumeroNotificacionesActivas(){
    this.chatProvider.currentContador.subscribe((contador : number) => {
      this.contadorNotificacionWeb = contador;
  }); 
  }

  checkDropDown(event : any){
    console.log(event);
    //event.stopPropagation();
  }

  private validarSeccionNotificaciones() {
    let index = this.currentUser.listPerfil.findIndex(p =>
      p.perfilId == C.TIPO_PERFIL.SUPERVISOR_DESPACHO ||
      p.perfilId == C.TIPO_PERFIL.ADMINISTRADOR_SISTEMA ||
      p.perfilId == C.TIPO_PERFIL.SUPER_USUARIO);

    this.showNotiSolicitud = (index != -1);
  }
}
