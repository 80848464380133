import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';

@Injectable()
export class LiquidacionCobranzaService {
  ciaId : string;
  usuId: number;


  constructor(private http : HttpClient2) {

    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
    this.usuId = currentUser.usuarioId;

  }

  registrarLiquidacion(data?, funcionalidad?: String, tipoLiquidacionCobranza?: number){
    return this.http.registrar("/liquidacion-cobranza/liquidar-cobranza/"+tipoLiquidacionCobranza, data, { funcionalidad});
  }

  registrarMultipleLiquidacion(data?, funcionalidad?: String){
    return this.http.registrar("/liquidacion-cobranza/liquidar-multiple-cobranza", data, { funcionalidad});
  }
  registrarModificacion(data?, funcionalidad?: String){
    return this.http.registrar("/liquidacion-cobranza/modificar-sin-efectivo", data,{ funcionalidad});
  }


  registrarLiquidacionTotal(usuarioId :number,codigoId : string, tipoRecojo: number, funcionalidad?: String){    
    return this.http.registrar('/liquidacion-cobranza/liquidar-total/'+usuarioId+"/"+codigoId+"/"+tipoRecojo ,{ funcionalidad });  
  }
  
 
}
