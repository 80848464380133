import { Tarea } from './tarea';

export class Observacion {
    //PK
    observacionId : number;

    //Atributos
    descripcion : string;
    fechaHora : Date;

    //Relaciones
    tarea : Tarea;

    //Otras Variables


    //Metodos
    static clonar(objeto : any) : Observacion{
        let guia : Observacion = new Observacion();
        //Propios
        guia.observacionId = objeto.observacionId;
        guia.descripcion = objeto.descripcion;
        guia.fechaHora = objeto.fechaHora;

        //Relaciones
        if(guia.tarea != null){
            guia.tarea = new Tarea();
            guia.tarea.copiar(objeto.tarea);
        }

        return guia;
    }
    copiar(objeto : any){
        //Propios
        this.observacionId = objeto.observacionId;
        this.descripcion = objeto.descripcion;
        this.fechaHora = objeto.fechaHora;

        //Relaciones
        if(objeto.tarea != null){
            this.tarea = new Tarea();
            this.tarea.copiar(objeto.tarea);
        }
    }

}
