import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Observable } from 'rxjs/Observable';
import { CobranzaEvidencia } from '../models/cobranzaEvidencia';
import { IResultado } from '../commons/interfaces';

@Injectable()
export class CobranzaEvidenciaService {
  ciaId : string;
  usuId: number;

  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = currentUser.esEmpresaTransporteAdministrador? "/"+currentUser?.companiaPadre: "/"+currentUser.compania.companiaId;
    this.usuId = currentUser.usuarioId;
  }

  listarPorClienteCobranza(cobranzaId: number) : Observable<Array<CobranzaEvidencia>>{
    return this.http.listar("/"+cobranzaId+"/cobranzas-evidencias").map(
      (data: IResultado) => {
        let list = Array<CobranzaEvidencia>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new CobranzaEvidencia();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  registrar(data, funcionalidad?: String){
    return this.http.registrar(`/cobranzas-evidencias/`,data, { funcionalidad});
  }

}