import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]

})
export class AppComponent implements OnInit {

  msgs: Message[] = [];
  currentUser: any;

  constructor(private activatedRoute: ActivatedRoute,private translate: TranslateService,
     private messageService: MessageService,
     private router: Router) {
    translate.addLangs(['es-PE', 'es-BO', 'es-EC', 'en']);
    translate.setDefaultLang('es-PE');
  }

  ngOnInit() : void{
    this.msgs = [];
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
   }

  loadAPIkeyGoogleMaps() {
    const script = document.createElement('script');
    script.type = "text/javascript";
    script.innerHTML = '';
    script.src = "https://maps.google.com/maps/api/js?key=" + environment['apiKeyGoogleMaps'] + "&sensor=false";
    script.async = false;
    script.defer = true;
    document.getElementsByTagName("head")[0].appendChild(script);
  } 

  showToast(severidad: string, titulo: string, detalle: string) {
    this.messageService.add({ severity: severidad, summary: titulo, detail: detalle, sticky: true });
  }

}
