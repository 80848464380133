export class SolicitudAutorizacionDTO {
    viajeId: number;
    notificacionId: number;
    codigo?: string;
    placa?: string;
    transportista?: string;
    licencia?: string;
    estadoSolicitud?: string;
    empresaTransporte?: string;
    fechaCreacion?: Date;
    fechaProceso?: Date;

    //Constructor
    constructor(){}; 

    copiar(objeto : any){
        //Propios
        this.viajeId = objeto.viajeId;
        this.notificacionId = objeto.notificacionId;
        this.codigo = objeto.codigo;
        this.placa = objeto.placa;
        this.transportista = objeto.transportista;
        this.licencia = objeto.licencia;
        this.estadoSolicitud = objeto.estadoSolicitud;
        this.empresaTransporte = objeto.empresaTransporte;
        this.fechaCreacion = objeto.fechaCreacion;
        this.fechaProceso = objeto.fechaProceso;
    }
}