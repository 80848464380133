import { Compania } from './compania';
import { UnidadTransporte } from './unidadTransporte';
import { Transportista } from './transportista';

export class EmpresaTransporte {
    //PK
    empresaTransporteId : number;

    //Atributos
    codigo : string;
    nombre : string;
    ruc : string;
    direccion : string;
    telefono : string;
    correo : string;
    contacto : string;
    esExclusivo : boolean;
    hub : boolean;
    estado : number;
    evidenciaAdicional : boolean;

    //Relaciones
    compania : Compania;
    companiaTransporte : Compania;
    listUnidadTransporte : UnidadTransporte[];
    listTransportista : Transportista[];

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    //Metodos
    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }
    copiar(objeto : any){
        //Propios
        this.empresaTransporteId = objeto.empresaTransporteId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.ruc = objeto.ruc;
        this.direccion = objeto.direccion;
        this.telefono = objeto.telefono;
        this.correo = objeto.correo;
        this.contacto = objeto.contacto;
        this.esExclusivo = objeto.esExclusivo;
        this.hub = objeto.hub;
        this.evidenciaAdicional = objeto.evidenciaAdicional;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.estado = objeto.estado;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }

        if(objeto.companiaTransporte != null){
            this.companiaTransporte = new Compania();
            this.companiaTransporte.copiar(objeto.companiaTransporte);
        }

        if(objeto.listUnidadTransporte != null){
            let unidadTransporte : UnidadTransporte;
            this.listUnidadTransporte = new Array();
            for (let r of objeto.listUnidadTransporte) {
                if(r != null){
                    unidadTransporte = new UnidadTransporte();
                    unidadTransporte.copiar(r);
                    this.listUnidadTransporte.push(unidadTransporte);
                }
            }
        }

        if(objeto.listTransportista != null){
            let transportista : Transportista;
            this.listTransportista = new Array();
            for (let r of objeto.listTransportista) {
                if(r != null){
                    transportista = new Transportista();
                    transportista.copiar(r);
                    this.listTransportista.push(transportista);
                }
            }
        }
    }

    instanciar(){
        if(this.compania==null) this.compania = new Compania();
        if(this.companiaTransporte==null) this.companiaTransporte = new Compania();
    }
}
