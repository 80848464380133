
export class EntidadFinanciera {
    entidadFinancieraId: number;
    codigo: string;
    nombre: string;
    longitudOperacion: number;
    estado: number;

    copiar(objeto: any) {
        this.entidadFinancieraId = objeto.entidadFinancieraId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.longitudOperacion = objeto.longitudOperacion;
        this.estado = objeto.estado;
    }

}