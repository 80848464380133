import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificacionModule } from '../modules/notificacion/notificacion.module';
import { LoadingAnimationComponent } from './loading-animation/loading-animation.component';

import { FooterComponent, HeaderComponent, SidebarComponent, HeaderFullComponent, StCardComponent, DescargarEvidenciaComponent } from './';
import { ChatMinimizadoComponent } from './chat/chat-minimizado/chat-minimizado.component';
import { ChatMaximizadoComponent } from './chat/chat-maximizado/chat-maximizado.component';
import { MensajeChatComponent } from './chat/mensaje-chat/mensaje-chat.component';
import { MessageModule, SharedModule, DialogModule, ToastModule, FieldsetModule, OverlayPanelModule, TableModule, CheckboxModule, CalendarModule, MultiSelectModule, PaginatorModule } from 'primeng';
import { ChatModalComponent } from './chat/chat-modal/chat-modal.component';
import { CustomFeatureComponent } from './custom-feature/custom-feature.component';
import { StCardMultipleComponent } from './ui/cards/st-card-multiple/st-card-multiple.component';
import { CardSensorComponent } from './ui/cards/card-sensor/card-sensor.component';
import { TablaCapturasComponent } from './ui/tables/tabla-capturas/tabla-capturas.component';
import { CustomTextareaComponent } from './custom-textarea/custom-textarea.component';
import { HistorialEstadoTareaComponent } from './historial-estado-tarea/historial-estado-tarea.component';
import { EstadosWrapperComponent } from './historial-estado-tarea/estados-wrapper/estados-wrapper.component';
import { GraficoSensoresComponent } from './ui/grafics/grafico-sensores/grafico-sensores.component';
import { EtiquetaComponent } from './custom-feature/components/etiqueta/etiqueta.component';
import { DefaultEtiquetaContentComponent } from './custom-feature/components/default-etiqueta-content/default-etiqueta-content.component';
import { LabelEtiquetaContentComponent } from './custom-feature/components/label-etiqueta-content/label-etiqueta-content.component';
import { InnerEtiquetaContentComponent } from './custom-feature/components/inner-etiqueta-content/inner-etiqueta-content.component';
import { DetalleSmsComponent } from './ui/detalle-sms/detalle-sms/detalle-sms.component';
import { PerfilDescripcionComponent } from './historial-estado-tarea/estados-wrapper/perfil-descripcion/perfil-descripcion.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbDropdownModule,
    NotificacionModule, DialogModule,
    OverlayPanelModule,
    FieldsetModule,
    FormsModule,
    ToastModule,
    MessageModule,
    TableModule,
    CheckboxModule,
    CalendarModule,
    MultiSelectModule,
    PaginatorModule,
    NgxPaginationModule
    ],
  declarations: [FooterComponent, HeaderComponent, SidebarComponent, HeaderFullComponent, StCardComponent, LoadingAnimationComponent, ChatMinimizadoComponent, ChatMaximizadoComponent, MensajeChatComponent, ChatModalComponent, CustomFeatureComponent, StCardMultipleComponent, CustomTextareaComponent, CardSensorComponent, TablaCapturasComponent, HistorialEstadoTareaComponent, EstadosWrapperComponent, GraficoSensoresComponent, EtiquetaComponent, DefaultEtiquetaContentComponent, LabelEtiquetaContentComponent, InnerEtiquetaContentComponent, DetalleSmsComponent, PerfilDescripcionComponent, DescargarEvidenciaComponent],
  exports: [FooterComponent, HeaderComponent, SidebarComponent, HeaderFullComponent, StCardComponent, LoadingAnimationComponent, ChatMinimizadoComponent, ChatMaximizadoComponent, MensajeChatComponent, ChatModalComponent,CustomFeatureComponent, StCardMultipleComponent, CustomTextareaComponent, CardSensorComponent, TablaCapturasComponent, HistorialEstadoTareaComponent, GraficoSensoresComponent, DetalleSmsComponent, DescargarEvidenciaComponent]
})
export class SeccionModule { }
