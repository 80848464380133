import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';

//Clases
import { ClienteTransporte } from '../models';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarClienteTransporteDTO } from '../dto';
import { IResultado } from '../index';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../commons';

@Injectable()
export class ClienteTransporteService {
  ciaId : string;
  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<ClienteTransporte>>{
    return this.http.listar(this.ciaId+"/clientes-transporte").map(
      (data: IResultado) => {
        let list = Array<ClienteTransporte>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ClienteTransporte();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  consultar(data) : Observable<Array<ClienteTransporte>>{
    return this.http.consultar(this.ciaId+"/clientes-transporte/consultar",data).map(
      (data: IResultado) => {
        let list = Array<ClienteTransporte>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ClienteTransporte();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });

  }

  obtener(id : number) : Observable<ClienteTransporte>{
    return this.http.obtener(this.ciaId+"/clientes-transporte/"+id).map(
      (data: IResultado) => {
        let obj = new ClienteTransporte();
        if(data){
          obj.copiar(data.resultado);
        }
        return obj;
    });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

  registrar(clienteTransporte : ClienteTransporte, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/clientes-transporte", clienteTransporte, { funcionalidad});
  }

  modificar(clienteTransporte : ClienteTransporte, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/clientes-transporte", clienteTransporte, {funcionalidad});
  }

  eliminar(clienteTransporteId : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/clientes-transporte/"+clienteTransporteId, {funcionalidad});
  }

  registrarImportacion(clienteTransporte: Array<ImportarClienteTransporteDTO>, companiaId?: string, funcionalidad?: String) {
    if (companiaId == undefined) companiaId = this.ciaId;
  return this.http.registrar(companiaId + "/clientes-transporte/registrar-importacion", clienteTransporte, { funcionalidad });
  }
  
  obtenerExcelExportacionReportes(data: any, tipoReporte: string): Observable<any> {
    return this.http.consultar("/reportes/" + tipoReporte + "/exportar-excel", data, { responseType: "text" });
  }
}
