import { Motivo } from './motivo';
import { C } from '../commons';
export class EvidenciaMotivo {
    //PK
    evidenciaMotivoId : number;

    //Atributos
    nombre : string;
    tipo : number;

    //Relaciones
    motivo : Motivo;

    //Otras Variables
    estado : number;

    //Metodos
    get tipoToString(){
        if(this.tipo!=null){
            if(this.tipo==C.EVIDENCIA.TIPO.TEXTO){
                return "COMMON.constantes.tipo-evidencia.texto";
            }
            if(this.tipo==C.EVIDENCIA.TIPO.TEXTO_IMAGENES){
                return "COMMON.constantes.tipo-evidencia.texto-imagenes";
            }
        }
    }

    copiar(objeto : any){
        //Propios
        this.evidenciaMotivoId = objeto.evidenciaMotivoId;
        this.nombre = objeto.nombre;
        this.estado = objeto.estado;
        this.tipo = objeto.tipo;

        //Relaciones
        if(objeto.motivo != null){
            this.motivo = new Motivo();
            this.motivo.copiar(objeto.motivo);
        }
    }

}
