import { Departamento } from './departamento';
export class Provincia {
    //Pk
    provinciaId : number;
    //Atributos
    provincia : string;
    //relaciones
    departamento : Departamento;

    constructor(){
        this.departamento = new Departamento();
    }
    copiar(objeto : any){
        //Propios
        this.provinciaId = objeto.provinciaId;
        this.provincia = objeto.provincia;

        //Relaciones
        if(objeto.departamento != null){
            this.departamento.copiar(objeto.departamento);
        }

    }
}
