import { Guia } from './guia';
import { Paquete } from './paquete';
import { Tarea } from './tarea';

export class Entrega extends Tarea{
    //PK
    entregaId : number;

    //Atributos
    nroPedido : string;
    debeCobrar : boolean;

    //Relaciones
    listGuia : Guia [];
    listPaquete : Paquete [];

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    //Metodos
    copiar(objeto : any){
        super.copiar(objeto);
        //Propios
        this["@type"] = "Entrega";
        this.entregaId = objeto.entregaId;
        this.nroPedido = objeto.nroPedido;
        this.debeCobrar = objeto.debeCobrar;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;

        //Relaciones
        if(objeto.listGuia != null){
            let guia : Guia;
            this.listGuia = new Array();
            for (let r of objeto.listGuia) {
                if(r != null){
                    guia = new Guia();
                    guia.copiar(r);
                    this.listGuia.push(guia);
                }
            }
        }

        //Relaciones
        if(objeto.listPaquete != null){
            let paquete : Paquete;
            this.listPaquete = new Array();
            for (let r of objeto.listPaquete) {
                if(r != null){
                    paquete = new Paquete();
                    paquete.copiar(r);
                    this.listPaquete.push(paquete);
                }
            }
        }
    }
}
