import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { C, Cookies } from '@shared/commons';
import { CustomFeatureSectionDTO } from '@shared/dto/customFeatureSection.dto';
import { FuncionalidadPersonalizacion } from '@shared/models/funcionalidadPersonalizacion';
import { CustomFeatureProvider, GeneralProvider } from '@shared/providers';
import { FuncionalidadPersonalizacionService } from '@shared/services/funcionalidad-personalizacion.service';
import { MessageService } from 'primeng';
import { BaseEtiqueta } from './types/base-etiqueta';
import { EtiquetaViewModel } from './types/etiqueta-view-model';

@Component({
  selector: 'app-custom-feature',
  templateUrl: './custom-feature.component.html',
  styleUrls: ['./custom-feature.component.scss'],
  providers: []
})
export class CustomFeatureComponent implements OnInit, AfterViewInit {
  @ViewChildren(BaseEtiqueta) etiquetas: QueryList<BaseEtiqueta>;

  @Input() funcionalidadId: number;
  @Output() onGuardarEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCerrarEmitter: EventEmitter<any> = new EventEmitter<any>();

  customFeatureSections: CustomFeatureSectionDTO[] = [];
  customFeatureSectionsView: any = [];
  funcionalidadPersonalizadas: FuncionalidadPersonalizacion[] = [];
  blocked: boolean = true;
  preFixEtiquetaHija: String = "_hijo";
  etiquetasLocales : any = []

  lenguage;

  constructor(
    private customFeatureProvider: CustomFeatureProvider,
    private general : GeneralProvider,
    private fpService: FuncionalidadPersonalizacionService,
    private messageService: MessageService
  ) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.etiquetasLocales = JSON.parse(localStorage.getItem("etiquetas"));
    this.lenguage = Cookies.getCookie("LANGUAGE");
  }

  ngOnInit(): void {
    this.customFeatureProvider.currentCustomFeatureSections
      .subscribe((sections: CustomFeatureSectionDTO[]) => {
        this.customFeatureSections = sections;
        this.mapToCustomFeatureSectionsView();
        this.incluirEtiquetasHijas();
      });

    this.customFeatureProvider.currentfuncionalidadPersonalizadas
      .subscribe((options: FuncionalidadPersonalizacion[]) => this.funcionalidadPersonalizadas = options);
  }

  ngAfterViewInit() {
    this.customFeatureProvider.currentIsOpen
      .subscribe((isOpen: boolean) => {
        if (isOpen) {
          this.blocked = true;
          setTimeout(() => this.selectedOptions(), 500);
        }
      })
  }


  selectedOptions() {
    this.customFeatureSectionsView = this.customFeatureSectionsView.map(customFeatureSection => {
      const _customFeatureSection = { ...customFeatureSection };
      _customFeatureSection.etiquetas = _customFeatureSection.etiquetas.map(etiqueta => {
        const funcionalidadPersonalizadaMatch = this.funcionalidadPersonalizadas
          .find(funcionalidadPersonalizada => funcionalidadPersonalizada.etiqueta.etiquetaId === etiqueta.etiquetaId);
        etiqueta.nombre = this.lenguage == "en" ? etiqueta.nombreEn : etiqueta.nombre;
        if (funcionalidadPersonalizadaMatch) {
          etiqueta.estado = funcionalidadPersonalizadaMatch.estado;
          etiqueta.nombre = funcionalidadPersonalizadaMatch.nombre;
          etiqueta.funcionalidadPersonalizacionId = funcionalidadPersonalizadaMatch.funcionalidadPersonalizacionId;
        }
        //validacion para setear campos configurables en el dashboard
        if(funcionalidadPersonalizadaMatch && etiqueta.codigo === "dashboard_cmn_filtro_marca"){
          //buscamos el campo en las etiquetas
          etiqueta.nombre =  this.general.getConfiguracionPersonalizada(C.ETIQUETA_CONFIGURABLE.CONF_ETIQUETA_MARCA);
          etiqueta.isConfigurable = true;
        }
        return etiqueta;
      });

      return _customFeatureSection;
    })

    this.blocked = false;
  }

  saveOptions() {
    if (!this.blocked) {
      this.blocked = true;
      const funcionalidadesPersonalizadasActualizadas = this.etiquetas.map(etiqueta => etiqueta.obtenerFuncionalidadPersonalizacion());
      this.fpService.guardar({
        opciones: funcionalidadesPersonalizadasActualizadas
      }).subscribe(r => {
        this.showToast('success', '', r.mensaje);
      },
        error => {
          this.showToast('error', '', error.mensaje);
          this.closeOptionsEmitter();
        },
        () => this.saveOptionsEmitter());
    }
  }

  closeOptionsEmitter() {
    this.blocked = false;
    this.onCerrarEmitter.emit();
  }

  saveOptionsEmitter() {
    this.blocked = false;
    this.onGuardarEmitter.emit();
  }

  private showToast(severidad: string, titulo: string, detalle: string) {
    this.messageService.add({ severity: severidad, summary: titulo, detail: detalle });
  }

  private mapToCustomFeatureSectionsView(): void {
    this.customFeatureSectionsView = this.customFeatureSections.map(customFeatureSection => {
      const _customFeatureSection = {
        ...customFeatureSection
      } as any;

      _customFeatureSection.etiquetas = customFeatureSection.etiquetas.map(etiqueta => ({
        ...etiqueta
      }) as EtiquetaViewModel);
      
      return _customFeatureSection;
    });
  }

  private incluirEtiquetasHijas(): void {
    const etiquetas = (this.customFeatureSectionsView as any).flatMap(customFeatureSection => customFeatureSection.etiquetas);

    // listado de etiquetas que contienen el prefijo para identificar etiquetas hijas
    const etiquetasHijas = etiquetas.filter(etiqueta => etiqueta.codigo.includes(this.preFixEtiquetaHija));

    this.customFeatureSectionsView.forEach(customFeatureSection => {
      etiquetasHijas.forEach(etiquetaHija => {
        customFeatureSection.etiquetas.find(etiqueta => {
          
          if(etiqueta.codigo === etiquetaHija.codigo.split(this.preFixEtiquetaHija)[0]){
            etiqueta.etiquetaHija = etiquetaHija;
          }
        })
      })
    });
  }

}
