<div class="card-container">
    <div class="titulo">{{titulo}}</div>    
    <div class="contenido">
        <div class="sensor-card">
            <div>
                <img width="21px" height="21px"  class="imagen-sensor"  [style.background]="sensor.maximo.data.color"  src="{{sensor.maximo.icono}}">
            </div>
            <span class="nivel-sensor">{{'WEB.reporte.espera-apt.maximo' | translate}}</span>
            <span class="grados" [style.color]="sensor.maximo.data.color">{{sensor.maximo.data.valor}}</span>

        </div>

        <div class="sensor-card">
            <div>
                <img width="21px" height="21px"  class="imagen-sensor"  [style.background]="sensor.minimo.data.color"  src="{{sensor.minimo.icono}}">
            </div>
            <span class="nivel-sensor">{{'WEB.reporte.espera-apt.minimo' | translate}}</span>
            <span class="grados" [style.color]="sensor.minimo.data.color">{{sensor.minimo.data.valor}}</span>
        </div>

        <div class="sensor-card">
            <div>
                <img width="21px" height="21px"  class="imagen-sensor"  [style.background]="sensor.promedio.data.color" src="{{sensor.promedio.icono}}">
            </div>
            <span class="nivel-sensor">{{'WEB.reporte.espera-apt.promedio' | translate}}</span>
            <span class="grados" [style.color]="sensor.promedio.data.color">{{sensor.promedio.data.valor}}</span>
        </div>
    </div>  
</div>