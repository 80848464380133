import { Component, EventEmitter, Input, OnInit, Output,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProcesoSolicitudDTO, SolicitudAutorizacionDTO } from '@shared/dto';
import { MotivoNoAutorizacion } from '@shared/models';
import { NotificacionProvider, SolicitudAutorizacionProvider } from '@shared/providers';
import { MotivoNoAutorizacionService, SolicitudAutorizacionService } from '@shared/services';
import { SelectItem } from 'primeng/api';
import { SolicitudAutorizacioNotiDTO } from '../resources';



@Component({
  selector: 'app-solicitud-autorizacion-item',
  templateUrl: './solicitud-autorizacion-item.component.html',
  styleUrls: ['./solicitud-autorizacion-item.component.scss'],
  providers: [SolicitudAutorizacionService, MotivoNoAutorizacionService]
})
export class SolicitudAutorizacionItemComponent implements OnInit {
  
  @Input()
  solicitud: SolicitudAutorizacioNotiDTO;
  solicitudNoAutorizado: SolicitudAutorizacioNotiDTO = null;
  solicitudAutorizaciones: SolicitudAutorizacionDTO[] = [];
  solicitudes : Array<SolicitudAutorizacioNotiDTO>;

   //modal
   dialogAutorizacion: boolean;
   bloqueado: boolean;
   motivos: SelectItem[] = [];
   motivoId: number = 0;
   comentario: string;
   eventoNoAuotrizacion: Event;

  @Output()
  cerrar = new EventEmitter<void>();
  page: string;

  @Output()
  mostrarToast = new EventEmitter<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private solicitudAutorizacionService: SolicitudAutorizacionService,
    private motivoNoAutorizacionService: MotivoNoAutorizacionService,
    private notificacionProvider:NotificacionProvider,
    private translate : TranslateService,
    private solicitudAutorizacionProvider: SolicitudAutorizacionProvider

  ) { 
  }

  ngOnInit(): void {}

  onClickVerDetalle(event): void {
    event.stopPropagation();
    this.page = this.activatedRoute.snapshot.queryParams['page'] || '/control/solicitud-autorizacion/' + this.solicitud.viajeId;
    this.router.navigate([this.page]);
    this.cerrar.emit();
  }

  onclickAutorizar(data, tipoProceso, event){
    this.procesoAutorizacion(data, tipoProceso, event);
  }

  procesoAutorizacion(data: SolicitudAutorizacioNotiDTO, tipoProceso: number, event){
    //creacion de objeto
    let solicitudAutorizacion = new  SolicitudAutorizacionDTO();
    solicitudAutorizacion.viajeId = data.viajeId;
    solicitudAutorizacion.notificacionId = data.notificacionId;
    
    //push a la lista de solicitudes de autorizacion
    this.solicitudAutorizaciones.push(solicitudAutorizacion);

    //creacion de objeto para luego pasarlo al servicio
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    let procesoSolicitud = new ProcesoSolicitudDTO;
    procesoSolicitud.tipoProceso = tipoProceso;
    if(tipoProceso == 2){
      procesoSolicitud.motivoId =this.motivoId;
      procesoSolicitud.comentario =this.comentario;
    }
    procesoSolicitud.listaSolicitudAutorizacion=this.solicitudAutorizaciones;

    //registrar proceso
    this.solicitudAutorizacionService.registarProceso(procesoSolicitud,currentUser.usuarioId).subscribe(
      respuesta=>{
      
        //Llamar a noitificacion provides para remover el elemento de la lista.
        this.mostrarToast.emit();
        this.notificacionProvider.removeSolicitud(data.notificacionId);
        this.solicitudAutorizacionProvider.removeSolicitudAutorizacione(data.notificacionId);  
      },
      error=>{
        console.log(error);   
      
      },
    );
  }



  onClickNoAutorizar(data : SolicitudAutorizacioNotiDTO, event): void{
    this.dialogAutorizacion = true;
    this.solicitudNoAutorizado = data;
    this.eventoNoAuotrizacion= event;
    this.caragarListaModal();
  }

  //MODAL DE NO AUTORIZACION
  caragarListaModal(){
    this.motivoNoAutorizacionService.listar().subscribe(
      (data : MotivoNoAutorizacion[]) =>{
        data.forEach(mo=>{
          this.motivos.push({
            value: mo.motivoNoAutorizacionId,
            label: `${mo.descripcion}`
          });
        })
      },
      (error) => {
        console.log(error);
      },
    );
  }
  onClickSeleccionar(){
    if (this.motivoId != 0) {
      this.bloqueado=false;
    }
  }
  
  onClickConfirmarModal(tipoProceso){
    if(this.motivoId != 0){
      this.dialogAutorizacion = false;
      this.motivos = [];
      this.onclickAutorizar(this.solicitudNoAutorizado,tipoProceso,this.eventoNoAuotrizacion);
      this.motivoId=0;
    }
  }
  onClickCancelarModal(){
    this.dialogAutorizacion = false;
    this.motivos = [];
    this.comentario=" ";
    this.motivoId=0;
    this.bloqueado=true;    
  }
  ocultarDialog() {
    this.motivos = [];
    this.bloqueado=true; 
  } 
}
