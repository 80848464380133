export class ClienteTransporte {
    //PK
    clienteTransporteId : number;

    //Atributos
    codigo : string;
    nombre : string;
    ruc :string;
    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    constructor(){
    }

    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    //Metodos
    copiar(objeto : any){
        //Propios
        this.clienteTransporteId = objeto.clienteTransporteId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.ruc = objeto.ruc;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
    }

}
