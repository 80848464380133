import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';

@Injectable({
  providedIn: 'root'
})
export class AppConfiguracionService {

  constructor(private http : HttpClient2) { }

  consultar(data) : Observable<Array<any>>{
    return this.http.consultar("/configuraciones/consultar", data).map(
      (data: IResultado) => {
        if(data){
          return data.resultado;
        }
    });
  }

}
