import { Compania } from '../index';

export class ImportarClienteTransporteDTO {
    //PK
    clienteTransporteId : number;

    //Atributos
    codigo : string;
    nombre : string;
    ruc : string;
    
    //Relaciones
    compania : Compania;

    //validaciones
    clienteTransporteValido: boolean;
    codigoValido: boolean;
    nombreValido: boolean;
    rucValido: boolean;

    //Otras Variables
    styleClass: string;

    //mensajes de error
    mensajeErrorCodigo: string;
    mensajeErrorNombre: string;
    mensajeErrorRUC: string;

    //Constructor
    constructor(){};

    //Metodos Color de Celda
    get getColorCeldaCodigo() : string{
        if(this.mensajeErrorCodigo!='')
            return "red-bg";
        else return "";
    }

    get getColorCeldaNombre() : string{
        if(this.mensajeErrorNombre!='')
            return "red-bg";
        else return "";
    }

    get getColorCeldaRUC() : string{
        if(this.mensajeErrorRUC!='')
            return "red-bg";
        else return "";
    }

    //Metodos Mensaje de Error
    get getMensajeErrorCodigo(): string{
        if(this.mensajeErrorCodigo!='')
            return this.mensajeErrorCodigo;
        else return "";
    }
    get getMensajeErrorNombre(): string{
        if(this.mensajeErrorNombre!='')
            return this.mensajeErrorNombre;
        else return "";
    }
    get getMensajeErrorRUC(): string{
        if(this.mensajeErrorRUC!='')
            return this.mensajeErrorRUC;
        else return "";
    }

    //Otros
    clone(): any {
        var cloneObj = new (this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    copiar(objeto : any){
        //Propios
        this.clienteTransporteId = objeto.formaPagoId;
        this.codigo = objeto.codigo;
        this.nombre = objeto.nombre;
        this.ruc = objeto.ruc;
        this.mensajeErrorCodigo = objeto.mensajeErrorCodigo;
        this.mensajeErrorNombre = objeto.mensajeErrorNombre;
        this.mensajeErrorRUC = objeto.mensajeErrorRUC;
        this.clienteTransporteValido = objeto.clienteTransporteValido;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }
}
