import { Compania } from '../index';

export class ImportarFormaPagoDTO {
    //PK
    formaPagoId : number;

    //Atributos
    codigo : string;
    tipo : string;
    descripcion : string;
    
    //Relaciones
    compania : Compania;

    //validaciones
    formaPagoValido: boolean;
    codigoValido: boolean;
    tipoValido: boolean;
    descripcionValida: boolean;

    //Otras Variables
    styleClass: string;

    //mensajes de error
    mensajeErrorCodigo: string;
    mensajeErrorTipo: string;
    mensajeErrorDescripcion: string;

    //Constructor
    constructor(){};

    //Metodos Color de Celda
    get getColorCeldaCodigo() : string{
        if(this.mensajeErrorCodigo!='')
            return "red-bg";
        else return "";
    }
    get getColorCeldaTipo() : string{
        if(this.mensajeErrorTipo!='')
            return "red-bg";
        else return "";
    }
    get getColorCeldaDescripcion() : string{
        if(this.mensajeErrorDescripcion!='')
            return "red-bg";
        else return "";
    }

    //Metodos Mensaje de Error
    get getMensajeErrorCodigo(): string{
        if(this.mensajeErrorCodigo!='')
            return this.mensajeErrorCodigo;
        else return "";
    }
    get getMensajeErrorTipo(): string{
        if(this.mensajeErrorTipo!='')
            return this.mensajeErrorTipo;
        else return "";
    }
    get getMensajeErrorDescripcion(): string{
        if(this.mensajeErrorDescripcion!='')
            return this.mensajeErrorDescripcion;
        else return "";
    }
    
    //Otros
    clone(): any {
        var cloneObj = new (this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    copiar(objeto : any){
        //Propios
        this.formaPagoId = objeto.formaPagoId;
        this.codigo = objeto.codigo;
        this.tipo = objeto.tipo;
        this.descripcion = objeto.descripcion;
        this.mensajeErrorCodigo = objeto.mensajeErrorCodigo;
        this.mensajeErrorTipo = objeto.mensajeErrorTipo;
        this.mensajeErrorDescripcion = objeto.mensajeErrorDescripcion
        this.formaPagoValido = objeto.formaPagoValido;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }

}