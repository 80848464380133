import { Compania } from './compania';

export class Perfil {
    //PK
    perfilId : number;

    //Atributos
    descripcion : string;
    descripcionEn : string;
    esSupervisor : boolean;
    estado : number;

    //Relaciones


    //Metodos
    copiar(objeto : any){
        //Propios
        this.perfilId = objeto.perfilId;
        this.descripcion = objeto.descripcion;
        this.descripcionEn = objeto.descripcionEn;
        this.esSupervisor = objeto.esSupervisor;
        this.estado = objeto.estado;
    }
}
