import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../../shared/commons';

//Clases
import { ZonaTransporte } from '../models';
//Otros
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarZonaTransporteDTO } from '../dto';
import { IResultado } from '../commons';

@Injectable()
export class ZonaTransporteService {
  ciaId : string;

  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<ZonaTransporte>>{

    return this.http.listar(this.ciaId+'/zonas-transporte').map(
      (data: IResultado) => {
        let list = Array<ZonaTransporte>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ZonaTransporte();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });

  }

  consultar(data) : Observable<Array<ZonaTransporte>>{

    return this.http.consultar(this.ciaId+"/zonas-transporte/consultar", data).map(
      (data: IResultado) => {
        let list = Array<ZonaTransporte>();
        let obj;
        for (const d of data.resultado) {
          obj = new ZonaTransporte();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });

  }

  obtener(id : number) : Observable<ZonaTransporte>{
    return this.http.obtener(this.ciaId+"/zonas-transporte/"+id).map(
      (data: IResultado) => {
        let obj = new ZonaTransporte();
        if(data){   
          obj.copiar(data.resultado);
        }
        return obj;
    });
  }

  registrar(zonaTransporte : ZonaTransporte, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/zonas-transporte", zonaTransporte, { funcionalidad });
  }

  modificar(zonaTransporte : ZonaTransporte, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/zonas-transporte", zonaTransporte, { funcionalidad });
  }

  eliminar(zonaTransporteId : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/zonas-transporte/"+zonaTransporteId, { funcionalidad });
  }
  
  registrarImportacion(zonaTransporte : Array<ImportarZonaTransporteDTO>, funcionalidad?: String) : Observable<any>{
    return this.http.registrarImportacion(this.ciaId+"/zonas-transporte/registrar-importacion", zonaTransporte, { funcionalidad });
  }

 obtenerHeaders(httpOptions? : Object) : HttpHeaders{
  let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
  return this.http.obtenerHeaders();
}
}
