//Establecemos nuestro tiempo máximo de vida de la cookie (10 años)
const MAX_AGE: number = 10 * 365 * 24 * 60 * 60;

export class Cookies {
    
    /**
     * 
     * @param cookieName nombre de la Cookie
     * @param cookieValue valor de la Cookie
     */
    public static createCockie(cookieName: string, cookieValue: string): void {
        document.cookie = `${cookieName} = ${cookieValue}; max-age=${MAX_AGE}; path=/`;
    }

    /**
     * 
     * @param nameCookie nombre de la Cookie a obtener
     * @return devuelve null si la Cookie no existe
     */
    
    public static getCookie(nameCookie: string): string {
        let nameEQ = nameCookie + "=";
        let lstCookies = document.cookie.split(";");
        for (var i = 0; i < lstCookies.length; i++) {
            var c = lstCookies[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) {
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
            }
        }
        return null;
    }

    /**
     * 
     * @param oldCookie nombre cockie
     * @param newCookie nuevo valor de la cockie
     */
    public static setChangeCockie(oldCookie: string, newCookie: string): void {
        if (this.getCookie(oldCookie) !== null) {
            document.cookie = `${oldCookie} = ${newCookie}; max-age=${MAX_AGE}; path=/`;
        }
    }
}
