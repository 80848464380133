import { Injectable } from '@angular/core';
import { IResultado } from '@shared/commons';
import { ViajeObservacion } from '@shared/models';
import { Observable } from 'rxjs';
import { HttpClient2 } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ViajeObservacionService {

  constructor(private http : HttpClient2) { }

  consultar(viajeObservacion : ViajeObservacion) : Observable<Array<ViajeObservacion>>{
    return this.http.consultar('/viaje-observacion/consultar', viajeObservacion).map(
      (data: IResultado) => {
        let list = Array<ViajeObservacion>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ViajeObservacion();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }
}
