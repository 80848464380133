

<div class="container-grafico-sensor">    
    <div class="title">{{'WEB.control.estados-viaje.detalle.sensores.titulo-grafico' | translate}}</div>

    <div class="umbral-content">
        <div class="umbrales" [style.width.px]="width">
            <div *ngFor="let item of umbrales">
                <b  [style.color]="this.SENSORS.get(item.sensorId).color">---</b> {{item.descripcionSensor}}
            </div>             
        </div>
        <div style="margin-left: 15px;">
            <p-checkbox binary="true" inputId="binary"  (onChange)="onChangeCheckUmbrales($event)" [(ngModel)]="mostrarUmbral"></p-checkbox>
            <label for="binary" style="margin-left: 5px;">{{'MODEL.sensores.ver-umbrales' | translate}}</label>
        </div>  
    </div>
    
   
    <div *ngIf="data.length > 0" class="sub-title">{{rangoFechas}}</div>    

    <canvas class="" #chartGraficoSensores id="chartGraficoSensores" ></canvas>
   
    <canvas [hidden]="ocultar" #chartPDFHumedad id="chartPDFHumedad" ></canvas>
    <canvas [hidden]="ocultar" #chartPDFTemperatura id="chartPDFTemperatura" ></canvas>
 
    
    
    
</div>