import { Provincia } from './provincia';

export class Distrito{
    //Pk
    distritoId : number;
    //Atributos
    distrito : string;
    //relaciones
    provincia : Provincia;

    constructor(){
        this.provincia = new Provincia();
    }
    copiar(objeto : any){
        //Propios
        this.distritoId = objeto.distritoId;
        this.distrito = objeto.distrito;

        //Relaciones
        if(objeto.provincia != null){
            this.provincia.copiar(objeto.provincia);
        }

    }
}
