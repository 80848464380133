
<div style="width: 100%;padding-bottom: 15px;padding-right: 20px;display: flex;justify-content: flex-end;font-size: 14px;">
    <p-checkbox #chkMostrarFueraRango [(ngModel)]="mostrarFueraRango" label="{{'COMMON.boton.mostrar-valores-fuera-rango'| translate}}" (onChange)="onChange($event)" class="checkbox-filtro"></p-checkbox>
</div>

<p-table #tbCapturas [value]="sensoresFiltered" [scrollable]="true" [style]="{width: anchoTabla}" [rows]="8" [paginator]="true">
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columnasSensores" style="width:250px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of columnasSensores">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sensor>
        <tr>
            <td *ngFor="let col of columnasSensores" class="{{(sensor[(col.field)+'-clss'])}}">
                    {{sensor[col.field]}}
            </td>
        </tr>
    </ng-template>
</p-table>
