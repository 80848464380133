import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingAnimationComponent } from '@shared/components';
import { SolicitudPedido } from '@shared/models/solicitudPedido';

@Component({
  selector: 'descargar-evidencia',
  templateUrl: './descargar-evidencia.component.html',
  styleUrls: ['./descargar-evidencia.component.scss'],
  providers: [TranslateService]
})
export class DescargarEvidenciaComponent implements OnInit {

  @ViewChild(LoadingAnimationComponent)
  loadingComponent: LoadingAnimationComponent;

  re = /^.*[\\\/]/;

  solicitudPedido: SolicitudPedido;
  evidencia1: string;
  evidencia2: string;

  constructor() { }

  ngOnInit(): void {
    this.inicializar();
  }

  inicializar() {
    this.evidencia1 = null;
    this.evidencia2 = null;
  }

  cargarDatos(solicitudPedido: SolicitudPedido): void {
    this.inicializar();
    this.solicitudPedido = solicitudPedido;

    if (this.solicitudPedido.evidencia1?.trim().length > 0) {
      this.evidencia1 = this.solicitudPedido.evidencia1.replace(this.re, '');
    }

    if (this.solicitudPedido.evidencia2?.trim().length > 0) {
      this.evidencia2 = this.solicitudPedido.evidencia2.replace(this.re, '');
    }
  }

  downloadFile(numEvidencia: number): void {
    this.loadingComponent.mostrarLoading();
    const link = document.createElement('a');

    let urlEvidencia = numEvidencia == 1 ? this.solicitudPedido.evidencia1 : this.solicitudPedido.evidencia2;
    let fileName = numEvidencia == 1 ? this.evidencia1 : this.evidencia2;

    //Navegadores que admiten el atributo de descarga HTML5
    if (link.download !== undefined) {
      fetch(urlEvidencia)
        .then(response => response.blob())
        .then(blob => {
          const href = URL.createObjectURL(blob);
          link.setAttribute('href', href);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loadingComponent.ocultarLoading();
        });
    }
  }
}
