import { C } from "@shared/commons";

export class ControlCobranzaDetalleDTO{
    fechaEmision: number;
    tipoDocumentoDescripcion: string;
    nroDocumento: string;
    montoPorCobrar : number; 
    fechaHoraPago : string; 
    formaPagoDescripcion: string;
    viajeId: number;
    codigoViaje: string;
    montoTotalCobrado: number;
    saldo: number;
    estado: number;

    constructor(){}
    
    copiar(objeto: any){
        this.fechaEmision = objeto.fechaEmision;
        this.tipoDocumentoDescripcion = objeto.tipoDocumentoDescripcion;
        this.nroDocumento = objeto.nroDocumento;
        this.montoPorCobrar = objeto.montoPorCobrar.toFixed(2);
        this.fechaHoraPago = objeto.fechaHoraPago;
        this.formaPagoDescripcion = objeto.formaPagoDescripcion;
        this.viajeId = objeto.viajeId;
        this.codigoViaje = objeto.codigoViaje;
        this.montoTotalCobrado = objeto.montoTotalCobrado.toFixed(2);
        this.saldo = objeto.saldo.toFixed(2);
        this.estado = objeto.estado;
    }

    instanciar() : void{
    }

    get estadoToString() : string{
        if(this.estado){

            if(this.estado == C.COBRANZA.ESTADO.POR_COBRAR){
                return "MODEL.cobranza.estado.por-cobrar";

            }else if(this.estado == C.COBRANZA.ESTADO.COBRO_PARCIAL){
                return "MODEL.cobranza.estado.cobro-parcial";

            }else if(this.estado == C.COBRANZA.ESTADO.COBRO_TOTAL){
                return "MODEL.cobranza.estado.cobro-total";

            }else{
                return "";
            }

        }else{
            return "";
        }
    }

}