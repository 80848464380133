import { Component, OnInit } from "@angular/core";
import { environment } from "./../../../../environments/environment";
declare var require: any;

/* Cockies */
import { Cookies } from "./../../commons/cookies";
import { TranslateService } from "@ngx-translate/core";
import { Usuario } from "../../models";
import { getCodeLangByPaisId } from "../../commons";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  environmentName: string;
  appVersion: string;
  webUrl: string;
  terminosLegales: string;
  politicasPrivacidad: string;
  logoConTexto: string;
  language_ES: string;
  language_EN: string;
  currentUser: Usuario;

  constructor(private translate: TranslateService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.environmentName = environment["envName"];
    this.webUrl = environment["webUrl"];
    this.terminosLegales = environment["terminosLegales"];
    this.politicasPrivacidad = environment["politicasPrivacidad"];
    this.logoConTexto = environment["logoConTexto"];
    this.appVersion = require("./../../../../../package.json").version;

    let language = Cookies.getCookie("LANGUAGE");
    this.translate.use(language);
  }

  changeLanguage(_language: string) {
    let codeLang = _language == "en" ? _language : "es-PE";

    if (this.currentUser != null && this.currentUser.compania["paisIdioma"] !== "en") {
      codeLang = _language == "en" ? _language : getCodeLangByPaisId(this.currentUser.compania['paisId']);
    }

    Cookies.setChangeCockie("LANGUAGE", codeLang);
    window.location.reload();
  }

}
