import { Compania } from '@shared/models/compania';

export class CentroCosto {
    centroCostoId: number;
    codigo: string;
    descripcion: string;

    //relaciones
    compania: Compania;

    //Metodos
    copiar(objeto : any){
        //Propios
        this.centroCostoId = objeto.centroCostoId;
        this.descripcion = objeto.descripcion;
        this.codigo = objeto.codigo;
        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }
}