import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { Compania, Etiqueta, Funcionalidad } from '@shared/models';
import { FuncionalidadPersonalizacion } from '@shared/models/funcionalidadPersonalizacion';
import { BaseEtiqueta } from '../../types/base-etiqueta';
import { EtiquetaContent } from '../../types/etiqueta-content';
import { EtiquetaViewModel } from '../../types/etiqueta-view-model';

@Component({
  selector: 'st-etiqueta',
  templateUrl: './etiqueta.component.html',
  styleUrls: ['./etiqueta.component.scss'],
  providers: [{
    provide: BaseEtiqueta, useExisting: EtiquetaComponent
  }]
})
export class EtiquetaComponent extends BaseEtiqueta implements OnInit {

  @ContentChild(EtiquetaContent, { static: true })
  contenido: EtiquetaContent;

  @Input() etiqueta: EtiquetaViewModel;

  companiaId: number;

  constructor() {
    super();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.companiaId = currentUser.compania.companiaId;
  }

  ngOnInit(): void { }

  obtenerFuncionalidadPersonalizacion(): FuncionalidadPersonalizacion {
    const nombreContenidoEtiqueta = this.contenido.obtenerNombre();

    const fp = new FuncionalidadPersonalizacion();
    fp.funcionalidadPersonalizacionId = this.etiqueta.funcionalidadPersonalizacionId;
    fp.estado = this.etiqueta.estado;
    fp.nombre = nombreContenidoEtiqueta;

    //etiqueta
    const etiqueta = new Etiqueta();
    etiqueta.etiquetaId = this.etiqueta.etiquetaId;
    etiqueta.codigo = this.etiqueta.codigo;
    etiqueta.obligatorio = this.etiqueta.obligatorio;
    fp.etiqueta = etiqueta;

    //compañia
    const compania = new Compania();
    compania.companiaId = this.companiaId;
    fp.compania = compania;

    //funcionalidad
    const funcionalidad = new Funcionalidad();
    funcionalidad.funcionalidadId = this.etiqueta.funcionalidad.funcionalidadId;
    fp.etiqueta.funcionalidad = funcionalidad;

    return fp;
  }

}
