import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'vacio'
})
export class VacioPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}
  
  transform(value: any, args?: any): any {
    if (typeof value === 'undefined' || value == null) {
      return this.translate.instant("COMMON.etiquetas.no-disponible");
    }
    return value;
  }

}
