import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';

import { SupervisorProgramacion } from '../models';

//Otros
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { IResultado } from '../commons';

@Injectable()
export class SupervisorProgramacionService {
  ciaId : string;
  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  obtener(id : number) : Observable<IResultado>{
    return this.http.obtener(this.ciaId+"/programaciones-supervisor/"+id);
  }

  consultar(data) : Observable<Array<SupervisorProgramacion>>{
    
    return this.http.consultar(this.ciaId+"/programaciones-supervisor/consultar", data).map(data =>{
      let list = Array<SupervisorProgramacion>();
      let obj;
      if(data){
        for (const item of data.resultado) {
          obj = new SupervisorProgramacion();
          obj.copiar(item);
          list.push(obj);
        }
      }
      return list;
    });
    
  }

  registrar(model : SupervisorProgramacion, funcionalidad?: String){    
    return this.http.registrar(this.ciaId+'/programaciones-supervisor', model, { funcionalidad });       
  }

  modificar(model : SupervisorProgramacion, funcionalidad?: String){    
    return this.http.modificar(this.ciaId+'/programaciones-supervisor', model, { funcionalidad });       
  }

  eliminar(id : number, funcionalidad?: String){    
    return this.http.eliminar(this.ciaId+'/programaciones-supervisor/'+id, { funcionalidad });       
  }
}
