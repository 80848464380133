import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../../shared/commons';

//Clases
import { ClienteFinal, Usuario } from '../models';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarClienteDTO } from '../dto/importarCliente.dto';
import { IResultado } from '../index';

@Injectable()
export class ClienteFinalService {
  ciaId : string;

  constructor(private http : HttpClient2) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = currentUser.esEmpresaTransporteAdministrador? "/"+currentUser?.companiaPadre: "/"+currentUser.compania.companiaId;
  }


  listar(companiaId?: number) : Observable<Array<ClienteFinal>>{
    if(companiaId != null){
      console.log(companiaId);
      return this.http.listar("/"+companiaId+"/clientes-finales").map(
        (data: IResultado) => {
          let list = Array<ClienteFinal>();
          let obj;
          if(data){
            for (const d of data.resultado) {
              obj = new ClienteFinal();
              obj.copiar(d);
              list.push(obj);
            }
          }
          return list;
        }
      );
    }else{
    return this.http.listar(this.ciaId+"/clientes-finales").map(
      (data: IResultado) => {
        let list = Array<ClienteFinal>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ClienteFinal();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      }
    );
    }
  }

  listarPorMarca(marcaId: number) : Observable<Array<ClienteFinal>>{
    return this.http.listar(this.ciaId+"/clientes-finales/listar-por-marca/" + marcaId).map(
      (data: IResultado) => {
        let list = Array<ClienteFinal>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ClienteFinal();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      }
    );
  }

  consultar(data) : Observable<Array<ClienteFinal>>{
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
 
    if(currentUser.marca?.marcaId != null){
      data.marcaId =currentUser.marca?.marcaId;
    } else {
      data.marcaId = null;
    }
    return this.http.consultar(this.ciaId+"/clientes-finales/consultar", data).map(
      (data: IResultado) => {
        let list = Array<ClienteFinal>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ClienteFinal();
            obj.copiar(d);
            list.push(obj);
          }
        } 
        return list;
    });

  }

  listarClienteSedeRecojos() : Observable<Array<ClienteFinal>>{
    return this.http.listar(this.ciaId+"/clientes-finales/lista-recojo").map(
      (data: IResultado) => {
        let list = Array<ClienteFinal>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new ClienteFinal();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      }
    );
  }

  obtener(id : number) : Observable<ClienteFinal>{
    return this.http.obtener(this.ciaId+"/clientes-finales/"+id).map(
      (data: IResultado) => {
        let obj = new ClienteFinal();
        if(data){
          obj.copiar(data.resultado);
        }
        return obj;
    });
  }

  registrar(clienteFinal : ClienteFinal, funcionalidad?: string){
    return this.http.registrar(this.ciaId+"/clientes-finales", clienteFinal, { funcionalidad });
  }

  registrarImportacion(clienteFinal : Array<ImportarClienteDTO>, funcionalidad?: string){
    return this.http.registrarImportacion(this.ciaId+"/clientes-finales/registrar-importacion", clienteFinal, { funcionalidad });
  }
  
  modificar(clienteFinal : ClienteFinal, funcionalidad?: string){
    return this.http.modificar(this.ciaId+"/clientes-finales", clienteFinal, { funcionalidad });
  }

  eliminar(clienteFinalId : number, funcionalidad?: string){
    return this.http.eliminar(this.ciaId+"/clientes-finales/" + clienteFinalId, { funcionalidad });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

}
