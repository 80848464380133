import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { HttpHeaders } from '@angular/common/http';
import { Cookies, IResultado } from '../commons';

//Clases
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarPaqueteDTO } from '@shared/dto/importarPaquete.dto';
import { Paquete } from '@shared/models';
import { Observable } from 'rxjs';
import { PaqueteProductoDTO } from '@shared/dto/paqueteProducto.dto';

@Injectable()
export class PaqueteService {
  ciaId : string;
  usuarioId : string;

  constructor(private http : HttpClient2) { 
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.ciaId = "/"+currentUser.compania.companiaId;
    this.usuarioId = ""+currentUser.usuarioId;
  }

  registrarImportacion(listImportarPaqueteDTO: Array<ImportarPaqueteDTO>, companiaId?: string, funcionalidad?: string) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar(companiaId + "/paquetes/registrar-importacion?usuarioId="+this.usuarioId, listImportarPaqueteDTO, {funcionalidad} );
  }

  confirmarEntrega(listPaqueteProductoDTOs: Array<PaqueteProductoDTO>, companiaId?: string, viajeId?: number, tareaId?: number, guiaId?: number, motivoId?: number, funcionalidad?: string) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar(companiaId + "/paquetes/confirmar-entrega?usuarioId="+this.usuarioId+"&&viajeId="+viajeId+"&&tareaId="+tareaId+"&&guiaId="+guiaId+"&&motivoId="+motivoId, listPaqueteProductoDTOs, {funcionalidad} );
  }

  rechazarEntrega(companiaId?: string, tareaId?: number, guiaId?: number,funcionalidad?: string) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar(companiaId + "/paquetes/rechazar-entrega?usuarioId="+this.usuarioId+"&&tareaId="+tareaId+"&&guiaId="+guiaId, {funcionalidad} );
  }

  listarPorTareaId(tareaId : number) : Observable<Array<Paquete>>{    
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));    
    let companiaId = currentUser.compania.companiaId; 

    return this.http.listar(`/${companiaId}/paquetes/${tareaId}/listar-por-tarea`).map(
      (data: IResultado) => {
        let list = Array<Paquete>();
        let obj;
        for (const d of data.resultado) {
          obj = new Paquete();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });
  }

 

  registrarSobrantes(paquete : Paquete , funcionalidad?: String){
    return this.http.registrar(`${this.ciaId}/paquetes/registrar-sobrantes`, paquete, { funcionalidad });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }
}
