import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* External Libraries */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* Own */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* Components */
import { WidgetQuimicaComponent } from './widget-quimica/widget-quimica.component';

import { GeneralProvider, HttpClient2, AuthGuard, AuthenticationService, SharedPipesModule, UsuarioService, CompaniaConfiguracionService } from "./shared";
import { ConfiguracionService } from './layout/seguridad/configuracion/resources';
import { ToastModule } from 'primeng/toast';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  // for development
  // return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-4/master/dist/assets/i18n/', '.json');
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    WidgetQuimicaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastModule,
    SharedPipesModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard, 
    GeneralProvider, 
    HttpClient2, 
    AuthenticationService, 
    UsuarioService, 
    ConfiguracionService,
    CompaniaConfiguracionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
