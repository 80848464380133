import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http'
import { Message } from 'primeng/primeng';

@Injectable()
export class DataProvider {
    public filtro : any;
    public params : HttpParams;
    public mensaje : Message;

    constructor(){
        this.mensaje = null;
    }


}
