<div class="fechas margen-fechas">
    <div>
        <p-calendar name="selectedFechaInicio" [(ngModel)]="selectedFechaInicio" [locale]="lang" dateFormat="dd/mm/yy"
        class="st-calendar" (onSelect)="refrescarDetalleReporte()"></p-calendar>
    </div>
    <div class="margen-fecha-fin">
        <p-calendar name="selectedFechaFin" [(ngModel)]="selectedFechaFin" [locale]="lang" dateFormat="dd/mm/yy"
        class="st-calendar" (onSelect)="refrescarDetalleReporte()"></p-calendar>
    </div>
</div>

<div class="ui-g-12 content contenedor">

    <p-table #dt tableStyleClass="width-bultos" [columns]="colsDetalleSms" [rows]="10" [autoLayout]="true" [value]="consumoDetalleSms"
     [paginator]="true" styleClass="st-data-table" [tableStyle]="{'table-layout':'auto'}" [(first)]="first">
      <ng-template pTemplate="header" let-columns >
        
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
            </tr>

            <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" >
                            <p-multiSelect *ngSwitchCase="'aplicacionToLongConstant'" 
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterAplicacion"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}"
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1" 
                            ></p-multiSelect>
                            <p-calendar #calendar *ngSwitchCase="'fechaString'" [locale]="lang"
                            dateFormat="dd/mm/yy" readonlyInput="readonlyInput" [(ngModel)]="fechaValue"
                            (onSelect)="dt.filter(calendar.formatDate($event,'yy-mm-dd'), 'fechaString' , col.filterMatchMode)"
                            (onClearClick)="limpiarCalendar()" resetFilterOnHide="true" showButtonBar="true"
                            class="st-calendar"></p-calendar>
                            <p-calendar #calendar *ngSwitchCase="'hora'" [locale]="lang"
                            dateFormat="HH:mm" readonlyInput="readonlyInput" [(ngModel)]="horaValue" [timeOnly]="true"
                            (onSelect)="dt.filter(calendar.formatDate($event,'HH:mm'), 'hora' , col.filterMatchMode)"
                            (onClearClick)="limpiarCalendar()" resetFilterOnHide="true" showButtonBar="true"
                            class="st-calendar"></p-calendar>
                            <p-multiSelect *ngSwitchCase="'viajeCodigo'"
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterViajeCodigo"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}" 
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1"
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'nroTarea'"
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterNroTarea"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}" 
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1"
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'estadoTareaToString'" 
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterEstadoTarea"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}"
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1" 
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'estadoSMSToString'" 
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterEstadoSms"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}"
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1" 
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'clienteCodigo'"
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterClienteCodigo"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}" 
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1"
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'clienteNombre'"
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterClienteNombre"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}" 
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1"
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'tipoDestinatarioToString'" 
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterTipoDestinatario"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}"
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1" 
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'destinatarioNombre'"
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterDestinatarioNombre"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}" 
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1"
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'telefono'"
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterTelefono"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}" 
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1"
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'correo'"
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterCorreo"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}" 
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1"
                            ></p-multiSelect>
                            <p-multiSelect *ngSwitchCase="'origenEnvioSms'"
                            (onChange)="dt.filter($event.value,col.field,'in')"
                            [options]="listFilterOrigenSms"
                            defaultLabel="{{'COMMON.boton.todos' | translate}}" 
                            resetFilterOnHide="true" 
                            maxSelectedLabels="1"
                            ></p-multiSelect>
                        </th>
            </tr>
        
      </ng-template>
    
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">           
          <tr>                   
              <td>{{rowData.aplicacionToLongConstant}}</td>
              <td>{{rowData.fecha | date:'dd/MM/yyyy'}}</td>              
              <td>{{rowData.hora}}</td>
              <td>{{rowData.viajeCodigo}}</td>
              <td>{{rowData.nroTarea}}</td>
              <td>{{rowData.estadoTareaToString}}</td>
              <td>{{rowData.estadoSMSToString}}</td>
              <td>{{rowData.clienteCodigo}}</td>
              <td>{{rowData.clienteNombre}}</td>
              <td>{{rowData.tipoDestinatarioToString}}</td>
              <td>{{rowData.destinatarioNombre}}</td>
              <td>{{rowData.cantSMS}}</td>  
              <td>{{rowData.telefono}}</td>
              <td>{{rowData.correo}}</td>    
              <td>{{rowData.origenEnvioSms}}</td>               
          </tr>
      </ng-template>
    
    </p-table>

    <div class="ui-g-12 center-all">        
        <button id="btnRegresar" class="btn btn-danger mr-1" routerLink="/seguridad/reporte-consumo-sms" *ngIf="detalleReporteBoolean">
            <span> <i class="fa fa-arrow-left"></i> {{'COMMON.boton.regresar' | translate}} </span>            
        </button>  

        <button type="button" class="btn btn-primary " (click)="exportarComoXLS()">
            <span><i class="fa fa-fw fa-download"></i>{{'COMMON.boton.exportar' | translate}}</span>
        </button>
       
    </div>

</div>

<p-toast position="top-right"></p-toast>
<app-loading-animation></app-loading-animation>
