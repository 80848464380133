import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DescargaRoutingModule } from './descarga-routing.module';
import { DescargaComponent } from './descarga.component';
import { CalendarModule, MultiSelectModule } from 'primeng';

import { SharedModule } from './../shared.module';
import { SeccionModule } from './../../components/seccion.module';


@NgModule({
  declarations: [DescargaComponent],
  imports: [
    CommonModule,
    DescargaRoutingModule,
    MultiSelectModule,
     CalendarModule,
     SharedModule, SeccionModule,
  ],
  exports: [DescargaComponent]
})
export class DescargaModule { }
