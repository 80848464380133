import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operator/map';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

/* Propios */
import { IResultado, Cookies } from '../commons';
import { C } from '../commons/index';
import { HeaderDTO } from '../dto/header.dto';


@Injectable()
export class HttpClient2 {
  //Variables Genericas
  token: string;

  constructor(private translate: TranslateService,
    private httpClient: HttpClient) { }


  /****************
   * METODOS BASICOS
   ****************/
  get(url: string, httpOptions?: {}): Observable<any> {
    return this.httpClient.get(C.BASE + url, httpOptions);
  }

  getNotificacionWeb(url: string, httpOptions?: {}): Observable<any> {
    return this.httpClient.get(C.BASE_CHAT_URL + url, httpOptions);
  }

  post(url: string, data: any, httpOptions?: {}): Observable<any> {
    return this.httpClient.post(C.BASE + url, data, httpOptions);
  }

  postFile(url: string, data: FormData, httpOptions?: {}): Observable<any> {
    return this.httpClient.post(C.BASE + url , data, httpOptions);
  }

  patch(url: string, data: any, httpOptions?: {}): Observable<any> {
    return this.httpClient.patch(C.BASE + url, data, httpOptions);
  }

  put(url: string, data: any, httpOptions?: {}): Observable<any> {
    return this.httpClient.put(C.BASE + url, data, httpOptions);
  }

  delete(url: string, httpOptions?: {}): Observable<any> {
    return this.httpClient.delete(C.BASE + url, httpOptions);
  }


  /****************
   * METODOS FULL
   ****************/
  listar(url: string, httpOptions?: Object): Observable<IResultado> {
    return this.get(url, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  listarNotificacionWeb(url: string, httpOptions?: Object): Observable<IResultado> {
    return this.getNotificacionWeb(url, this.addGeneralOptions(httpOptions, false, true))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  obtener(url: string, httpOptions?: Object): Observable<IResultado> {
    return this.get(url, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  consultar(url: string, data: any, httpOptions?: Object): Observable<IResultado> {
    return this.post(url, data, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  consultarSinCurrentUser(url: string, data: any, httpOptions?: Object): Observable<IResultado> {
    return this.post(url, data, this.addGeneralOptionsSinUser(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  
  registrar(url: string, data: any, httpOptions?: Object): Observable<IResultado> {
    return this.post(url, data, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  registrarFile(url: string, data: FormData, httpOptions?: Object): Observable<IResultado> {
    return this.post(url, data, this.addGeneralOptions(httpOptions, true))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  modificarParcial(url: string, data: any, httpOptions?: Object): Observable<IResultado> {
    return this.patch(url, data, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  modificar(url: string, data: any, httpOptions?: Object): Observable<IResultado> {
    return this.put(url, data, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  modificarViajeEmpresaTransporte(url: string, data: any, httpOptions?: Object): Observable<IResultado> {
    return this.put(url, data, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  inactivar(url: string, httpOptions?: Object): Observable<IResultado> {
    return this.patch(url, {}, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }
  activar(url: string, httpOptions?: Object): Observable<IResultado> {
    return this.patch(url, {}, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  eliminar(url: string, httpOptions?: Object): Observable<IResultado> {
    return this.delete(url, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });
  }

  registrarImportacion(url: string, data: any, httpOptions?: Object): Observable<IResultado> {
    return this.post(url, data, this.addGeneralOptions(httpOptions))
      .map((response: HttpResponse<IResultado>) => {
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error.error);
      });

  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    let language = Cookies.getCookie("LANGUAGE");

    let headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    if(currentUser != null){
      headers = headers.append('X-AUTH-TOKEN', 'Bearer ' + currentUser.token);
    }
    if(language != null){
      headers = headers.append('Language', language);
    }

    if(httpOptions === undefined){
      httpOptions = {};
    }

    httpOptions['headers'] = headers;
    httpOptions['observe'] = 'response';
    return httpOptions['headers'];
  }


  /*******************
   * METODOS PRIVADOS
   *******************/
  private addGeneralOptions(httpOptions?: Object, noUseContent?: boolean, noAuth?: boolean ): Object {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let language = Cookies.getCookie("LANGUAGE");
    const app = C.APP;

    let headers = new HttpHeaders();
    if(noUseContent){
      headers.append('Content-Type', 'application/json');
    } else {
      headers.append('Content-Type', 'multipart/form-data');
    }    
    headers.append('Accept', 'application/json');
    if (!noAuth && currentUser != null) {
      headers = headers.append('X-AUTH-TOKEN', 'Bearer ' + currentUser.token);
    }
    if (language != null) {
      headers = headers.append('Language', language);
    }

    const headerDTO = new HeaderDTO();
    headerDTO.aplicacion = app;
    headerDTO.usuarioId = currentUser ? currentUser.usuarioId : null;

    if (httpOptions != undefined) {
      headerDTO.funcionalidad = httpOptions['funcionalidad'];
      headerDTO.gmt = httpOptions['gmt'];
    }
    
    headers = headers.append('HeadersDTO', JSON.stringify(headerDTO));
    
    if (httpOptions === undefined) {
      httpOptions = {};
    }

    httpOptions['headers'] = headers;
    httpOptions['observe'] = 'response';

    return httpOptions;
  }

  private addGeneralOptionsSinUser(httpOptions?: Object, noUseContent?: boolean, noAuth?: boolean , usuarioId?: number ): Object {
    let token = "739f2617-7043-39ec-8291-413f28da4b23";
    let language = Cookies.getCookie("LANGUAGE");
    const app = C.APP;

    let headers = new HttpHeaders();
    if(noUseContent){
      headers.append('Content-Type', 'application/json');
    } else {
      headers.append('Content-Type', 'multipart/form-data');
    }    
    headers.append('Accept', 'application/json');
    if (!noAuth && token != null) {
      headers = headers.append('X-AUTH-TOKEN', 'Bearer ' + token);
    }
    if (language != null) {
      headers = headers.append('Language', language);
    }

    const headerDTO = new HeaderDTO();
    headerDTO.aplicacion = app;
    headerDTO.usuarioId = usuarioId ? usuarioId : null;

    if (httpOptions != undefined) {
      headerDTO.funcionalidad = httpOptions['funcionalidad'];
    }
    
    headers = headers.append('HeadersDTO', JSON.stringify(headerDTO));
    
    if (httpOptions === undefined) {
      httpOptions = {};
    }

    httpOptions['headers'] = headers;
    httpOptions['observe'] = 'response';

    return httpOptions;
  }

}
