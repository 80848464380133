import { Compania } from './compania';

export class TipoIncidente {
    //PK
    tipoIncidenteId : number;

    //Atributos
    nombre : string;
    estado : number;    
    codigo : string;
    editable : number;    

    //Relaciones    
    compania : Compania;
    
    //Otras Variables

    //Metodos
    static clonar(objeto : any) : TipoIncidente{
        let tipoIncidente : TipoIncidente = new TipoIncidente();
        //Propios
        tipoIncidente.tipoIncidenteId = objeto.tipoIncidenteId;
        tipoIncidente.nombre = objeto.nombre;
        tipoIncidente.estado = objeto.estado;
        tipoIncidente.codigo = objeto.codigo;
        tipoIncidente.editable = objeto.editable;
                        
        //Relaciones        
        if(tipoIncidente.compania != null){
            tipoIncidente.compania = new Compania();
            tipoIncidente.compania.copiar(objeto.compania);
        }

        
        return tipoIncidente;
    }
    copiar(objeto : any){
        //Propios
        this.tipoIncidenteId = objeto.tipoIncidenteId;
        this.nombre = objeto.nombre;
        this.estado = objeto.estado;
        this.codigo = objeto.codigo;
        this.editable = objeto.editable;

        //Relaciones        
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
    }
    
}