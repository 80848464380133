import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Cookies } from '../commons';

@Pipe({ name: 'duracion' })
export class DuracionPipe implements PipeTransform {
  
  transform(value: any, ...args: string[]): string {
    let resultado: string;
    let meses_text: string, dias_text: string, horas_text: string, minutos_text: string;
    if (typeof args === 'undefined' || args.length == 0) {
      throw new Error('DurationPipe: missing required time unit argument');
    }
    if (typeof args[1] !== 'undefined' && args[1] == "long") {
      let _language: string = Cookies.getCookie("LANGUAGE");
      meses_text = _language === "es" ? " meses " : " months ";
      dias_text = _language === "es" ? " días " : " days ";
      horas_text = _language === "es" ? " horas " : " hours ";
      minutos_text = _language === "es" ? " minutos" : " minutes ";
    } else {
      meses_text = "M ";
      dias_text = "d ";
      horas_text = "h ";
      minutos_text = "m";
    }
    //args[0] {seconds | minutes | hours}
    //args[1] {long | short}    
    let duracion: moment.Duration = moment.duration(value, args[0] as moment.unitOfTime.DurationConstructor);
    if (duracion.months() > 0) {
      resultado = duracion.months() + meses_text +
        duracion.days() + dias_text +
        duracion.hours() + horas_text +
        duracion.minutes() + minutos_text;
    } else if (duracion.days() > 0) {
      resultado = duracion.days() + dias_text +
        duracion.hours() + horas_text +
        duracion.minutes() + minutos_text;
    } else if (duracion.hours() > 0) {
      resultado = " " + duracion.hours() + horas_text + duracion.minutes() + minutos_text;
    } else {
      resultado = duracion.minutes() + minutos_text;
    }

    return resultado;
  }
}