import { Injectable } from '@angular/core';
import { SolicitudAutorizacionDTO } from '@shared/dto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SolicitudAutorizacionProvider {
  
 //Variables a compartir entre componentes
 private solicitudAutorizaciones = new BehaviorSubject<Array<SolicitudAutorizacionDTO>>([]);

 currentSolicitudAutorizaciones = this.solicitudAutorizaciones.asObservable();
  constructor() { }

  changeSolicitudeAutorizacione(solicitudAutorizaciones : Array<SolicitudAutorizacionDTO>) {
    this.solicitudAutorizaciones.next(solicitudAutorizaciones);
  }

  removeSolicitudAutorizacione(notificacionId : number){
   const solicitudes: any[] = this.solicitudAutorizaciones.getValue();
   this.solicitudAutorizaciones.next(solicitudes.filter(n2 => n2.notificacionId != notificacionId));
  }
}
