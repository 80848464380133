import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Usuario, Perfil } from '../models';
import { IResultado } from '../index';
import { Observable } from 'rxjs/Observable';
import { ImportarUsuarioDTO } from '../dto/importarUsuario.dto';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UsuarioService {
  currentUser: any;
  ciaId: string;
  esEmpresaTransporteAdministrador:any;
  constructor(private http: HttpClient2) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/" + this.currentUser?.compania.companiaId;
    this.esEmpresaTransporteAdministrador = this.currentUser?.esEmpresaTransporteAdministrador;
  }

  consultar(data, companiaId?: string): Observable<Array<Usuario>> {
    if (companiaId == undefined) companiaId = this.ciaId;
    const request = this.esEmpresaTransporteAdministrador? {
      ...data,
      empresaTransporteId:this.currentUser.empresaTransporte.empresaTransporteId
    }: {...data};
    return this.http.consultar(companiaId + "/usuarios/consultar", request).map(
      (data: IResultado) => {
        let list = Array<Usuario>();
        let obj;
        if (data) {
          for (const d of data.resultado) {
            obj = new Usuario();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      });
  }

  obtener(id: number): Observable<Usuario> {
    return this.http.obtener(this.ciaId + "/usuarios/" + id).map(
      (data: IResultado) => {
        let obj = new Usuario();
        if (data) {
          obj.copiar(data.resultado);
        }
        return obj;
      });
  }
  obtenerPerfiles(id: number): Observable<Array<Perfil>> {
    return this.http.obtener(this.ciaId + "/usuarios/" + id + "/perfiles").map(
      (data: IResultado) => {
        let list = Array<Perfil>();
        if (data) {
          let obj;
          for (const d of data.resultado) {
            obj = new Perfil();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      });
  }

  /*resetearContrasena(usuario: Usuario,tipoCorreo: number, companiaId?: string, funcionalidad?: String) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar("/" + companiaId + "/usuarios/resetear-contrasena/"+tipoCorreo, usuario, { funcionalidad });
  }*/

  envioCorreoPorReseteoOlvidoClave(usuario: Usuario,tipoCorreo: number, companiaId?: string, funcionalidad?: String) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar("/" + companiaId + "/usuarios/envio-correo-reseteo-olvido-clave/"+tipoCorreo, usuario, { funcionalidad });
  }

  obtenerUsuarioActual(): any {
    return JSON.parse(localStorage.getItem("currentUser"));
  }
  
  registrar(usuario: Usuario, companiaId?: string, funcionalidad?: String) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar(companiaId + "/usuarios", usuario, { funcionalidad });
  }

  modificar(usuario: Usuario, companiaId?: string, funcionalidad?: String) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.modificar(companiaId + "/usuarios", usuario, { funcionalidad });
  }

  eliminar(usuarioId: number, funcionalidad?: String) {
    return this.http.eliminar(this.ciaId + "/usuarios/" + usuarioId, { funcionalidad });
  }

  registrarImportacion(usuarios: Array<ImportarUsuarioDTO>, companiaId?: string, funcionalidad?: String) {
    if (companiaId == undefined) companiaId = this.ciaId;
    return this.http.registrar(companiaId + "/usuarios/registrar-importacion", usuarios, { funcionalidad });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }
}