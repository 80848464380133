export class ControlCobranzaDTO{
    clienteFinalId: number;
    nombreClienteFinal: string;
    sedeId: number;
    nombreSede: string;
    nombreCanal: string;
    cantPorCobrar : number; 
    cantCobrado : number; 
    importePendiente: number;

    constructor(){}
    
    copiar(objeto: any){
        this.clienteFinalId= objeto.clienteFinalId;
        this.nombreClienteFinal= objeto.nombreClienteFinal;
        this.sedeId= objeto.sedeId;
        this.nombreSede= objeto.nombreSede;
        this.nombreCanal= objeto.nombreCanal;
        this.cantPorCobrar= objeto.porCobrar;
        this.cantCobrado= objeto.cobrado;
        this.importePendiente= objeto.importePendiente.toFixed(2);
    }

    instanciar() : void{
    }

}