import { ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { DateTime } from 'luxon';

import { Chart } from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { C, dateAndHourToString, dateToString} from '@shared/commons';
import { TranslateService } from '@ngx-translate/core';
import { UnidadTransporteSensor } from '@shared/models/unidadTransporteSensor';
import { ResultadoDTO } from 'src/app/entrega/resources/dto/resultado.dto';

@Component({
  selector: 'app-grafico-sensores',
  templateUrl: './grafico-sensores.component.html',
  styleUrls: ['./grafico-sensores.component.scss']
})
export class GraficoSensoresComponent {

  data : Array<any> = [];

  config : any;

  average : any; 
  SENSORS : Map <string, any>;
   
  umbrales: Array<UnidadTransporteSensor>;
  limiteUmbrales = [];
  configSensores;
  listSensores;
  mostrarUmbral: boolean = false;
 
  width : number;

  @ViewChild("chartGraficoSensores")
  canvasElement : ElementRef; 

  @ViewChild("chartPDFTemperatura")
  chartPDFTemperatura : ElementRef; 
  @ViewChild("chartPDFHumedad")
  chartPDFHumedad : ElementRef;

  ocultar = false;

  lineChart : any;

  rangoFechas : string = "";

  constructor(private translate : TranslateService,
              private rendered2 : Renderer2) { 
    this.average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

    this.SENSORS= new Map([
      ['T', {"descripcion": this.translate.instant("MODEL.sensores.tipo-sensor.temperatura") + " ("+ C.UNIDADES_MEDIDA.TEMPERATURA + ")" , "color": "#FF475D"}],
      ['H', {"descripcion": this.translate.instant("MODEL.sensores.tipo-sensor.humedad") + " ("+C.UNIDADES_MEDIDA.HUMEDAD + ")", "color": "#0D69BD"}],
      ['M', {"descripcion": this.translate.instant("MODEL.sensores.tipo-sensor.magnetismo") +C.UNIDADES_MEDIDA.MAGNETISMO , "color": "#414E67"}],
      ['A', {"descripcion": this.translate.instant("MODEL.sensores.tipo-sensor.aceleracion") + " ("+C.UNIDADES_MEDIDA.ACELERACION + ")", "color": "#FF621F"}],
      ['L', {"descripcion": this.translate.instant("MODEL.sensores.tipo-sensor.luz") + " ("+C.UNIDADES_MEDIDA.LUZ + ")", "color": "#FEB13D"}]
    ]);

  }

  cargarDatos(confSensores, dataSensores, umbrales){

    this.configSensores = confSensores;
    this.listSensores = dataSensores;
   
    let idsSensoresConfigurados = confSensores.map(e => e.sensorId);

    this.config = this.getConfig();
    this.configurarUmbrales(umbrales);

    if(dataSensores.length > 0){     

      this.data = dataSensores;//this.getData();

      this.data = this.parsearData(dataSensores);
  
      this.data.filter( d => idsSensoresConfigurados.includes(d.MultireadingTypeId));
  
      let fechas = this.obtenerFechaMinMax(this.data);
  
      let del = this.translate.instant("WEB.control.estados-viaje.detalle.sensores.del")
      let hasta = this.translate.instant("WEB.control.estados-viaje.detalle.sensores.hasta")
      this.rangoFechas = del + dateToString(fechas.min, C.FORMATOFECHA.FECHA_LONG) + hasta + dateToString(fechas.max, C.FORMATOFECHA.FECHA_LONG);
      
      let difminutos = this.obtenerDiferenciaFechasEnMinutos(fechas.min, fechas.max);
      
      //Realizamos la validación correspondiente para saber con qué periodo de horas
      //se va a tener que agrupar los datos
      let periodo = this.obtenerPeriodoEnMinutos(difminutos);

      this.config.data = this.obtenerData(this.data, periodo, fechas, difminutos);

      this.dibujarCanvasToExport(periodo, fechas, difminutos, umbrales);
      
      
    }

    //Si el gráfico ha sido creado previamente, la limpiamos
    if (this.lineChart) {
      this.lineChart.clear();
      this.lineChart.destroy();
    }
    this.lineChart = new Chart(this.canvasElement.nativeElement, this.config);
  }

 

  dibujarCanvasToExport(periodo, fechas, difminutos, umbrales: Array<UnidadTransporteSensor>){    

    let dataHumedad = this.data.filter(d => d.MultireadingTypeId == "H");
    let dataTemperatura = this.data.filter(d => d.MultireadingTypeId == "T");
    let config : any; 
    let configT : any;
    config = this.getConfigH();
    config.data = this.obtenerData(dataHumedad, periodo, fechas, difminutos);  

    config.data.labels = config.data.labels.map(fecha => {
      if(fecha){
      return fecha.substring(11,19)   
      }
    });

    this.setUmbralToExport(umbrales.filter(umbral => umbral.sensorId == "H"), config);
    new Chart(this.chartPDFHumedad.nativeElement, config);  

    configT = this.getConfigT();   
    configT.data = this.obtenerData(dataTemperatura, periodo, fechas, difminutos); 
    configT.data.labels = configT.data.labels.map(fecha => {
      if(fecha){
      return fecha.substring(11,19)   
      }
    });
    this.setUmbralToExport(umbrales.filter(umbral => umbral.sensorId == "T"), configT);   

    new Chart(this.chartPDFTemperatura.nativeElement, configT);    
        
  }

  setUmbralToExport(umbralesUnidadTransporte : Array<UnidadTransporteSensor>, config){
    let confCompania = JSON.parse(localStorage.getItem("compania_configuraciones"));
    
    let umbralesGeneral = confCompania.filter( e => e.campo === C.CONFIGURACION.SENSORES.ACELERACION.CONF_SENS_ACELERACION || e.campo === C.CONFIGURACION.SENSORES.HUMEDAD.CONF_SENS_HUMEDAD 
                                       || e.campo === C.CONFIGURACION.SENSORES.LUZ.CONF_SENS_LUZ || e.campo === C.CONFIGURACION.SENSORES.TEMPERATURA.CONF_SENS_TEMPERATURA)
                                       .map(obj => {                                                                                
                                         return this.getNuevoUmbralObj(obj);
                                       });
                                      
    // obtenemos los umbrales activos de unidadTransporte
    // si umbral general esta activo se actualiza limites de umbrales
    let umbrales = umbralesUnidadTransporte.filter( us => us.estado === true)
                                  .map(o => {
                                    let uts = o;
                                    if(o.umbralGeneral === true){
                                      let obj =  umbralesGeneral.find( ug => ug.sensorId === o.sensorId);
                                      uts.umbralMin = obj.valor.split("|")[0];
                                      uts.umbralMax = obj.valor.split("|")[1];                                 
                                    } 
                                    return uts;
                                  });

    //damos un ancho al legend de umbrales
    // this.width = umbrales.length * 100;

    let limiteUmbrales = [];
    
    umbrales.forEach(umbral => {
        if(umbral.estado){
          // limiteUmbrales.push({mode:'horizontal', type: 'line', scaleID: 'y-axis-0', borderWidth: 1, borderDash:[5], borderDashOffset: 1, borderColor: this.SENSORS.get(umbral.sensorId).color, value: umbral.umbralMax});
          // limiteUmbrales.push({mode:'horizontal', type: 'line', scaleID: 'y-axis-0', borderWidth: 1, borderDash:[5], borderDashOffset: 1, borderColor: this.SENSORS.get(umbral.sensorId).color, value: umbral.umbralMin});
          limiteUmbrales.push({mode:'horizontal', type: 'line', scaleID: 'y-axis-0', borderWidth: 1, borderDash:[7], borderDashOffset: 1, borderColor: '#f06f21', value: umbral.umbralMax});
          limiteUmbrales.push({mode:'horizontal', type: 'line', scaleID: 'y-axis-0', borderWidth: 1, borderDash:[2], borderDashOffset: 1, borderColor: '#FE0000', value: umbral.umbralMin});
        }
    })

    config.options.annotation.annotations =  limiteUmbrales; 
  }

  ocultarGraficosSoloParaExportar(){
    this.ocultar = false;
    setTimeout(() => {
        this.ocultar= true;
    }, 500);
  }

  obtenerPeriodoEnMinutos(difhoras){
    if(difhoras <= 60) return 1;
    if(difhoras <= 240) return 5;
    if(difhoras <= 480) return 10;
    if(difhoras <= 720) return 15;
    if(difhoras <= 960) return 20;
    if(difhoras <= 1200) return 25;
    if(difhoras <= 1440) return 30;
    if(difhoras <= 10080) return 240;
    return 1440;
  }

  obtenerFechaMinMax(data){
    return {
      "min": new Date(Math.min(...data.map(e => new Date(e.fechaHora)))),
      "max": new Date(Math.max(...data.map(e => new Date(e.fechaHora))))
    }
  }

  obtenerDiferenciaFechasEnMinutos(min, max){
    const diffTime = Math.abs(max - min);
    return Math.ceil(diffTime / (1000 * 60)); 
  }


  parsearData(dataSensores){

    let newList = [];

    for(let data of dataSensores){
            
      if(data['A']|| data['A'] === 0 ){
        let obj : any = {};
        obj.fechaHora = dateAndHourToString(DateTime.fromFormat(data['fechaHora'], 'dd/MM/y HH:mm'), C.FORMATOFECHA.BD_LONG);

        obj.MultireadingTypeId = 'A';
        obj.Value = Number(data['A']);
        newList.push(obj);
      }

      if(data['H'] || data['H'] === 0){
        let obj : any = {};
        obj.fechaHora = dateAndHourToString(DateTime.fromFormat(data['fechaHora'], 'dd/MM/y HH:mm'), C.FORMATOFECHA.BD_LONG);

        obj.MultireadingTypeId = 'H';
        obj.Value = Number(data['H']);
        newList.push(obj);
      }

      if(data['M']|| data['M'] === 0){
        let obj : any = {};
        obj.fechaHora = dateAndHourToString(DateTime.fromFormat(data['fechaHora'], 'dd/MM/y HH:mm'), C.FORMATOFECHA.BD_LONG);

        obj.MultireadingTypeId = 'M';
        obj.Value = Number(data['M']);
        newList.push(obj);
      }

      if(data['L'] || data['L'] === 0){
        let obj : any = {};
        obj.fechaHora = dateAndHourToString(DateTime.fromFormat(data['fechaHora'], 'dd/MM/y HH:mm'), C.FORMATOFECHA.BD_LONG);

        obj.MultireadingTypeId = 'L';
        obj.Value = Number(data['L']);
        newList.push(obj);
      }

      if(data['T'] || data['T'] === 0){
        let obj : any = {};
        obj.fechaHora = dateAndHourToString(DateTime.fromFormat(data['fechaHora'], 'dd/MM/y HH:mm'), C.FORMATOFECHA.BD_LONG);

        obj.MultireadingTypeId = 'T';
        obj.Value = Number(data['T']);
        newList.push(obj);
      }
    }

    return newList;
  }
/*
periodo = Un valor de cada cuantas horas se agrupa la informacion
08,09,10,11, 12,13,14,15, 16 -> Horas
las 08 horas tomaria desde 08 hasta las 11 horas -> si el periodo fuera de 4 horas
las 12 horas tomaria desde 12 hasta las 15 horas -> si el periodo fuera de 4 horas
*/
obtenerData(data, periodo, fechas, difminutos){
	let lds;
	let result : any = { labels : null , datasets : null};

	lds = this.obtenerLabelsDataSetsHoras(fechas, data, periodo, difminutos);

	result.labels = lds.labels;
	result.datasets = [];
	for (const key in lds.data){
		let val = lds.data[key];
		let obj = {
			backgroundColor: this.SENSORS.get(key).color,
			borderColor: this.SENSORS.get(key).color,
			data: val,
      label: this.SENSORS.get(key).descripcion,
      fill: false,
      tension: 0.1
		}
		result.datasets.push(obj);
	}

	return result;
}


obtenerLabelsDataSetsHoras(fechas, data, periodo, difminutos){
	let result = {labels:[], data:[]};
	let fecha_hora_min = DateTime.fromJSDate(fechas.min);
  let formatoFechaLabel = periodo == 24 ? "y-MM-dd" : "y-MM-dd HH:mm";

	// Llenamos los labels

  for (let i = 0; i <= difminutos; i+=periodo) {
    let new_date = fecha_hora_min.plus({minute: i});
    result.labels.push(new_date.toFormat(formatoFechaLabel));	
  }  

	// Generar Lista por Tipo
	for (let v of this.SENSORS.keys()) {
		let sensor = data.filter(type => type.MultireadingTypeId == v);
		if (sensor.length>0) result.data[v] = sensor;
	}

	// Separamos por Periodo en Minutos
	let data2 = [];
	for (const key in result.data) {
		let list = [];

		let init_fecha_hora = DateTime.fromFormat(result.data[key][0].fechaHora, 'y-MM-dd HH:mm:ss');
		let init_fecha_hora_format = init_fecha_hora.toFormat(formatoFechaLabel);
		let max_fecha_hora = init_fecha_hora.plus({minute: periodo}).set({seconds:0});

		for (const item of result.data[key]) {
			let fecha_hora = DateTime.fromFormat(item.fechaHora, 'y-MM-dd HH:mm:ss');
			let fecha_hora_format = fecha_hora.toFormat(formatoFechaLabel);
			
			if(fecha_hora < max_fecha_hora){
				if(typeof(list[init_fecha_hora_format]) == "undefined") list[init_fecha_hora_format] = [];
				list[init_fecha_hora_format].push(item.Value);
			}else{
				init_fecha_hora = fecha_hora;
				init_fecha_hora_format = fecha_hora.toFormat(formatoFechaLabel);
				max_fecha_hora = init_fecha_hora.plus({minute: periodo}).set({seconds:0});

				if(typeof(list[fecha_hora_format]) == "undefined") list[fecha_hora_format] = [];
				list[fecha_hora_format].push(item.Value);
			}
		}
		data2[key] = list;
	}
	result.data = data2;

	// Sacamos los Promedios
	for (const key1 in result.data) {
		let val1 = result.data[key1];
		let list = [];
		for (const key2 in val1) {
			list[key2] = Math.round((this.average(val1[key2]) + Number.EPSILON) * 100) / 100;
		}
		result.data[key1] = list;
    
  }

	// Completamos los Horas Faltantes
	for (const key1 in result.data) {
		let length_labels = result.labels.length;
		var arrKeys = Object.keys(result.data[key1]);
		for (let i = 0; i<length_labels; i++){
			if(!arrKeys.includes(result.labels[i])){
				result.data[key1][result.labels[i]] = null;
			}
		}
		result.data[key1] = this.convertirArrayNormal(result.data[key1]);
	}

	return result;
}


convertirArrayNormal(list){
	let newList = [];

	for(const key in list){
		newList.push({'key':key, 'value':list[key]});
	}

	newList = newList.sort(this.compareValues('key'));

	return newList.map(e => e.value);
}


compareValues(key, order = 'asc') {
	return function innerSort(a, b) {
	  if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
		// property doesn't exist on either object
		return 0;
	  }
  
	  const varA = (typeof a[key] === 'string')
		? a[key].toUpperCase() : a[key];
	  const varB = (typeof b[key] === 'string')
		? b[key].toUpperCase() : b[key];
  
	  let comparison = 0;
	  if (varA > varB) {
		comparison = 1;
	  } else if (varA < varB) {
		comparison = -1;
	  }
	  return (
		(order === 'desc') ? (comparison * -1) : comparison
	  );
	};
}

getConfig(){
  let config = {
    type: 'line',
    plugins:[ChartAnnotation],
    options: {
      responsive: true,
      annotation:{
        annotations:[{
          type: 'line',
          borderWidth: 1,
          borderDash:[5],
          borderDashOffset: 1,
          borderColor: '#FE0000',
          value: 25
        }]
      },
      spanGaps: true,
      legend: {
        display: true,
        align: 'end'
      },
      title: {
        display: false
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        xAxes: [{
            display: true
        }],
        yAxes: [{
          display: true
        }]
      }
    }
  };

  return config;
}

  getConfigH(){
    let config = {
      type: 'line',
      plugins:[ChartAnnotation],
      options: {
        responsive: true,
        annotation:{
          annotations:[{
            type: 'line',
            borderWidth: 1,
            borderDash:[5],
            borderDashOffset: 1,
            borderColor: '#FE0000',
            value: 25
          }]
        },
        spanGaps: true,
        legend: {
          display: true,
          align: 'end'
        },
        title: {
          display: true,
          text: 'Humedad',
          fontColor: 'black',
          fontSize:18
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Time'
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'HUMEDAD (%)'
            }
          }]
        }
      }
    };

    return config;
  }
  
  getConfigT(){
    let config = {
      type: 'line',
      plugins:[ChartAnnotation],
      options: {
        responsive: true,
        annotation:{
          annotations:[{
            type: 'line',
            borderWidth: 1,
            borderDash:[5],
            borderDashOffset: 1,
            borderColor: '#FE0000',
            value: 25
          }]
        },
        spanGaps: true,
        legend: {
          display: true,
          align: 'end',
          fontColor: 'black',
          hidden: true
        },
        title: {
          display: true,
          text: 'Temperatura',
          fontColor: 'black',
          fontSize:22
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Time'
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'TEMPERATURA (C°)',
              fontSize:17
            }
          }]
        }
      }
    };
    

    return config;
  }

  configurarUmbrales(umbralesUnidadTransporte: Array<UnidadTransporteSensor>){
    let confCompania = JSON.parse(localStorage.getItem("compania_configuraciones"));
    
    let umbralesGeneral = confCompania.filter( e => e.campo === C.CONFIGURACION.SENSORES.ACELERACION.CONF_SENS_ACELERACION || e.campo === C.CONFIGURACION.SENSORES.HUMEDAD.CONF_SENS_HUMEDAD 
                                       || e.campo === C.CONFIGURACION.SENSORES.LUZ.CONF_SENS_LUZ || e.campo === C.CONFIGURACION.SENSORES.TEMPERATURA.CONF_SENS_TEMPERATURA)
                                       .map(obj => {                                                                                
                                         return this.getNuevoUmbralObj(obj);
                                       });
                                      
    // obtenemos los umbrales activos de unidadTransporte
    // si umbral general esta activo se actualiza limites de umbrales
    this.umbrales = umbralesUnidadTransporte.filter( us => us.estado === true)
                                  .map(o => {
                                    let uts = o;
                                    if(o.umbralGeneral === true){
                                      let obj =  umbralesGeneral.find( ug => ug.sensorId === o.sensorId);
                                      uts.umbralMin = obj.valor.split("|")[0];
                                      uts.umbralMax = obj.valor.split("|")[1];                                 
                                    } 
                                    return uts;
                                  });

    //damos un ancho al legend de umbrales
    this.width = this.umbrales.length * 100;

    this.limiteUmbrales = [];
    
    this.umbrales.forEach(umbral => {
        if(umbral.estado){
          this.limiteUmbrales.push({mode:'horizontal', type: 'line', scaleID: 'y-axis-0', borderWidth: 1, borderDash:[5], borderDashOffset: 1, borderColor: this.SENSORS.get(umbral.sensorId).color, value: umbral.umbralMax});
          this.limiteUmbrales.push({mode:'horizontal', type: 'line', scaleID: 'y-axis-0', borderWidth: 1, borderDash:[5], borderDashOffset: 1, borderColor: this.SENSORS.get(umbral.sensorId).color, value: umbral.umbralMin});
        }
    })

    this.config.options.annotation.annotations =  this.mostrarUmbral ? this.limiteUmbrales : [];
  }

  onChangeCheckUmbrales(event){
    this.mostrarUmbral = event;

    this.cargarDatos(this.configSensores, this.listSensores, this.umbrales);    
  }

  getNuevoUmbralObj(obj){
    let nuevoObj = {};
    if(obj.campo === C.CONFIGURACION.SENSORES.ACELERACION.CONF_SENS_ACELERACION){
      nuevoObj = {  ...obj, sensorId: C.SENSOR.ACELERACION }
    }
    if(obj.campo === C.CONFIGURACION.SENSORES.HUMEDAD.CONF_SENS_HUMEDAD){
      nuevoObj = {  ...obj, sensorId: C.SENSOR.HUMEDAD }
    }
    if(obj.campo === C.CONFIGURACION.SENSORES.LUZ.CONF_SENS_LUZ){
      nuevoObj = {  ...obj, sensorId: C.SENSOR.LUZ }
    }
    if(obj.campo === C.CONFIGURACION.SENSORES.TEMPERATURA.CONF_SENS_TEMPERATURA){
      nuevoObj = {  ...obj, sensorId: C.SENSOR.TEMPERATURA }
    }

    return nuevoObj;
  }

}
