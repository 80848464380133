import { Compania } from './compania';
import { Distrito } from './distrito';

export class TipoUsuario {
    //PK
    tipoUsuarioId : number;

    //Atributos
    descripcion : string;


    //Metodos
    copiar(objeto : any){
        //Propios
        this.tipoUsuarioId = objeto.tipoUsuarioId;
        this.descripcion = objeto.descripcion;

    }

}
