import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';
import { HttpHeaders } from '@angular/common/http';
import { Cookies } from '../../shared/commons';

//Clases
import { Motivo } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarMotivoDTO } from '../dto/importarMotivo.dto';

@Injectable()
export class MotivoService {
  ciaId : string;
  constructor(private http : HttpClient2) { 
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<Motivo>>{    
    return this.http.listar(this.ciaId+"/motivos").map(
      (data: IResultado) => {
        let list = Array<Motivo>();
        let obj;
        for (const d of data.resultado) {
          obj = new Motivo();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });
  }

  consultar(data) : Observable<Array<Motivo>>{
    return this.http.consultar(this.ciaId+"/motivos/consultar", data).map(
      (data: IResultado) => {
        let list = Array<Motivo>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Motivo();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
    
  }

  obtener(id : number) : Observable<Motivo>{    
    return this.http.obtener(this.ciaId+"/motivos/"+id).map(
      (data: IResultado) => {
        if(data){
          let obj = new Motivo();
          obj.copiar(data.resultado);
          return obj;
        }
    });  
  }

  registrar(motivo : Motivo , funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/motivos", motivo, { funcionalidad });
  }

  modificar(motivo : Motivo, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/motivos", motivo, { funcionalidad });
  }

  eliminar(motivoId : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/motivos/"+motivoId, { funcionalidad });
  }

  registrarImportacion(motivo : Array<ImportarMotivoDTO>,  funcionalidad?: String) : Observable<any>{
    return this.http.registrarImportacion(this.ciaId+"/motivos/registrar-importacion", motivo, { funcionalidad });
  }

  listarPorCompania() : Observable<Array<Motivo>>{    
    return this.http.listar(this.ciaId+"/motivos/listar-compania").map(
      (data: IResultado) => {
        let list = Array<Motivo>();
        if(data){
          let obj;
          for (const d of data.resultado) {
            obj = new Motivo();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

}

