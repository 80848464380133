import { DatePipe } from '@angular/common';
import { SelectItem } from 'primeng/primeng';
import * as moment from 'moment';
import { C } from './constantes';
import { environment } from '@environments/environment';

export function formatDate(value, formato?: string): string {
    if (typeof formato === "undefined") { formato = "yyyy-MM-ddTHH:mm"; }

    let datePipe = new DatePipe("en-US");
    return datePipe.transform(value, formato);

}

export function fechaActual() {
    var now = new Date();
    var dateString = moment(now).format('YYYY-MM-DD');
    return dateString;
}

export function fechaActualDia() {
    var now = new Date();
    var dateString = moment(now).format('DD/MM/YYYY');
    return dateString;
}

export function rangoFechasActual(fecha: Date, tipo: string, respuesta: string) {
    var format = 'YYYY-MM-DD HH:mm:ss';
    var dateMoment = moment(fecha);
    var startDate;
    var endDate;

    switch (tipo) {
        case 'D': startDate = moment([dateMoment.year(), dateMoment.month(), dateMoment.date()]);//Dia
            endDate = moment(startDate).endOf('day');
            break;
        case 'M': startDate = moment([dateMoment.year(), dateMoment.month()]);//Mes
            endDate = moment(startDate).endOf('month');
            break;
        case '2D': startDate = moment(dateMoment).subtract(1, 'days');//Desde dia anterior al actual
            endDate = moment(dateMoment);
            break;
    }

    if (respuesta == 'F') {//Formato
        return { inicio: moment(startDate).format(format), fin: moment(endDate).format(format) };
    } else {//Date
        return { inicio: startDate.toDate(), fin: endDate.toDate() };
    }
}
/**
 * Fechas 
 */
export function stringToDate(date: string, format: string) {
    if (date == "") return null;

    var dateMoment = moment(date, format);
    return dateMoment.toDate();
}
export function dateToString(date: Date, formato?: string) {
    let dateMoment = "";
    if (typeof formato === "undefined") { formato = "yyyy-MM-dd"; }
    if (date != null) {
        let datePipe = new DatePipe("en-US");
        dateMoment = datePipe.transform(date, formato);
    }
    return dateMoment;
}
export function hourToString(date: Date, formato?: string) {
    let dateMoment = "";
    if (typeof formato === "undefined") { formato = "HH:mm:ss"; }
    if (date != null) {
        let datePipe = new DatePipe("en-US");
        dateMoment = datePipe.transform(date, formato);
    }
    return dateMoment;
}
export function dateAndHourToString(date: Date, formato?: string) {
    let dateMoment = "";
    if (typeof formato === "undefined") { formato = "dd/MM/yyyy HH:mm:ss"; }
    if (date != null) {
        let datePipe = new DatePipe("en-US");
        dateMoment = datePipe.transform(date, formato);
    }
    return dateMoment;
}

export function dateAndHourToString2(date: Date, formato?: string) {
    let dateMoment = "";
    if (typeof formato === "undefined") { formato = "yyyy/MM/dd HH:mm:ss"; }
    if (date != null) {
        let datePipe = new DatePipe("en-US");
        dateMoment = datePipe.transform(date, formato);
    }
    return dateMoment;
}
/**
 * Listas  
 */
export function uniqueFilter(dato, lista: Array<SelectItem>) {
    if (dato != undefined) {
        let objeto = { label: dato, value: dato };
        if (lista.findIndex(o => o.value === objeto.value) == -1) {
            lista.push(objeto);
        }
    }
}

export function uniqueFilterFull(label, value, lista: Array<SelectItem>) {
    if (label != undefined) {
        let objeto = { label: label, value: value };
        if (lista.findIndex(o => o.value === objeto.value) == -1) {
            lista.push(objeto);
        }
    }
}
/**
 * Uso: lista = lista.sort(compareValues('codigo')); || lista = lista.sort(compareValues('codigo', 'desc'));
 * @param key 
 * @param order 
 */
export function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

export const groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

/**
 * Encoding
 */
export function b64EncodeUnicode(str): string {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode(Number('0x') + p1);
        }));
}

export function b64DecodeUnicode(str): string {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

/**
 * Descarga de Errores
 */

export function DownloadText(arg: {
    fileName: string,
    text: string
}) {


    var setting = {
        element: {
            dynamicDownload: null as HTMLElement
        }
    }

    if (!setting.element.dynamicDownload) {
        setting.element.dynamicDownload = document.createElement('a');
    }
    const element = setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);
    var event = new MouseEvent("click");
    element.dispatchEvent(event);
}


/**
 * Metodos para Manejo de Idiomas
 */
export function getCodeLangByBrowserLang() {
    let codeLang = navigator.language;
    let supportLang = ["es-PE", "es-BO", "es-EC"];

    if (typeof (supportLang.find(e => e == codeLang)) != 'undefined')
        return codeLang;

    if (codeLang.substring(0, 2) == "en")
        return "en"

    return "es-PE";
}

export function getCodeLangByPaisId(paisId: number) {
    switch (paisId) {
      case 1: return "es-PE";
      case 2: return "es-BO";
      case 3: return "es-EC";
      case 4: return "en";
    }
}

export function langIsEspanish(lang: string) {
    let supportLang = ["es-PE", "es-BO", "es-EC"];
    if (supportLang.includes(lang)) {
        return true;
    }
    return false;
}

export function estadoFacturacionString(estado: number): string {
    if (estado != null) {
        switch (estado) {
            case C.ESTADO_VIAJE_FACTURACION.ESTADO.PENDIENTE:
                return "WEB.control.estados-facturacion.resumen-facturacion.pendiente";
            case C.ESTADO_VIAJE_FACTURACION.ESTADO.POR_FACTURAR:
                return "WEB.control.estados-facturacion.resumen-facturacion.por-facturar";
            case C.ESTADO_VIAJE_FACTURACION.ESTADO.FACTURADO:
                return "WEB.control.estados-facturacion.resumen-facturacion.facturado";
        }
    }
    return "";
}

export function estadoSolicitudPedidoString(estado : number): string {
    if (estado !=null) {
        switch (estado) {
            case C.ESTADO_SOLICITUD_PEDIDO.ESTADO.RECIBIDO:
                return "WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.recibido";
            case C.ESTADO_SOLICITUD_PEDIDO.ESTADO.DIGITADO:
                return "WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.digitado";
            case C.ESTADO_SOLICITUD_PEDIDO.ESTADO.APROBADO:
                return "WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.aprobado";
            case C.ESTADO_SOLICITUD_PEDIDO.ESTADO.FACTURADO:
                return "WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.facturado";
            case C.ESTADO_SOLICITUD_PEDIDO.ESTADO.DESPACHADO:
                return "WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.despachado";
        }
    }
    return "";
}

export function obtenerRangoFechaEstimada(fecha1: Date, fecha2: Date) : string{
    let fechaRango: string = "";
    if (fecha1 != undefined && fecha1 != null && fecha2 != undefined && fecha2 != null) {
        let fecha;
        let horaInicio;
        let horaFin;

        fecha = formatDate(fecha1, 'dd/MM/yyyy');
        horaInicio = formatDate(fecha1, 'HH:mm');
        horaFin = formatDate(fecha2, 'HH:mm');

        fechaRango = `${fecha} de ${horaInicio} a ${horaFin}`;
    }

    return fechaRango;
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
}

export function capitalizar(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function obtenerEtiquetaConfigurable(codigo, etiquetas) {
    return etiquetas[codigo];
}

export function obtenerCoordenadasCompania(usuario) {
    if (usuario != null) {
        let longitud = usuario.compania.coordenada.split(",")[0];
        let latitud = usuario.compania.coordenada.split(",")[1];
        return { lat: latitud, lng: longitud }
    } else {
        //se usa por defecto
        return environment.mapInitialPosition
    }
}