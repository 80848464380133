import { Component, Input, OnInit } from '@angular/core';
import { Cookies } from '@shared/commons';
import { Compania, Etiqueta, Funcionalidad } from '@shared/models';
import { FuncionalidadPersonalizacion } from '@shared/models/funcionalidadPersonalizacion';
import { BaseEtiqueta } from '../../types/base-etiqueta';
import { EtiquetaViewModel } from '../../types/etiqueta-view-model';

@Component({
  selector: 'st-inner-etiqueta-content',
  templateUrl: './inner-etiqueta-content.component.html',
  styleUrls: ['./inner-etiqueta-content.component.scss'],
  providers: [{
    provide: BaseEtiqueta, useExisting: InnerEtiquetaContentComponent
  }]
})
export class InnerEtiquetaContentComponent extends BaseEtiqueta implements OnInit {

  @Input() etiqueta: EtiquetaViewModel;

  companiaId: number;
  lenguage;

  constructor() {
    super();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.companiaId = currentUser.compania.companiaId;
    this.lenguage = Cookies.getCookie("LANGUAGE");
  }

  ngOnInit(): void { }

  obtenerFuncionalidadPersonalizacion(): FuncionalidadPersonalizacion {
    const fp = new FuncionalidadPersonalizacion();
    fp.funcionalidadPersonalizacionId = this.etiqueta.funcionalidadPersonalizacionId;
    fp.estado = this.etiqueta.estado;
    fp.nombre = this.etiqueta.nombre;

    //etiqueta
    const etiqueta = new Etiqueta();
    etiqueta.etiquetaId = this.etiqueta.etiquetaId;
    etiqueta.codigo = this.etiqueta.codigo;
    etiqueta.obligatorio = this.etiqueta.obligatorio;
    fp.etiqueta = etiqueta;

    //compañia
    const compania = new Compania();
    compania.companiaId = this.companiaId;
    fp.compania = compania;

    //funcionalidad
    const funcionalidad = new Funcionalidad();
    funcionalidad.funcionalidadId = this.etiqueta.funcionalidad.funcionalidadId;
    fp.etiqueta.funcionalidad = funcionalidad;

    return fp;
  }

}
