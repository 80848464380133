import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { C, IResultado } from '@shared/commons';
import { SolicitudAutorizacioNotiDTO } from '../dto/solicitudAutorizacionNoti.dto';
import { NotificacionDTO } from '../dto/notificacion.dto';
import { HttpClient2 } from '@shared/services';
import { ChatDTO } from '@shared/components/chat/resources';
import { NotificacionSensorDTO } from '../dto/notificacionSensor.dto';
import { NotificacionSmsDTO } from '../dto/notificacionSms.dto';

@Injectable()
export class NotificacionService {

  ciaId: string;
  constructor(private http: HttpClient2) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/" + currentUser.compania.companiaId;
  }

  listarSolicitudesAutorizacion() : Observable<Array<SolicitudAutorizacioNotiDTO>>{    
    return this.http.listar(this.ciaId+"/notificaciones/web/solicitudes-autorizacion/").map(
      (data: IResultado) => {
        let list = Array<SolicitudAutorizacioNotiDTO>();
        let obj;
        for (const d of data.resultado) {
          obj = new SolicitudAutorizacioNotiDTO();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
      });
  }

  listarAlertasTareas(): Observable<Array<NotificacionDTO>> {
    return this.http.listar(this.ciaId + "/notificaciones/web/alertas-tareas").map(
      (data: IResultado) => {
        let list = Array<NotificacionDTO>();
        //let obj;
        for (const d of data.resultado) {
          // obj = new NotificacionDTO();
          // obj.copiar(d);
          list.push(d);
        }
        return list;
      });
  }

  listarAlertasTareasSupervisor(usuarioId: number): Observable<Array<NotificacionDTO>> {
    return this.http.listar(this.ciaId + "/notificaciones/web/alertas-tareas-supervisor"+"/"+usuarioId).map(
      (data: IResultado) => {
        let list = Array<NotificacionDTO>();
        //let obj;
        for (const d of data.resultado) {
          // obj = new NotificacionDTO();
          // obj.copiar(d);
          list.push(d);
        }
        return list;
      });
  }

  listarAlertasSensores() : Observable<Array<NotificacionSensorDTO>>{    
    return this.http.listar(this.ciaId+"/notificaciones/web/alertas-sensores").map(
      (data: IResultado) => {
        let list = Array<NotificacionSensorDTO>();
        let obj;
        for (const d of data.resultado) {
          obj = new NotificacionSensorDTO();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
      });
  }

  listarAlertasSms() : Observable<Array<NotificacionSmsDTO>>{    
    return this.http.listar(this.ciaId+"/notificaciones/web/alertas-sms").map(
      (data: IResultado) => {
        let list = Array<NotificacionSmsDTO>();
        let obj;
        for (const d of data.resultado) {
          obj = new NotificacionSmsDTO();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
      });
  }

  listarNotificacionWeb(comapaniaId: number, viajesId: number[], usuarioId: number) : Observable<Array<ChatDTO>>{
    return this.http.listarNotificacionWeb(`/conversaciones/ultimos-mensajes?companiaId=${comapaniaId}&viajesId=${viajesId}&usuarioId=${usuarioId}`)
    .map((resultado: IResultado) => {
        let list = Array<ChatDTO>();
        let obj;
        if(resultado){
          
          for (const d of resultado.data) {
            obj = new ChatDTO();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  modificar(model : NotificacionDTO, funcionalidad?: String){ 
    return this.http.modificar(`${this.ciaId}/notificaciones/web/solicitudes-autorizacion/`, model, { funcionalidad});
  }
  
}
