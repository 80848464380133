import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ceInputEntero]'
})
export class InputEnteroDirective {

  constructor() { }

  @HostListener('input', ['$event']) onInput(e) {    
    e.target.value=e.target.value.replace(/[^0-9]/,'');    
  }
}
