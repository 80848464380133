import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild  {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const currentUser = JSON.parse(localStorage.getItem("currentUser")); 
        if(currentUser.esPerfilSac && (currentUser.compania.checkCompaniaUsuarioSac === false)){
          return false;
        }

        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { page: state.url }});
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        const currentUser = JSON.parse(localStorage.getItem("currentUser")); 
        if(currentUser!= null){
            if(currentUser.esPerfilSac && (currentUser.compania.checkCompaniaUsuarioSac === false)){
              return false;
            }
        }

        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { page: state.url }});
        return false;
    }
}