import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { C } from '@shared/commons';
import { ChatViewDTO } from '@shared/dto';
import { ChatProvider } from '@shared/providers';
import { SocketIOService } from '@shared/services/socket-io.service';
import { Socket } from 'socket.io-client';
import { ChatDTO } from '../resources';

@Component({
    selector: 'app-chat-minimizado',
    templateUrl: './chat-minimizado.component.html',
    styleUrls: ['./chat-minimizado.component.scss'],
})
export class ChatMinimizadoComponent implements OnInit{
    @Input()view: ChatViewDTO;

    chatSocket: Socket;    
    pintarChat: boolean= false;
    constructor(private chatProvider: ChatProvider) { }
    
    ngOnInit(){
        // Evaluar si hay Chats Por Leer
        this.chatProvider.currentNotificacionChats.subscribe((chats: ChatDTO[]) => {            
            let chatPorLeer: ChatDTO = chats.find(chat => chat.viajeId == this.view.viajeId && chat.estado == C.CHAT_VARS.ESTADO.ENVIADO);
            this.pintarChat = (chatPorLeer!=null);            
        }); 
    }

    onClickMaximizar(view){
        this.chatProvider.editViewChat(view.viajeId, true);
    }
    onClickCerrar(view: ChatViewDTO){
        this.chatProvider.removeViewChat(view.viajeId);
    }
    
}