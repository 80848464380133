import { Usuario } from './usuario';
import { Viaje } from './viaje';
import { C } from '../commons/constantes';
import { dateToString, dateAndHourToString } from '../commons';
import { Almacen } from './almacen';


export class EstadoViaje {
    //PK
    estadoViajeId : number;

    //Atributos
    estado : number;
    fechaInicio : Date;
    fechaFin : Date;


    //Relaciones
    viaje : Viaje;
    usuario : Usuario;
    almacen: Almacen;

    motivoEstadoId: number;
    tareaIds : Array<number>;
    etiquetas: any = {};


    //Metodos
    copiar(objeto : any){
        //Propios
        this.estadoViajeId = objeto.estadoViajeId;
        this.estado = objeto.estado;
        this.fechaInicio = objeto.fechaInicio;
        this.fechaFin = objeto.fechaFin;        


        //Relaciones
        if(objeto.viaje != null){
            this.viaje = new Viaje();
            this.viaje.copiar(objeto.viaje);
        }
        if(objeto.usuario != null){
            this.usuario = new Usuario();
            this.usuario.copiar(objeto.usuario);
        }

        if(objeto.almacen != null){
            this.almacen = new Almacen();
            this.almacen.copiar(objeto.almacen);
        }

        this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));

    }

    get estadoToString() : string{
        //console.count('estadoToString');
        this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
        if(this.estado!=null){
            switch(this.estado){
                case C.VIAJE.ESTADO.PROGRAMADO:
                    return this.etiquetas['estado_viaje_programado'];
                case C.VIAJE.ESTADO.APT:
                    return this.etiquetas['estado_viaje_en_apt'];
                case C.VIAJE.ESTADO.EN_RUTA:
                    return this.etiquetas['estado_viaje_en_ruta'];
                case C.VIAJE.ESTADO.FINALIZADO:
                    return this.etiquetas['estado_viaje_finalizado'];
                case C.VIAJE.ESTADO.INICIO_CARGA:
                    return this.etiquetas['estado_viaje_inicio_carga'];
                case C.VIAJE.ESTADO.FIN_CARGA:
                    return this.etiquetas['estado_viaje_fin_carga'];
                case C.VIAJE.ESTADO.EN_HUB:
                    return  this.etiquetas['estado_viaje_en_hub'];
                case C.VIAJE.ESTADO.EN_PROGRAMACION:
                    return  this.etiquetas['estado_viaje_en_programacion'];
                case C.VIAJE.ESTADO.EN_REPARTO:
                    return  this.etiquetas['estado_viaje_en_reparto'];

            }
        }
        return "";
    }

    get estadoToIconName() : string {
        this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
        if(this.estado!=null){
            switch(this.estado){
                case C.VIAJE.ESTADO.PROGRAMADO:
                    return "estado_viaje_programado.svg";
                case C.VIAJE.ESTADO.APT:
                    return "estado_viaje_apt.svg";
                case C.VIAJE.ESTADO.EN_RUTA:
                    return "estado_viaje_ruta.svg";
                case C.VIAJE.ESTADO.FINALIZADO:
                    return "estado_viaje_finalizado.svg";
                case C.VIAJE.ESTADO.INICIO_CARGA:
                    return "estado_viaje_inicio_carga.svg";
                case C.VIAJE.ESTADO.FIN_CARGA:
                    return "estado_viaje_fin_carga.svg";
            }
        }
        return "";
    }

    get fechaInicioToString(): string {
        let label = '';
        if (typeof (this.fechaInicio) != "undefined" && this.fechaInicio != null) {
            label = dateAndHourToString(this.fechaInicio);
        }
        return label;
    }

    get fechaInicioToFiltro(): string {
        let label = '';
        if (typeof (this.fechaInicio) != "undefined" && this.fechaInicio != null) {
            label = dateToString(this.fechaInicio);
        }
        return label;
    }
}
