<div class="estados-dia">
    <svg width="29" height="19" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9652 17.2869V12.9652H16.4505C16.1463 12.0654 15.9689 11.0895 15.9689 10.0883H12.9652V5.76653H17.0841C18.2881 3.57398 20.392 1.93908 22.8887 1.34341C22.5592 0.557643 21.7861 0 20.8862 0H2.15453C0.963201 0 0 0.963201 0 2.15453V17.9967C0 19.188 0.963201 20.1512 2.15453 20.1512H20.8862C21.7861 20.1512 22.5592 19.5935 22.876 18.8204C21.672 18.5289 20.5567 17.9967 19.5935 17.2743H12.9652V17.2869ZM10.0883 17.2869H2.87693V12.9652H10.0756V17.2869H10.0883ZM10.0883 10.0883H2.87693V5.76653H10.0756V10.0883H10.0883Z" fill="black"/>
        <path d="M24.5609 11.1273L26.1831 12.3313C26.3479 12.4707 26.6014 12.4327 26.7281 12.268L27.2097 11.6596C27.3491 11.4949 27.3111 11.2414 27.1463 11.1147L25.7395 10.0881V6.59015C25.7395 6.3747 25.5621 6.19727 25.3467 6.19727H24.5862C24.3708 6.19727 24.1934 6.3747 24.1934 6.59015V10.3669C24.206 10.6584 24.3328 10.9372 24.5609 11.1273Z" fill="white"/>
        <path d="M24.979 4.05469C24.3073 4.05469 23.6609 4.16875 23.0526 4.37153C21.8866 4.76441 20.8981 5.49949 20.1757 6.46269C19.4152 7.47659 18.959 8.71861 18.959 10.0747C18.959 11.1139 19.2251 12.1025 19.6941 12.9516C19.8335 13.2051 19.9982 13.4586 20.1757 13.6867C20.8981 14.6499 21.8993 15.385 23.0526 15.7779C23.6609 15.9806 24.3073 16.0947 24.979 16.0947C28.3122 16.0947 30.999 13.3952 30.999 10.0747C30.999 6.75419 28.3122 4.05469 24.979 4.05469ZM27.2222 11.6589L26.7406 12.2672C26.6012 12.432 26.3604 12.4573 26.1957 12.3306L24.5734 11.1266C24.3453 10.9365 24.2059 10.6577 24.2059 10.3662V6.58943C24.2059 6.37397 24.3833 6.19654 24.5988 6.19654H25.3719C25.5873 6.19654 25.7648 6.37397 25.7648 6.58943V10.0874L27.1715 11.1139C27.3363 11.2533 27.3616 11.4941 27.2222 11.6589Z" fill="#FB2A2D"/>
    </svg>
    <p>{{ historial.dia }}</p>
</div>
<div class="estados-card" *ngFor="let estado of historial.estados">
    <div class="estados-card__hora">
        <p>{{ estado.hora }}</p>
        <ng-container *ngIf="estado.valor === 1 || estado.valor === 2">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z" fill="#02B591"/>
                <path d="M27.4958 12H12.497C11.6661 12 11 12.6735 11 13.4975V23.5002C11 24.3314 11.6733 24.9978 12.497 24.9978H18.4994L17.998 26.4953H15.7489C15.3335 26.4953 14.9968 26.8321 14.9968 27.2476C14.9968 27.6632 15.3335 28 15.7489 28H24.2511C24.6665 28 25.0032 27.6632 25.0032 27.2476C25.0032 26.8321 24.6665 26.4953 24.2511 26.4953H22.002L21.5006 24.9978H27.503C28.3339 24.9978 29 24.3242 29 23.5002V13.5047C29 12.6735 28.3267 12 27.4958 12ZM27.0016 23.0058H12.9912V14.0063H26.9944V23.0058H27.0016Z" fill="white"/>
            </svg>
        </ng-container>
        <ng-container *ngIf="estado.valor === 3">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z" fill="#02B591"/>
                <path d="M28.3797 22.6467H27.9376V19.6642C27.9376 19.3133 27.7972 18.9765 27.5516 18.7309L24.8007 15.98C24.5551 15.7344 24.2183 15.594 23.8674 15.594H22.6534V14.2677C22.6534 13.5379 22.0639 12.9414 21.3271 12.9414H12.506C11.7762 12.9414 11.1797 13.5309 11.1797 14.2677V23.0888C11.1797 23.8186 11.7692 24.4151 12.506 24.4151H12.9481C12.9481 25.8747 14.1341 27.0607 15.5937 27.0607C17.0534 27.0607 18.2393 25.8747 18.2393 24.4151H21.7692C21.7692 25.8747 22.9551 27.0607 24.4148 27.0607C25.8744 27.0607 27.0604 25.8747 27.0604 24.4151H28.3867C28.6323 24.4151 28.8288 24.2186 28.8288 23.973V23.0888C28.8218 22.8432 28.6253 22.6467 28.3797 22.6467ZM15.5937 25.7344C14.8639 25.7344 14.2674 25.1449 14.2674 24.4081C14.2674 23.6782 14.8569 23.0818 15.5937 23.0818C16.3235 23.0818 16.92 23.6712 16.92 24.4081C16.913 25.1449 16.3235 25.7344 15.5937 25.7344ZM24.4078 25.7344C23.6779 25.7344 23.0814 25.1449 23.0814 24.4081C23.0814 23.6782 23.6709 23.0818 24.4078 23.0818C25.1376 23.0818 25.7341 23.6712 25.7341 24.4081C25.7341 25.1449 25.1446 25.7344 24.4078 25.7344ZM26.6183 20.0011H22.6464V16.9133H23.8604L26.6113 19.6642V20.0011H26.6183Z" fill="white"/>
            </svg>    
        </ng-container>        
        <ng-container *ngIf="(estado.valor >= 4  && estado.valor <= 6) || estado.valor === 13">  
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#02B591"/>
                <path d="M30.6903 17.7579C30.7671 17.4681 30.5966 17.1697 30.3152 17.093L27.7236 16.3854L26.8967 19.5397L24.825 18.977L25.652 15.8227L23.0689 15.1151C22.7875 15.0384 22.4892 15.2089 22.4124 15.4988L20.75 21.8074C20.6733 22.0972 20.8438 22.3956 21.1251 22.4724L28.3715 24.4417C28.6528 24.5184 28.9512 24.3479 29.0279 24.058L30.6903 17.7579ZM9.28369 11.2959V24.902C9.28369 27.0077 10.9631 28.7128 13.0348 28.7128C15.0552 28.7128 16.692 27.0845 16.7688 25.0469L29.9487 28.6957C30.0851 28.7383 30.2385 28.6531 30.2726 28.5082L30.6903 26.931C30.733 26.7861 30.6477 26.6412 30.5028 26.5985L18.9171 23.3845V12.3786C18.9171 11.7733 18.4397 11.2874 17.8429 11.2874H9.28369V11.2959ZM11.432 24.8935C11.432 23.9898 12.1481 23.2652 13.0433 23.2652C13.9299 23.2652 14.6545 23.9984 14.6545 24.8935C14.6545 25.7972 13.9384 26.5218 13.0433 26.5218C12.1481 26.5303 11.432 25.7972 11.432 24.8935Z" fill="white"/>
            </svg>    
        </ng-container>     
        <ng-container *ngIf="(estado.valor >= 7 && estado.valor <= 12) || estado.valor === 16">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z" fill="#02B591"/>
                <path d="M18.4133 25.3258L15.1902 21.9856C14.9968 21.7851 14.9968 21.4586 15.1902 21.2581L15.8921 20.5307C16.0855 20.3303 16.4006 20.3303 16.594 20.5307L18.7643 22.7798L23.4128 17.9625C23.6062 17.7621 23.9214 17.7621 24.1148 17.9625L24.8167 18.6899C25.0101 18.8903 25.0101 19.2169 24.8167 19.4173L19.1153 25.3258C18.9219 25.5262 18.6067 25.5262 18.4133 25.3258Z" fill="white"/>
                <path d="M28.7704 14.5184L25.6332 11.2672C25.4971 11.0965 25.2894 11 25.0602 11H14.9466C14.7174 11 14.5097 11.0965 14.3951 11.2449L11.2579 14.4961C11.0931 14.6371 11 14.8524 11 15.0899V28.1835C11 28.6437 11.3438 29 11.7879 29H28.2117C28.6558 29 28.9996 28.6437 28.9996 28.1835V15.0973C29.0068 14.8524 28.9137 14.6371 28.7704 14.5184ZM13.6931 14.2734L15.2689 12.6404H19.2155V14.2734H13.6931ZM20.7913 14.2734V12.6404H24.7379L26.3136 14.2734H20.7913ZM27.4238 15.9138V27.367H12.5829V15.9138H27.4238Z" fill="white"/>
            </svg> 
        </ng-container>         
    </div>
    <div class="estados-card__estado" [ngStyle]="estado.ubigeo && {'justify-content': 'space-around'}">
        <p>{{ estado.descripcion }}</p>
        <p class="p-normal">{{ estado.ubigeo }}</p>
    </div>
    <div class="estados-card__estado">
        <div style="display: flex;  margin-bottom: .5em">
            <p style="padding-right: 1.6em;">{{ 'WEB.control.estados-viaje.historial-estados-tarea.usuario' | translate }}: </p>
            <p class="p-normal">{{ estado.usuario }}</p>
        </div>
        <div style="display: grid; grid-template-columns: .2fr 1fr 0.2fr; align-items: center;  margin-bottom: .5em">
            <p style="padding-right: 1em;">{{ 'WEB.control.estados-viaje.historial-estados-tarea.perfil' | translate }}: </p>
            <span>{{estado.perfilesToString || ''}}</span>
            <button *ngIf="estado.perfiles >= 3" class="boton-ver-mas" (click)="onClickCargarVentanaPerfiles(estado)"><span class="color: #000000;align-items: center;">...</span> Ver más</button>
        </div>
        <div style="display: grid; grid-template-columns: .2fr 1fr 0.2fr; align-items: center;  margin-bottom: .5em" *ngIf="estado.motivoId != null ">
            <p style="padding-right: 1em;">{{ 'WEB.control.estados-viaje.historial-estados-tarea.motivo' | translate }}:</p>
            <p class="p-normal">{{ estado.motivo }}</p>
        </div>
    </div>
</div>

<p-dialog modal=true [header]="nombrePerfil"  [(visible)]="ventanaPerfiles"  [contentStyle]="{'overflow':'visible'}" responsive="true"
 styleClass="st-dialog" [style]="{width: '610px'}" >

    <div class="ui-g">
        <div class="grid-container two-columns">
            <app-perfil-descripcion *ngFor="let itemPerfil of perfilesEstadosActual | paginate: {id: 'paginator', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: perfilesEstadosActual?.length}" [perfil]="itemPerfil">
            </app-perfil-descripcion>
        </div>
        <div class="paginator" *ngIf="perfilesEstadosActual.length > 10">
            <p-paginator [rows]="itemsPerPage" [totalRecords]="perfilesEstadosActual.length" (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>

</p-dialog>