<div class="chat" [ngClass]="{'hide-campo': view.view}">
    <div class="chat-header" [ngClass]="pintarChat ? 'chat-header-notificacion-true' : 'chat-header-notificacion-false'">
        <div class="wrapper-chat-header-info">
            <div class="chat-header-item"> {{'WEB.chat.titulos-chat.transportista' | translate}} : {{view.transportista}}</div>
            <div class="chat-header-item"> {{'WEB.chat.titulos-chat.viaje' | translate}} : {{view.codigo}}</div>
        </div>
        <div class="wrapper-chat-window-buttons" [ngClass]="pintarChat ? 'wrapper-chat-window-buttons-notificacion' : 'wrapper-chat-window-buttons'">
            <button type="button" (click)="onClickMaximizar(view)"><img src="assets/images/maximizar-chat.svg" /></button>
            <button type="button" (click)="onClickCerrar(view)"><img src="assets/images/cerrar-chat.svg" /></button>
        </div>        
    </div>
</div>