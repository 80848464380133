export class EstadoNotificacion {
      estadoNotificacionId : number;    
      fuenteApp: string;    
      tipoEstado : string;    
      estadoId : number;    
      tipoDestinatario : string;    
      destinatarioId : number;    
      tipoNotificacion : string;    
      telefono : string;    
      correo : string;    
      estado : number;    
      motivo : string;    
      smsId : string;
      cantSms : number; 
      origenEnvio : string;    
      fechaCreacion : string;

      //otras variables
      tareaId: number;
}