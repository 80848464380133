import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient2 } from '../../../../../shared/services';
import { Usuario, formatDate } from '../../../../../shared';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

//Libreria: ExcelJs
import * as fs from 'file-saver';
import * as Excel from 'exceljs';

import { ConsumoSMSDTO } from '../';
import { ExcelUtils } from 'src/app/shared/commons/excel-utils';

@Injectable()
export class ReporteService {

  currentUser : any;
  logoCompaniaB64 : string;

  constructor(private translate : TranslateService,
              private http : HttpClient2) {   
                this.currentUser  = JSON.parse(localStorage.getItem("currentUser"));

                if(this.currentUser.logo != null && this.currentUser.logo.length){
                  this.logoCompaniaB64 = this.currentUser.logo;this.currentUser  = JSON.parse(localStorage.getItem("currentUser"));

                  if(this.currentUser.logo != null && this.currentUser.logo.length){
                    this.logoCompaniaB64 = this.currentUser.logo;
                    this.getLogoSTToBase64(this.currentUser.logo, 
                      resolve => {
                          this.logoCompaniaB64 = resolve;
                      });
  
                  }else{
                    this.getLogoSTToBase64('assets/images/smart_tracing_eslogan.svg', 
                      resolve => {
                          this.logoCompaniaB64 = resolve;
                      });
                  }
                  this.getLogoSTToBase64(this.currentUser.logo, 
                    resolve => {
                        this.logoCompaniaB64 = resolve;
                    });

                }else{
                  this.getLogoSTToBase64('assets/images/smart_tracing_eslogan.svg', 
                    resolve => {
                        this.logoCompaniaB64 = resolve;
                    });
                }
                
                
  }

  //Servicios
  consumoSMS(httpParams : HttpParams) : Observable<Array<ConsumoSMSDTO>>{

    return this.http.listar("/reportes/reporte-consumo-sms", { params: httpParams }).map(
      (data) => {
        let resultado = new Array<ConsumoSMSDTO>();
        if (data) {
          data.resultado.forEach(objeto => {
            let tarea = new ConsumoSMSDTO();
            tarea.copiar(objeto);

            resultado.push(tarea);
          });
        }
        return resultado;
      }
    );
  }

  consultar(companiaId, data) : Observable<Array<Usuario>>{
    return this.http.consultar("/"+companiaId+"/usuarios/consultar", data).map(
      (data) => {
        let resultado = new Array<Usuario>();
        if (data) {
          data.resultado.forEach(objeto => {
            let usuario = new Usuario();
            usuario.copiar(objeto);

            resultado.push(usuario);
          });
        }
        return resultado;
      }
    );
  }

  async obtenerExcelExportacionReportes(titulo, headers, datos, rangoBusqueda:{inicio,fin} ) {
    
    //Creamos el Archivo Excel
    const workbook = new Excel.Workbook();

    //Añadimos la Hoja de trabajo
    const worksheet = workbook.addWorksheet(titulo, {views: [{showGridLines: false}]});

    //Generamos los datos de Cabecera
    this.generateHeadboard(workbook, worksheet, datos, rangoBusqueda);

    //Damos como nivel de inicio 
    worksheet.getRow(worksheet.lastRow.number + 1).outlineLevel = 0;

    //Añadimos los datos para las columnas
    worksheet.addRows(headers);

    //Recorremos la fila (headers) que acabamos de añadir
    worksheet.lastRow.eachCell((cell: any, column) => {
      //Combinamos las celdas de la fila con las celdas de la siguiente fila
      worksheet.mergeCells(cell.row , column, cell.row + 1, column);
      //Damos estilo a las celdas de la Cabecera
      ExcelUtils.addStyle(cell, 'header');
    });
    
    //Añadimos los registros que van a ser exportados
    worksheet.addRows(datos);
    //Damos estilo al contenido
    for (var i = 0; i < datos.length ; i++) {
      ExcelUtils.addStyle(worksheet.getRow(worksheet.lastRow.number-i), 'data');
    }

    //Añadimos autosize
    ExcelUtils.addAutoSize(worksheet,  8);

    //Por ultimo añadimos el titulo para que no sea afectado
    //por el tamaño de celdas
    this.generateTitle(worksheet, titulo);

    //Guardamos el Archivo en nuestra pc
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.translate.instant("WEB.seguridad.reporte-consumo-sms.exportacion.nombre-archivo"));
    });

  }

  async obtenerExcelExportacionReportesTipoProcedencia(titulo, headers, datos, rangoBusqueda:{inicio,fin} ) {
    
    //Creamos el Archivo Excel
    const workbook = new Excel.Workbook();

    //Añadimos la Hoja de trabajo
    const worksheet = workbook.addWorksheet(titulo, {views: [{showGridLines: false}]});

    //Generamos los datos de Cabecera
    this.generateHeadboard(workbook, worksheet, datos, rangoBusqueda);

    //Damos como nivel de inicio 
    worksheet.getRow(worksheet.lastRow.number + 1).outlineLevel = 0;

    //Añadimos los datos para las columnas
    worksheet.addRows(headers);

    //Recorremos la fila (headers) que acabamos de añadir
    worksheet.lastRow.eachCell((cell: any, column) => {
      //Combinamos las celdas de la fila con las celdas de la siguiente fila
      worksheet.mergeCells(cell.row , column, cell.row + 1, column);
      //Damos estilo a las celdas de la Cabecera
      ExcelUtils.addStyle(cell, 'header');
    });
    
    //Añadimos los registros que van a ser exportados
    worksheet.addRows(datos);
    //Damos estilo al contenido
    for (var i = 0; i < datos.length ; i++) {
      ExcelUtils.addStyle(worksheet.getRow(worksheet.lastRow.number-i), 'data');
    }

    //Añadimos autosize
    ExcelUtils.addAutoSize(worksheet,  8);

    //Por ultimo añadimos el titulo para que no sea afectado
    //por el tamaño de celdas
    this.generateTitle(worksheet, titulo);

    //Guardamos el Archivo en nuestra pc
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.translate.instant("WEB.reporte.viaje-tipo-procendencia-gps.detalle.nombre-archivo-principal"));
    });

  }

  //Metodo para extraer una imagen del proyecto y devolverla en base64
  getLogoSTToBase64(url, callBack){

      var xhr = new XMLHttpRequest();  
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callBack(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url, true);

      xhr.responseType = 'blob';
      xhr.send();
    
  }

  generateHeadboard(workbook, worksheet, datos, rangoBusqueda){

    ExcelUtils.addBase64Image(workbook, worksheet, this.logoCompaniaB64,
      {col:0, row:0, w:215, h:68});

    worksheet.getRow(8).values = [];
    
    /*if(rangoBusqueda){
      const rowValues = [];
      rowValues[2] = this.translate.instant('COMMON.excel.fecha-inicial-busqueda');
      rowValues[4] = ': ' + formatDate(rangoBusqueda.inicio, "MM/yyyy");
      rowValues[6] = this.translate.instant('COMMON.excel.fecha-final-busqueda');
      rowValues[8] = ': ' + formatDate(rangoBusqueda.fin, "MM/yyyy");
      worksheet.lastRow.values = rowValues;

      worksheet.lastRow.eachCell((cell: any, column) => {
        if(column == 2 || column == 6){
          ExcelUtils.addStyle(cell, 'data-bold');
        }else{
          ExcelUtils.addStyle(cell, 'data');
        }
      });  
      worksheet.getRow(9).values = [];
    }*/

    // Agregamos los datos de Cabecera
    const rowValues = [];
    rowValues[2] = this.translate.instant('COMMON.excel.cantidad-registros');
    rowValues[4] = ': ' + datos.length;
    rowValues[6] = this.translate.instant('COMMON.excel.fecha-hora-generacion');
    rowValues[8] = ': ' + formatDate(new Date(), "dd/MM/yyyy HH:mm:ss");
    worksheet.lastRow.values = rowValues;

    //Damos estilo los datos de Cabecera
    worksheet.lastRow.eachCell((cell: any, column) => {
      if(column == 2 || column == 6){
        ExcelUtils.addStyle(cell, 'data-bold');
      }else{
        ExcelUtils.addStyle(cell, 'data');
      }
    });    

  }

  generateTitle(worksheet, titulo){

    //Agregamos el titulo
    worksheet.mergeCells('A5:I6');
    worksheet.getCell('A5').value = titulo;

    //Añadimos Estilo para el titulo
    ExcelUtils.addStyle(worksheet.getCell('A5'),'title');
    
  }

}
