export class PrimeUtils {

    public static getCalendarLanguage(_language: string): Object {
        if (_language == "en") {
            return {
                "firstDayOfWeek": 1,
                "today": "Today",
                "clear": "Clear",
                "dayNames": [
                    "sunday",
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                    "saturday"
                ],
                "dayNamesShort": [
                    "sun",
                    "mon",
                    "tues",
                    "wedn",
                    "thurs",
                    "fri",
                    "satu"
                ],
                "dayNamesMin": [
                    "S",
                    "M",
                    "T",
                    "W",
                    "T",
                    "F",
                    "S"
                ],
                "monthNames": [
                    "january",
                    "february",
                    "march",
                    "april",
                    "may",
                    "june",
                    "july",
                    "agoust",
                    "september",
                    "october",
                    "november",
                    "december"
                ],
                "monthNamesShort": [
                    "jan",
                    "feb",
                    "mar",
                    "apr",
                    "may",
                    "jun",
                    "jul",
                    "ago",
                    "sep",
                    "oct",
                    "nov",
                    "dec"
                ]
            };
        } else {
            return {
                "firstDayOfWeek": 1,
                "today": "Hoy",
                "clear": "Borrar",
                "dayNames": [
                    "domingo",
                    "lunes",
                    "martes",
                    "miércoles",
                    "jueves",
                    "viernes",
                    "sábado"
                ],
                "dayNamesShort": [
                    "dom",
                    "lun",
                    "mar",
                    "mié",
                    "jue",
                    "vie",
                    "sáb"
                ],
                "dayNamesMin": [
                    "D",
                    "L",
                    "M",
                    "X",
                    "J",
                    "V",
                    "S"
                ],
                "monthNames": [
                    "enero",
                    "febrero",
                    "marzo",
                    "abril",
                    "mayo",
                    "junio",
                    "julio",
                    "agosto",
                    "septiembre",
                    "octubre",
                    "noviembre",
                    "diciembre"
                ],
                "monthNamesShort": [
                    "ene",
                    "feb",
                    "mar",
                    "abr",
                    "may",
                    "jun",
                    "jul",
                    "ago",
                    "sep",
                    "oct",
                    "nov",
                    "dic"
                ]
            };
        }
    }
}