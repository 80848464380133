import { EmpresaTransporte } from './empresaTransporte';
import { Sensor } from './sensor';
import { UnidadTransporteSensor } from './unidadTransporteSensor';

export class UnidadTransporte {
    //PK
    unidadTransporteId : number;

    //Atributos
    placa : string;
    capacidad : number;
    cargaUtil : number;
    estado : number;
    estadoMonitoreoGps: string;
    tipoGps: string;
    sensores : Array<string>;
    unidadTransporteSensores : Array<UnidadTransporteSensor>;

    //Relaciones
    empresaTransporte : EmpresaTransporte;
    

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;
    disponibilidad: boolean;

    constructor(){
        this.empresaTransporte = new EmpresaTransporte();
    }

    //Metodos
    copiar(objeto : any){
        //Propios
        this.unidadTransporteId = objeto.unidadTransporteId;
        this.placa = objeto.placa;
        this.capacidad = objeto.capacidad;
        this.cargaUtil = objeto.cargaUtil;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.estado = objeto.estado;
        this.disponibilidad = objeto.disponibilidad;
        this.sensores = objeto.sensores;
        this.unidadTransporteSensores = objeto.unidadTransporteSensores;
        this.estadoMonitoreoGps = objeto.estadoMonitoreoGps;
        this.tipoGps = objeto.tipoGps;
        //Relaciones
        if(objeto.empresaTransporte != null){
            this.empresaTransporte.copiar(objeto.empresaTransporte);
        }

    }
}
