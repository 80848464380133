
export class TipoDocumento {
    tipoDocumentoId: number;
    descripcion: string;
    estado: number;

    copiar(objeto: any) {
        //Propios
        this.tipoDocumentoId = objeto.tipoDocumentoId;
        this.descripcion = objeto.descripcion;  
        this.estado = objeto.estado; 
    }
}