export class Pais{
    //Pk
    paisId : number;
    //Atributos
    pais : string;
    codigo: string;
    idioma: string;
    coordenada: string;

    copiar(objeto : any){
        //Propios
        this.paisId = objeto.paisId;
        this.pais = objeto.pais; 
        this.codigo = objeto.codigo;       
        this.idioma = objeto.idioma;     
        this.coordenada = objeto.coordenada;
    }
}