import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient2 } from '../../../../../shared/services';
import { ISelectObject, IResultado } from '../../../../../shared/commons';
import { Usuario } from '../../../../../shared';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class ConsumoSMSService {

  constructor(private translate : TranslateService,
              private http : HttpClient2) { }

  //Servicios
  aplicaciones() : Observable<Array<any>>{

    return this.http.listar("/consumo-sms/listar-aplicaciones").map(
      (data) => {
        let resultado = new Array<any>();
        if(data){
            resultado = data.resultado;
            return resultado;
        }
        return resultado;
      }
    );
  }


}