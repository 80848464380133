import { Sede, ClienteFinal, Viaje, Tarea, Entrega, Recojo} from './index';
import { dateToString, dateAndHourToString } from '../commons';

export class PuntoVisita {
    //PK
    puntoVisitaId : number;
    viajeId : number;

    //Atributos
    orden : number;
    fechaEstimadaLlegada : Date;
    fechaHoraCita : Date;
    esTokenCliente : Boolean;
    tokenDigitalSupervisor : string;
    radioControl : string;
    marca : string;
    onTime : string;
    onTimeHora : string;

    //Relaciones
    sede : Sede;
    clienteFinal : ClienteFinal;
    viaje : Viaje;
    tarea : Tarea;

    //Otras Variables
    estadoRegistro : string;
    estadoRegistroToColor : string;
    estadoRegistroToIcon : string;
    resultadoRegistro : string;

    setEstadoRegistro(estadoRegistro : string){
        this.estadoRegistro = estadoRegistro;
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    this.estadoRegistroToColor= "btn-success";
                    this.estadoRegistroToIcon= "fa fa-fw fa-check-circle";
                    break;
                case 'P':
                    this.estadoRegistroToColor= "btn-warning";
                    this.estadoRegistroToIcon= "fa fa-fw fa-warning";
                    break;
                case 'F':
                    this.estadoRegistroToColor= "btn-danger";
                    this.estadoRegistroToIcon= "fa fa-fw fa-times-circle";
                    break;
            }
        }
    }

    get fechaHoraCitaToFiltro() : string{
        let label = '';
        if(typeof(this.fechaHoraCita) != "undefined" && this.fechaHoraCita != null){
            label = dateToString(this.fechaHoraCita);
        }
        return label;
    }

    get fechaHoraCitaToString() : string{
      let label = '';
      if(typeof(this.fechaHoraCita) != "undefined" && this.fechaHoraCita != null){
          label = dateAndHourToString(this.fechaHoraCita);
      }
      return label;
    }

    get fechaEstimadaLlegadaToFiltro() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegada) != "undefined" && this.fechaEstimadaLlegada != null){
            label = dateToString(this.fechaEstimadaLlegada);
        }
        return label;
    }

    get fechaEstimadaLlegadaToLineaTiempo() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegada) != "undefined" && this.fechaEstimadaLlegada != null){
            label = dateToString(this.fechaEstimadaLlegada,"HH:mm");
        }
        return label;
    }

    get esTokenClienteToString() : string{
        if(this.esTokenCliente == null)
            return '';
        
        if(this.esTokenCliente)
            return 'WEB.control.estados-viaje.detalle.datos-tabla.cliente';

        return 'WEB.control.estados-viaje.detalle.datos-tabla.supervisor';
    }

    //Metodos
    copiar(objeto : any){
        //Propios
        this.puntoVisitaId = objeto.puntoVisitaId;
        this.viajeId = objeto.viajeId;
        this.orden = objeto.orden;
        this.fechaEstimadaLlegada = objeto.fechaEstimadaLlegada;
        this.fechaHoraCita = objeto.fechaHoraCita;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.esTokenCliente = objeto.esTokenCliente;
        this.tokenDigitalSupervisor = objeto.tokenDigitalSupervisor;
        this.radioControl = objeto.radioControl;
        this.marca = objeto.marca;
        this.onTimeHora = objeto.onTimeHora;
        this.onTime = objeto.onTime;
        this.setEstadoRegistro(objeto.estadoRegistro);

        //Relaciones
        if(objeto.sede != null){
            this.sede = new Sede();
            this.sede.copiar(objeto.sede);
        }
        if(objeto.clienteFinal != null){
            this.clienteFinal = new ClienteFinal();
            this.clienteFinal.copiar(objeto.clienteFinal);
        }
        if(objeto.viaje != null){
            this.viaje = new Viaje();
        }
        if(objeto.tarea != null){
            if(objeto.tarea.tipoTarea == 0){
                let recojo : Recojo = new Recojo();
                recojo.copiar(objeto.tarea);
                this.tarea = recojo;
            }else{
                let entrega : Entrega = new Entrega();
                entrega.copiar(objeto.tarea);
                this.tarea = entrega;
            }
        }
    }

    instanciar(){
        if(this.sede==null) this.sede = new Sede();
        if(this.clienteFinal==null) this.clienteFinal = new ClienteFinal();
        if(this.viaje==null) this.viaje = new Viaje();
    }
}
