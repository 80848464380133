<div class="container">
    <ng-template [ngIf]="header != undefined">
      <div [style.background-color]="header_background" class="header flex-center">
        <p>{{header}}</p>
      </div>
    </ng-template>
  
    <ng-template [ngIf]="cabecera != undefined || detalle != undefined">
      <div class="body">
        <ng-template [ngIf]="cabecera != undefined">
          <div class="cabecera flex-center">
            <p>{{cabecera}}</p>
          </div>
        </ng-template>
  
        <ng-template [ngIf]="detalle != undefined">
          <div class="detalle">
            <ng-template ngFor let-item [ngForOf]="detalle">
              <div>
                <div>{{item.descripcion}}</div>
                <div>{{item.valor}} </div>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </ng-template>
  
    <ng-template [ngIf]="footer != undefined">
      <div class="footer">
        <div>
            <button (click)="onClickDetalle.emit()">
                <span><i class="fa fa-fw fa-list-ul"></i>{{'COMMON.boton.detalle' | translate}}</span>
            </button>
        </div>
      </div>
    </ng-template>
  </div>