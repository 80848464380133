<div class="content-descarga" [formGroup]="formFiltros">
    <div>
        <label for=""  style="margin-bottom: 1px;">{{'MODEL.viaje.fechaEstimadaInicio' | translate}}</label>
        <p-calendar formControlName="fechaInicio"   [locale]="lang" dateFormat="dd/mm/yy" readonlyInput="readonlyInput" class="st-calendar"></p-calendar>
    </div>
    <div >
         <label for="" style="margin-bottom: 1px;">{{'MODEL.viaje.fechaEstimadaFin' | translate}}</label><br>
        <p-calendar formControlName="fechaFin" [locale]="lang" dateFormat="dd/mm/yy" readonlyInput="readonlyInput" class="st-calendar"></p-calendar>
    </div>
    <div >
         <label for="" style="margin-bottom: 1px;">{{'WEB.dashboard.diario.filtros.cliente-transporte' | translate}}</label><br>
        <p-multiSelect  [options]="listClienteTransporte" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                        formControlName="clientesTransporte" styleClass="st-multiSelect" [filter]="true" [showToggleAll]="false" [virtualScroll]="true" [itemSize]="30"
                       ></p-multiSelect>
    </div>

    <div >
        <label for="" style="margin-bottom: 1px;">{{'MODEL.clienteFinal.titulo' | translate}}</label><br>
       <p-multiSelect  [options]="listCliente" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                       formControlName="clientes" styleClass="st-multiSelect" [filter]="true"  [showToggleAll]="false" [virtualScroll]="true" [itemSize]="30"
                       (onChange)="onChangeCliente($event)"></p-multiSelect>
    </div>

   <div >
    <label for="" style="margin-bottom: 1px;">{{'MODEL.sede.titulo' | translate}}</label><br>
    <p-multiSelect  [options]="listSede" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                   formControlName="sedes" styleClass="st-multiSelect" [filter]="true" [showToggleAll]="false" [virtualScroll]="true" [itemSize]="30"
                   ></p-multiSelect>
                 
    </div>

    <div >
         <label for="" style="margin-bottom: 1px;">{{'WEB.dashboard.diario.filtros.canales' | translate}}</label><br>
        <p-multiSelect  [options]="listCanal" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                        formControlName="canales" styleClass="st-multiSelect" [filter]="true"  [showToggleAll]="false" [virtualScroll]="true" [itemSize]="30"
                        (onChange)="onChangeCanal($event)"></p-multiSelect>
    </div>
    <div >
         <label for="" style="margin-bottom: 1px;">{{'WEB.dashboard.diario.filtros.sub-canales' | translate}}</label><br>
        <p-multiSelect  [options]="listSubCanal" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                        formControlName="subCanales" styleClass="st-multiSelect" [filter]="true"  [showToggleAll]="false" [virtualScroll]="true" [itemSize]="30"
                        ></p-multiSelect>
    </div>
    <div >
         <label for="" style="margin-bottom: 1px;">{{'WEB.dashboard.diario.filtros.zonas-transporte' | translate}}</label><br>
        <p-multiSelect  [options]="listZonaTransporte" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                        formControlName="zonasTransporte" styleClass="st-multiSelect" [filter]="true" [showToggleAll]="false" [virtualScroll]="true" [itemSize]="30"
                       ></p-multiSelect>
    </div>
    <div >
         <label for="" style="margin-bottom: 1px;">{{'WEB.dashboard.diario.filtros.categorias' | translate}}</label><br>
        <p-multiSelect  [options]="listCategoria" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                        formControlName="categorias" styleClass="st-multiSelect" [filter]="true"  [showToggleAll]="false" (onChange)="onChangeCombo()" [virtualScroll]="true" [itemSize]="30"></p-multiSelect>
    </div>
    <div >
         <label for="" style="margin-bottom: 1px;">{{'WEB.dashboard.diario.filtros.tipos-tarea' | translate}}</label><br>
        <p-multiSelect  [options]="listTipoTarea" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                        formControlName="tiposTarea" styleClass="st-multiSelect" [filter]="true"  [showToggleAll]="false" [virtualScroll]="true" [itemSize]="30"
                       ></p-multiSelect>
    </div>
    <div>
      <label for="" style="margin-bottom: 1px;">{{'WEB.mantenimiento.marca.titulo' | translate}}</label><br>
      <p-multiSelect  [options]="listMarca" defaultLabel="{{'WEB.control.estados-viaje.resumen-viaje.seleccione' | translate}}"
                      formControlName="marcas" styleClass="st-multiSelect" [filter]="true" [showToggleAll]="false" [virtualScroll]="true" [itemSize]="30"
                      ></p-multiSelect>
  </div>

</div>

<div class="btn-descargar">
    <div >
        <div *ngIf="sinRegistros">
            <div *ngIf="sinRegistros" class="alert alert-danger">
                {{'WEB.reporte.error-exportacion-lista-vacia' | translate}}
            </div>
        </div>
    </div>
    <button id="btn-tareas-exportar" type="button" class="btn btn-primary" (click)="exportarComoXLS()" >
        <span><i class="fa fa-fw fa-download"></i>{{'COMMON.boton.descargar' | translate}}</span>
    </button>
</div>
<app-loading-animation></app-loading-animation>
