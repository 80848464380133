import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../index';

//Clases
import { Rubro } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class RubroService {

  constructor(private http : HttpClient2) { }

  listar() : Observable<Array<Rubro>>{    
    return this.http.listar("/rubros").map(
      (data: IResultado) => {
        let list = Array<Rubro>();
        let obj;
        for (const d of data.resultado) {
          obj = new Rubro();
          obj.copiar(d);
          list.push(obj);
        }
        return list;
    });
  }
}
