export class ChatDTO {
    conversacionId: number;
    companiaId: number;
    viajeId?: number;
    usuarioId?: number;
    usuarioNombre?: string;
    estado?: number;
    tipo?: number;
    texto?: string;
    url?: string;
    fechaEnvio?: number;
    codigoViaje?: string;

    //Constructor
    constructor(){}; 

    copiar?(objeto : any){
        //Propios
        this.conversacionId = objeto.conversacionId;
        this.companiaId = objeto.companiaId;
        this.viajeId = objeto.viajeId;
        this.usuarioId = objeto.usuarioId;
        this.usuarioNombre = objeto.usuarioNombre;
        this.estado = objeto.estado;
        this.tipo = objeto.tipo;
        this.texto = objeto.texto;
        this.url = objeto.url;
        this.fechaEnvio = objeto.fechaEnvio;
        this.codigoViaje = objeto.codigoViaje;
    }
}