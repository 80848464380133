
export class ExportarDashboardDiarioDTO{

    //Atributos
    tituloNroIncidentes : string;
    nroIncidentes : number;
    tituloNroViajes : string;
    nroViajes : number;
    tituloNroTareas : string;
    nroTareas : number;

    tituloEstadoViaje : string;
    listEstadosViaje : Array<Map<string, any>>;

    tituloEstadoTiempo : string;
    listEstadosTiempo : Array<Map<string, any>>;

    tituloEstadoTarea : string;
    listEstadosTarea : Array<Map<string, any>>;

    tituloRadioControl : string;
    descDentroRadioControl : string;
    valDentroRadioControl : number;
    porcentajeDentroRadioControl : number;

    descFueraRadioControl : string;
    valFueraRadioControl : number;
    porcentajeFueraRadioControl : number;
    
    //Constructor
    constructor(){};

    //Metodo Color de Celda
    /*cellStyle(isValid: boolean){
        if(!isValid)
            return "red-bg";
        return "";
    }*/
    
}