import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-st-card',
  templateUrl: './st-card.component.html',
  styleUrls: ['./st-card.component.scss']
})
export class StCardComponent {

  @Input() header: string;
  @Input() cabecera: string;
  @Input() detalle: {descripcion:string, valor:string} [];
  @Input() header_background: string;
  @Input() footer: boolean;
  @Output() onClickDetalle: EventEmitter<any> = new EventEmitter<any>();

}
