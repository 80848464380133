import { EmpresaTransporte } from './empresaTransporte';
import { Usuario } from './usuario';

export class Transportista {
    //PK
    transportistaId : number;

    //Atributos
    nombre : string;
    apellido : string;
    docIdentificacion : string;
    licencia : string;
    fcmId : string;
    estado : number;
    foto : string;

    //Relaciones
    empresaTransporte : EmpresaTransporte;
    usuario : Usuario; 

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;
    disponibilidad: boolean;

    constructor(){
        this.empresaTransporte = new EmpresaTransporte();
        this.usuario = new Usuario();
    }

    //Metodos
    get nombreCompleto() : string{
        let nombreCompleto;
        if(this.nombre != undefined){
            nombreCompleto = this.nombre;

            if(this.apellido != undefined){
                nombreCompleto += " ";
                nombreCompleto += this.apellido;
            }
        }

        return nombreCompleto;
    }
    copiar(objeto : any){
        //Propios
        this.transportistaId = objeto.transportistaId;
        this.nombre = objeto.nombre;
        this.apellido = objeto.apellido;
        this.docIdentificacion = objeto.docIdentificacion;
        this.licencia = objeto.licencia;
        this.fcmId = objeto.fcmId;
        this.foto = objeto.foto;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.estado = objeto.estado;
        this.disponibilidad = objeto.disponibilidad;

        //Relaciones
        if(objeto.empresaTransporte != null){
            this.empresaTransporte.copiar(objeto.empresaTransporte);
        }
        if(objeto.usuario != null){
            this.usuario.copiar(objeto.usuario);
        }
    }
}
