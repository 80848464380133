<p-dialog header="{{'COMMON.chat.modal.titulo' | translate }}" [(visible)]="chatProvider.displayModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="contenedor">
        <img src="assets/svg/icons/limite_chat.svg"  width="70" height="70">
        <p class="texto">{{'COMMON.chat.modal.texto1'| translate}}</p>
        <br>
        <p>{{'COMMON.chat.modal.texto2'| translate}}</p>
        <br>
        <button  class="btn btn-primary buton" [style]="{width: '80%'}" (click)="showModalDialog(false)" >    
            <span>{{'COMMON.chat.boton.entiendo'| translate}}</span>
        </button>
        <br>
    </div>
</p-dialog>
