import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EtiquetaContent } from '../../types/etiqueta-content';

@Component({
  selector: 'st-label-etiqueta-content',
  templateUrl: './label-etiqueta-content.component.html',
  styleUrls: ['./label-etiqueta-content.component.scss'],
  providers: [{ 
    provide: EtiquetaContent, 
    useExisting: LabelEtiquetaContentComponent
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelEtiquetaContentComponent extends EtiquetaContent implements OnInit {

  @Input() nombreEtiqueta: string;

  constructor() { super() }

  ngOnInit(): void {
  }

  obtenerNombre(): string {
    return this.nombreEtiqueta;
  }

}
