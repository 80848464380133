import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-quimica',
  template: `
    <div class="wrapper">
      <img src="../../assets/images/quimica-text1.png" class="text1"/>
      <img src="../../assets/images/quimica-text2.png" class="text2"/>
      <iframe [src]="url | domseguro" height="418" width="450"></iframe>
      <div class="quimica-logo"></div> 
      <div class="footer">
        <span>Proveído por</span>
        <img src="../../assets/images/comsatellogo.svg"/>
      </div>
    </div>
    `,
  styles: [`
    .wrapper{
      height: 100vh;
      margin: 0;
      background-image: url('../../assets/images/widget-background.png');
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      position: relative;
    }
    .wrapper .text1{
      position: absolute;
      top: 27.03%;
      left: 7.76%;
    }
    .wrapper .text2{
      position: absolute;
      top: 22.41%;
      left: 33.13%;
    }
    .wrapper iframe{
      border: none;
      position: absolute;
      top: 19.17%;
      right: 149px;
      box-shadow: 0px 0px 10px #848484;
    }
    .wrapper .quimica-logo{
      background-image: url('../../assets/images/quimica-watermark.png');
      position: absolute;
      bottom: 65px;
      left: 0;
      height: 387px;
      width: 195px;
    }
    .wrapper .footer{
      height: 51px;
      width: 100%;
      border: none;
      position: absolute;
      bottom: 0;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-shadow: 0px 2px 7px black;
    }
    .wrapper .footer span{
      font-size: 1.2rem;
      margin-right: 30px;
    }
    .wrapper .footer img{
      margin-right: 40px;
    }
  `]
})
export class WidgetQuimicaComponent implements OnInit {

  url: string;

  constructor() { }

  ngOnInit(): void {
    this.url = location.origin + "/#/widget/74";
  }

}
