import { MarcaService } from '@shared/services/marca.service';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Cookies, dateToString, rangoFechasActual } from '@shared/commons';
import { PrimeUtils } from '@shared/commons/prime-utils';
import { LoadingAnimationComponent } from '@shared/components';
import { Canal, Categoria, ClienteFinal, ClienteTransporte, Sede, SubCanal, ZonaTransporte } from '@shared/models';
import { Marca } from '@shared/models/marca';
import { GeneralProvider } from '@shared/providers';
import { CanalService, CategoriaService, ClienteFinalService, ClienteTransporteService, SedeService, SubCanalService, UnidadTransporteService, ZonaTransporteService } from '@shared/services';
import { SelectItem } from 'primeng';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-descarga',
  templateUrl: './descarga.component.html',
  styleUrls: ['./descarga.component.scss'],
  providers: [ClienteTransporteService, UnidadTransporteService, MarcaService, CanalService, 
    SubCanalService, ZonaTransporteService, CategoriaService, TranslateService,
    ClienteFinalService,SedeService]
})
export class DescargaComponent implements OnInit {
  @Output() descargaEmit: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();
  lang: any;
  usuarioId: string;
  rangoMes;
  currentUser;

  @ViewChild(LoadingAnimationComponent)
  loadingComponent: LoadingAnimationComponent;

  //Variables Filtros
  formFiltros: FormGroup;
  listClienteTransporte: Array<SelectItem>;
  listCliente: Array<SelectItem>;
  listSede: Array<SelectItem>;
  listCanal: Array<SelectItem>;
  listSubCanal: Array<SelectItem>;
  listZonaTransporte: Array<SelectItem>;
  listCategoria: Array<SelectItem>;
  listTipoTarea: Array<SelectItem>;
  listMarca: Array<SelectItem>;

  flagVisibility: boolean;

  //Error de lista vacia
  sinRegistros: boolean;

  //mensaje loading
  mensajeLoading: string;

  constructor(private general: GeneralProvider,
    private translate: TranslateService,
    private clienteTransporteService: ClienteTransporteService,
    private unidadTransporteService: UnidadTransporteService,
    private canalService: CanalService,
    private subCanalService: SubCanalService,
    private zonaTransporteService: ZonaTransporteService,
    private categoriaService: CategoriaService,
    private marcaService: MarcaService,
    private clienteFinalService:ClienteFinalService,
    private sedeService:SedeService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.usuarioId = this.currentUser.usuarioId;
    this.rangoMes = rangoFechasActual(new Date(), '2D', 'D');
  }



  ngOnInit(): void {
    let _language = Cookies.getCookie("LANGUAGE");
    this.lang = PrimeUtils.getCalendarLanguage(_language);
    //Constantes y/o Etiquetas
    this.listTipoTarea = this.general.getListaTipoTarea();
    this.cargarForm();
    this.cargarDatosCombo();
  }



  cargarForm() {
    this.formFiltros = new FormGroup({
      fechaInicio: new FormControl(this.rangoMes.inicio),
      fechaFin: new FormControl(this.rangoMes.fin),
      clientesTransporte: new FormControl(null),
      clientes: new FormControl(null),
      sedes: new FormControl(null),
      canales: new FormControl(null),
      subCanales: new FormControl(null),
      zonasTransporte: new FormControl(null),
      categorias: new FormControl(null),
      tiposTarea: new FormControl(null),
      tipoPeriodo: new FormControl(null),
      supervisorId: new FormControl(null),
      isReporteRadioControl: new FormControl(null),
      marcas: new FormControl(null)
    });
    this.sinRegistros = false;

    this.translate.get([
      'WEB.loader.obteniendo-data'
    ])
      .subscribe(t => {
        this.mensajeLoading = t['WEB.loader.obteniendo-data'];
      });
  }

  cargarDatosCombo() {
    let listObservables = new Array<Observable<any>>();
    listObservables.push(this.clienteTransporteService.listar());
    listObservables.push(this.canalService.listar());
    listObservables.push(this.zonaTransporteService.listar());
    listObservables.push(this.categoriaService.listar());
    listObservables.push(this.marcaService.listar());
    listObservables.push(this.clienteFinalService.listar());

    //Ejecutamos todas las llamadas y cuando se termine ocultamos el loading
    Observable.forkJoin(listObservables).subscribe(
      results => {
        //Clientes de Transporte
        let clientesTransporte = <Array<ClienteTransporte>>results[0];
        this.listClienteTransporte = [];
        for (let o of clientesTransporte) {
          this.listClienteTransporte.push({ label: o.nombre, value: o.clienteTransporteId });
        }

        //Canales
        let canales = <Array<Canal>>results[1];
        this.listCanal = [];
        for (let o of canales) {
          this.listCanal.push({ label: o.nombre, value: o.canalId });
        }

        //Zonas de Transporte
        let zonasTransporte = <Array<ZonaTransporte>>results[2];
        this.listZonaTransporte = [];
        for (let o of zonasTransporte) {
          this.listZonaTransporte.push({ label: o.nombre, value: o.zonaTransporteId });
        }

        //Categorias
        let categorias = <Array<Categoria>>results[3];
        this.listCategoria = [];
        for (let o of categorias) {
          this.listCategoria.push({ label: o.nombre, value: o.categoriaId });
        }

        //Marcas
        let marcas = <Array<Marca>>results[4];
        this.listMarca = [];
        for (let o of marcas) {
          this.listMarca.push({ label: o.descripcion, value: o.marcaId });
        }

        //Clientes
        let clientes = <Array<ClienteFinal>>results[5];
        this.listCliente = [];
        for (let o of clientes) {
          this.listCliente.push({ label: o.nombre, value: o.clienteFinalId });
        }

      },
      error => {
        console.log(error);
      },
      () => {
        this.loadingComponent.ocultarLoading();
      });
  }


  //Funciones Canal
  onChangeCanal(event): void {

    this.loadingComponent.mostrarLoading();
    this.subCanalService.listarMultiple(event.value).subscribe(
      (data: Array<SubCanal>) => {
        this.listSubCanal = [];
        for (let o of data) {
          this.listSubCanal.push({ label: o.nombre, value: o.subCanalId });
        }
        //Mantener la seleccion de los sub canales
        if (this.formFiltros.value.subCanales != null) {
          let valuesSubCanal = new Array<number>();
          valuesSubCanal = this.formFiltros.value.subCanales.filter((id) => {
            for (let subcanal of data) {
              if (id == subcanal.subCanalId) {
                return true;
              }
            }
            return false;
          });
          this.formFiltros.controls.subCanales.setValue(valuesSubCanal);
        }

        this.onChangeCombo();
      }
    );
    this.loadingComponent.ocultarLoading
  }

  //Funciones Cliente
  onChangeCliente(event): void {

    this.loadingComponent.mostrarLoading();
    this.sedeService.listarMultiple(event.value).subscribe(
      (data: Array<Sede>) => {
        this.listSede = [];
        for (let o of data) {
          this.listSede.push({ label: o.nombre, value: o.sedeId });
        }
        //Mantener la seleccion de los sub canales
        if (this.formFiltros.value.sedes != null) {
          let valuesSedes = new Array<number>();
          valuesSedes = this.formFiltros.value.sedes.filter((id) => {
            for (let sede of data) {
              if (id == sede.sedeId) {
                return true;
              }
            }
            return false;
          });
          this.formFiltros.controls.sedes.setValue(valuesSedes);
        }

        this.onChangeCombo();
      }
    );
    this.loadingComponent.ocultarLoading
  }
  
  //Funciones Componente
  onChangeCombo() {
    this.loadingComponent.mostrarLoading();
    let params: HttpParams = this.getParams();
    // this.change.emit(params);
    this.loadingComponent.ocultarLoading();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    let datos = this.formFiltros.value;

    if (datos.fechaInicio != null) {
      params = params.append("fechaInicio", dateToString(datos.fechaInicio));
    }
    if (datos.fechaFin != null) {
      params = params.append("fechaFin", dateToString(datos.fechaFin));
    }
    if (datos.clientesTransporte != null && datos.clientesTransporte.length) {
      params = params.append("clientesTransporte", datos.clientesTransporte.toString());
    }
    if (datos.canales != null && datos.canales.length) {
      params = params.append("canales", datos.canales.toString());
    }
    if (datos.subCanales != null && datos.subCanales.length) {
      params = params.append("subcanales", datos.subCanales.toString());
    }
    if (datos.zonasTransporte != null && datos.zonasTransporte.length) {
      params = params.append("zonasTransporte", datos.zonasTransporte.toString());
    }
    if (datos.categorias != null && datos.categorias.length) {
      params = params.append("categorias", datos.categorias.toString());
    }
    if (datos.tiposTarea != null && datos.tiposTarea.length) {
      params = params.append("tiposTarea", datos.tiposTarea.toString());
    }
    if (datos.tipoPeriodo != null) {
      params = params.append("tipoPeriodo", datos.tipoPeriodo);
    }
    if (datos.supervisorId != null) {
      params = params.append("supervisorId", datos.supervisorId);
    }
    if (datos.isReporteRadioControl != null) {
      params = params.append("isReporteRadioControl", datos.isReporteRadioControl);
    }
    if (datos.marcas != null && datos.marcas.length) {
      params = params.append("marcas", datos.marcas.toString());
    }
    if (datos.clientes != null && datos.clientes.length) {
      params = params.append("clientesFinales", datos.clientes.toString());
    }
    if (datos.sedes != null && datos.sedes.length) {
      params = params.append("sedes", datos.sedes.toString());
    }
    params = params.append("usuarioId", this.usuarioId);

    return params;
  }

  exportarComoXLS() {
    this.loadingComponent.cambiarMensaje(this.mensajeLoading);
    this.loadingComponent.mostrarLoading();
    this.descargaEmit.emit(this.getParams());
  }

  mostrarMensajeSinRegistros() {
    this.sinRegistros = true;
    setTimeout(() => {
      this.sinRegistros = false;
    }, 2000);
  }
}
