import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { SelectItem } from 'primeng/primeng';

import { Usuario, Funcionalidad, LogSession, LogActividad } from '../models';


import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class LogActividadService {
  ciaId : string;
  currentUser : any;
  logActividad : LogActividad;

  constructor(private http : HttpClient2) { 
    this.currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.ciaId = "/"+this.currentUser.compania.companiaId;

    this.logActividad = new LogActividad();
    this.logActividad.usuario = new Usuario();
    this.logActividad.usuario.usuarioId = this.currentUser.usuarioId;
    this.logActividad.logSession = new LogSession();
    this.logActividad.logSession.logSessionId = this.currentUser.logSessionId;
  }

  registrar(funcionalidad : number){
    this.logActividad.funcionalidad = new Funcionalidad();
    this.logActividad.funcionalidad.funcionalidadId = funcionalidad;
    return this.http.registrar('/log-actividad', this.logActividad);       
  }

  cerrar(logActividadId : number){
    this.logActividad.logActividadId = logActividadId;
    return this.http.modificarParcial('/log-actividad', this.logActividad);
  }
  
}
