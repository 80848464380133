
export class NotificacionSmsDTO {

    //PK
    notificacionId : number;

    //Atributos
    tipoNotificacion : number;
    fechaCreacion : Date;
    comentario: string;
    adicional1 : number;

    copiar(objeto : any){
        this.notificacionId = objeto.notificacionId;
        this.tipoNotificacion = objeto.tipoNotificacion;
        this.fechaCreacion = objeto.fechaCreacion;
        this.comentario = objeto.comentario;
        this.adicional1 = objeto.adicional1;
    }


}
