import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { environment } from '../../../environments/environment'
import { io, Socket } from 'socket.io-client';
import { C } from '@shared/commons';

@Injectable({
  providedIn: 'root'
})
export class SocketIOService {

  private socket : Socket; 
  private restHost : String = environment['chatWebUrl'];

  constructor() { 
    let socketHost = this.restHost.replace(C.CHAT_VARS.REST_PATH, '');
    this.socket = io( socketHost,{
        path :  C.CHAT_VARS.SOCKET_PATH,
        transports: ["websocket"]
    });
  }

  public obtenerSocket(): Socket{
    return this.socket;
  }


}
