import { Configuracion } from "./configuracion";
import { Etiqueta } from "./etiqueta";
import { Pais } from "./pais";

export class Compania {
    //PK
    companiaId : number;

    //Atributos
    codigo : string;
    estado : number;
    nombre : string;
    ruc : string;
    activarSms: number | boolean;
    esLimitado: number;
    limiteMensualSms: number;
    limiteDiarioRuteo: number;
    tipoGestion: number;
    transbordo: number;
    skuGestion: number;
    esTransportista : boolean;
    evidenciaTotal : boolean;
    evidenciaParcial : boolean;
    evidenciaRechazo : boolean;
    logo: any;
    editarDireccion: boolean;
    cambioEstadoTarea: boolean;
    ignorarBloqueoControl: boolean;
    estadoApt: boolean;
    estadoRuta: boolean;
    estadoInicioCarga : boolean;
    estadoFinCarga : boolean;
    listCompaniaEtiqueta:any;
    adicionarEtiqueta: boolean;
    contrasenhaTask: string;
    companiaCliente: boolean;
    checkCompaniaUsuarioSac: boolean;
    gestionPedido: number;
    gestionPedidosProvincia: number;
    estadoAutomatico: number;
    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;
    esTransportistaToString : string;
    agruparEntregas:number;
    //Relaciones
    demoEtiquetaCount: number;
    pais: Pais;
    //listCompaniaEtiqueta : CompaniaEtiqueta [];
    configuracion? : Array<Configuracion>;
    mezclarViajes: boolean;
    homologacion : boolean;
    estadoMonitoreoGps: boolean;
    monitoreoGps: number;
    rutasUniversales: number;
    producto: number;

    //Constructor
    constructor(companiaId?: number){
        this.companiaId=companiaId;
    };

    //Metodos
    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    get activarSmsToString(){
        if(this.activarSms!=0 && this.activarSms == 1){
            return "COMMON.boton.si";
        }

        return "COMMON.boton.no";
    }

    clone(): any {
        var cloneObj = new (this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    copiar(objeto : any){
        //Propios
        this.companiaId = objeto.companiaId;
        this.codigo = objeto.codigo;
        this.estado = objeto.estado;
        this.nombre = objeto.nombre;
        this.ruc = objeto.ruc;
        this.activarSms = objeto.activarSms;
        this.tipoGestion = objeto.tipoGestion;
        this.transbordo = objeto.transbordo;
        this.skuGestion = objeto.skuGestion;
        this.esTransportista = objeto.esTransportista;
        this.evidenciaTotal = objeto.evidenciaTotal;
        this.evidenciaParcial = objeto.evidenciaParcial;
        this.evidenciaRechazo = objeto.evidenciaRechazo;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.logo = objeto.logo;
        this.editarDireccion = objeto.editarDireccion;
        this.cambioEstadoTarea = objeto.cambioEstadoTarea;
        this.estadoApt = objeto.estadoApt;
        this.estadoInicioCarga = objeto.estadoInicioCarga;
        this.estadoFinCarga = objeto.estadoFinCarga;
        this.estadoRuta = objeto.estadoRuta;
        this.ignorarBloqueoControl=objeto.ignorarBloqueoControl;
        this.esLimitado = objeto.esLimitado;
        this.limiteMensualSms = objeto.limiteMensualSms;
        this.limiteDiarioRuteo = objeto.limiteDiarioRuteo;
        this.contrasenhaTask = objeto.contrasenhaTask;
        this.agruparEntregas = objeto.agruparEntregas;
        this.estadoAutomatico = objeto.estadoAutomatico;
        this.companiaCliente = false;
        this.checkCompaniaUsuarioSac = false;
        this.mezclarViajes = objeto.mezclarViajes;
        this.homologacion = objeto.homologacion;
        this.gestionPedido = objeto.gestionPedido;
        this.gestionPedidosProvincia = objeto.gestionPedidosProvincia;
        this.monitoreoGps = objeto.monitoreoGps;
        this.rutasUniversales = objeto.rutasUniversales;
        this.producto = objeto.producto;
        //Relaciones
        if(objeto.pais != null){
            this.pais = new Pais();
            this.pais.copiar(objeto.pais);
        }
    }

    get tipoControlToString(): string{
        if(this.esLimitado){
            return 'MODEL.compania.limite-sms';
        }
        return 'MODEL.compania.sin-limite-sms';
    }

}