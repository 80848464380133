import { Component, OnInit, AfterViewInit , Renderer2, ViewChild, Input, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChatDTO, ChatService } from '../resources';

@Component({
    selector: 'app-mensaje-chat',
    templateUrl: './mensaje-chat.component.html',
    styleUrls: ['./mensaje-chat.component.scss'],
})
export class MensajeChatComponent implements OnInit, AfterViewInit {
    @Input()chat: ChatDTO;
    @ViewChild('wrapperMensajeChat') wrapperMensajeChat: ElementRef;
    @ViewChild('mensajeChat') mensajeChat: ElementRef;

    currentUser:any;

    txtEstadoLeido: string;
    txtEstadoEnviado: string;

    constructor(
        private translate : TranslateService,
        private renderer: Renderer2)
    {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    }
    
    ngOnInit(){
      this.obtenerEtiquetas();
    }
    
    ngAfterViewInit(){
      
        if (this.chat.usuarioId != this.currentUser.usuarioId ){
            this.renderer.addClass(this.wrapperMensajeChat.nativeElement, 'wrapper-mensaje-chat-transportista');
            this.renderer.addClass(this.mensajeChat.nativeElement, 'mensaje-chat-transportista');
        }
        else{
            this.renderer.addClass(this.wrapperMensajeChat.nativeElement, 'wrapper-mensaje-chat-supervisor');
            this.renderer.addClass(this.mensajeChat.nativeElement, 'mensaje-chat-supervisor');
        }
    }

    private obtenerEtiquetas(){
        this.translate.get([
            'WEB.chat.estado.leido',
            'WEB.chat.estado.enviado',
        ]).subscribe (translations => {
            this.txtEstadoLeido = translations['WEB.chat.estado.leido'];
            this.txtEstadoEnviado = translations['WEB.chat.estado.enviado'];
        });
    }
}