<div class="ui-g filtros" [formGroup]="formFiltros">
    <div class="ui-g-12 ui-md-4 ui-lg-1">
        <p-calendar formControlName="fechaInicio" (onSelect)="onChangeCombo('fechaInicio')"  [locale]="lang" dateFormat="dd/mm/yy" readonlyInput="readonlyInput" class="st-calendar"></p-calendar>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-1">
        <p-calendar formControlName="fechaFin" (onSelect)="onChangeCombo('fechaFin')" [locale]="lang" dateFormat="dd/mm/yy" readonlyInput="readonlyInput" class="st-calendar"></p-calendar>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-2">
        <p-multiSelect  [options]="listClienteTransporte" defaultLabel="{{'WEB.dashboard.diario.filtros.cliente-transporte' | translate}}"
                        formControlName="clientesTransporte" styleClass="st-multiSelect" [filter]="true" [displaySelectedLabel]="false" [showToggleAll]="false"
                        (onChange)="onChangeCombo()"></p-multiSelect>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-1">
        <p-multiSelect  [options]="listCanal" defaultLabel="{{'WEB.dashboard.diario.filtros.canales' | translate}}"
                        formControlName="canales" styleClass="st-multiSelect" [filter]="true" [displaySelectedLabel]="false" [showToggleAll]="false"
                        (onChange)="onChangeCanal($event)"></p-multiSelect>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-2">
        <p-multiSelect  [options]="listSubCanal" defaultLabel="{{'WEB.dashboard.diario.filtros.sub-canales' | translate}}"
                        formControlName="subCanales" styleClass="st-multiSelect" [filter]="true" [displaySelectedLabel]="false" [showToggleAll]="false"
                        (onChange)="onChangeCombo()"></p-multiSelect>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-2">
        <p-multiSelect  [options]="listZonaTransporte" defaultLabel="{{'WEB.dashboard.diario.filtros.zonas-transporte' | translate}}"
                        formControlName="zonasTransporte" styleClass="st-multiSelect" [filter]="true" [displaySelectedLabel]="false" [showToggleAll]="false"
                        (onChange)="onChangeCombo()"></p-multiSelect>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-1">
        <p-multiSelect  [options]="listCategoria" defaultLabel="{{'WEB.dashboard.diario.filtros.categorias' | translate}}"
                        formControlName="categorias" styleClass="st-multiSelect" [filter]="true" [displaySelectedLabel]="false" [showToggleAll]="false" (onChange)="onChangeCombo()"></p-multiSelect>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-2">
        <p-multiSelect  [options]="listTipoTarea" defaultLabel="{{'WEB.dashboard.diario.filtros.tipos-tarea' | translate}}"
                        formControlName="tiposTarea" styleClass="st-multiSelect" [filter]="true" [displaySelectedLabel]="false" [showToggleAll]="false"
                        (onChange)="onChangeCombo()"></p-multiSelect>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-1" *ngIf="this.flagSupervisor">
      <p-multiSelect  [options]="listMarca" defaultLabel="{{ this.titleHeaderPage }}"
                      formControlName="marcas" styleClass="st-multiSelect" [filter]="true" [displaySelectedLabel]="false" [showToggleAll]="false"
                      (onChange)="onChangeCombo($event)"></p-multiSelect>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-1" *ngIf="this.flagSupervisor">
        <p-multiSelect  [options]="listSupervisor" defaultLabel="{{'WEB.seguridad.supervisor.titulo' | translate}}"
                        formControlName="supervisores" styleClass="st-multiSelect" [filter]="true" [displaySelectedLabel]="false" [showToggleAll]="false"
                        (onChange)="onChangeCombo($event)"></p-multiSelect>
      </div>

</div>
<app-loading-animation></app-loading-animation>
