import { throwIfEmpty } from "rxjs/operators";



export class NotificacionSensorDTO {

    //PK
    notificacionId : number;

    //Atributos
    tipoNotificacion : number;
    fechaCreacion : Date;
    entidadTipo : String;
    entidadId : number;
    comentario: string;
    adicional1 : number;
    adicional2 : string;
    codigoViaje: string;
    umbralMin: string;
    umbralMax: string;
    value1MultiReadingId: number;
    multiReadingId : number;
    multiReadingTypeId: string;

    copiar(objeto : any){
        this.notificacionId = objeto.notificacionId;
        this.tipoNotificacion = objeto.tipoNotificacion;
        this.fechaCreacion = objeto.fechaCreacion;
        this.entidadTipo = objeto.entidadTipo;
        this.entidadId = objeto.entidadId;
        this.comentario = objeto.comentario;
        this.adicional1 = objeto.adicional1;
        this.adicional2 = objeto.adicional2;
        this.codigoViaje = objeto.codigoViaje;
        this.value1MultiReadingId = objeto.value1MultiReadingId;
        this.multiReadingId = objeto.multiReadingId;
        this.multiReadingTypeId = objeto.multiReadingTypeId;
        if(this.adicional2 != null && this.adicional2 != undefined){
            const umbrales = this.adicional2.split("|");
            if (umbrales.length >=1) {
                this.umbralMin = umbrales[0];
            }
            if (umbrales.length >=2) {
                this.umbralMax = umbrales[1];
            }
           
        }
    }


}
