import { ClienteTransporte } from './clienteTransporte';
import { ClienteFinal } from './clienteFinal';
import { Tarea } from './tarea';

export class Recojo extends Tarea{
    //PK
    recojoId : number;

    //Atributos
    nroRecojo : string;

    //Relaciones
    clienteTransporte : ClienteTransporte;
    clienteFinal : ClienteFinal;

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    //Metodos
    copiar(objeto : any){
        super.copiar(objeto);
        //Propios
        this["@type"] = "Recojo";
        this.recojoId = objeto.recojoId;
        this.nroRecojo = objeto.nroRecojo;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;

        //Relaciones
        if(objeto.clienteTransporte != null){
            this.clienteTransporte = new ClienteTransporte();
            this.clienteTransporte.copiar(objeto.clienteTransporte);
        }
        if(objeto.clienteFinal != null){
            this.clienteFinal = new ClienteFinal();
            this.clienteFinal.copiar(objeto.clienteFinal);
        }
    }
}
