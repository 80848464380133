import { HttpClient, HttpResponse, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { C, IResultado } from '@shared/commons';
import { HttpClient2 } from '@shared/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatDTO } from './chat.dto';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private http2 : HttpClient2,
    private http1 : HttpClient) { }

  listarHistorial(viajeId: number): Observable<Array<ChatDTO>> {
      const href = `${C.BASE_CHAT_URL}/viajes/${viajeId}/conversaciones`;

      return this.http1.get(href, { observe: 'response' })
          .pipe(
              map((response: HttpResponse<IResultado>) => {
                  let body: IResultado = response.body;
                  let list = Array<ChatDTO>();
                  let obj;
                  if (body.data) {
                    for (const d of body.data) {
                      obj = new ChatDTO();
                      obj.copiar(d);
                      list.push(obj);
                    }
                  }
                  return list;
              })
          );

  }

  subirArchivo(formData: FormData) : Observable<any> {
      return this.http2.registrar('/images/upload', formData);
  }


  

}
