import { EstadoGuiaEntrega } from ".";

export class GuiaEvidencia{
    guiaEvidenciaId : number;
    imagenEvidencia : string;
    observacion : string;
    imagenEvidenciaUrl : string;
    imagenEvidenciab64: any;

    //
    estadoGuiaEntrega : EstadoGuiaEntrega;

    copiar(objeto : any){
        //Propios
        this.guiaEvidenciaId = objeto.guiaEvidenciaId;
        this.imagenEvidencia = objeto.imagenEvidencia;
        this.observacion = objeto.observacion;
        this.imagenEvidenciaUrl = objeto.imagenEvidenciaUrl;
        this.estadoGuiaEntrega = new EstadoGuiaEntrega();
        this.estadoGuiaEntrega.copiar(objeto.estadoGuiaEntrega);
    }
}