import { Compania } from '.';
import { TipoProducto } from './tipoProducto';

export class Producto {
    //PK
    productoId : number;

    //Atributos
    codigo : string = "";
    descripcion : string = "";
    unidadPresentacion : string = ""; 
    unidadVenta : string = "";
    unidadProducto : number = 0;
    productosContenedor : number = 0;

    //Relaciones
    compania : Compania;
    tipoProducto : TipoProducto;
    
    //Metodos
    copiar(objeto : any){
        //Propios

        this.productoId = objeto.productoId;
        this.codigo = objeto.codigo;
        this.descripcion = objeto.descripcion;
        this.unidadPresentacion = objeto.unidadPresentacion;
        this.unidadVenta = objeto.unidadVenta;
        this.unidadProducto = objeto.unidadProducto;
        this.productosContenedor = objeto.productosContenedor;
        
        //Relaciones

        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }

        if(objeto.tipoProducto != null){
            this.tipoProducto = new TipoProducto();
            this.tipoProducto.copiar(objeto.tipoProducto);
        }
    }
    
    instanciar(){
        if(this.compania==null) this.compania = new Compania();
        if(this.tipoProducto==null) this.tipoProducto = new TipoProducto();
    }

}
