import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { C } from '../commons/constantes';
import { IResultado } from '../index';

//Clases
import { Perfil } from '../models';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class PerfilService {
  esSuperUsuario : any;
  esEmpresaTransporteAdministrador : any;
  
  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));        
    this.esSuperUsuario = currentUser.esSuperUsuario;
    this.esEmpresaTransporteAdministrador = currentUser.esEmpresaTransporteAdministrador;
  }

  listar() : Observable<Array<Perfil>>{
    return this.http.listar("/perfiles").map((data : IResultado) => {
      let result = Array<Perfil>();
      if(data){
        result = data.resultado;
        if(!this.esSuperUsuario){
          result = result.filter(perfil => perfil.perfilId !== C.TIPO_PERFIL.SUPER_USUARIO);
        }
        if(this.esEmpresaTransporteAdministrador){
          result = result.filter(perfil => perfil.perfilId === C.TIPO_PERFIL.EMPRESA_TRANSPORTE || perfil.perfilId === C.TIPO_PERFIL.EMPRESA_TRANSPORTE_TRANSPORTISTA);
        }
      }
      return result;
    });
  }

}
