<div class="wrapper-mensaje-chat" #wrapperMensajeChat>
    <div class="mensaje-chat" #mensajeChat>
        <div *ngIf="chat.usuarioId != currentUser.usuarioId " class="autor-mensaje">
            <strong>{{ chat.usuarioNombre }}</strong>
        </div>
        <div *ngIf="chat.texto" class="texto-mensaje">
            {{ chat.texto }}
        </div>
        <img *ngIf="chat.url" class="url-mensaje" src="{{chat.url}}">
    </div>
    <small class="hora-estado-mensaje"><span>{{ chat.fechaEnvio | date:'HH:mm' }}</span><span *ngIf="chat.usuarioId == currentUser.usuarioId ">&nbsp;&nbsp;{{chat.estado == 1 ? txtEstadoEnviado : txtEstadoLeido}}</span></small>
</div>