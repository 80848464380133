import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DetalleReporteSmsDTO } from '@shared/dto';
import { Observable } from 'rxjs';
import { HttpClient2 } from '.';

@Injectable({
  providedIn: 'root'
})
export class DetalleSmsService {
  ciaId: string;

  constructor(private http: HttpClient2,
    private translate: TranslateService) {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.ciaId = "/" + currentUser.compania.companiaId;
    }

  reporte(params: any): Observable<Array<DetalleReporteSmsDTO>> {
 
    return this.http.consultar("/reportes" +"/detalle-reporte-consumo-sms", params).map(
      (data) => {        
        let resultado = new Array<DetalleReporteSmsDTO>();
        if (data) {                 
          data.resultado.forEach(objeto => {
            let detalleReporteSmsDto = new DetalleReporteSmsDTO();           
            detalleReporteSmsDto.copiar(objeto);
            resultado.push(detalleReporteSmsDto);
          });
        }      
        return resultado;
      }
    );
  }
}
