import { Compania } from './compania';

export class MotivoNoAutorizacion {
    //PK
    motivoNoAutorizacionId : number;

    //Atributos
    descripcion : string;
    codigo : string;
    estado : number;    
        
    //Relaciones    
    companiaId : Compania;

    //Constructor
    constructor(){};

    static clonar(objeto : any) : MotivoNoAutorizacion{
        let motivoNoAutorizacion : MotivoNoAutorizacion = new MotivoNoAutorizacion();
        //Propios
        motivoNoAutorizacion.motivoNoAutorizacionId = objeto.motivoNoAutorizacionId;
        motivoNoAutorizacion.descripcion = objeto.descripcion;
        motivoNoAutorizacion.codigo = objeto.codigo;
        motivoNoAutorizacion.estado = objeto.estado;
                        
        //Relaciones        
        if(motivoNoAutorizacion.companiaId != null){
            motivoNoAutorizacion.companiaId = new Compania();
            motivoNoAutorizacion.companiaId.copiar(objeto.compania);
        }
        return motivoNoAutorizacion;
    }

    copiar(objeto : any){
        //Propios
        this.motivoNoAutorizacionId = objeto.motivoNoAutorizacionId;
        this.descripcion = objeto.descripcion;
        this.codigo = objeto.codigo;
        this.estado = objeto.estado;
                        
        //Relaciones        
        if(objeto.companiaId != null){
            this.companiaId = new Compania();
            this.companiaId.copiar(objeto.compania);
        }
    }
    
}