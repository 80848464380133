import { Compania, Usuario } from '../../../../../shared';
import { C } from '../../../../../shared';
import { TranslateService } from '@ngx-translate/core';

export class ConsumoSMSDTO{
    mesAno : Date;
    cantConsumida: number;
    cantFallida: number;
    tipoControl: number;
    cantDisponible: number;
    ultEnvio : Date;

    //Relaciones
    compania : Compania;

    private translate: TranslateService;

    constructor(){}

    copiar(objeto : any){        
 
        this.mesAno = objeto.mesAno;
        this.cantConsumida = objeto.cantConsumida;
        this.ultEnvio = objeto.ultEnvio;
        this.cantFallida = objeto.cantFallida;
        
        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);

            if(this.compania.esLimitado){
                this.cantDisponible = this.compania.limiteMensualSms - this.cantConsumida;
            }
        }
                    
    }



}