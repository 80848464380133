import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-st-card-multiple',
  templateUrl: './st-card-multiple.component.html',
  styleUrls: ['./st-card-multiple.component.scss']
})
export class StCardMultipleComponent implements OnInit {
  
  @Input() headerMultiple: Array<{}>;
  @Input() cabecera: string;
  @Input() header_background: string;
  @Input() footer: boolean;
  @Output() onClickDetalle: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
