import { ConfiguracionMarcaDTO } from '@shared/dto/configuracionMarca.dto';
import { Compania } from './compania';
import { ConfiguracionMarca } from './configuracionMarca';

export class Marca {
    //PK
    marcaId : number;

    //Atributos
    codigo : string;
    descripcion : string;
    estado : number;

    //Relaciones
    compania : Compania;

    configuracionMarcas?: ConfiguracionMarca[];


    constructor(
        marcaId?:number,
        codigo?:string,
        descripcion?:string,
        estado?:number,
        compania?: Compania,
        configuracionMarcas?: ConfiguracionMarca[]) {
        this.marcaId=marcaId;
        this.codigo=codigo;
        this.descripcion=descripcion;
        this.estado=estado;
        this.compania=compania;
        this.configuracionMarcas=configuracionMarcas;
      }

    copiar(objeto : any){
        //Propios
        this.marcaId = objeto.marcaId;
        this.codigo = objeto.codigo;
        this.descripcion = objeto.descripcion;
        this.estado = objeto.estado;

        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }

        //Relaciones
        if(objeto.configuracionMarcas != null){
            let confmMarca : ConfiguracionMarca;
            this.configuracionMarcas = new Array();
            for (let r of objeto.configuracionMarcas) {
                if(r != null){
                    confmMarca = new ConfiguracionMarca();
                    confmMarca.copiar(r);
                    this.configuracionMarcas.push(confmMarca);
                }
            }
        }

    }

    instanciar(){
        if(this.compania==null) this.compania = new Compania();
        
    }
}