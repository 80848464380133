import { Cobranza } from '.';


export class CobranzaEvidencia {
    cobranzaEvidenciaId: number;
    observacion: string;
    imagenEvidenciaUrl: string;
    fechaCreacion: string;
    //relaciones
    cobranza: Cobranza;

    copiar(objeto: any){
        this.cobranzaEvidenciaId = objeto.cobranzaEvidenciaId;
        this.observacion = objeto.observacion;
        this.imagenEvidenciaUrl = objeto.imagenEvidenciaUrl;
        this.fechaCreacion = objeto.fechaCreacion;

        if(objeto.cobranza != null){
            this.cobranza = new Cobranza();
            this.cobranza.copiar(objeto.cobranza);
        }
    }

}