import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient2 } from './http-client.service';
import { IResultado } from '../commons/interfaces';
import { TipoDocumento } from '../models/tipoDocumento';

@Injectable()
export class TipoDocumentoService {
  ciaId : string;

  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = currentUser.esEmpresaTransporteAdministrador? "/"+currentUser?.companiaPadre: "/"+currentUser.compania.companiaId;
  }

  
  listar() : Observable<Array<TipoDocumento>>{
    return this.http.listar("/tipo-documento").map(
      (data: IResultado) => {
        let list = Array<TipoDocumento>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new TipoDocumento();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
      }
    );
  }
}
