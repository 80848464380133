import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { BusquedaAvanzadaComponent } from './busqueda-avanzada.component';

import { CalendarModule, DropdownModule, MultiSelectModule, SharedModule } from 'primeng';
import { SeccionModule } from '@shared/components/seccion.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [SharedModule, SeccionModule, TranslateModule,
    MultiSelectModule, CalendarModule, DropdownModule,
    FormsModule, ReactiveFormsModule

  ],
  declarations: [BusquedaAvanzadaComponent],
  exports: [BusquedaAvanzadaComponent]
})
export class BusquedaAvanzadaModule { }
