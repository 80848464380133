import { SolicitudAutorizacionDTO } from './solicitudAutorizacion.dto';

export class ProcesoSolicitudDTO {
    tipoProceso: number;
    motivoId: number;
    comentario: string;
    listaSolicitudAutorizacion:  SolicitudAutorizacionDTO[];
    
    constructor(){}

    copiar(objeto : any){
        //Propios
        this.tipoProceso = objeto.tipoProceso;
        this.motivoId = objeto.motivoId;
        this.comentario = objeto.comentario;

        if(objeto.listaSolicitudAutorizacion != null){
            let solicitudAutorizacion : SolicitudAutorizacionDTO;
            this.listaSolicitudAutorizacion = new Array();
            for (let r of objeto.listaSolicitudAutorizacion) { 
                if(r != null){
                    solicitudAutorizacion = new SolicitudAutorizacionDTO();
                    solicitudAutorizacion.copiar(r);
                    this.listaSolicitudAutorizacion.push(solicitudAutorizacion);
                }
            }
        }
    }
}
