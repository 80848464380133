import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Usuario, Compania } from '@shared/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompaniaService,  AppConfiguracionService } from '@shared/services';
import { CompaniaEtiqueta } from '../../models/companiaEtiqueta';
import { C } from '@shared/commons';
import { ChatProvider } from '@shared/providers';

@Component({
  selector: 'app-header-full',
  templateUrl: './header-full.component.html',
  styleUrls: ['./header-full.component.scss'],
  providers: [CompaniaService, AppConfiguracionService, FormBuilder]
})
export class HeaderFullComponent implements OnInit {
  //Sección Perfil
  currentUser: Usuario;
  logoCompania: string;
  listadoCompania : Compania[];
  result: any;
  formBusqueda: FormGroup;
  companiaId: any;
  etiquetas: any = {};

  constructor(
    public router: Router,
    private appConfiguracionService: AppConfiguracionService,
    private fb: FormBuilder,
    private chatProvider: ChatProvider
  ) {
                
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992) {
        this.toggleSidebar();
      }
    });

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
  }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.companiaId = currentUser.compania.companiaId;

    this.formBusqueda = this.fb.group({
      companiaId: this.companiaId,
      codigo: '',
      nombre: '',
      ruc: '',
      esTransportista: null,
    });

    if (currentUser.logo != null && currentUser.logo.length) {
        this.result = currentUser.logo;
    } else {
        this.result = "./assets/images/smart_tracing_eslogan.svg";
    }
      
      this.setWhatsappSupport();

    this.setLogIntegracionURL();
  }

  onLoggedout() {
    localStorage.removeItem('isLoggedin');
    this.chatProvider.changeViewsChats([]);
    //localStorage.removeItem('etiquetas');
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle('push-right');
  }
  setWhatsappSupport(){
    let md = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    let url = md ? 'https://api.whatsapp.com/send' : 'https://web.whatsapp.com/send';
    let param = {"llaves": "ITRACING_WHATSAPP_SOPORTE_CONTACTO,ITRACING_WHATSAPP_MENSAJE_INICIAL"};
    this.appConfiguracionService.consultar(param).subscribe(
      (data : any) =>{
        let phn = data.filter(e => e.llave == "ITRACING_WHATSAPP_SOPORTE_CONTACTO")[0].valor || '';
        let msg = data.filter(e => e.llave == "ITRACING_WHATSAPP_MENSAJE_INICIAL")[0].valor || '';
        
        msg = msg.replace("{0}", this.currentUser.nombre);
        msg = msg.replace("{1}", this.currentUser.compania.nombre);
        let message = msg || '';
        let phone = phn || '';

        const soporteWhatsapp: any = document.querySelector('#soporteWhatsapp');
        soporteWhatsapp.setAttribute("href", url + '?phone=' + phone + '&text=' + message);
      }, (error) => {
        console.log(error);
      }
    );
  }

  setLogIntegracionURL(){
    const logIntegracionURL: any = document.querySelector('#logIntegracionURL');
    logIntegracionURL.setAttribute("href", '/#/log-integracion');
  }
}