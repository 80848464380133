import { Compania } from './compania';
import { SubCanal } from './subcanal';

export class Canal {
    //PK
    canalId : number;

    //Atributos
    codigo : string;
    estado : number;
    nombre : string;
    solicitarCodigoCierre : boolean;
    tipoProductoIds? : any;
    correoOportunidadVenta : string;
    //Relaciones
    compania : Compania;
    listSubCanal : SubCanal[];
    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    constructor(){
    }

    get estadoRegistroToIcon() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "fa fa-fw fa-check";

                case 'P':
                    return "fa fa-fw fa-warning";

                case 'F':
                    return "fa fa-fw fa-times";

            }
        }
    }

    get estadoRegistroToColor() : string{
        if(this.estadoRegistro!=null){
            switch(this.estadoRegistro){
                case 'R':
                    return "btn-success";

                case 'P':
                    return "btn-warning";

                case 'F':
                    return "btn-danger";

            }
        }
    }

    //Metodos
    copiar(objeto : any){
        //Propios
        this.canalId = objeto.canalId;
        this.codigo = objeto.codigo;
        this.estado = objeto.estado;
        this.nombre = objeto.nombre;
        this.solicitarCodigoCierre = objeto.solicitarCodigoCierre;
        this.correoOportunidadVenta = objeto.correoOportunidadVenta;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.tipoProductoIds=objeto.tipoProductoIds;
        //Relaciones
        if(objeto.compania != null){
            this.compania = new Compania();
            this.compania.copiar(objeto.compania);
        }
        if(objeto.listSubCanal != null){
            let subCanal : SubCanal;
            this.listSubCanal = new Array();
            for (let r of objeto.listSubCanal) {
                if(r != null){
                    subCanal = new SubCanal();
                    subCanal.copiar(r);
                    this.listSubCanal.push(subCanal);
                }
            }
        }
        
    }

}
