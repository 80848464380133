export class EmpresaTransporteDTO {

    //PK
    empresaTransporteId: number;

    //Atributos
    empresaTransporteNombre : string;
    totalBulto: number;

    //Constructor
    constructor(){};


}