export class Posicion {
  //PK
  unidadTransporteId: number;

  //atributos
  coordenada: string;
  fechaHora: Date;

  constructor() {}

  copiar(objeto: any) {
    //Propios
    this.unidadTransporteId = objeto.unidadTransporteId;
    this.coordenada = objeto.coordenada;
    this.fechaHora = objeto.fechaHora;
  }
}
