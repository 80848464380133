export class SolicitudAutorizacioNotiDTO {
    //PK
    notificacionId : number;

    //Atributos
    estado : number;    
    fechaCreacion : Date;
    paquetesCompletados : number;
    totalPaquetes : number;
    tipoNotificacion : number;
    entidadTipo : String;
    entidadId : number;

    //Atributos Viaje  
    viajeId: number;
    codigoViaje: string;    
    placa: string;
    // Atributos Compania
    companiaId: number;      
        
    //Metodos        

    copiar(objeto : any){
        //Propios
        this.notificacionId = objeto.notificacionId;
        this.estado = objeto.estado;        
        this.fechaCreacion = objeto.fechaCreacion;
        this.paquetesCompletados = objeto.paquetesCompletados;
        this.totalPaquetes = objeto.totalPaquetes;
        this.tipoNotificacion = objeto.tipoNotificacion;
        this.entidadTipo = objeto.entidadTipo;
        this.entidadId = objeto.entidadId;

        //Relaciones
        if(objeto.viaje != null){            
            this.viajeId = objeto.viaje.viajeId;
            this.codigoViaje = objeto.viaje.codigo;
            if(objeto.viaje.unidadTransporte != null){
                this.placa = objeto.viaje.unidadTransporte.placa;
            }
            
        }
        if(objeto.compania != null){            
            this.companiaId = objeto.compania.companiaId;
        }
        
    }
}
