
import { PuntoVisita, Usuario } from '../../../../models';

export class NotificacionDTO {

    //PK
    notificacionId : number;

    //Atributos
    estado : number;
    fechaCreacion : Date;
    tipoNotificacion : number;
    entidadTipo : String;
    entidadId : number;
    comentario: string;

    //Atributos Compania
    companiaId : number;
        
    //Otros atributos
    puntoVisita : PuntoVisita;
    usuario: Usuario;

    //Metodos
    // copiar(objeto : any){
    //     //Propios
    //     this.notificacionId = objeto.notificacionId;
    //     this.estado = objeto.estado;
    //     this.fechaCreacion = objeto.fechaCreacion;
    //     this.entidadTipo = objeto.entidadTipo;
    //     this.entidadId = objeto.entidadId;
    //     this.companiaId = objeto.companiaId;

    //     //Relaciones
    //     if(objeto.puntoVisita != null) {
    //         this.puntoVisita = objeto.puntoVisita;
    //     }
    // }
}
