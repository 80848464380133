import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { HttpHeaders } from '@angular/common/http'
import { Cookies } from '../../shared/commons';
//Clases
import { EmpresaTransporte } from '../models';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ImportarEmpresaTransporteDTO } from '../dto/importarEmpresaTransporte.dto';
import { IResultado } from '../index';
import { EmpresaTransporteHubDevolucionDTO } from '@shared/dto/empresaTransporteHubDevolucion.dto';

@Injectable()
export class EmpresaTransporteService {

  ciaId : string;

  constructor(private http : HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = "/"+currentUser.compania.companiaId;
  }

  listar() : Observable<Array<EmpresaTransporte>>{
    let ciaId = "/"+JSON.parse(localStorage.getItem("currentUser")).compania.companiaId;
    return this.http.listar(ciaId+'/empresas-transporte').map(
      (data: IResultado) => {
        let list = Array<EmpresaTransporte>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new EmpresaTransporte();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  listarHijasPorCompaniaPadre() : Observable<Array<EmpresaTransporte>>{
    return this.http.listar(this.ciaId+'/empresas-transporte/hijas').map(
      (data: IResultado) => {
        let list = Array<any>();
        if (data) {
          for (const d of data.resultado) {
            list.push(d);
          }
        }
        return list;
    });
  }

  consultar(data){
    return this.http.consultar(this.ciaId+"/empresas-transporte/consultar", data).map(
      (data: IResultado) => {
        if(data){
          let list = Array<EmpresaTransporte>();
          let obj;
          for (const d of data.resultado) {
            obj = new EmpresaTransporte();
            obj.copiar(d);
            list.push(obj);
          }
          return list;
        }
    });
  }

  obtener(id : number) : Observable<EmpresaTransporte>{
    return this.http.obtener(this.ciaId+"/empresas-transporte/"+id).map(
      (data: IResultado) => {
        let obj = new EmpresaTransporte();
        obj.copiar(data.resultado);
        return obj;
    });
  }

  registrar(empresaTransporte : EmpresaTransporte, funcionalidad?: String){
    return this.http.registrar(this.ciaId+"/empresas-transporte", empresaTransporte, { funcionalidad });
  }

  modificar(empresaTransporte : EmpresaTransporte, funcionalidad?: String){
    return this.http.modificar(this.ciaId+"/empresas-transporte", empresaTransporte, { funcionalidad });
  }

  eliminar(empresaTransporteId : number, funcionalidad?: String){
    return this.http.eliminar(this.ciaId+"/empresas-transporte/"+empresaTransporteId, { funcionalidad });
  }
  
  obtenerExcelExportacionReportes(data: any, tipoReporte: string, ): Observable<any> {
    return this.http.consultar("/reportes/" + tipoReporte + "/exportar-excel", data, { responseType: "text" });
  }

  registrarImportacion(empresaTransporte : Array<ImportarEmpresaTransporteDTO>, funcionalidad?: String) : Observable<any>{
    return this.http.registrarImportacion(this.ciaId+"/empresas-transporte/registrar-importacion", empresaTransporte, { funcionalidad });
  }

  obtenerHeaders(httpOptions? : Object) : HttpHeaders{
    return this.http.obtenerHeaders();
  }

  listarcantidadempresatransportes(): Observable<Array<EmpresaTransporteHubDevolucionDTO>>{
    return this.http.listar(this.ciaId + '/empresas-transporte/cantidad-empresa').map(
      (data: IResultado)=>{
        return data.resultado;
      });
  }
}
