<div class="ui-g ui-fluid">
  <div class="container-loading" *ngIf="blocked">
    <div class="wrap-loading">
      <div class="spinner-wrap-loading">
        <div class="spinner-loading">
          <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
        </div>
      </div>
    </div>
    <div class="loading">
      <div class="loading-text">
        <span class="loading-text-words">{{'WEB.loader.cargando' | translate}}</span>
      </div>
    </div>
  </div>
  <div class="ui-g-12">
    <h3>{{'WEB.funcionalidaPersonalizacion.titulo' | translate}}</h3>
  </div>
  <div class="ui-g-12 pnl-custom-feature-body">
    <ng-template ngFor let-section [ngForOf]="customFeatureSectionsView">
      <p-fieldset legend="{{section.nombre}}" class="ui-g-12 ui-md-12">
        <ng-container *ngFor="let etiqueta of section.etiquetas;">

          <ng-container *ngIf="!etiqueta.codigo.includes('dashboard_cmn_filtro') 
              && !etiqueta.codigo.includes(preFixEtiquetaHija)">
            <st-etiqueta [etiqueta]="etiqueta">
              <st-default-etiqueta-content [nombreEtiqueta]="etiqueta.nombre"></st-default-etiqueta-content>
              <st-inner-etiqueta-content *ngIf="etiqueta.etiquetaHija" [etiqueta]="etiqueta.etiquetaHija">
              </st-inner-etiqueta-content>
            </st-etiqueta>
          </ng-container>

          <ng-container *ngIf="etiqueta.codigo.includes('dashboard_cmn_filtro')">
            <st-etiqueta [etiqueta]="etiqueta">
              <st-label-etiqueta-content [nombreEtiqueta]="etiqueta.isConfigurable ? etiqueta.nombre : lenguage === 'en' ? etiqueta.nombreEn : etiqueta.nombre"></st-label-etiqueta-content>
              <!--<st-label-etiqueta-content [nombreEtiqueta]="lenguage === 'en' ? etiqueta.nombreEn : etiqueta.nombre"></st-label-etiqueta-content>-->
            </st-etiqueta>
          </ng-container>

        </ng-container>
      </p-fieldset>
    </ng-template>
  </div>
  <div class="ui-g-12 d-flex justify-content-between">
    <button class="btn btn-primary ui-g-6 m-1" (click)="saveOptions()" ng-disabled="blocked">
      <span> <i class="fa fa-fw fa-save"></i> {{'COMMON.boton.guardar' | translate}} </span>
    </button>

    <button class="btn btn-black ui-g-6 m-1" (click)="closeOptionsEmitter()">
      <span> <i class="fa fa-fw fa-close"></i> {{'COMMON.boton.cerrar' | translate}} </span>
    </button>
  </div>
</div>