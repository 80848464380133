import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class ContactoService {

constructor(private http : HttpClient2) { }

  listar(sedeId : number){
    return this.http.listar("/"+sedeId + "/contactos");    
  }
}
