<div id="header" class="ui-dialog-titlebar ui-helper-clearfix ui-corner-top">
    <span class="ui-dialog-title" id="ui-dialog-4-label">{{'WEB.notificacion.titulo-conversacion' | translate }}</span>
    <div class="ui-dialog-titlebar-icons">        
        <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all" (click)="cerrarPanel($event);"><span class="pi pi-times"></span></a>
    </div>
</div>

<div id="body">
    <div [className]="mensajes?.length != 0 ? 'content-marco-principal' : 'content-marco-principal-vacio'" >
        <div *ngFor="let item of mensajes" [className]="item.estado == 1 ? 'activo' : 'inactivo'" (click)="onClickOpciones(item)">
            <div class="d-flex justify-content-between" >
                <div class="seccion-header-1">
                    <img src="assets/svg/icons/notificacion_web_black.svg" style="width: 12px; " />
                    {{'WEB.notificacion.datos-conversacion.transportista' | translate }}
                     {{ item.usuarioNombre }}
                     <img *ngIf="item.estado == 1" src="assets/svg/icons/notificacion_circulo.svg" style="width: 9px; " />
                     <br>&nbsp;&nbsp;&nbsp;&nbsp;
                     {{'WEB.notificacion.datos-conversacion.viaje' | translate }}
                     {{ item.codigoViaje }}
                </div>
                <div class="seccion-header-2">
                    {{'WEB.notificacion.datos-conversacion.ultimo-mensaje' | translate }}
                    {{ item.fechaEnvio | date:'HH:mm:ss' }}
                </div>
                
            </div>
            <hr>
            <div class="seccion-body">
                {{ item.texto || ('WEB.chat.mensajes.imagen-recibida' | translate)}}
            </div>
            
        </div>
    </div>
</div>